import { xlate } from "../../../commonUtil/i18n/locales";

export let DaysOfWeekOptions = [
  { value: "0", label: "Sunday", abbr: "Sun" },
  { value: "1", label: "Monday", abbr: "Mon" },
  { value: "2", label: "Tuesday", abbr: "Tue" },
  { value: "3", label: "Wednesday", abbr: "Wed" },
  { value: "4", label: "Thursday", abbr: "Thu" },
  { value: "5", label: "Friday", abbr: "Fri" },
  { value: "6", label: "Saturday", abbr: "Sat" }
];

export function initialStoreHours() {
  DaysOfWeekOptions = [
    {
      value: "0",
      label: xlate("xmm.portal.common.sunday"),
      abbr: " ".concat(xlate("xmm.portal.abbreviation.sunday"))
    },
    {
      value: "1",
      label: xlate("xmm.portal.common.monday"),
      abbr: " ".concat(xlate("xmm.portal.abbreviation.monday"))
    },
    {
      value: "2",
      label: xlate("xmm.portal.common.tuesday"),
      abbr: " ".concat(xlate("xmm.portal.abbreviation.tuesday"))
    },
    {
      value: "3",
      label: xlate("xmm.portal.common.wednesday"),
      abbr: " ".concat(xlate("xmm.portal.abbreviation.wednesday"))
    },
    {
      value: "4",
      label: xlate("xmm.portal.common.thursday"),
      abbr: " ".concat(xlate("xmm.portal.abbreviation.thursday"))
    },
    {
      value: "5",
      label: xlate("xmm.portal.common.friday"),
      abbr: " ".concat(xlate("xmm.portal.abbreviation.friday"))
    },
    {
      value: "6",
      label: xlate("xmm.portal.common.saturday"),
      abbr: " ".concat(xlate("xmm.portal.abbreviation.saturday"))
    }
  ];
}
