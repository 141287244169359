/* eslint-disable no-console */
import React from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import Nav from "react-bootstrap/lib/Nav";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import Tooltip from "@cx/ui/Tooltip";
import IconHelpOutline from "@cx/ui/Icons/IconHelpOutline";
import IconPublic from "@cx/ui/Icons/IconPublic";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { AppContext, AppConsumer } from "../app-context";
import { namespace, redirectToDefaultPage } from "../../api/xmmAxios";
import Button from "@cx/ui/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Popover from "@cx/ui/Popover";
import Badge from "@cx/ui/Badge";
import AboutUsDialog from "../Header/AboutUsDialog";
import { loadPageByWebkey } from "../../commonUtil/utils/browser";
import { doesEmpty, isEmpty } from "../../commonUtil/utils/object";
import { xlate } from "../../commonUtil/i18n/locales";
import * as gtmEvents from "../utils/gtag-eventlist";
import SlidingDrawerPage from "../../commonUtil/components/SlidingDrawerPage";
import uiConfig from "../../commonUtil/config/UIConfig.json";
import Beamer from "./Beamer";

export default class Header extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    const { baseLocale, locale } = context;
    let code = "EN";
    if (!doesEmpty(locale)) {
      console.log("Header locale", baseLocale, locale);
      const lang = locale.split("_");
      code = lang[0].toUpperCase();
    }
    this.state = {
      isActive: false,
      locale,
      languageCode: code,
      showSlide: false,
      sliderWidth: 500,
      showDealerSlide: false,
      dealerSliderWidth: 500,
      disableAction: false,
      user: context.user,
      dealer: context.dealer,
      scopedDealer: context.scopedDealer
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleHeader = this.toggleHeader.bind(this);
    this.openAboutUsDialog = this.openAboutUsDialog.bind(this);
    this.closeAboutUsDialog = this.closeAboutUsDialog.bind(this);
    this.launchLivePortal = this.launchLivePortal.bind(this);
    this.launchStagingPortal = this.launchStagingPortal.bind(this);
    this.openTrainingPage = this.openTrainingPage.bind(this);
    this.openSupportPage = this.openSupportPage.bind(this);
    this.toggleLang = this.toggleLang.bind(this);
    this.showSliderPage = this.showSliderPage.bind(this);
    this.closeSliderPage = this.closeSliderPage.bind(this);
  }

  /**
   * Add event listener
   */
  componentDidMount() {}

  // Testing - Handler to show hide title when logo clicked
  toggleHeader = () => {
    this.setState(prevState => {
      return { isActive: !prevState.isActive };
    });
  };

  launchLivePortal = () => {
    const { isStaging, locale, webKey } = this.context;
    if (isStaging) {
      gtmEvents.trackCatalogs("live");
      loadPageByWebkey(webKey, locale);
    }
  };

  launchStagingPortal = () => {
    const { isStaging, locale, stagingMakeList, webKey } = this.context;
    if (!isStaging && stagingMakeList && stagingMakeList.length !== 0) {
      gtmEvents.trackCatalogs("staging");
      loadPageByWebkey(webKey, locale, true);
    }
  };
  openAboutUsDialog = () => {
    this.setState({
      showAboutUsDialog: true
    });
  };

  closeAboutUsDialog = () => {
    this.setState({ showAboutUsDialog: false });
  };
  openTrainingPage = () => {
    const loginUrl = "https://login.xtime.com/?pt=ht";

    // target window reference
    let winRef = null;
    if (winRef && !winRef.closed) {
      // Window is open, refresh it
      winRef.location.reload(true);
    } else {
      winRef = window.open(loginUrl, "_XtimeUniversity");
    }
  };
  openSupportPage = () => {
    const supportUrl = "https://casfx.my.site.com/xtime/s/"

    // target window reference
    let winRef = null;
    if (winRef && !winRef.closed) {
      // Window is open, refresh it
      winRef.location.reload(true);
    } else {
      winRef = window.open(supportUrl, "_CustomerSupport");
    }
  };
  /* This renders table with make, pricing workflow as popOver */
  renderMakesTooltip() {
    const { makelist } = this.context;
    const posts = makelist.map(post => {
      const pricingMethod = post.pricingMethod;
      const workflow =
        pricingMethod === 1
          ? xlate("xmm.portal.common.calculated_lbl")
          : pricingMethod === 2
          ? xlate("xmm.portal.common.flat_lbl")
          : xlate("xmm.portal.common.no_pricing_lbl");
      return (
        <tr className="ant-table-row" key={post.make}>
          <td>
            <div>{post.make}</div>
          </td>
          <td>
            <span className="float-right">{workflow}</span>
          </td>
        </tr>
      );
    });
    const main = (
      <div className="plain-container">
        {xlate("xmm.portal.header.mixed_pricing_help")}
        <table width="100%">
          <thead className="ant-table-thead">
            <tr>
              <th>{""}</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody>{posts}</tbody>
        </table>
      </div>
    );
    return main;
  }
  getMixedBadge() {
    const { mixedPricing } = this.context;
    const mixedPricingTip = (
      <div>
        <b>{xlate("xmm.portal.header.mixed_pricing_lbl")}</b>
        <div className="xmm-popover-text">{this.renderMakesTooltip()}</div>
      </div>
    );
    const clsHide = mixedPricing ? "xmm-popover-badge" : "hide";
    return (
      <div className="header-manage-badge">
        <div className={clsHide}>
          <Popover
            htmlId="mixedPopover"
            trigger={["click", "outsideClick"]}
            popoverContent={mixedPricingTip}
          >
            <Badge htmlId="mixedBadge" className="hand-cursor">
              {xlate("xmm.portal.header.mixed_pricing_lbl")}
            </Badge>
          </Popover>
        </div>
      </div>
    );
  }
  getLiveAndStagedCatalogsButtonGroup(isStaging, liveCount, stagedCount) {
    if (
      liveCount === undefined ||
      stagedCount === undefined ||
      stagedCount === 0
    ) {
      return "";
    }
    const { localeStrings } = this.context;
    const stagingCatalogText =
      "Use Staged Mode to set up Catalogs that are transitioning to Parts & Labor Menu Pricing.  Once these catalogs are fully configured, please contact your account manager to go Live.";
    const liveCountStr = `${localeStrings["xmm.portal.nav.live"]} (${liveCount})`;
    const stagedCountStr = `${localeStrings["xmm.portal.nav.staged"]} (${stagedCount})`;
    const disableLive = liveCount === 0 || isStaging;
    const liveCatalogClassname = disableLive
      ? "header-catalogs-button"
      : "header-catalogs-button-selected";
    const stagedCatalogClassname = !disableLive
      ? "header-catalogs-button"
      : "header-catalogs-button-selected";

    return (
      <div className="header-manage-catalogs">
        <span className="header-manage-catalogs-text">
          {/* {localeStrings["xmm.portal.nav.manage_catalogs"]} */}
        </span>
        <ButtonGroup className="header-catalogs">
          <Button
            className={liveCatalogClassname}
            htmlId="liveCatalogsLink"
            size="small"
            buttonStyle="secondary"
            disabled={liveCount === 0}
            onClick={() => {
              this.launchLivePortal();
            }}
          >
            <Tooltip htmlId="tooltipLiveCount" tooltipContent={""}>
              {liveCountStr}
            </Tooltip>
          </Button>
          <Button
            className={stagedCatalogClassname}
            htmlId="stagedCatalogsLink"
            size="small"
            buttonStyle="secondary"
            onClick={() => {
              this.launchStagingPortal();
            }}
          >
            <Tooltip
              htmlId="tooltipStagedCount"
              tooltipContent={stagingCatalogText}
            >
              {stagedCountStr}
            </Tooltip>
          </Button>
        </ButtonGroup>
      </div>
    );
  }
  /* This will set selected locale to App context and reload page */
  toggleLang = (event, locale) => {
    const { reloadPageWithLocale } = this.context;
    const lang = locale.split("_");
    const languageCode = lang[0].toUpperCase();
    this.setState({
      languageCode
    });
    reloadPageWithLocale(locale);
  };
  renderMenuLocales = () => {
    const { languages } = this.context;
    const items = [];
    if (!isEmpty(languages) && languages.length > 1) {
      languages.forEach(obj => {
        items.push(
          <MenuItem
            key={obj.value}
            onClick={event => {
              event.preventDefault();
              this.toggleLang(event, obj.value);
            }}
          >
            {obj.label}
          </MenuItem>
        );
      });
    }
    // console.log("render", languages);
    return items;
  };
  showSliderPage = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      disableAction: true
    }));
  };
  /* Close Handler for Slider and click event outside the drawer
                    use this context callback to show speed bump when Form is dirty
                  */
  closeSliderPage = () => {
    this.setState({
      showSlide: false,
      disableAction: false
    });
  };
  showDealerSliderPage = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showDealerSlide: !prevState.showDealerSlide,
      disableAction: true
    }));
  };
  /* Close Handler for Slider and click event outside the drawer
                    use this context callback to show speed bump when Form is dirty
                  */
  closeDealerSliderPage = () => {
    this.setState({
      showDealerSlide: false,
      disableAction: false
    });
  };
  changeDealer = event => {
    const dealer = event.target.value[0].value;
    loadPageByWebkey(dealer.webKey);
  };
  getScopedDealers = scopedDealers => {
    if (scopedDealers) {
      const scopeDealersList = [...scopedDealers];

      return scopeDealersList;
    }
    return [];
  };
  render() {
    const { isStaging, liveCount, stagedCount, localeStrings, languages } =
      this.context;
    const clsHideMenu =
      isEmpty(languages) || languages.length === 1
        ? "navbar-icon-caret no-dropdown"
        : "navbar-icon-caret";
    const mixedPricingBadge = this.getMixedBadge();
    const liveAndStagedButtonGroup = this.getLiveAndStagedCatalogsButtonGroup(
      isStaging,
      liveCount,
      stagedCount
    );
    const aboutUsModal = (
      <AboutUsDialog
        show={this.state.showAboutUsDialog}
        closeDialog={this.closeAboutUsDialog}
      />
    );
    const navDropdownHelp = <IconHelpOutline className="header-icon" />;
    const navDropdownLanguage = (
      <div>
        <span className="nav-language-text">{this.state.languageCode}</span>
        <IconPublic className="header-icon" />{" "}
      </div>
    );
    const { user, webKey, getClassWithBridgeBar } = this.context;
    const { userName } = user;
    const hostname = window.location.hostname;
    const scopedDealersList = this.getScopedDealers(this.context.scopedDealers);
    let appUrl = "";
    let quotingUrl = "";
    if (hostname.indexOf("localhost") !== -1) {
      appUrl = uiConfig["dev5"].serviceQuotingUrl;
    } else {
      appUrl = uiConfig[namespace].serviceQuotingUrl;
    }
    if (appUrl && appUrl.length > 0) {
      quotingUrl =
        appUrl + `/?userName=${userName}&webKey=${webKey}&appSource=SCH`;
    }
    const content = !quotingUrl ? (
      <div className="xmm-content-adjust">
        <h3>
          Application cannot be launched. Please check user permissions and
          launch parameters, or else contact Xtime support.
        </h3>
      </div>
    ) : (
      <embed
        src={quotingUrl}
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    );
    const quoteSlider = (
      <SlidingDrawerPage
        title="New Quote"
        htmlId="QuotingSlider"
        showSlide={this.state.showSlide}
        toggleDrawer={this.closeSliderPage}
        sliderWidth={this.state.sliderWidth}
        skipHide={false}
        closeButton={true}
        className= {getClassWithBridgeBar("xmm-slider")}
      >
        {content}
      </SlidingDrawerPage>
    );
    const contentDealerSelector = (
      <div>
        <SearchableSelect
          htmlId="delaerFilter"
          name="dealerFilter"
          label={" "}
          enableMultiSelect={false}
          displayDeselectOption={false}
          displayPlaceholder={false}
          displayLabel={false}
          onChange={this.changeDealer}
          options={scopedDealersList}
          placeholder={this.context.dealer.dealerName}
        />
      </div>
    );
    const dealerSelectorSlider = (
      <SlidingDrawerPage
        title="Select a Dealership"
        htmlId="dealerSelectorSlider"
        showSlide={this.state.showDealerSlide}
        toggleDrawer={this.closeDealerSliderPage}
        flexWidth={false}
        sliderWidth={this.state.dealerSliderWidth}
        skipHide={false}
        closeButton={true}
        className={getClassWithBridgeBar("xmm-slider")}
      >
        {contentDealerSelector}
      </SlidingDrawerPage>
    );
    const quoteMenu =
      namespace.indexOf("dev5") !== -1 || namespace.indexOf("qa5") !== -1 ? (
        <MenuItem onClick={this.showSliderPage}>New Quote</MenuItem>
      ) : null;
    return (
      <AppConsumer>
        {context => (
          <Navbar fluid={true} staticTop={true} className="header-bar">
            <Navbar.Header className="header-bar__nav-header">
              <Navbar.Brand className="header-bar__nav-brand">
                <img
                  alt=""
                  className="header-bar__nav-logo"
                  src={require("../../images/xtime-logo-small.png")}
                />
                {localeStrings["xmm.portal.common.app_name"]}
              </Navbar.Brand>
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav className="float-right">
                <MenuItem
                  title={context.dealer.dealerName}
                  id="dealer-nav-dropdown"
                  pullRight
                  onToggle={() => {
                    /* Do nothing. This prop must be declared to supress a warning. */
                  }}
                  onClick={this.showDealerSliderPage}
                >
                  <span className="xmm-dealer-selector">
                    {context.dealer.dealerName}{" "}
                    <IconKeyboardArrowDown htmlId="IconKeyboardArrowDown" />
                  </span>
                </MenuItem>
                <NavDropdown
                  title={navDropdownLanguage}
                  eventKey={this.state.languageCode}
                  id="language-nav-dropdown"
                  className={clsHideMenu}
                  onToggle={() => {
                    /* Do nothing. This prop must be declared to supress a warning. */
                  }}
                >
                  {this.renderMenuLocales()}
                </NavDropdown>
                <NavDropdown
                  title={navDropdownHelp}
                  id="help-nav-dropdown"
                  className="navbar-dropdown-icon"
                  onToggle={() => {
                    /* Do nothing. This prop must be declared to supress a warning. */
                  }}
                >
                  <MenuItem onClick={this.openAboutUsDialog}>
                    {localeStrings["xmm.portal.nav.about"]}
                  </MenuItem>
                  <MenuItem onClick={this.openTrainingPage}>
                    {localeStrings["xmm.portal.nav.training"]}
                  </MenuItem>
                  <MenuItem onClick={this.openSupportPage}>
                    {localeStrings["xmm.portal.nav.customer_support"]}
                  </MenuItem>
                  {/* hide quote menu reference */}
                  {/* {quoteMenu} */}
                </NavDropdown>
                <NavDropdown
                  title={context.user.userName}
                  id="user-nav-dropdown"
                  pullRight
                  onToggle={() => {
                    /* Do nothing. This prop must be declared to supress a warning. */
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("refreshToken");
                      redirectToDefaultPage();
                    }}
                  >
                    Close
                  </MenuItem>
                </NavDropdown>
              </Nav>
              {mixedPricingBadge}
              {liveAndStagedButtonGroup}
            </Navbar.Collapse>
            {aboutUsModal}
            {quoteSlider}
            {dealerSelectorSlider}
          </Navbar>
        )}
      </AppConsumer>
    );
  }
}
