import React from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import { useServiceSearchContext } from "../../state/service-search.context";
import isEmpty from "lodash/isEmpty";
import "./CustomOperationFooter.scss";

const CustomOperationFooter = () => {
  // const { onAddCustomOperationClick } = props;
  const { state } = useServiceSearchContext();
  const { appConfig } = state;
  const hasAccess = !isEmpty(appConfig) && appConfig.customOperationPage;
  const handleAddCustomOperation = () => {
    // @todo: disable handler for add service page until Tellus more page added
    // onAddCustomOperationClick();
  };
  const labelTxt = "Can't find what you need?";
  const btnTxt = "Add custom operation";
  const content = !hasAccess ? (
    ""
  ) : (
    <div id="customOperationFooterContainer">
      <span id="footerLabel">{labelTxt}</span>
      <Button
        htmlId="customOperationButton"
        type="button"
        size="sm"
        buttonStyle="link"
        onClick={handleAddCustomOperation}
      >
        {btnTxt}
      </Button>
    </div>
  );
  return <>{content}</>;
};

CustomOperationFooter.defaultProps = {
  onAddCustomOperationClick: () => {}
};

CustomOperationFooter.propTypes = {
  onAddCustomOperationClick: PropTypes.func
};

export default CustomOperationFooter;
