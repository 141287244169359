/* eslint-disable react/sort-comp */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import { bool, PropTypes } from "prop-types";
import Button from "@cx/ui/Button";
import RadioButtonList from "@cx/ui/RadioButtonList";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import SearchableSelect from "@cx/ui/SearchableSelect";
import ModalDialog from "@cx/ui/ModalDialog";
import { FormattedMessage } from "react-intl";
import { OperationContext } from "../../operation-context";

export default class CopyDealerOperationsModal extends React.Component {
  static contextType = OperationContext;
  constructor(props, context) {
    super(props, context);
    this.onMakeChange = this.onMakeChange.bind(this);
    this.onChangeCopyValue = this.onChangeCopyValue.bind(this);
    this.state = {
      make: "",
      disableCopy: false,
      copyType: "1"
    };
  }
  static propTypes = {
    onChange: PropTypes.func
  };

  onChangeCopyValue = cxEvent => {
    const { value } = cxEvent.target;
    this.setState({
      copyType: value,
      disableCopy: value === "1" ? false : true
    });
  };

  initializeStates = () => {
    this.setState({
      make: [],
      disableCopy: false,
      copyType: "1"
    });
  };

  onMakeChange = event => {
    const { name, value } = event.target;
    // load notifications from cache
    if (value) {
      this.setState({
        [name]: value,
        disableCopy: false
      });
      this.findMake(value);
    }
  };

  findMake() {
    // const rawMakes = this.context.makelist;
  }
  render() {
    const { localeStrings } = this.context;
    const { disableCopy, make, copyType } = this.state;
    return (
      <ModalDialog
        htmlId="copyOperationsModalDialog"
        className="sample-modal-dialog"
        show={this.props.show}
        header={<ModalDialog.Title>{this.props.title}</ModalDialog.Title>}
        footer={
          <div>
            <Button
              htmlId="cancelCopyOpsButton"
              onClick={() => {
                this.initializeStates();
                this.props.closeDialog();
              }}
              text="Cancel"
              buttonStyle="secondary"
            >
              <FormattedMessage
                defaultMessage="Cancel"
                id="xmm.portal.common.cancel_button"
              />
            </Button>
            <Button
              htmlId="copyOpsActionButton"
              text="Copy"
              disabled={disableCopy}
              buttonStyle="primary"
              onClick={() => {
                this.props.copyOperations(make, copyType);
                this.initializeStates();
                this.props.closeDialog();
              }}
            >
              <FormattedMessage
                defaultMessage="Copy"
                id="xmm.portal.common.copy_label"
              />
            </Button>
          </div>
        }
        onHide={this.props.closeDialog}
      >
        <div>
          <RadioButtonList
            className="xmm-centered-radios"
            inline
            htmlId="copyOperationRadioButton"
            name="copyOperationRadioButton"
            label=""
            displayLabel={false}
            onChange={this.onChangeCopyValue}
            value={this.state.copyType}
            options={[
              {
                value: "1",
                label:
                  this.context.localeStrings[
                    "xmm.portal.operations.copy_same_make_label"
                  ]
              },
              {
                value: "2",
                label:
                  this.context.localeStrings[
                    "xmm.portal.operations.copy_different_make_label"
                  ]
              }
            ]}
          />
          <div hidden={this.state.copyType === "2"}>
            <ul>
              <li>
                {localeStrings["xmm.portal.operations.copy_same_make_text1"]}
              </li>
              <li>
                {localeStrings["xmm.portal.operations.copy_same_make_text2"]}
              </li>
              <li>
                {localeStrings["xmm.portal.operations.copy_same_make_text3"]}
              </li>
            </ul>
          </div>
          <div hidden={this.state.copyType === "1"}>
            <Row>
              <Col xs={4} md={4}>
                <SearchableSelect
                  htmlId="makeSelect"
                  label={""}
                  name="make"
                  className="xmm-make-modal"
                  displayDeselectOption={false}
                  displayLabel={false}
                  displayPlaceholder={true}
                  placeholder={
                    localeStrings["xmm.portal.common.select_a_make_lbl"]
                  }
                  onChange={this.onMakeChange}
                  options={this.props.makesList}
                  value={this.state.make}
                  maxHeight={200}
                />
              </Col>
            </Row>
            <ul>
              <li>
                {
                  localeStrings[
                    "xmm.portal.operations.copy_different_make_text1"
                  ]
                }
              </li>
              <li>
                {
                  localeStrings[
                    "xmm.portal.operations.copy_different_make_text2"
                  ]
                }
              </li>
              <li>
                {
                  localeStrings[
                    "xmm.portal.operations.copy_different_make_text3"
                  ]
                }
              </li>
              <li>
                {
                  localeStrings[
                    "xmm.portal.operations.copy_different_make_text4"
                  ]
                }
              </li>
              <li>
                {
                  localeStrings[
                    "xmm.portal.operations.copy_different_make_text5"
                  ]
                }
              </li>
            </ul>
          </div>
        </div>
      </ModalDialog>
    );
  }
}

CopyDealerOperationsModal.propTypes = {
  show: bool,
  makesList: PropTypes.array,
  closeDialog: PropTypes.func,
  copyOperations: PropTypes.func,
  title: PropTypes.object
};
