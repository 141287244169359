/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import PriceInput from "@cx/ui/PriceInput";
import SelectInput from "@cx/ui/SelectInput";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
// import { DisplayFormikState } from "../../../../reusable/helper";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { findRecord, doesEmpty } from "../../../../../commonUtil/utils/object";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
// import { validateFieldError } from "../../../../../commonUtil/utils/error";
import * as formvalidator from "../../../../formik/formvalidator";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import { TypeOptionsLaborRate } from "../../../../../constants/ModuleConstants";

class AddLaborRateFormPage extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    // Add Default filter to show only Calculated pricing workflow supported records.
    const { makelist, makeVariantMap } = context;
    const filterList = [];
    const anyMake = makeVariantMap["ANY"];
    if (anyMake && anyMake.pricingMethod === 1) {
      filterList.push(anyMake);
    }
    makelist.forEach(m => {
      if (m.pricingMethod === 1) {
        filterList.push(m);
      }
    });
    this.onChange = this.onChange.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onMakeChange = this.onMakeChange.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    const typeOptionsLaborRate = [...TypeOptionsLaborRate];
    this.initializeLocaleValues();
    // local state used as cache

    const { commonOpsLaborGridsEnabled } = context.dealer;

    this.state = {
      commonOpsLaborGridsEnabled,
      typeOptionsLaborRate,
      newLaborRate: JSON.parse(JSON.stringify(props.rowRecord)),
      editOption: props.editOption,
      laborRates: props.laborRates,
      supportedMakes: filterList,
      // This holds selected Make record
      selectedMake: {
        make: "",
        variant: "",
        dealerCode: context.dealer.dealerCode
      },
      dirty: false,
      valid: false,
      errors: {
        make: "",
        laborRateCode: "",
        description: "",
        unpublishedPackageRate: "",
        unpublishedStandaloneRate: "",
        laborRateType: ""
      },
      showRateInputs: true
    };
  }

  componentDidMount() {
    this.preSelectMake();
  }
  initializeLocaleValues() {
    this.selectLabel = xlate("xmm.portal.common.select_a_make_lbl");
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.makeErrorMsg = xlate("xmm.portal.errors.make_is_required");
    this.typeErrorMsg = xlate("xmm.portal.errors.type_is_required");
    this.codeErrorMsg = xlate("xmm.portal.errors.code_is_required");
    this.descriptionErrorMsg = xlate(
      "xmm.portal.errors.description_is_required"
    );
    this.maxPriceErrorMsg = xlate("xmm.portal.errros.rank.max_valid");
    this.packageRateErrorMsg = xlate(
      "xmm.portal.errors.package_rate_is_required"
    );
    this.alacarteErrorMsg = xlate(
      "xmm.portal.errors.alacarte_rate_is_required"
    );
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.duplicateRateCodeError = xlate(
      "xmm.portal.errors.miissing_labor_rate_code_or_description"
    );
  }
  // CX PriceInput - blur event not exposed; Hence handle blur event inside onChange()
  onChangePrice = (event, isValid, domEvent) => {
    const { name, value } = event.target;
    if (domEvent && domEvent.type === "blur") {
      this.onBlur(name);
      return;
    }
    if (
      domEvent &&
      domEvent.type !== "blur" &&
      isDifferentValue(this.state.newLaborRate[name], value)
    ) {
      this.setState(
        prevState => {
          return {
            newLaborRate: {
              ...prevState.newLaborRate,
              [name]: value
            }
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };

  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };

  // changeEvent for textfield
  onChange = (event, isValid, domEvent) => {
    const { name, value } = event.target;
    if (domEvent && domEvent.type === "blur") {
      this.onBlur(name);
      return;
    }
    const { newLaborRate } = this.state;
    if (
      domEvent &&
      domEvent.type !== "blur" &&
      isDifferentValue(newLaborRate[name], value.trim())
    ) {
      this.setState(
        prevState => {
          return {
            newLaborRate: {
              ...prevState.newLaborRate,
              [name]: value.trim()
            }
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };

  /* This method will pre-select make in case we have one make; else default to "All Makes"  */
  preSelectMake() {
    // const rawMakes = this.context.makelist.filter(function (obj) {
    //   return obj.pricingMethod === 1;
    // });
    const { supportedMakes } = this.state;
    if (supportedMakes && supportedMakes.length === 1) {
      const selectedMake = supportedMakes[0];
      const make = !doesEmpty(selectedMake) ? selectedMake.value : "";
      // When dealer has single Make, call rest API to get opcode status
      this.setState(
        {
          selectedMake,
          make,
          showMask: false // false - to show loading
        },
        () => {
          // Do nothing
        }
      );
    } else if (supportedMakes.length > 1) {
      // Add Default filter to show only Calculated pricing workflow supported records.
      this.setState({
        // supportedMakes: filterList,
        showMask: true
      });
    }
  }

  onMakeChange = event => {
    const { name, value } = event.target;
    if (isDifferentValue(this.state.newLaborRate[name], value)) {
      this.setState(
        prevState => {
          return {
            newLaborRate: {
              ...prevState.newLaborRate,
              [name]: value
            },
            showMask: true // false - to show loading icon
          };
        },
        () => {
          this.markDirty(name, true);
          this.findMake(value);
        }
      );
    }
  };
  /* This method returns make obj from make selection */
  findMake(makeName) {
    const rawMakes = this.state.supportedMakes;
    const property = "make";
    const selectedMake = findRecord(rawMakes, property, makeName);
    if (typeof selectedMake === "object") {
      this.setState(
        prevState => {
          const { errors } = prevState;

          return {
            newLaborRate: {
              ...prevState.newLaborRate,
              dealerLaborRateCodeId: "",
              make: selectedMake.make,
              dealerCode: selectedMake.dealerCode,
              variant: selectedMake.variant
            },
            showMask: true, // false - to show loading icon
            selectedMake,
            errors
          };
        },
        () => {
          // callback if any
        }
      );
    }
  }
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
  }
  isValidForm() {
    const {
      make,
      description,
      laborRateCode,
      unpublishedPackageRate,
      unpublishedStandaloneRate,
      laborRateType
    } = this.state.newLaborRate;
    const { commonOpsLaborGridsEnabled } = this.context.dealer;
    if (commonOpsLaborGridsEnabled && !laborRateType) {
      return false;
    }
    if (!make) {
      return false;
    }
    if (
      !description ||
      formvalidator.validateAlphaNumPunctuation(description)
    ) {
      return false;
    }
    if (
      !laborRateCode ||
      formvalidator.validateAlphaNumPunctuation(laborRateCode)
    ) {
      return false;
    }
    if (
      make !== "ANY" &&
      ((commonOpsLaborGridsEnabled && laborRateType === "0") ||
        !commonOpsLaborGridsEnabled) &&
      (!unpublishedPackageRate ||
        (parseFloat(unpublishedPackageRate) || 0) > 9999.99)
    ) {
      return false;
    }
    if (
      ((commonOpsLaborGridsEnabled && laborRateType === "0") ||
        !commonOpsLaborGridsEnabled) &&
      (!unpublishedStandaloneRate ||
        (parseFloat(unpublishedStandaloneRate) || 0) > 9999.99)
    ) {
      return false;
    }
    return true;
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const {
      make,
      description,
      laborRateCode,
      unpublishedPackageRate,
      unpublishedStandaloneRate,
      laborRateType
    } = this.state.newLaborRate;
    const { errors } = this.state;
    if (!fieldName || fieldName === "make") {
      errors["make"] = !make ? this.makeErrorMsg : "";
    }
    if (!fieldName || fieldName === "laborRateType") {
      errors["laborRateType"] = !laborRateType ? this.typeErrorMsg : "";
    }
    if (!fieldName || fieldName === "laborRateCode") {
      errors["laborRateCode"] = !laborRateCode ? this.codeErrorMsg : "";
      if (errors["laborRateCode"] === "") {
        errors["laborRateCode"] =
          formvalidator.validateAlphaNumPunctuation(laborRateCode);
      }
    }
    if (!fieldName || fieldName === "description") {
      errors["description"] = !description ? this.descriptionErrorMsg : "";
      if (errors["description"] === "") {
        errors["description"] =
          formvalidator.validateAlphaNumPunctuation(description);
      }
    }
    if (!fieldName || fieldName === "unpublishedPackageRate") {
      errors["unpublishedPackageRate"] = !unpublishedPackageRate
        ? this.packageRateErrorMsg
        : "";
      if (
        unpublishedPackageRate &&
        (parseFloat(unpublishedPackageRate) || 0) > 9999.99
      ) {
        errors["unpublishedPackageRate"] = this.maxPriceErrorMsg;
      }
    }
    if (!fieldName || fieldName === "unpublishedStandaloneRate") {
      errors["unpublishedStandaloneRate"] = !unpublishedStandaloneRate
        ? this.alacarteErrorMsg
        : "";
      if (
        unpublishedStandaloneRate &&
        (parseFloat(unpublishedStandaloneRate) || 0) > 9999.99
      ) {
        errors["unpublishedStandaloneRate"] = this.maxPriceErrorMsg;
      }
    }

    const valid = this.isValidForm();
    this.setState({ valid, errors });
    return valid;
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  resetState() {
    this.setState({
      newLaborRate: JSON.parse(JSON.stringify(this.props.rowRecord)),
      selectedMake: {
        make: "",
        variant: "",
        dealerCode: ""
      },
      // state for disable fields
      dirty: false,
      valid: false,
      errors: {
        make: "",
        description: "",
        laborRateCode: "",
        unpublishedPackageRate: "",
        unpublishedStandaloneRate: "",
        laborRateType: ""
      }
    });
  }
  getAddPayload() {
    // console.log("payload", this.state.newLaborRate);
    /*
    const { dealerCode, make, variant } = this.state.selectedMake;
    const payload = {
      dealerLaborRateCodeId: "",
      make,
      variant,
      dealerCode,
      laborRateCode: "M1",
      description: "Maintenance1",
      publishedPackageRate: "0.00",
      unpublishedPackageRate: "12",
      publishedStandaloneRate: "0.00",
      unpublishedStandaloneRate: "12"
    };
    */
    return this.state.newLaborRate;
  }
  foundDuplicates() {
    const datalist = this.state.laborRates;
    const { make, laborRateCode, description } = this.state.newLaborRate;
    let i;
    for (i = 0; i < datalist.length; i++) {
      const s = datalist[i];
      if (
        s.make === make &&
        (s.laborRateCode.toUpperCase() === laborRateCode.toUpperCase() ||
          s.description.toUpperCase() === description.toUpperCase())
      ) {
        // console.log("Found duplicates", make, laborRateCode, description);
        // const { localeStrings } = this.context;
        // const errMsg =
        //   localeStrings[
        //     "xmm.portal.errors.miissing_labor_rate_code_or_description"
        //   ];
        // toast.error(errMsg, {
        //   closeOnClick: true
        // });
        return true;
      }
    }
    return false;
  }
  // TODO - first validate duplicates for code, description per selected make; then proceed
  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors } = this.state;
    const payload = this.getAddPayload();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    if (this.foundDuplicates()) {
      this.updateStatusBox(this.duplicateRateCodeError, "warning", false);
      return;
    }
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      // toast.warning(this.correctInvalidFielsError);
      this.updateStatusBox(this.correctInvalidFielsError, "warning", false);
      return;
    }
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/table/dealerLaborRateCode";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) {
          // console.log(data);
          if (
            data.dealerLaborRateCode &&
            typeof data.dealerLaborRateCode === "object"
          ) {
            const dealerLaborRateCode = data.dealerLaborRateCode;
            this.updateStatusBox(this.savedMsg, "success", true);
            // callback grid to add record
            this.props.updateLaborRateAfterSave(dealerLaborRateCode);
            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.hideSlider();
                this.resetState();
              }, 2000);
            });
          }
          // error case
          if (!data.success) {
            this.updateStatusBox(this.saveErrorMsg, "error", false, true);
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.laborrates.add_rate_click");
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    let editSection = null;
    const msgSection = null;
    const {
      commonOpsLaborGridsEnabled,
      newLaborRate,
      valid,
      errors,
      typeOptionsLaborRate
    } = this.state;
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    const isValid = valid;
    if (this.props.editForm) {
      // show form when edit clicked or add clicked
      editSection = (
        <React.Fragment>
          <div className="xmm-tab-bar">
            {statusBox} <span className="xmm-msg">{msgSection}</span>
            <Button
              htmlId="saveAction"
              buttonStyle="primary"
              disabled={!isValid}
              onClick={this.saveHandler}
            >
              {this.saveLabel}
            </Button>
          </div>
          <Card htmlId="theCard" className="labor-rate-form-container">
            <div className="comment-form">
              <Grid htmlId="settingGrid">
                <Row className="show-grid">
                  <Col xs={4} md={4}>
                    <span className="text-float-right">
                      {this.context.localeStrings["xmm.portal.grid.make"]}{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <SelectInput
                      htmlId="make"
                      label={this.context.localeStrings["xmm.portal.grid.make"]}
                      name="make"
                      placeholder={this.selectLabel}
                      displayDeselectOption={false}
                      onChange={this.onMakeChange}
                      displayLabel={false}
                      options={this.state.supportedMakes}
                      value={newLaborRate.make}
                      error={errors.make}
                      maxHeight={200}
                    />
                  </Col>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {
                        this.context.localeStrings[
                          "xmm.portal.parts_labor.grid_code"
                        ]
                      }{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="laborRateCode"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.parts_labor.grid_code"
                        ]
                      }
                      name="laborRateCode"
                      maxLength={10}
                      minLength={1}
                      required
                      value={newLaborRate.laborRateCode}
                      onChange={this.onChange}
                      error={this.state.errors.laborRateCode}
                      displayLabel={false}
                      // getError={validateFieldError}
                    />
                  </Col>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {
                        this.context.localeStrings[
                          "xmm.portal.grid.description"
                        ]
                      }{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="description"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.grid.description"
                        ]
                      }
                      name="description"
                      required
                      value={newLaborRate.description}
                      maxLength={32}
                      minLength={1}
                      onChange={this.onChange}
                      error={this.state.errors.description}
                      displayLabel={false}
                      // getError={validateFieldError}
                    />
                  </Col>
                  {!!commonOpsLaborGridsEnabled && (
                    <>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {
                            this.context.localeStrings[
                              "xmm.portal.grid.type_lbl"
                            ]
                          }{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <SelectInput
                          htmlId="laborRateType"
                          name="laborRateType"
                          label="laborRateType"
                          placeholder="Select a Type"
                          options={typeOptionsLaborRate}
                          value={newLaborRate.laborRateType}
                          error={errors.laborRateType}
                          errorMessage={this.state.errors.laborRateType}
                          onChange={this.onChange}
                          displayLabel={false}
                          displayDeselectOption={false}
                        />
                        {(!commonOpsLaborGridsEnabled ||
                          newLaborRate.laborRateType === "1") && (
                          <>
                            <span className="float-left message-lbl-container">
                              {
                                this.context.localeStrings[
                                  "xmm.portal.grid.message_lbl_rate"
                                ]
                              }
                            </span>
                          </>
                        )}
                      </Col>
                    </>
                  )}
                  {(!commonOpsLaborGridsEnabled ||
                    newLaborRate.laborRateType === "0") && (
                    <>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {
                            this.context.localeStrings[
                              "xmm.portal.parts_labor.grid_package_rate"
                            ]
                          }{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <PriceInput
                          htmlId="unpublishedPackageRate"
                          label={
                            this.context.localeStrings[
                              "xmm.portal.parts_labor.grid_package_rate"
                            ]
                          }
                          name="unpublishedPackageRate"
                          displayLabel={false}
                          onChange={this.onChangePrice}
                          required
                          value={newLaborRate.unpublishedPackageRate}
                          maxLength={7}
                          minLength={1}
                          error={this.state.errors.unpublishedPackageRate}
                        />
                      </Col>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {
                            this.context.localeStrings[
                              "xmm.portal.parts_labor.grid_a_la_carte_rate"
                            ]
                          }{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={6} md={6}>
                        <PriceInput
                          htmlId="unpublishedStandaloneRate"
                          label={
                            this.context.localeStrings[
                              "xmm.portal.parts_labor.grid_a_la_carte_rate"
                            ]
                          }
                          name="unpublishedStandaloneRate"
                          displayLabel={false}
                          onChange={this.onChangePrice}
                          required
                          value={newLaborRate.unpublishedStandaloneRate}
                          maxLength={7}
                          minLength={1}
                          error={this.state.errors.unpublishedStandaloneRate}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Grid>

              {/* <DisplayFormikState {...formstate} /> */}
            </div>
          </Card>
        </React.Fragment>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default AddLaborRateFormPage;

AddLaborRateFormPage.propTypes = {
  rowRecord: PropTypes.object,
  operationName: PropTypes.string,
  editForm: PropTypes.bool,
  editOption: PropTypes.string,
  laborRates: PropTypes.array,
  hideSlider: PropTypes.func,
  updateLaborRateAfterSave: PropTypes.func
};

/* eslint-enable no-console */
