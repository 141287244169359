import React from "react";
export const OperationContext = React.createContext({
  // localeStrings: {},
  webKey: "",
  dealerCode: "",
  userName: "",
  editOption: null, // set as edit, add, delete
  loadOperation: {},
  selectionlist: [],
  operations: [],
  serviceKinds: ["repair", "maintenance", "autorecall"], // set default values used in grid dropdown
  filterConfig: {},
  sortConfig: [],
  /* Implement these handlers in context provider scope */
  updateGridAfterSave: () => {},
  updateGridAfterBulkEdit: () => {},
  deleteSingleOperation: () => {}
});
