/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-console */
import React from "react";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import { bool, PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import SelectInput from "@cx/ui/SelectInput";
import { OperationContext } from "../../operation-context";
export default class ExportDealerOperationsModal extends React.Component {
  static contextType = OperationContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      makes: [],
      exportDisable: true
    };
  }

  onChange = cxEvent => {
    const { value } = cxEvent.target;
    this.setState({ make: value });
    if (value === "") {
      this.setState({
        exportDisable: true
      });
    } else {
      this.setState({
        exportDisable: false
      });
    }
  };

  render() {
    const exportOperations = this.props.exportOperations;
    const { localeStrings } = this.context;
    return (
      <div>
        <ModalDialog
          htmlId="exportOperationsModalDialog"
          className="sample-modal-dialog"
          show={this.props.show}
          header={
            <ModalDialog.Title>
              <FormattedMessage
                defaultMessage="Export Operations"
                id="xmm.portal.operations.export_title"
              />
            </ModalDialog.Title>
          }
          footer={
            <div>
              <SelectInput
                htmlId="makeSelect"
                label={localeStrings["xmm.portal.common.make_lbl"]}
                name="make"
                placeholder={
                  localeStrings["xmm.portal.common.select_a_make_lbl"]
                }
                displayLabel={false}
                displayDeselectOption={false}
                options={this.props.makesList}
                onChange={this.onChange}
                maxHeight={200}
              />
              <Button
                htmlId="cancelDeleteOpsButton"
                onClick={this.props.closeDialog}
                text="Close Button"
                buttonStyle="secondary"
              >
                <FormattedMessage
                  defaultMessage="Cancel"
                  id="xmm.portal.common.cancel_button"
                />
              </Button>
              <Button
                htmlId="okExportOpsButton"
                onClick={() => exportOperations(this.state.make)}
                text="Export Operations"
                buttonStyle="primary"
                disabled={this.state.exportDisable}
              >
                <FormattedMessage
                  defaultMessage="Export"
                  id="xmm.portal.common.export_button"
                />
              </Button>
            </div>
          }
          onHide={this.props.closeDialog}
        >
          <div>
            <p>Select a Make to export</p>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

ExportDealerOperationsModal.propTypes = {
  show: bool,
  exportOperations: PropTypes.func,
  closeDialog: PropTypes.func,
  makesList: PropTypes.array
};
