import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import MenuTypeField from "./MenuType";
import VehiclesField from "./Vehicles";
import OverrideField from "./Override";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class BulkEditTabs extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: ""
    };
  }

  componentDidMount() {}

  render() {
    const MenuType = <MenuTypeField closeSlider={this.props.closeSlider} />;
    const Vehicle = <VehiclesField closeSlider={this.props.closeSlider} />;
    const Override = (
      <OverrideField
        showFindOpcodeModal={this.props.showFindOpcodeModal}
        closeSlider={this.props.closeSlider}
      />
    );
    return (
      <Tabs htmlId="pkgBulkeditTabs">
        <Tab label={xlate("xmm.portal.common.menutype")}>{MenuType}</Tab>
        <Tab label={xlate("xmm.portal.common.vehicles")}>{Vehicle}</Tab>
        <Tab label={xlate("xmm.portal.common.value")}>{Override}</Tab>
      </Tabs>
    );
  }
}

export default BulkEditTabs;

BulkEditTabs.propTypes = {
  closeSlider: PropTypes.func,
  showFindOpcodeModal: PropTypes.func
};
