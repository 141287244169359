/* eslint-disable no-console */
import React, { useContext } from "react";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import { AppContext } from "../../../../app-context";

const NoPricingRow = () => {
  const context = useContext(AppContext);
  const { localeStrings } = context;
  const pricingDisabledTip =
    localeStrings["xmm.portal.settings.pricing_disabled_tip"];
  return (
    <div className="xmm-colspan-label">
      {localeStrings["xmm.portal.settings.pricing_disabled_lbl"]}
      <Tooltip
        htmlId="tipRight"
        tooltipContent={pricingDisabledTip}
        position="right"
      >
        <IconInfoOutline htmlId="iconInfo" />
      </Tooltip>
    </div>
  );
};

export default NoPricingRow;

/* eslint-enable no-console */
