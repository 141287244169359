/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import NumericInput from "@cx/ui/NumericInput";
import SelectInput from "@cx/ui/SelectInput";
import IconDelete from "@cx/ui/Icons/IconDelete";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
class AddPowertrainWarrantyForm extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.state = {
      field: props.field
    };
  }

  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };

  updatePowertrainWarranty = name => {
    const { field } = this.state;
    const index = this.props.index;    
    window.dispatchEvent(
      new CustomEvent("updatePowertrainWarranties", {
        detail: { index, field, name },
        bubbles: true,
        cancelable: true
      })
    );
  };

  onChange = event => {
    const { name, value } = event.target;
    const { field } = this.state;
    if (isDifferentValue(field[name], value)) {
      this.setState(
        () => {
          field[name] = value;
          return { field };
        },
        () => {
          this.updatePowertrainWarranty(name);
        }
      );
    }
  };
  render() {
    const { localeStrings } = this.context;
    const { index, fuelList, extended } = this.props;
    const powertrainCard = this.props.field;
    const deletePowertrain = [
      {
        label: localeStrings["xmm.portal.common.delete_button"],
        value: "delete",        
      }
    ];
    const editSection = (
      <React.Fragment>
        <Card
          htmlId="addPowertrainWarranty"
          className="header-card"
          dropdownOptions={deletePowertrain}
          dropdownProps={{
            buttonStyle: "link",
            className: "button-dropdown-hidden",
            children: "",
            displayCaret: false,
            icon: <IconDelete htmlId="IconDeleteWarranty" />,
            size: "small",
            onClick: () => {
              window.dispatchEvent(
                new CustomEvent("removePowertrainWarrantyEvent", {
                  detail: { index },
                  bubbles: true,
                  cancelable: true
                })
              );
            }
          }}
          header={localeStrings["xmm.portal.pricing_rules.powertrain_warranty"]}
        >
          <div className="comment-form">
            <Grid htmlId="settingGrid">
              <Row className="show-grid">
                <Row className="column-row">
                  <Col xs={6} md={6}>
                    <SelectInput
                      htmlId="fuelType"
                      name="fuelType"
                      label={
                        localeStrings["xmm.portal.pricing_rules.fuel_type"]
                      }
                      options={fuelList}
                      onChange={this.onChange}
                      value={powertrainCard.fuelType}
                      displayDeselectOption={false}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <NumericInput
                      htmlId={`hourlyRate${index}`}
                      name="hourlyRate"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.grid.hourly_rate"
                        ]
                      }
                      inputPrefix="$"
                      onChange={this.onChange}
                      value={powertrainCard.hourlyRate || ""}
                      maxValue={999}
                      required
                    />
                  </Col>
                </Row>
                <Row className={extended ? "column-row" : "hide"}>
                  <Col xs={6} md={6}>
                    <NumericInput
                      htmlId={`baseCoverage${index}`}
                      name="baseCoverage"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.pricing_rules.base_coverage"
                        ]
                      }
                      inputSuffix={
                        localeStrings["xmm.portal.vehiclegroups.years"]
                      }
                      onChange={this.onChange}
                      value={powertrainCard.baseCoverage || ""}
                      maxValue={99}
                      required
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <NumericInput
                      htmlId={`mileageCoverage${index}`}
                      name="mileageCoverage"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.pricing_rules.mileage_coverage"
                        ]
                      }
                      inputSuffix={
                        localeStrings["xmm.portal.common.mileages.unit.miles"]
                      }
                      onChange={this.onChange}
                      value={powertrainCard.mileageCoverage || ""}
                      maxValue={999999}
                      required
                    />
                  </Col>
                </Row>
                <Row className="column-row">
                <Col xs={6} md={6} >
                      <NumericInput
                        htmlId={`deductible${index}`}
                        name="deductible"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.pricing_rules.save_deductible_lbl"
                          ]
                        }
                        inputPrefix="$"
                        onChange={this.onChange}
                        value={powertrainCard.deductible || ""}
                        maxValue={9999}
                        required
                      />
                    </Col>
                </Row>
              </Row>
            </Grid>
          </div>
        </Card>
      </React.Fragment>
    );
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default AddPowertrainWarrantyForm;

AddPowertrainWarrantyForm.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  fuelList: PropTypes.array,
  extended: PropTypes.boolean
};
/* eslint-enable no-console */
