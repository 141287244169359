/* eslint-disable no-console */
import React, { Component } from "react";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import { PropTypes } from "prop-types";
/** Reusable modal window - pass props {title, msg, callback func()} from parent */
class ConfirmPopup extends Component {
  state = {
    showModal: this.props.show,
    callback: null,
    title: this.props.title || "Are you sure?",
    message:
      this.props.message ||
      "You have unsaved changes. Are you sure you would like to proceed?",
    okText: this.props.okText || "Ok",
    cancelText: this.props.cancelText || "Cancel",
    hideCancel: this.props.hideCancel || false,
    hideOk: this.props.hideOk || false
  };

  hide = () => {
    this.props.cancelAction();
    // this.setState({ showModal: false, callback: null });
  };
  confirm = () => {
    // this.state.callback();
    this.props.okAction();
    this.hide();
  };

  // TODO - Special handler if needed callback to execute (not used)
  show = callback => event => {
    if (event) {
      // prevent default event action, e.g: form submit
      event.preventDefault();
    }
    // we need to destructure the event and get current target
    const eventObj = {
      ...event,
      target: { ...event.target }
    };
    // console.log("confirm popup show", event, eventObj);
    this.setState({
      showModal: true,
      // save original callback with event in closure
      callback: () => callback(eventObj)
    });
  };
  render() {
    const { title, hideCancel, hideOk, okText, cancelText } = this.state;
    const classOk = hideOk ? "hide" : "";
    const classCancel = hideCancel ? "hide" : "";
    return (
      <React.Fragment>
        {/* {this.props.children(this.show)} */}
        <ModalDialog
          htmlId="ConfirmPopup"
          className="sample-modal-dialog"
          show={this.props.show}
          header={<ModalDialog.Title>{title}</ModalDialog.Title>}
          footer={
            <div>
              <Button
                htmlId="popupCancelbtn"
                onClick={this.hide}
                className={classCancel}
                buttonStyle="danger"
                text="Cancel"
              >
                {cancelText}
              </Button>
              <Button
                htmlId="popupOkbtn"
                onClick={this.confirm}
                buttonStyle="primary"
                className={classOk}
                text="Proceed"
              >
                {okText}
              </Button>
            </div>
          }
          onHide={this.hide}
        >
          <div>{this.props.message}</div>
        </ModalDialog>
      </React.Fragment>
    );
  }
}
export default ConfirmPopup;

ConfirmPopup.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  hideCancel: PropTypes.bool,
  hideOk: PropTypes.bool,
  cancelAction: PropTypes.func,
  okAction: PropTypes.func
};

/* Pass these custom props to display confirm popup
<ConfirmPopup
        title="Are you Sure?"
        message="Sample Message."
        okText="Proceed"
        cancelText="Cancel"
        okAction={this.handleProceedAction}
        cancelAction={this.handleCancelAction}
        hideCancel={true}
      >
      <div>{message}</div>
      </ConfirmPopup>
      */
