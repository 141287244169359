/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../../components/app-context";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import SelectOperationsGridContainer from "./SelectOperationsGridContainer";
import CustomLoadingOverlay from "../../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";

class AddOperationsModal extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.operationGridRef = React.createRef();
    this.onTotalOperationCount = this.onTotalOperationCount.bind(this);
    this.onSelectedRows = this.onSelectedRows.bind(this);    
    this.initializeLocaleValues();
    const gridOptions = {
      totalOperationCount: 0,
      createdBy: "all",
      visibleColumns: ["checked", "internalName"],
      columnDefs: this.getColumnList(),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
        suppressMenu: true,
        width: 200,
        sortingOrder: ["asc", "desc", null],
        rowGroup: false,
        headerComponentParams: {
          template: `
            <div class="ag-cell-label-container" role="presentation">
              <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
                <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
                <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
              </div>
            </div>
            `
        },
        getQuickFilterText: params => {
          return params.value;
        }
      },
      rowData: null,      
      components: {
        usedIndicatorCellRenderer
      },
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: false,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        loadingMessage: xlate("xmm.portal.common.no_records_msg"),
        isLoading: false,
        noRows: false
      },
      columnTypes: {
        numberColumn: {
          sortable: true,
          filter: "agNumberColumnFilter"
        }
      },
      localeText: {
        filteredRows: xlate("xmm.portal.ag_grid.filteredRows"),
        selectedRows: xlate("xmm.portal.ag_grid.selectedRows"),
        totalRows: xlate("xmm.portal.ag_grid.totalRows"),
        totalAndFilteredRows: xlate("xmm.portal.ag_grid.totalAndFilteredRows"),
        noRowsToShow: xlate("xmm.portal.ag_grid.noRowsToShow")
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listener
   */
  componentDidMount() {}
  /* This called when component gets updates
   This method is not called for the initial render.
  */
  componentDidUpdate() {}
  initializeLocaleValues() {    
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.closeToolPanel();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleColumnResized = () => {};
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (this.gridApi) {
      const { value } = event.target;
      this.gridApi.setQuickFilter(value);
    }
  };

  onFilterChanged = () => {};

  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  getColumnList() {
    const baseCols = [
      {
        headerName: xlate("xmm.portal.common.name_lbl"),
        field: "internalName",
        pinned: "left",
        headerClass: "ag-text-header",
        sortingOrder: ["asc", "desc"],
        cellClass: "xmm-wrap-cell",
        minWidth: 280,
        autoHeight: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: xlate("xmm.portal.grid.createdby"),
        field: "createdBy",
        filter: "agSetColumnFilter",
        suppressColumnsToolPanel: true,
        suppressSizeToFit: true
      },
      {
        headerName: xlate("xmm.portal.operations.grid.service_category"),
        field: "serviceCategoryNodeName",
        filter: "agSetColumnFilter",
        suppressColumnsToolPanel: true,        
        suppressSizeToFit: true
      },
      {
        headerName: xlate("xmm.portal.common.used_in_dealer_menus"),
        field: "usedIndicator",
        cellRenderer: "usedIndicatorCellRenderer",
        minWidth: 50,
        maxWidth: 150,
        suppressSizeToFit: true
      }
    ];
    return baseCols;
  }

  saveSelected = () => {
    const { rowData } = this.state;
    this.props.saveSelectedOperations(rowData);
    this.props.closeModal();
  };
  customClose = () => {
    this.setState({ rowData: [] });
    this.props.closeModal();
  };

  onChange = cxEvent => {
    const { name, value } = cxEvent.target;
    this.setState({ [name]: value, dirty: true });
  };

  onTotalOperationCount = totalOperationCount => {
    this.setState({ totalOperationCount });
  };

  onSelectedRows = selectedRows => {
    this.setState({ rowData: selectedRows });
  };
  onValidate = () => {
    const valid =
      this.operationGridRef &&
      this.operationGridRef.current &&
      this.operationGridRef.current.isValid();
    this.setState({ valid });
    console.log("DealerMenuForm.onValidate", valid);
  };

  render() {
    const { make, menuTypes } = this.props;
    const { rowData, totalOperationCount } = this.state;
    const { getClassWithBridgeBar } = this.context;
    const cls = getClassWithBridgeBar("ag-grid-container") + " xmm-pricing-ops";
    return (
      <React.Fragment>
        <ModalDialog
          htmlId="ValidateOpcodeModal"
          show={this.props.show}
          backdrop={"static"}
          className="xmm-add-operations-modal"
          header={<ModalDialog.Title>{this.props.title}</ModalDialog.Title>}
          onHide={this.customClose}
          footer={
            <div>
              <Button
                htmlId="modalSaveButton"
                onClick={this.saveSelected}
                buttonStyle="primary"
                disabled={totalOperationCount < 1}
              >
                {this.saveLabel}
              </Button>
              
            </div>
          }
        >
          <SelectOperationsGridContainer
            ref={this.operationGridRef}
            make={make}
            menuTypes={menuTypes}
            onTotalCount={this.onTotalOperationCount}
            onSelectedRows={this.onSelectedRows}
            onValidate={this.onValidate}
            isDMS={true}
          />
          <hr></hr>
          <div>
            <span className="sub-title">{xlate("xmm.portal.dealermenus.selected_operations")}</span>
            <Badge htmlId="badgeBlue" color="blue">
              {totalOperationCount}
            </Badge>
          </div>
          <div className={cls}>
            <AgGridReact
              localeText={this.state.localeText}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowSelection="multiple"
              rowDeselection={true}
              rowMultiSelectWithClick={true}
              isRowSelectable={this.state.isRowSelectable}
              suppressRowClickSelection={true}
              suppressMenuHide={false}
              rowData={rowData}
              columnTypes={this.state.columnTypes}
              onGridReady={this.onGridReady}
              onColumnResized={this.handleColumnResized}
              onColumnGroupOpened={this.sizeToFit}
              onSelectionChanged={this.handleSelectionChanged}
              components={this.state.components}
              loadingOverlayComponent={this.state.loadingOverlayComponent}
              loadingOverlayComponentParams={
                this.state.loadingOverlayComponentParams
              }
              noRowsOverlayComponent={this.state.noRowsOverlayComponent}
              noRowsOverlayComponentParams={
                this.state.noRowsOverlayComponentParams
              }
              animateRows={true}
              rowStyle={this.state.rowStyle}
              statusBar={this.state.statusBar}
              enableRangeSelection={false}
              enableCharts={false}
              enableCellTextSelection={true}
              enableBrowserTooltips={false}
              onFilterChanged={this.onFilterChanged}
            />
          </div>
        </ModalDialog>
      </React.Fragment>
    );
  }
}
export default AddOperationsModal;

AddOperationsModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  closeModal: PropTypes.func,
  make: PropTypes.string,
  menuTypes: PropTypes.array,
  saveSelectedOperations: PropTypes.func
};
/* eslint-enable no-console */

function usedIndicatorCellRenderer(params) {
    const usedIndicator = params.value;
    return !usedIndicator ? "" : <i className="fas fa-check" />;
  }