/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import AccordionGroup from "@cx/ui/AccordionGroup";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Button from "@cx/ui/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Tooltip from "@cx/ui/Tooltip";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import AdvancedRow from "./AdvancedRow";
import { isEmpty } from "../../../../../commonUtil/utils/object";
import { AppContext } from "../../../../app-context";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";

class AdvancedSettings extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.advancedRows = [];

    this.state = {
      isExpand: false
    };
  }
  componentDidMount() {}

  toggleClick = event => {
    event.preventDefault();
    this.setState(prevState => ({
      isExpand: !prevState.isExpand
    }));
  };
  // This handler calls its parent handler to autosave record field changes
  onUpdate = (catalog, name, payload) => {
    this.props.onSave(catalog, name, payload, "advancedSettingsRef");
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
  };
  hasBmwValueServicesProgram(dealerCatalogs, isBmwValueServicesEnabled) {
    for (let index = 0; index < dealerCatalogs.length; index++) {
      const catalog = dealerCatalogs[index];
      const { make } = catalog;
      if ((make === "BMW" || make === "MINI") && isBmwValueServicesEnabled) {
        return true;
      }
    }
    return false;
  }
  callChildMethod = (msg, type, close, errorInTooltip) => {
    this.updateStatusBox(msg, type, close, errorInTooltip);
  };

  /* These Utils used for form validation style */
  updateStatusBox = (msg, type, close, errorInTooltip) => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  };
  renderAccordionHeader = () => {
    const { localeStrings } = this.context;
    const statusHtml = this.state.statusMsg ? (
      <div className="status-box pull-right">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          // autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    // const totalDisplay = totalOperationCount > 0 ? () : "";
    const title = localeStrings["xmm.portal.settings.adv_settings_lbl"];
    return (
      <div className="xmm-accordion-header">
        <span>{title}</span>
        {statusHtml}
      </div>
    );
  };

  renderTrimEtc() {
    const { isBmwValueServicesEnabled } = this.props;
    const { dealerCatalogs, localeStrings } = this.context;
    let resultTable = null;
    let catalogHtml = null;
    const trimTip = localeStrings["xmm.portal.settings.show_trim_selector_tip"];
    const annualMileageTip =
      localeStrings["xmm.portal.settings.show_annual_mileage_estimate_tip"];
    const menuMileageToleranceTip =
      localeStrings["xmm.portal.settings.show_menu_mileage_tolerance_tip"];
    const monthMileageToleranceTip =
      localeStrings["xmm.portal.settings.show_menu_month_tolerance_tip"];
    const isBmwValueSvcs = this.hasBmwValueServicesProgram(
      dealerCatalogs,
      isBmwValueServicesEnabled
    );
    const clsBmwValueSvcs = isBmwValueSvcs
      ? "xmm-fixed-col"
      : "xmm-fixed-col hidden";

    if (dealerCatalogs && dealerCatalogs.length !== 0) {
      catalogHtml = dealerCatalogs.map((record, index) => {
        return (
          <AdvancedRow
            key={"trim-" + record.make}
            ref={ref => (this.advancedRows[index + 1] = ref)}
            catalog={record}
            isBmwValueServicesEnabled={isBmwValueServicesEnabled}
            onUpdate={this.onUpdate}
          />
        );
      });

      resultTable = (
        <Grid className="setting-table">
          <Row>
            <Col md={2} sm={2}>
              &nbsp;
            </Col>
            <Col md={10} sm={10} className="xmm-form-content xmm-form-header">
              <div className="xmm-fixed-col">
                {localeStrings["xmm.portal.settings.show_trim_selector_lbl"]}
                <Tooltip
                  htmlId="trimTipId"
                  position="right"
                  tooltipContent={trimTip}
                >
                  <IconInfoOutline htmlId="IconDisplayTrim" />
                </Tooltip>
              </div>
              <div className="xmm-fixed-col">
                {
                  localeStrings[
                    "xmm.portal.settings.default_driving_condition_lbl"
                  ]
                }
              </div>
              <div className={clsBmwValueSvcs}>
                {
                  localeStrings[
                    "xmm.portal.settings.show_bwm_value_services_lbl"
                  ]
                }
              </div>
              <div className="xmm-fixed-col">
                {localeStrings["xmm.portal.settings.annual_mileage_estimate"]}
                <Tooltip
                  htmlId="annualMileageTipId"
                  position="right"
                  tooltipContent={annualMileageTip}
                >
                  <IconInfoOutline htmlId="IconAnnualMileage" />
                </Tooltip>
              </div>
              <div className="xmm-fixed-col">
                {localeStrings["xmm.portal.settings.menu_mileage_tolerance"]}
                <Tooltip
                  htmlId="menuMileageToleranceTipId"
                  position="right"
                  tooltipContent={menuMileageToleranceTip}
                >
                  <IconInfoOutline htmlId="IconMenuMileageTolerance" />
                </Tooltip>
              </div>
              <div className="xmm-fixed-col">
                {localeStrings["xmm.portal.settings.menu_month_tolerance"]}
                <Tooltip
                  htmlId="monthMileageToleranceTipId"
                  position="right"
                  tooltipContent={monthMileageToleranceTip}
                >
                  <IconInfoOutline htmlId="IconMonthMileageTolerance" />
                </Tooltip>
              </div>
            </Col>{" "}
          </Row>
          {catalogHtml}
        </Grid>
      );
    }
    return resultTable;
  }

  render() {
    const { dealerCatalogs, localeStrings } = this.context;
    const resultTable = this.renderTrimEtc();
    const hasResults =
      !isEmpty(dealerCatalogs) && dealerCatalogs.length > 0 ? true : false;
    const clsHideButton = hasResults ? "hide" : "hide";
    const accordianHeader = this.renderAccordionHeader();
    return (
      <AccordionGroup.Container htmlId="advancedAccordion" independent>
        <AccordionGroup.Item
          // header={localeStrings["xmm.portal.settings.adv_settings_lbl"]}
          header={accordianHeader}
          eventKey="1"
          htmlId="AccordionGroupAdvanced"
        >
          <div className="btn-div">
            <ButtonGroup className={clsHideButton}>
              <Button htmlId="allMakebtn" buttonStyle="primary">
                {localeStrings["xmm.portal.settings.manage_all_btn"]}
              </Button>
              <Button htmlId="oneMakebtn" buttonStyle="secondary">
                {localeStrings["xmm.portal.settings.manage_by_btn"]}
              </Button>
            </ButtonGroup>
          </div>
          {/* <span className="float-right">{statusHtml}</span> */}
          {resultTable}
        </AccordionGroup.Item>
      </AccordionGroup.Container>
    );
  }
}

export default AdvancedSettings;

AdvancedSettings.propTypes = {
  isBmwValueServicesEnabled: PropTypes.bool,
  onSave: PropTypes.func
};
