const KEY_BACKSPACE = 8;
const KEY_F2 = 113;
const KEY_DELETE = 46;

function SimpleCellEditor() {}

/**
 * ? This Component SimpleCellEditor written to use within ag-grid cell
 */
SimpleCellEditor.prototype.init = function (params) {
  this.gui = document.createElement("input");
  this.gui.type = "text";
  this.gui.classList.add("ag-cell-edit-input");
  this.params = params;

  let startValue;

  const keyPressBackspaceOrDelete =
    params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE;

  if (keyPressBackspaceOrDelete) {
    startValue = "";
  } else if (params.charPress) {
    startValue = params.charPress;
  } else {
    startValue = params.value;
    if (params.keyPress !== KEY_F2) {
      this.highlightAllOnFocus = true;
    }
  }

  if (startValue !== null && startValue !== undefined) {
    this.gui.value = startValue;
  }
};

SimpleCellEditor.prototype.getGui = function () {
  return this.gui;
};

SimpleCellEditor.prototype.getValue = function () {
  return this.gui.value;
};

SimpleCellEditor.prototype.afterGuiAttached = function () {
  this.gui.focus();
};

SimpleCellEditor.prototype.myCustomFunction = function () {
  return {
    rowIndex: this.params.rowIndex,
    colId: this.params.column.getId()
  };
};
