// This context behaves like app level global state
import React from "react";
const initialState = {
  webKey: "",
  dealerCode: "",
  dealer: {
    webKey: "",
    dealerCode: "",
    dealerName: "",
    dmsType: "",
    supportsPull: 0,
    opcodeScoringEnabled: 0,
    locales: {}
  },
  scopedDealers: [],
  user: {},
  isStaging: null,
  locale: "en_US",
  localeStrings: {},
  testName: "first",
  operationlist: [],

  liveCount: null,
  stagedCount: null,
  allMakeList: [],
  makelist: [],
  dealerCatalogs: [],
  stagingMakeList: [],
  makeVariantMap: {},
  makeLaborRatesMap: {},
  makeRateCodesMap: {},
  dealerLaborRateCodes: null,
  rawDealerVariantChanges: null,
  allMakesPricingMethod: null,
  serviceCategories: [],
  serviceCategoryValueLabelMap: {},
  selectedMenuType: null,
  manualOpcodes: null,
  variantFilters: null,
  contentPriceStatuses: null,
  /* preview - App state props */
  preview: {
    search: null,
    drivingConditions: null,
    currentMileagePoint: null,
    mileagePoints: null,
    isPreview: null, // default set null
    chartResults: null,
    menuResults: null,
    sortedMenuTypes: null,
    alacarteResults: null
  },
  previewSettings: {
    search: null,
    selectors: {
      makes: [],
      models: [],
      years: [],
      trims: [],
      engineSizeTypes: [],
      driveTypes: [],
      transmissionTypes: [],
      drivingConditions: []
    },
    recentPreviewed: [],
    favorites: [],
    commonVehicles: []
  },
  /* Implement this handler in context provider scope */
  setOperationGridState: () => {},
  updateOperationList: () => {},
  updateMakeList: () => {},
  updateServiceCategories: () => {},
  setContentPriceStatuses: () => {},
  toggleAuth: () => {},
  toggleTestName: () => {}
};

export const AppContext = React.createContext(initialState);
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;
