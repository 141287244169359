/* eslint-disable no-console */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import Tab from "@cx/ui/Tab";
import Tabs from "@cx/ui/Tabs";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import Confirmation from "./Confirmation";
import PartsGridExt from "../PartsGridExt";
import AddPartInput from "../AddPartInput";

const AddPartsModal = props => {
  const partsGridLookupRef = useRef(null);
  const [partsList, setPartslist] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [partsPricingInUse, setPartsPricingInUse] = useState(false);
  const [newPartPricingInUse, setNewPartPricingInUse] = useState(false);
  const [newPart, setNewPart] = useState(null);
  const [partManufacturerOptions, setPartManufacturerOptions] = useState([]);
  const [replacePartsFlag, setReplacePartsFlag] = useState(false);

  useEffect(() => {
    const { make, partManufacturerCodeMap, replaceParts } = props;
    const oem = partManufacturerCodeMap[make];
    const partManufacturerList = [];
    if (oem) {
      const { oemCode, oemName } = oem;
      partManufacturerList.push({
        label: `${oemCode} - ${oemName}`,
        value: oemCode
      });
    }
    partManufacturerList.push({ label: "OT - Other", value: "OT" });
    setPartManufacturerOptions(partManufacturerList);
    setReplacePartsFlag(replaceParts);
  }, []);
  useEffect(() => {
    if (props.partsList && props.partsList.length !== 0) {
      setRowId(props.partsList.length);
      setPartslist([...props.partsList]);
      setPartsPricingInUse(true);
    }
  }, [props.partsList]);
  useEffect(() => {
    if (partsPricingInUse) {
      props.onGetPartsPricing(partsList, onGetPartsPricingCallback, true);
      setPartsPricingInUse(false);
    }
  }, [partsList, partsPricingInUse]);
  useEffect(() => {
    if (newPartPricingInUse) {
      props.onGetPartsPricing([newPart], onGetPartsPricingCallback, false);
      setNewPartPricingInUse(false);
    }
  }, [newPart, newPartPricingInUse]);

  const addPartOvderride = p => {
    const part = {
      rowId: rowId + 1,
      dealerOperationRuleId: props.dealerOperationRuleId,
      dtDmsPartCode: p.partManufacturer,
      partMake: p.partManufacturer,
      partType: "part",
      priceSource: "",
      // partName: "",
      oemPartNumber: p.partNumber,
      quantity: p.quantity,
      relationship: p.relationship
      // unitPrice: 0
    };
    const newPartsList = [...partsList, part];
    setRowId(rowId + 1);
    setPartslist(newPartsList);
    setNewPart(part);
    setNewPartPricingInUse(true);
  };

  const onGetPartsPricingCallback = () => {
    setPartslist([...partsList]);
    partsGridLookupRef.current && partsGridLookupRef.current.refreshGrid();
  };
  const confirmUpdate = () => {
    //
    props.onUpdateParts(partsList);
  };
  const closeDialog = () => {
    //
    setShowUpdateModal(false);
    // props.onClose();
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const hasChanges = () => {
    const adds = partsList.filter(part => !part.dealerPartOverrideId);
    const updates = partsList.filter(
      part => !!part.dealerPartOverrideId && !!part.modified
    );
    const currentPartsMap = {};
    partsList.forEach(part => {
      currentPartsMap[part.rowId] = part;
    });
    const deletes = [];
    props.partsList.forEach(p => {
      if (!currentPartsMap[p.rowId]) {
        deletes.push(p);
      }
    });
    return adds.length !== 0 || deletes.length !== 0 || updates.length !== 0;
  };
  const includeOEMPartsTooltip = (
    <div>
      <p className="xmm-popover-text">
        If this toggle is turned on, then you will be including the existing
        parts from the OEM, plus the parts that you enter in the table below.
        Meaning, you are adding additional parts to what parts are already setup
        for the vehicle and operation.
      </p>
      <p className="xmm-popover-text">
        If this toggle is turned off, then you will only include the parts you
        add below, on your vehicle(s) and operation. Meaning, you are replacing
        all OEM parts for the vehicle(s) and operation, with the parts you
        added.
      </p>
    </div>
  );

  const content = (
    <div>
      <Grid>
        <Row>
          <Col xs={12} md={12}>
            <Tabs htmlId="PartsTabs">
              <Tab label={"Add part"}>
                <AddPartInput
                  defaultPartManufacturerCode={
                    props.defaultPartManufacturerCode
                  }
                  partManufacturerOptions={partManufacturerOptions}
                  onAddPart={part => {
                    addPartOvderride(part);
                    props.onModified();
                  }}
                />
              </Tab>
              <Tab label={"Add part kit"} disabled={true} hidden={true} />
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <hr />
          </Col>
          <Col
            xs={6}
            md={6}
            xsOffset={6}
            mdOffset={6}
            className="parts-toggle"
            hidden={!props.showIncludeOEMParts}
          >
            <span>{"Include OEM Parts"} </span>
            <Popover
              htmlId="includeOEMPartsTooltip"
              name="includeOEMPartsTooltip"
              popoverContent={includeOEMPartsTooltip}
              position="right"
              trigger={["click", "outsideClick"]}
            >
              <IconInfoOutline className="info-blue" />
            </Popover>
            <ToggleSwitch
              htmlId="sourceToggle"
              name="menuTypeId-chk"
              checked={!replacePartsFlag}
              onClick={() => {
                setReplacePartsFlag(!replacePartsFlag);
                props.onModified();
              }}
            />
          </Col>
          <Col xs={12} md={12}>
            <PartsGridExt
              ref={partsGridLookupRef}
              parts={partsList}
              partsGridLabel="Service parts"
              // partsOverridePrice={"123456"}
              allowAutoHeight={false}
              maxRows={10}
              showHeader={true}
              showTotal={true}
              showNotes={false}
              showCheckboxes={false}
              showRemove={true}
              showPartRelationship={false}
              allowDescriptionEdit={true}
              allowPriceEdit={false}
              disableQtyEdit={true}
              allowPartRelationshipEdit={true}
              onModified={() => props.onModified()}
              onSelectRow={() => {
                console.log("onSelectRow...");
              }}
              onRemoveRow={partToRemove => {
                console.log("onRemove...");
                for (let i = 0; i < partsList.length; i++) {
                  if (partsList[i].rowId === partToRemove.rowId) {
                    partsList.splice(i, 1);
                    setPartslist([...partsList]);
                    break;
                  }
                }
                props.onModified();
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            md={12}
            className="text-right parts-grid-update-btn-container"
          >
            <Button
              buttonStyle="secondary"
              onClick={() => {
                setTimeout(() => {
                  props.onUpdateParts(partsList, replacePartsFlag);
                }, 100);
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Grid>
    </div>
  );
  const dialogMsg =
    "You are about to change an override. Are you sure you want to proceed?";
  const updateConfirmationModal = (
    <Confirmation
      htmlId="updateConfirmationDialog"
      message={dialogMsg}
      proceedButtonStyle="danger"
      show={showUpdateModal}
      actionFunction={confirmUpdate}
      closeDialog={closeDialog}
    />
  );

  return (
    <>
      {content}
      {updateConfirmationModal}
    </>
  );
};

export default AddPartsModal;

AddPartsModal.propTypes = {
  make: PropTypes.string,
  defaultPartManufacturerCode: PropTypes.string,
  dealerOperationRuleId: PropTypes.number,
  partsList: PropTypes.array,
  partManufacturerCodeMap: PropTypes.object,
  replaceParts: PropTypes.bool,
  showIncludeOEMParts: PropTypes.bool,
  onModified: PropTypes.func,
  onGetPartsPricing: PropTypes.func,
  onUpdateParts: PropTypes.func
};

AddPartsModal.defaultProps = {
  make: "",
  defaultPartManufacturerCode: "",
  partsList: [],
  partManufacturerCodeMap: {},
  replaceParts: false,
  showIncludeOEMParts: false,
  onModified: () => {},
  onGetPartsPricing: () => {},
  onUpdateParts: () => {}
};
