/**
 *
 *
 * sortByMake apply sorter among makes
 * @param {string} a
 * @param {string} b
 * @return {number}
 */
export function sortByMake(a, b) {
  if (a === "ANY") {
    return -1;
  }
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
}
