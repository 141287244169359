import React from "react";

export default React.createContext({
  appContext: null,
  localeStrings: {},
  locale: "",
  webKey: "",
  dealerCode: "",
  makelist: null,
  search: null,
  vehicleInfo: null,
  drivingConditions: "",
  preview: null,
  previewSettings: null
});
