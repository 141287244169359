import React from "react";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import { PropTypes } from "prop-types";
import PublishSchedule from "./content/PublishSchedule";
import PublishHistory from "./content/PublishHistory";
import AutoPublish from "./content/AutoPublish";
import { xlate } from "../../../commonUtil/i18n/locales";

/** Traditional style */
const PublishTabs = props => (
  <Tabs htmlId="PublishTabs">
    <Tab label={xlate("xmm.portal.nav.publish")}>
      <PublishSchedule closeslider={props.closeslider} />
    </Tab>
    <Tab label={xlate("xmm.portal.publish.history_lbl")}>
      <PublishHistory />
    </Tab>
    <Tab label={xlate("xmm.portal.publish.auto_publish_lbl")}>
      <AutoPublish />
    </Tab>
  </Tabs>
);

export default PublishTabs;

PublishTabs.propTypes = {
  closeslider: PropTypes.func
};
