/* eslint-disable no-console */
import React from "react";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import NavMenuItem from "./NavMenuItem";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import { string, PropTypes } from "prop-types";
import { injectIntl } from "react-intl";
// import * as gtmEvents from "../utils/gtag-eventlist";
import { routes } from "../module-routes";
import { AppContext } from "../../components/app-context";

class NavMenuLink extends React.Component {
  static propTypes = {
    // intl: intlShape.isRequired,
    label: string,
    header: string,
    items: PropTypes.array,
    links: PropTypes.object
  };
  static contextType = AppContext;
  constructor(props, context) {
    super(props);
    this.getMenuItem = this.getMenuItem.bind(this);
    this.onNavMenuLinkChanged = this.onNavMenuLinkChanged.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);

    const path = window.location.pathname;
    // console.log(routes);
    const arrayLength = routes.length;
    let parentName = "dashboard";
    let nodeName = "dashboard";
    for (let i = 0; i < arrayLength; i++) {
      if (routes[i].path === path) {
        parentName = routes[i].parentName;
        nodeName = routes[i].nodeName;
        break;
      }
    }
    const { currentPage } = context;
    Object.assign(currentPage, { parentName, nodeName, dirty: false });
    this.state = {
      active: parentName, // set as default visited menu
      parentName,
      nodeName
    };
  }

  componentDidMount() {
    window.addEventListener(
      "navMenuLinkChanged",
      this.onNavMenuLinkChanged,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "navMenuLinkChanged",
      this.onNavMenuLinkChanged,
      false
    );
  }

  onNavMenuLinkChanged = event => {
    event.stopPropagation();
    const { parent, node } = event.detail;
    this.toggleMenu(event, parent, node);
  };

  /* handler when child menuitem is selected */
  // Track page navigation using GTM track type "pageview"; hits are logged in GA (under realtime content, behavior/ site content)
  toggleMenu = (event, parentName, nodeName) => {
    // const pagePath = "/" + parentName + "/" + nodeName;
    // gtmEvents.trackGtagPageview(pagePath);
    // console.log("Nav Menu toggle click", pagePath, "parent:", parent);

    setTimeout(() => {
      // react takes time to update state, need timeout on sync block.
      const { currentPage } = this.context;
      Object.assign(currentPage, { parentName, nodeName, dirty: false });
      this.setState({ active: parentName, parentName, nodeName });
    }, 200);
    // Note: workaround for bootstrap3 issue - https://github.com/react-bootstrap/react-bootstrap/issues/541
    window.document.dispatchEvent(new MouseEvent("click"));
  };

  /* Not Used - returns direct link like bootstrap MenuItem */
  getMenuItem = (items, header) => {
    console.log(header, items);
    return items.map(
      ({ path, icon, label, localekey, parentName, nodeName }) => (
        <NavMenuItem
          key={path}
          to={path}
          icon={icon}
          parent={parentName}
          node={nodeName}
          clicked={this.toggleMenu}
          label={this.props.intl.formatMessage({
            defaultMessage: label,
            id: localekey
          })}
        />
      )
    );
  };
  /* This renders - list of Bootstrap Dropdown components  */
  renderLinks() {
    const { links } = this.props;
    const { active } = this.state;
    const navlinks = [];
    // Map.forEach() doesn't work in React return()
    links.forEach((value, key) => {
      const strKey = key.toString();
      const activelink = active === strKey ? "nav__link--active" : "nav__link";
      // use locale key as menu header
      let menuName = strKey;

      let leaf = false;
      if (value.length > 0 && value[0].parentLocaleKey) {
        menuName = value[0].parentLocaleKey;
        // console.log(strKey, menuName);
        // enhance this logic using [index]
        leaf = value[0].hasOwnProperty("leaf") && value[0].leaf;
      }
      const TitleLocale = this.props.intl.formatMessage({
        id: menuName
      });
      const titleArrow = (
        <span className="xmm-nav-dropdown">
          {TitleLocale}
          <IconKeyboardArrowDown htmlId="xmmCaretDropdown" />
        </span>
      );
      // console.log(value, strKey);

      // When leaf=true; render direct link eg: dashboard; else render dropdown menu
      if (leaf) {
        const { path, icon, label, localekey, parentName, nodeName } = value[0];
        navlinks.push(
          <NavMenuItem
            key={path}
            to={path}
            icon={icon}
            parent={parentName}
            node={nodeName}
            clicked={this.toggleMenu}
            label={this.props.intl.formatMessage({
              defaultMessage: label,
              id: localekey
            })}
          />
        );
      } else
        navlinks.push(
          <NavDropdown
            eventKey={menuName}
            title={titleArrow}
            key={strKey}
            name={strKey}
            id="basic-nav-dropdown"
            label={strKey}
            className={activelink}
            headername={strKey}
            // onClick={event => {
            //   event.persist();
            //   console.log("NavDropdown click", event.target);
            // }}
          >
            {value.map(
              ({ path, icon, label, localekey, parentName, nodeName }) => (
                <NavMenuItem
                  key={path}
                  to={path}
                  icon={icon}
                  parent={parentName}
                  node={nodeName}
                  clicked={this.toggleMenu}
                  label={this.props.intl.formatMessage({
                    defaultMessage: label,
                    id: localekey
                  })}
                />
              )
            )}
          </NavDropdown>
        );
    });
    return navlinks;
  }
  render() {
    return <React.Fragment>{this.renderLinks()}</React.Fragment>;
  }
}

export default injectIntl(NavMenuLink);
