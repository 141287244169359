import ManageVehicleGroupModal from "./ManageVehicleGroupModal";
import AddGroupMenuButton from "./AddGroupMenuButton";
import GroupNameFilter from "./GroupNameFilter";
import GroupNameList from "./GroupNameList";
import VehicleGroupForm from "./VehicleGroupForm";
import ShowVehiclesGrid from "./ShowVehiclesGrid";

// bundle components into one object and export
const VGroupAPI = {
  ManageVehicleGroupModal,
  AddGroupMenuButton,
  GroupNameFilter,
  GroupNameList,
  VehicleGroupForm,
  ShowVehiclesGrid
};

export default VGroupAPI;
