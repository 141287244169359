/* eslint-disable no-console */
import React from "react";
import { AppContext } from "../../../app-context";
import SettingsContext from "../settings-context";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import CatalogPricing from "./content/CatalogPricing";
import DisplayPricing from "./content/DisplayPricing";
import PartsLabor from "./content/PartsLabor";
import Taxes from "./content/Taxes";
import ShopSupplies from "./content/ShopSupplies";
import PriceRounding from "./content/PriceRounding";
import AdvancedSettings from "./content/AdvancedSettings";
import ConsumerMenus from "./content/ConsumerMenus";
import { isEmpty, isArrayExist } from "../../../../commonUtil/utils/object";
import { makeSecureRestApi } from "../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
// import { sleep } from "../../../reusable/helper";

class SettingsPage extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.settingRefs = [];
    this.state = {};
  }
  componentDidMount() {
    // Always render dealerCatalogs from App context
    // Testing - App context makelist is not ready for every Setting page refresh. Hence add timeout
    // sleep(2000).then(() => {
    //   const dealerCatalogs = this.sortedCatalogs(this.getDealerCatalogs());
    //   console.log("filtered has catalogs", dealerCatalogs);
    //   this.setState(prevState => {
    //     return { dealerCatalogs };
    //   });
    // });
  }

  // Deep clone makelist here
  getDealerCatalogs() {
    const { dealerCatalogs, makeVariantMap } = this.context;
    let rawCatalogs = [];
    if (!isEmpty(dealerCatalogs)) {
      rawCatalogs = dealerCatalogs.map(m => {
        const make = Object.assign({}, m);
        return make;
      });
    }
    // For now, ANY catalog not supported in common-ops
    if (!isEmpty(makeVariantMap) && !isEmpty(makeVariantMap["ANY"])) {
      rawCatalogs.splice(0, 0, makeVariantMap["ANY"]);
    }
    // console.log("rawCatalogs", rawCatalogs);
    return rawCatalogs;
  }

  sortedCatalogs(rawCatalogs) {
    return rawCatalogs.sort((a, b) => {
      if (a.make === b.make) {
        return 0;
      }
      return a.make > b.make ? 1 : -1;
    });
  }

  getSavePayload(record) {
    const payload = {
      dealerCode: record.dealerCode,
      make: record.make,
      annualMileage: record.annualMileage,
      showPricing: record.showPricing,
      menuMileageTolerance: record.menuMileageTolerance,
      menuMonthTolerance: record.menuMonthTolerance,
      defaultDrivingCondition: record.defaultDrivingCondition,
      showPricingMsa: record.showPricingMsa,
      showPricingConsumer: record.showPricingConsumer,
      showPricingBusiness: record.showPricingBusiness,
      laborTimePrecision: record.laborTimePrecision,
      roundUpFluidQuantities: record.roundUpFluidQuantities,

      // properties added to support brand-specific proc call
      showPricingPackage: record.showPricingPackage,
      showPricingAlacarte: record.showPricingAlacarte,
      shopSupplyChargeType: record.shopSupplyChargeType,
      shopSupplyValue: record.shopSupplyValue,
      shopSupplyMinimumValue: record.shopSupplyMinimumValue,
      shopSupplyMaximumValue: record.shopSupplyMaximumValue,
      shopSupplyApplyToParts: record.shopSupplyApplyToParts,
      shopSupplyApplyToLabor: record.shopSupplyApplyToLabor,
      shopSupplyApplyToTotal: record.shopSupplyApplyToTotal,
      shopSupplyTaxable: record.shopSupplyTaxable,
      salesTaxRate: record.salesTaxRate,
      salesTaxApplyToParts: record.salesTaxApplyToParts,
      salesTaxApplyToLabor: record.salesTaxApplyToLabor,
      salesTaxApplyToTotal: record.salesTaxApplyToTotal,
      packageRoundBy: record.packageRoundBy,
      packageRoundTo: record.packageRoundTo,
      packageRoundRule: record.packageRoundRule,
      pricingMethod: record.pricingMethod,
      // Add param to support disable vehicle pricing
      newVehiclePricingMethod: record.newVehiclePricingMethod,
      // Add params to support Consumer flags like Menu Pricing, Ala Carte
      showPricingConsumerMenu: record.showPricingConsumerMenu,
      showPricingConsumerAlacarte: record.showPricingConsumerAlacarte,
      // Add param to support skipTrim flag for Consumer app
      skipTrim: record.skipTrim,
      // Add param to support priorityOpcodeStrings string to support Common-ops app
      priorityOpcodeStrings: record.priorityOpcodeStrings
    };
    // console.log("payload to save", property, payload);
    return payload;
  }
  /* save Handler - to save changes of {catalog Pricing, Display Pricing, Roundup fluids, skipTrim fields etc } */
  saveDealerCatalog = (catalog, name, payload, screenName) => {
    // const payload = this.getSavePayload(catalog);
    const { localeStrings } = this.context;
    console.log("payload to save", name, payload);
    if (payload && Object.keys(payload).length === 0) {
      return;
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    // const restAPI = "/ops/proxyapi/ddsproxy/rest/proc/editDealerCatalogSimpleP";
    const restAPI = `ops/dealerapi/saveDealerCatalogSettings/${catalog.dealerCode}/${catalog.make}`;
    makeSecureRestApi(
      {
        url: restAPI,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        this.onSucessCallback(response, screenName);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["xmm.portal.settings.save_data_error"];

        this.triggerChildStatus(screenName, msg, "error", false, true);
        // toast.error(msg, {
        //   closeOnClick: true
        // });
      }
    );
  };
  onSucessCallback = (record, screenName) => {
    if (record) {
      this.context.updateDealerCatalogs(record);

      this.triggerChildStatus(
        screenName,
        this.context.localeStrings["xmm.portal.common.saved"],
        "success",
        true
      );
    }
  };
  getDealerCatalog(catalog) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const { localeStrings } = this.context;
    const payload = {
      make: catalog.make,
      variant: catalog.variant,
      dealerCode: catalog.dealerCode
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/dealerCatalog",
        method: "get",
        data: {},
        params: payload,
        headers
      },
      response => {
        if (response) {
          const datalist = response;
          if (isArrayExist(datalist) && datalist.length > 0) {
            const record = datalist[0];
            this.onSucessCallback(record);
          }
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["xmm.portal.settings.save_data_error"];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  triggerChildStatus(screenName, msg, type, close, errorInTooltip) {
    this.settingRefs[screenName].callChildMethod(
      msg,
      type,
      close,
      errorInTooltip
    );
  }

  // callBackStatus = (msg, type, close, errorInTooltip, screenName) => {};
  render() {
    const { dealerCatalogs, localeStrings, isBmwValueServicesEnabled } =
      this.context;
    // set component state to settings-context
    const contextValue = {
      appContext: this.context, // send AppContext{} as props
      localeStrings,
      locale: this.context.locale
    };
    let widgets = (
      <div className="xmm-content-adjust">
        <LoadingIndicator htmlId="SettingsLoading" />
      </div>
    );

    if (dealerCatalogs && dealerCatalogs.length !== 0) {
      widgets = (
        <div className="xmm-center-container">
          <CatalogPricing
            ref={catalogPricingRef => {
              this.settingRefs["catalogPricingRef"] = catalogPricingRef;
            }}
            onSave={this.saveDealerCatalog}
          />
          <DisplayPricing
            ref={displayPricingRef => {
              this.settingRefs["displayPricingRef"] = displayPricingRef;
            }}
            onSave={this.saveDealerCatalog}
          />
          <PartsLabor
            ref={partsLaborRef => {
              this.settingRefs["partsLaborRef"] = partsLaborRef;
            }}
            onSave={this.saveDealerCatalog}
          />
          <Taxes
            ref={taxesRef => {
              this.settingRefs["taxesRef"] = taxesRef;
            }}
            onSave={this.saveDealerCatalog}
          />
          <ShopSupplies
            ref={shopSuppliesRef => {
              this.settingRefs["shopSuppliesRef"] = shopSuppliesRef;
            }}
            onSave={this.saveDealerCatalog}
          />
          <PriceRounding
            ref={priceRoundingRef => {
              this.settingRefs["priceRoundingRef"] = priceRoundingRef;
            }}
            onSave={this.saveDealerCatalog}
          />
          <ConsumerMenus
            ref={consumerMenusRef => {
              this.settingRefs["consumerMenusRef"] = consumerMenusRef;
            }}
            onSave={this.saveDealerCatalog}
          />
          <AdvancedSettings
            ref={advancedSettingsRef => {
              this.settingRefs["advancedSettingsRef"] = advancedSettingsRef;
            }}
            isBmwValueServicesEnabled={isBmwValueServicesEnabled}
            onSave={this.saveDealerCatalog}
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <SettingsContext.Provider value={contextValue}>
          <div className="xmm-center-container">
            <div className="sticky-page-header">
              <h3> {localeStrings["xmm.portal.nav.settings"]}</h3>
            </div>
            {widgets}
          </div>
        </SettingsContext.Provider>
      </React.Fragment>
    );
  }
}

export default SettingsPage;

/* eslint-enable no-console */
