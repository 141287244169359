/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Tooltip from "@cx/ui/Tooltip";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import NumericInput from "@cx/ui/NumericInput";
import PartsLaborRow from "./PartsLaborRow";
import { AppContext } from "../../../../app-context";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
// import { doesEmpty } from "../../../../../commonUtil/utils/object";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import SelectInput from "@cx/ui/SelectInput";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class PartsLabor extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onChangeMarkup = this.onChangeMarkup.bind(this);
    this.onBlurMarkup = this.onBlurMarkup.bind(this);

    this.onChangeDefaultPart = this.onChangeDefaultPart.bind(this);
    this.updateLaborTimeMarkupPercent =
      this.updateLaborTimeMarkupPercent.bind(this);
    this.updateDefaultPart = this.updateDefaultPart.bind(this);

    this.initializeLocaleValues();

    this.partsLaborRows = [];
    const { dealer } = context;
    const { laborTimeMarkupPercent } = dealer;
    this.state = {
      laborTimeMarkupPercent,
      selectDefaultParts:
        dealer.selectDefaultParts !== null
          ? toEmptyStringIfUndefined(dealer.selectDefaultParts)
          : "1",
      dirty: false
    };
  }
  componentDidMount() {}

  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
  }

  // This handler calls its parent handler to autosave record field changes
  onUpdate = (catalog, name, payload) => {
    this.props.onSave(catalog, name, payload, "partsLaborRef");
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
  };
  onChangeDefaultPart = event => {
    const { value } = event.target;
    if (isDifferentValue(this.state.selectDefaultParts, value)) {
      this.updateDefaultPart(value);
    }
  };

  updateDefaultPart(newValue) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { dealerCode, dealer } = this.context;

    makeSecureRestApi(
      {
        url: `/ops/proxyapi/ddsproxy/rest/table/dealerSettings/${dealerCode}?_method=put`,
        method: "post",
        data: { selectDefaultParts: parseFloat(newValue) }
      },
      response => {
        this.updateStatusBox(this.savedMsg, "success", true);
        if (response && response.dealerSettings) {
          const { selectDefaultParts } = response.dealerSettings;
          dealer.selectDefaultParts =
            toEmptyStringIfUndefined(selectDefaultParts);
          this.setState({
            selectDefaultParts: dealer.selectDefaultParts
          });
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  onBlurMarkup = event => {
    const { value } = event.target;
    this.setState({}, () => {
      const { dirty } = this.state;
      if (dirty) {
        const newVal = toEmptyStringIfUndefined(value);
        this.updateLaborTimeMarkupPercent();
        this.setState({ dirty: false, laborTimeMarkupPercent: newVal });
      }
    });
  };
  // min = 0 max = 20% for Rate with 5 decimals
  onChangeMarkup = cxEvent => {
    const { value } = cxEvent.target;
    if (isDifferentValue(this.state.laborTimeMarkupPercent, value)) {
      this.setState({
        dirty: true, // set dirty flag to avoid server calls when there is no change
        laborTimeMarkupPercent: toEmptyStringIfUndefined(value)
      });
    }
  };
  updateLaborTimeMarkupPercent() {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { dealerCode, dealer } = this.context;
    const { laborTimeMarkupPercent } = this.state;
    if (isNaN(laborTimeMarkupPercent) || laborTimeMarkupPercent > 100) {
      return;
    }
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/ddsproxy/rest/table/dealerSettings/${dealerCode}?_method=put`,
        method: "post",
        data: { laborTimeMarkupPercent: parseFloat(laborTimeMarkupPercent) }
      },
      response => {
        this.updateStatusBox(this.savedMsg, "success", true);
        if (response && response.dealerSettings) {
          const { laborTimeMarkupPercent } = response.dealerSettings;
          dealer.laborTimeMarkupPercent = toEmptyStringIfUndefined(
            laborTimeMarkupPercent
          );
          this.setState({
            laborTimeMarkupPercent: dealer.laborTimeMarkupPercent
          });
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  renderMarkupTooltip() {
    const { localeStrings } = this.context;
    const laborTimeMarkupPercent = this.state.laborTimeMarkupPercent
      ? parseFloat(this.state.laborTimeMarkupPercent)
      : 0;
    const markupIndex = 1 + laborTimeMarkupPercent / 100;
    const posts = Array.from(Array(100)).map((_, i) => {
      const baseLabor = Math.round((i * 0.1 + 0.1) * 10) / 10;
      const laborAfterMarkup = Math.round(baseLabor * markupIndex * 10) / 10;
      return (
        <tr className="ant-table-row" key={baseLabor}>
          <td>
            <div>{baseLabor}</div>
          </td>
          <td>
            <div>{laborAfterMarkup}</div>
          </td>
        </tr>
      );
    });
    const main = (
      <div>
        <div>
          <b>Labor Time Example with {laborTimeMarkupPercent}% Markup</b>
          <p className="xmm-popover-text">
            {
              localeStrings[
                "xmm.portal.settings.parts_markup_popover_description"
              ]
            }
          </p>
        </div>
        <div className="table-container xmm-markup-container">
          <table>
            <thead className="ant-table-thead">
              <tr>
                <th>
                  {
                    localeStrings[
                      "xmm.portal.settings.parts_markup_popover_base_labor_time_lbl"
                    ]
                  }
                </th>
                <th>
                  {
                    localeStrings[
                      "xmm.portal.settings.parts_markup_popover_labor_time_markup_lbl"
                    ]
                  }
                </th>
              </tr>
            </thead>
            <tbody>{posts}</tbody>
          </table>
        </div>
      </div>
    );
    return main;
  }
  callChildMethod = (msg, type, close, errorInTooltip) => {
    this.updateStatusBox(msg, type, close, errorInTooltip);
  };

  /* These Utils used for form validation style */
  updateStatusBox = (msg, type, close, errorInTooltip) => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  };
  render() {
    const { dealer, dealerCatalogs, locale, localeStrings } = this.context;
    let resultTable = null;
    let catalogHtml = null;
    const partsTip = localeStrings["xmm.portal.settings.parts_tip"];
    const roundFluidsTip =
      localeStrings["xmm.portal.settings.round_fluids_tip"];
    const partsMarkupTip =
      localeStrings["xmm.portal.settings.parts_markup_tip"];
    const seeExamplesLinkText =
      localeStrings["xmm.portal.settings.see_examples_link_text"];
    const laborTimeMarkupPercent = this.state.laborTimeMarkupPercent;
    const markupPopover = this.renderMarkupTooltip();
    const showSelectDefaultPartClass = dealer.commonOpsRepairsEnabled
      ? ""
      : "hide";
    const defaultPopover = (
      <div>
        <b>
          {
            localeStrings[
              "xmm.portal.settings.default_part_pop_mainten__repair_operation_title"
            ]
          }
        </b>
        <p className="xmm-popover-text">
          {
            localeStrings[
              "xmm.portal.settings.default_part_pop_mainten__repair_operation_desc"
            ]
          }
        </p>
        <b>
          {
            localeStrings[
              "xmm.portal.settings.default_part_pop_maintein_operation_title"
            ]
          }
        </b>
        <p className="xmm-popover-text">
          {
            localeStrings[
              "xmm.portal.settings.default_part_pop_maintein_operation_desc"
            ]
          }
        </p>
        <b>
          {
            localeStrings[
              "xmm.portal.settings.default_part_pop_repair_operation_title"
            ]
          }
        </b>
        <p className="xmm-popover-text">
          {
            localeStrings[
              "xmm.portal.settings.default_part_pop_repair_operation_desc"
            ]
          }
        </p>
        <b>
          {localeStrings["xmm.portal.settings.default_part_pop_none_title"]}
        </b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.settings.default_part_pop_none_desc"]}
        </p>
      </div>
    );

    if (dealerCatalogs && dealerCatalogs.length !== 0) {
      catalogHtml = dealerCatalogs.map((record, index) => {
        return (
          <PartsLaborRow
            key={"partsLabor-" + record.make}
            ref={ref => (this.partsLaborRows[index + 1] = ref)}
            catalog={record}
            onUpdate={this.onUpdate}
          />
        );
      });

      const laborTimeMarkupPercentClass = locale === "en_US" ? "" : "hide";

      resultTable = (
        <Grid className="setting-table">
          <Row className={laborTimeMarkupPercentClass}>
            <Col xs={10} xsOffset={2} className="xmm-form-content">
              <div className="flex-content separation">
                <div>
                  {localeStrings["xmm.portal.settings.labor_time_markup_lbl"]}
                </div>
                <Tooltip
                  htmlId="tipMarkups"
                  tooltipContent={partsMarkupTip}
                  position="top"
                >
                  <IconInfoOutline
                    htmlId="IconMarkups"
                    className="hand-cursor"
                  />
                </Tooltip>
                <NumericInput
                  htmlId="partsMarkupInput"
                  inputSuffix="%"
                  label=""
                  displayLabel={false}
                  className="partsMarkupInput"
                  name="partsMarkupInput"
                  value={laborTimeMarkupPercent}
                  onChange={this.onChangeMarkup}
                  onBlur={this.onBlurMarkup}
                  minLength={0}
                  maxLength={3}
                  maxValue={100}
                />
                <Popover
                  htmlId="popoverBaseSettings"
                  popoverContent={markupPopover}
                  position="right"
                  className="xmm-wide-popover"
                  trigger={["click", "outsideClick"]}
                >
                  <Button
                    htmlId="seeMarkupExamplesLink"
                    buttonStyle="link"
                    size="small"
                    className="xmm-string-link"
                    hidden={this.state.toggleStatus === "showall"}
                    // onClick={() => {
                    //   //
                    // }}
                  >
                    {seeExamplesLinkText}
                  </Button>
                </Popover>
              </div>
            </Col>
          </Row>
          <Row className={showSelectDefaultPartClass}>
            <Col md={2} sm={2}>
              {" "}
              &nbsp;{" "}
            </Col>
            <Col md={10} sm={10} className="xmm-form-content xmm-form-default">
              <div>{localeStrings["xmm.portal.settings.default_part_lbl"]}</div>
              <div className="container-pop-default">
                <Popover
                  htmlId="tipDefaultPopover"
                  popoverContent={defaultPopover}
                  position="right"
                  trigger={["click", "outsideClick"]}
                >
                  <IconInfoOutline htmlId="defaultPopover" />
                </Popover>
              </div>
              <div className="select-input-container">
                <SelectInput
                  htmlId="defaultPart"
                  label="default Part"
                  name="defaultPart"
                  className="select-default-part"
                  onChange={this.onChangeDefaultPart}
                  placeholder="Select"
                  options={[
                    {
                      value: "0",
                      label:
                        localeStrings[
                          "xmm.portal.settings.default_part_mainten__repair_operation"
                        ]
                    },
                    {
                      value: "1",
                      label:
                        localeStrings[
                          "xmm.portal.settings.default_part_maintein_operation"
                        ]
                    },
                    {
                      value: "2",
                      label:
                        localeStrings[
                          "xmm.portal.settings.default_part_repair_operation"
                        ]
                    },
                    {
                      value: "3",
                      label:
                        localeStrings["xmm.portal.settings.default_part_none"]
                    }
                  ]}
                  displayDeselectOption={false}
                  displayLabel={false}
                  layout="horizontal"
                  value={this.state.selectDefaultParts}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={2} sm={2}>
              {" "}
              &nbsp;{" "}
            </Col>
            <Col md={10} sm={10} className="xmm-form-content xmm-form-header">
              <div className="xmm-input-col">
                {localeStrings["xmm.portal.settings.pricing_missing_lbl"]}
                <Tooltip
                  htmlId="tipParts"
                  tooltipContent={partsTip}
                  position="top"
                >
                  <IconInfoOutline htmlId="IconPriceBy" />
                </Tooltip>
              </div>
              <div className="xmm-input-col">
                {localeStrings["xmm.portal.settings.labor_scale_lbl"]}
              </div>
              <div className="xmm-fixed-col xmm-inline-tip">
                {localeStrings["xmm.portal.settings.round_fluids_lbl"]}{" "}
                <Tooltip
                  htmlId="tipFluids"
                  tooltipContent={roundFluidsTip}
                  position="top"
                >
                  <IconInfoOutline htmlId="IconFluids" />
                </Tooltip>
              </div>
            </Col>
          </Row>
          {catalogHtml}
        </Grid>
      );
    }
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    return (
      <Card htmlId="partsLaborCard">
        <div className="settings-card-header">
          <div className="header-left">
            <h4>{localeStrings["xmm.portal.settings.parts_labor_lbl"]}</h4>

            <div className="btn-div" />
          </div>
          <span className="float-right">{statusHtml}</span>
        </div>
        {resultTable}
      </Card>
    );
  }
}

export default PartsLabor;
PartsLabor.propTypes = {
  onSave: PropTypes.func
};
