/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import NavMenuLink from "./NavMenuLink";
import PublishButton from "./PublishButton";
import PreviewButton from "./PreviewButton";
const Nav = props => {
  const { links } = props;
  const navlinks = (
    <NavMenuLink
      key={"common-ops-header"}
      label={"common-ops-header"}
      links={links}
    />
  );
  return (
    <div className="nav-container">
      <div className="nav">
        <ul className="nav__pills nav">{navlinks}</ul>
      </div>
      <PublishButton />
      <PreviewButton />
    </div>
  );
};

// CX-pattern returns tab-look header
/*
const Nav = props => {
  const { intl, links } = props;
  return (
    <div className="nav-container">
      <div className="nav">
        <ul className="nav__pills nav">
          {links.map(({ path, icon, label, localekey }) => (
            <NavLink
              key={path}
              to={path}
              icon={icon}
              label={intl.formatMessage({
                defaultMessage: label,
                id: localekey
              })}
            />
          ))}
        </ul>
      </div>
      <PublishButton />
    </div>
  );
};
*/

Nav.propTypes = {
  links: PropTypes.object.isRequired
};

export default Nav;
