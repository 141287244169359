/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import { shouldShowContentPanelUp } from "../../../../../commonUtil/utils/menu";
import {isUserCreatedRule} from "./utils";
export default class LaborPricingEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    // console.log("LaborRateCodeEditor", props);
    this.state = this.createInitialState(props);
  }

  componentDidMount() {
    this.afterGuiAttached();
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data, parentHandle } = props;    
    const variant = data.make === "ANY" ? parentHandle.context.allMakeList.find(make => make.make === "all") : parentHandle.context.allMakeList.find(make => make.make === data.make);
    return {
      laborPricing: {...data, variant: variant.variant},
      highlightAllOnFocus
    };
  }

  onClick = event => {
    console.log("LaborRateCodeEditor.onClick", event);
  };

  getValue() {
    return this.state.dealerPayType;
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      const menuItemsUp = shouldShowContentPanelUp(
        "#grid-wrapper",
        this.wrapperRef,
        60
      );
      this.setState({
        highlightAllOnFocus: false,
        menuItemsUp
      });
    }
  }

  isPopup() {
    return true;
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  shouldShowContentPanelUp() {
    const gridBoundingBox = document
      .querySelector("#grid-wrapper")
      .getBoundingClientRect();
    const menuBoundingBox = this.wrapperRef.getBoundingClientRect();
    // console.log("getBoundingClientRect", gridBoundingBox, selectorBoundingBox);
    const panelHeight = 60;
    // if bottom of panel is beyond bottom of the grid rows
    if (menuBoundingBox.bottom + panelHeight > gridBoundingBox.bottom) {
      if (menuBoundingBox.top - panelHeight > gridBoundingBox.top + 40) {
        // show menu items up
        return true;
      } else {
        // show menu itmes down
        return false;
      }
    }
    return false;
  }

  renderDealerRuleMoreButton = () => {
    const { parentHandle } = this.props;
    const { laborPricing } = this.state;
    const { confirmDeleteLaborPricing, editLaborPricing } = parentHandle;
    const { context } = parentHandle;
    const { localeStrings } = context;    
    const editText =
      localeStrings["xmm.portal.common.edit_button"];
    const deleteText =
      localeStrings["xmm.portal.common.delete_button"];
    /*
    Note: Only the normal dealer labor pricing rule will have data in the service_type_code and service_type columns of the t_dealer_labor_pricing_rule table.
    The special dealer labor pricing rule will always be null for these two columns.
    This is the way we are using to distinguish with the normal dealer labor pricing rule and the special dealer labor pricing rule.*/
    if(isUserCreatedRule(laborPricing.serviceType, laborPricing.serviceTypeCode)){
        return (
            <Dropdown
              icon={<IconMore />}
              htmlId="discountActionBtn"
              name="discountActionBtn"
              className="xmm-dotted-dropdown btn--icon"
              buttonStyle="link"
              displayCaret={false}
              size="small"
              defaultOpen={true}
              popover={true}
              options={[
                {
                  label: editText,
                  value: "edit-labor-pricing",
                  onSelect: () => {
                    editLaborPricing(laborPricing);
                    this.props.api.stopEditing();
                  }
                },
                {
                  label: deleteText,
                  value: "delete-labor-pricing",
                  onSelect: () => {
                    confirmDeleteLaborPricing(laborPricing);
                    this.props.api.stopEditing();
                  }
                }
              ]}
              pullRight
            />
          );
    }
    return "";
    
  };
  render() {
    const { dealerScheduleRule } = this.state;
    const dealerRuleButton =
      this.renderDealerRuleMoreButton(dealerScheduleRule);
    return (
      <React.Fragment>
        <div ref={this.setWrapperRef}>{dealerRuleButton}</div>
      </React.Fragment>
    );
  }
}

LaborPricingEditor.propTypes = {
  data: PropTypes.object,
  parentHandle: PropTypes.object
};
