import React, { Component } from "react";
// import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import Col from "@cx/ui/Col";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../components/app-context";
import {
  getMakeBrandLogoUrl,
  makeSecureRestApi
} from "../../../../../api/xmmAxios";
import { toEmptyStringIfUndefined } from "../../../../../commonUtil/utils/string";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../../utils/gtag-eventlist";

export default class MenuTypeCard extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.menuType !== prevState.menuType) {
      return { menuType: nextProps.menuType };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.onManageBtnClick = this.onManageBtnClick.bind(this);
    this.onMenuTypeEnableDisableClick =
      this.onMenuTypeEnableDisableClick.bind(this);
    this.initializeLocaleValues();
    this.state = { menuType: props.menuType };
  }

  componentDidMount() {
    //
  }
  initializeLocaleValues() {
    this.inheritFromLabel = xlate("xmm.portal.common.inherit_from");
    this.copyLabel = xlate("xmm.portal.common.copy_label");
    this.deleteLabel = xlate("xmm.portal.common.delete_button");
    this.manageSettingsLabel = xlate("xmm.portal.common.manage_settings");
    this.oemLabel = xlate("xmm.portal.common.oem");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
  }
  onManageBtnClick = () => {
    const { menuType } = this.state;
    this.dispatchShowMenuTypeSlider(menuType);
  };

  onMenuTypeEnableDisableClick = () => {
    const { menuType } = this.state;
    menuType.enabled = menuType.enabled === 1 ? 0 : 1;
    this.setState({ menuType }, () => {
      this.enableMenuType();
    });
  };

  dispatchFilterMenuTypesByMakeAndShowDisabled(dealerMenuType) {
    const { make, showDisabled } = this.props.headerRef.state;
    window.dispatchEvent(
      new CustomEvent("filterMenuTypesByMakeAndShowDisabled", {
        detail: { make, showDisabled, dealerMenuType },
        bubbles: true,
        cancelable: true
      })
    );
  }

  enableMenuType() {
    const { updateStatusBox } = this.props.headerRef;
    updateStatusBox(this.savingdMsg, "pending", false);
    const { enabled, menuTypeId } = this.state.menuType;
    const restEndPoint = `ops/proxyapi/ddsproxy/rest/table/dealerMenuType/${menuTypeId}?_method=put`;
    const data = { enabled };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data,
        params: {}
      },
      data => {
        // NOTE: we get blank response on success; call this service to get single catalog record
        // ag-grid API to update rowData for edited cell.
        if (data && data.dealerMenuType) {
          this.dispatchFilterMenuTypesByMakeAndShowDisabled(
            data.dealerMenuType
          );
        }
        updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        updateStatusBox(msg, "error", false, true);
      }
    );
  }

  dispatchShowMenuTypeSlider(menuType) {
    const sliderMenuType = Object.assign({}, menuType);
    const { includesId } = sliderMenuType;
    sliderMenuType.includesId = includesId
      ? toEmptyStringIfUndefined(includesId)
      : "none";
    window.dispatchEvent(
      new CustomEvent("showMenuTypeSlider", {
        detail: {
          showSlider: true,
          sliderMenuType
        },
        bubbles: true,
        cancelable: true
      })
    );
  }
  render() {
    const { index, parentMenuTypeMap } = this.props;
    const menuTypeEnableToggleId = `menuTypeEnableToggleId_${index}`;
    const menuTypeManageBtn = `menuTypeManageBtn_${index}`;
    const {
      make,
      variant,
      enabled,
      enabledDealerUpdateable,
      dealerModifiable,
      menuTypeId,
      includesId,
      parentId
    } = this.state.menuType;
    const makeBrandLogoUrl = getMakeBrandLogoUrl(make, variant);
    const oemUpdatableByDealer = enabledDealerUpdateable === 1;
    const deletable =
      dealerModifiable === 1 &&
      parentId === null &&
      !parentMenuTypeMap[menuTypeId];
    // const editable = dealerModifiable === 1 || oemUpdatableByDealer;
    const copyable = dealerModifiable === 1;
    const oemFlag = dealerModifiable === 0 && parentId !== null;
    const enableFlag = !oemFlag || oemUpdatableByDealer;
    const oem = oemFlag ? this.oemLabel : "";
    // const manageable = enabled === 1 && enableFlag;
    const inheritedFromMenuType = includesId
      ? this.props.menuTypeMap[includesId.toString()]
      : null;
    const inheritedFromMenuTypeName = inheritedFromMenuType
      ? inheritedFromMenuType.description
      : "";
    const inheritedFromMenuTypeNameClassName = inheritedFromMenuTypeName
      ? "xmm_inherit_from"
      : "hide";

    const actionDropdown = [
      // {
      //   label: "Settings",
      //   disabled: !editable,
      //   onSelect: () => {
      //     const { menuType } = this.state;
      //     this.dispatchShowMenuTypeSlider(menuType);
      //   }
      // },
      {
        label: this.copyLabel,
        value: "copyLabel",
        disabled: !copyable,
        onSelect: () => {
          window.dispatchEvent(
            new CustomEvent("copyMenuType", {
              detail: {
                menuType: this.state.menuType
              },
              bubbles: true,
              cancelable: true
            })
          );
          gtmEvents.gtmTrackEvent("xmm.maintenancemenus.menutypes.copy_click");
        }
      },
      {
        label: this.deleteLabel,
        value: "deleteLabel",
        disabled: !deletable,
        onSelect: () => {
          const { make, showDisabled } = this.props.headerRef.state;
          window.dispatchEvent(
            new CustomEvent("deleteMenuType", {
              detail: {
                make,
                showDisabled,
                menuType: this.state.menuType
              },
              bubbles: true,
              cancelable: true
            })
          );
          gtmEvents.gtmTrackEvent(
            "xmm.maintenancemenus.menutypes.delete_click"
          );
        }
      }
    ];
    return (
      <Card
        htmlId={"menuTypeId_" + this.props.menuType.menuTypeId}
        className={enabled === 1 ? "" : "menu-type-disabled"}
        dropdownOptions={actionDropdown}
      >
        <Col xs={12} md={12} className="xmm-menu-type-card">
          <div className="xmm-circle-image-container">
            <img
              className="xmm-circle-image"
              src={makeBrandLogoUrl}
              alt={this.state.menuType.make}
            />
          </div>
          {/* <div className="xmm-card-description">
            {this.state.menuType.description}
          </div> */}
          <div className="xmm-card-description">
            <b>{this.state.menuType.description}</b>
            <i className={inheritedFromMenuTypeNameClassName}>
              {this.inheritFromLabel}&nbsp;{inheritedFromMenuTypeName}
            </i>
          </div>
          <div className="menu-types-oem">{oem}</div>
          <div>
            <ToggleSwitch
              htmlId={menuTypeEnableToggleId}
              defaultChecked={enabled === 1}
              disabled={!enableFlag}
              onClick={this.onMenuTypeEnableDisableClick}
            />
          </div>
          <div className="pull-right">
            <Button
              htmlId={menuTypeManageBtn}
              buttonStyle="secondary"
              // disabled={!manageable}
              onClick={this.onManageBtnClick}
            >
              {this.manageSettingsLabel}
            </Button>
          </div>
        </Col>
      </Card>
    );
  }
}

MenuTypeCard.propTypes = {
  menuType: PropTypes.object,
  menuTypeMap: PropTypes.object,
  parentMenuTypeMap: PropTypes.object,
  headerRef: PropTypes.object,
  index: PropTypes.number
};
