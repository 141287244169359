/* eslint-disable no-console */
import React from "react";
import { string, PropTypes } from "prop-types";
import { Link, Route } from "react-router-dom";
import { AppContext } from "../../components/app-context";
import { navigateToMenuLink } from "../../commonUtil/utils/menu";

export default class NavMenuItem extends React.Component {
  static propTypes = {
    label: string.isRequired,
    to: string.isRequired,
    parent: string,
    node: string,
    clicked: PropTypes.func
  };
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.isDirtyFunc = this.isDirtyFunc.bind(this);
    this.navigateToPage = this.navigateToPage.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = {};
  }
  isDirtyFunc() {
    const { currentPage } = this.context;
    const { dirty } = currentPage;
    return dirty;
  }
  navigateToPage = () => {
    const { to, parent, node } = this.props;
    const pathname = to; // node === "dashboard" ? "" : node;
    const { search } = window.location;
    this.history.push({ pathname, search });
    navigateToMenuLink(parent, node);
  };
  onClick = event => {
    const { discardUnsavedChanges } = this.context;

    if (
      !discardUnsavedChanges ||
      discardUnsavedChanges({}, this.navigateToPage, this.isDirtyFunc)
    ) {
      this.props.clicked(event, this.props.parent, this.props.node);
    } else {
      event.preventDefault();
    }
  };
  /* react-router will update styles on visited Link */
  getLinkClass = (path, match) =>
    match && match.url === path ? "nav__link--active" : "nav__link";

  /* Router Link provides declarative, accessible navigation within app
    https://reacttraining.com/react-router/web/api/Link/to-string
    https://tylermcginnis.com/react-router-pass-props-to-link/
  */
  getLink = ({ match, history }) => {
    const { to, label } = this.props;
    // console.log("getLink to:label:parent", to, label, this.props.parent);
    const locationDescriptor = {
      pathname: to,
      search: window.location.search,
      state: { fromDashboard: false }
    };
    this.history = history;

    return (
      <Link
        id={label}
        role="menuitem"
        className={this.getLinkClass(to, match)}
        to={locationDescriptor}
        onClick={this.onClick}
      >
        {label}
      </Link>
    );
  };

  render() {
    return (
      <li role="presentation">
        <Route path={this.props.to} exact>
          {this.getLink}
        </Route>
      </li>
    );
  }
}
