/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../components/app-context";
import AccordionGroup from "@cx/ui/AccordionGroup";
import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";
import SelectMenuContainer from "./SelectMenuContainer";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../../api/xmmAxios";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import { doesEmpty } from "../../../../../commonUtil/utils/object";

class DMSDealerMenuForm extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.operationGridRef = React.createRef();
    this.selectMenuContainerRef = React.createRef();
    this.isDirty = this.isDirty.bind(this);
    this.onTotalMenuCount = this.onTotalMenuCount.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.handleAddOperations = this.handleAddOperations.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.initializeLocaleValues();
    this.state = {
      intervalName: "",
      intervalId: "",
      metaVehicleFilterId: "",
      metaVehicleScope: "",
      menuTypeId: "",
      operationId: "",
      dirty: false,
      valid: false,
      errors: {},
      totalMenuCount: 0
    };
  }

  componentDidMount() {
    window.addEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }
  initializeLocaleValues() {
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.operationsLabel = xlate("xmm.portal.operations.title");
  }
  onTotalMenuCount = totalMenuCount => {
    this.setState({ totalMenuCount });
  };
  onValidate = () => {
    const valid =
      this.selectMenuContainerRef &&
      this.selectMenuContainerRef.current &&
      this.selectMenuContainerRef.current.isValid() 
      // this.operationGridRef &&
      // this.operationGridRef.current &&
      // this.operationGridRef.current.isValid();
    this.setState({ valid });
    console.log("DealerMenuForm.onValidate", valid);
  };
  validate = () => {};
  isDirty = () => {
    return (
      // this.operationGridRef.current.isDirty() ||
      this.selectMenuContainerRef.current.isDirty()
    );
  };

  addDealerMenuRules = (selectionlist, selectMenuCriteriaList) => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    showBodyMask();
    let count = selectionlist.length * selectMenuCriteriaList.length;
    const restUrl = "/ops/proxyapi/ddsproxy/rest/proc/editDealerScheduleRuleP";
    selectionlist.forEach(operation => {
      const { serviceId } = operation;
      selectMenuCriteriaList.forEach(ruleCriteria => {
        const { menuTypeId, interval, vehicleGroup } = ruleCriteria;
        const { dealerCode, make, variant, metaVehicleFilterId } = vehicleGroup;
        const { intervalId, intervalName } = interval;
        const mileages = doesEmpty(intervalId) ? [intervalName] : null;
        const data = {
          dealerScheduleRuleId: "",
          make,
          variant,
          dealerCode,
          serviceId,
          menuTypeId,
          metaVehicleFilterId,
          intervalId,
          mileages
        };
        if (!doesEmpty(intervalId)) {
          delete data.mileages;
        }
        makeSecureRestApi(
          {
            url: restUrl,
            method: "post",
            data
          },
          data => {
            if (data) {
              console.log(data);
            }
            count--;
            if (count <= 0) {
              hideBodyMask();
              this.updateStatusBox(this.savedMsg, "success", true);
              this.props.updateDealerMenuGrid();
              this.props.updateContentStatus(selectionlist);
              setTimeout(() => {
                this.props.hideSlider();
              }, 2000);
            }
          },
          error => {
            count--;
            const msg = error["message"]
              ? error.message
              : "There was an error while adding dealer menu rule.  Please try again later.";
            this.updateStatusBox(msg, "error", false, true);
            hideBodyMask();
          }
        );
      });
    });
  };

  onCreateRules = () => {
    // const selectionlist = this.operationGridRef.current.getSelectionList();
    const selectionlist = this.props.selectedOperations;
    const selectMenuCriteriaList =
      this.selectMenuContainerRef.current.getSelectMenuCriteriaList();
    console.log(selectionlist, selectMenuCriteriaList);
    this.addDealerMenuRules(selectionlist, selectMenuCriteriaList);
  };

  renderSelectOperationAccordionHeader = () => {
    const { localeStrings } = this.context;
    const totalOperationCount = this.props.selectedOperations.length;
    const title = localeStrings["xmm.portal.dealermenus.select_operations"];
    return (
      <div>
        <span>{title}</span>
        <Badge htmlId="badgeBlue" color="blue" className="pull-right">
          {totalOperationCount}
        </Badge>
      </div>
    );
  };
  renderSelectMenuAccordionHeader = () => {
    const { localeStrings } = this.context;
    const { totalMenuCount } = this.state;
    const title = localeStrings["xmm.portal.dealermenus.select_menu"];
    return (
      <div>
        <span>{title}</span>
        <Badge htmlId="badgeBlue" color="blue" className="pull-right">
          {totalMenuCount}
        </Badge>
      </div>
    );
  };
  renderCreateRuleButtonLabel = () => {
    const { localeStrings } = this.context;
    const { totalMenuCount, valid } = this.state;    
    const totalOperationCount = this.props.selectedOperations.length;
    const totalRules = totalMenuCount * totalOperationCount;
    return totalRules === 0 || !valid
      ? localeStrings["xmm.portal.dealermenus.save_pricing"]
      : localeStrings["xmm.portal.common.save_x_pricing"].replace(
          "%1",
          totalRules
        );
  };
  renderCreateRuleSummary = () => {
    const { totalMenuCount, valid } = this.state;    
    const totalOperationCount = this.props.selectedOperations.length;
    const totalRules = totalMenuCount * totalOperationCount;
    return totalRules === 0 || !valid
      ? ""
      : xlate("xmm.portal.common.save_x_pricing_for_y_operations")
          .replace("%1", totalMenuCount)
          .replace("%2", totalOperationCount);
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  handleAddOperations = () => {
    this.props.openOperationsModal();
  }
  render() {
    const { make, menuTypes } = this.props;
    const { valid } = this.state;
    const selectOperationAccordionHeader =
      this.renderSelectOperationAccordionHeader();
    const selectMenuAccordionHeader = this.renderSelectMenuAccordionHeader();
    const createRuleButtonLabel = this.renderCreateRuleButtonLabel();
    const createRuleSummary = this.renderCreateRuleSummary();
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    return (
      <React.Fragment>
        <div className="xmm-tab-bar">
          {statusBox}{" "}
          <span className="xmm-creating-rules-label">{createRuleSummary}</span>
          <Button
            htmlId="createRules"
            className=""
            size="small"
            form="opsForm"
            buttonStyle="primary"
            disabled={!valid}
            onClick={this.onCreateRules}
          >
            {createRuleButtonLabel}
          </Button>
        </div>
        <div className="xmm-slider-tab-scroll comment-form">
          <AccordionGroup.Container
            htmlId="dealerRuleConfigAccordionGroup"
            independent
          >
            <AccordionGroup.Item
              header={selectMenuAccordionHeader}
              eventKey="2"
              htmlId="dealerRuleConfigAccordion"
              defaultExpanded
            >
              <SelectMenuContainer
                ref={this.selectMenuContainerRef}
                make={make}
                menuTypes={menuTypes}
                onTotalCount={this.onTotalMenuCount}
                onValidate={this.onValidate}
              />
              <div className="xmm-tab-bar">
                <Button
                  htmlId="addOperations"
                  size="small"
                  buttonStyle="secondary"
                  onClick={this.handleAddOperations}
                >
                  {this.operationsLabel}
                </Button>
              </div>
            </AccordionGroup.Item>
          </AccordionGroup.Container>
        </div>
      </React.Fragment>
    );
  }
}

export default DMSDealerMenuForm;

DMSDealerMenuForm.propTypes = {
  make: PropTypes.string,
  menuTypes: PropTypes.array,
  selectedOperations: PropTypes.array,
  hideSlider: PropTypes.func,
  updateDealerMenuGrid: PropTypes.func,
  updateContentStatus: PropTypes.func,
  openOperationsModal: PropTypes.func
};

/* eslint-enable no-console */
