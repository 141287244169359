/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { sanitize } from "../../../commonUtil/utils/string";

export default class LaborRateTypeEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.onChange = this.handleChange.bind(this);
    this.onClick = this.handleClick.bind(this);
    this.state = this.createInitialState(props);
  }

  componentDidMount() {
    this.afterGuiAttached();
  }

  createInitialState(props) {
    return {
      data: props.data,
      value: props.value || 0,
      highlightAllOnFocus: true
    };
  }

  handleChange = event => {
    const value = sanitize(event.target.value);
    const { data } = this.props;
    window.dispatchEvent(
      new CustomEvent("showConfirmEditEvent", {
        detail: {
          data,
          oldValue: data.laborRateType,
          newValue: Number(value),
          colDef: this.props.colDef
        },
        bubbles: true,
        cancelable: true
      })
    );
  };

  handleClick = event => {
    console.log("LaborRateTypeEditor.onClick", event);
    const { parentHandle } = this.props;
    window.dispatchEvent(
      new CustomEvent("showLaborGridModalEvent", {
        detail: {
          data: this.state.data,
          colDef: this.props.colDef,
          parentHandle
        },
        bubbles: true,
        cancelable: true
      })
    );
  };

  getValue() {
    return this.state.value;
  }

  // for testing
  setValue(newValue) {
    this.setState({
      value: newValue
    });
  }

  isPopup() {
    return false;
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    const eInput = this.inputRef.current;
    eInput.focus();
    if (this.state.highlightAllOnFocus) {
      // eInput.select();
      this.setState({
        highlightAllOnFocus: false
      });
    }
  }

  render() {
    const { value } = this.state;
    const editIcon =
      value === 1 || value === "1" ? (
        <span className="labor-rate-type-icon" onClick={this.handleClick}>
          {/* <IconModeEdit htmlId="laborGridEdit" /> */}
          <i className="far fa-edit" />
        </span>
      ) : (
        ""
      );
    return (
      <React.Fragment>
        <div className="xmm-labor-rate-type-cell" title="Labor Grid">
          {editIcon}
          <select
            id="laborRateTypeSelector"
            name="laborRateType"
            className="xmm-cell-editor"
            ref={this.inputRef}
            value={value}
            onChange={this.handleChange}
            onBlur={() => {}}
          >
            <option value="0">Flat Rate</option>
            <option value="1">Labor Grid</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
}

LaborRateTypeEditor.propTypes = {
  parentHandle: PropTypes.object
};
