/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../components/app-context";
import Badge from "@cx/ui/Badge";
// import DropdownButton from "react-bootstrap/lib/DropdownButton";
// import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import Dropdown from "@cx/ui/Dropdown";
import IconMore from "@cx/ui/Icons/IconMore";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
// import { FormattedMessage } from "react-intl";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import {
  isDifferentValue,
  isEmptyString,
  toEmptyStringIfUndefined,
  sanitize
} from "../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import * as formvalidator from "../../formik/formvalidator";
import MileageGrid from "./MileageGrid";
import StatusBox from "../../../commonUtil/components/templates/StatusBox";
// import { toast } from "@cx/ui/Toast";
import { IntervalRecordDef } from "../../../constants/ModuleConstants";
import { toNumber } from "../../../commonUtil/utils/value";
import CopyIntervalModal from "./CopyIntervalModal";
import * as gtmEvents from "../../utils/gtag-eventlist";
import { xlate } from "../../../commonUtil/i18n/locales";
import { doesEmpty } from "../../../commonUtil/utils/object";

const MAX_MILEAGE = 550000;

class IntervalForm extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChangeInput: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.showMileageGridRef = React.createRef();

    this.onBlurInput = this.onBlurInput.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onDeleteInterval = this.onDeleteInterval.bind(this);
    this.onCopyInterval = this.onCopyInterval.bind(this);
    this.onCopyToOtherMakeInterval = this.onCopyToOtherMakeInterval.bind(this);
    this.showCopyMakeModal = this.showCopyMakeModal.bind(this);
    this.closeCopyModal = this.closeCopyModal.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    const { localeStrings } = this.context;
    this.initializeLocaleValues(localeStrings);
    // local state used as cache
    this.state = {
      // Better set default value  for newRecord which is compared in getDerivedStateFromProps
      newRecord: {},
      intervalId: "",
      editForm: props.editForm,
      isDeleted: false,
      supportedMakes: context.makelist,
      mileages: [],
      dealerIntervalsMap: {},
      // This holds selected Make record
      selectedMake: {
        make: "",
        variant: "",
        dealerCode: context.dealer.dealerCode
      },
      dirty: false,
      valid: false,
      errors: {
        name: "",
        mileageFrom: "",
        mileageTo: "",
        mileageInterval: "",
        mileages: "",
        make: ""
      }
    };
  }

  componentDidMount() {
    // console.log("Interval Form props", this.state.newRecord);
  }
  initializeLocaleValues(localeStrings) {
    this.nameLabel = localeStrings["xmm.portal.common.name_lbl"];
    this.unitsLabel = localeStrings["xmm.portal.common.units"];
    this.beginLabel = localeStrings["xmm.portal.common.begin"];
    this.endLabel = localeStrings["xmm.portal.common.end"];
    this.repeatIntervalLabel =
      localeStrings["xmm.portal.common.repeat_interval"];
    this.genMileagePoints =
      localeStrings["xmm.portal.common.gen_mileage_points"];
    this.copyToAnotherMakeLabel =
      localeStrings["xmm.portal.common.copy_to_another_make"];
    this.copyLabel = localeStrings["xmm.portal.common.copy_label"];
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.atLeastOneMileageError = xlate(
      "xmm.portal.errors.at_least_one_mileage"
    );
    this.uniqueIntervalNameError = xlate(
      "xmm.portal.errors.unique_interval_name"
    );
    this.duplicateMileagePointsError = xlate(
      "xmm.portal.errors.duplicate_mileage_points"
    );
    this.nameRequiredError = xlate("xmm.portal.errors.name_required");
    this.fieldRequiredError = xlate("xmm.portal.errors.required_field");
    this.copyToNameLabel = xlate("xmm.portal.common.copy_to_name");
    this.minIntervalLabel = xlate("xmm.portal.errors.min_interval_mileage");
    this.maxIntervalLabel = xlate("xmm.portal.errors.max_interval_mileage");
    this.endGreaterThanBeginError = xlate(
      "xmm.portal.errors.end_greater_than_begin_mileage"
    );
    this.minIntervalIncrementError = xlate(
      "xmm.portal.errors.min_interval_inremeent"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.mileagePointsWarning = xlate(
      "xmm.portal.manage_intervals.mileage_points_matches_another"
    );
    this.saveError = xlate("xmm.portal.errors.save_data_error");
    this.deleteBtnLabel = xlate("xmm.portal.common.delete_button");
  }
  /* Call this setter when item selected from Interval selector or when Add clicked  */
  setIntervalRecord = interval => {
    if (interval) {
      // convert mileages to array[of objects]
      const miles = convertMiles(interval);
      // Note: Add timeout to update React state when called from Sync function
      const transfromInterval = JSON.parse(JSON.stringify(interval));
      transfromInterval.usageCount = toNumber(interval.usageCount);
      // console.log("setInterval", transfromInterval);
      setTimeout(() => {
        this.setState({
          newRecord: transfromInterval,
          intervalId: transfromInterval.intervalId,
          isDeleted: false,
          dirty: false,
          valid: false,
          mileages: miles,
          errors: {
            name: "",
            mileageFrom: "",
            mileageTo: "",
            mileageInterval: "",
            mileages: "",
            make: ""
          }
        });
      }, 300);
    }
  };

  /* Instead of passing props hashmap{intervals per make}; update  hashmap(intervals) state of Form  */
  setDealerIntervalsMap = intervalsPerMakeMap => {
    if (intervalsPerMakeMap) {
      setTimeout(() => {
        this.setState({
          dealerIntervalsMap: intervalsPerMakeMap
        });
      }, 200);
    }
  };
  /* Instead of passing props {mileages per interval}; update state of Mileages grid */
  setGridMileages = (mileages, intervalId) => {
    if (this.showMileageGridRef && this.showMileageGridRef.current) {
      this.showMileageGridRef.current.setMileages(mileages, intervalId);
    }
  };
  reset = () => {
    this.updateStatusBox("", "text", true);
    this.resetState();
    if (this.showMileageGridRef && this.showMileageGridRef.current) {
      this.showMileageGridRef.current.reset();
    }
  };

  // changeEvent common for TextInput and NumericInput
  onBlurInput = cxEvent => {
    const { name } = cxEvent.target;
    this.validate(name);
  };
  onChangeInput = cxEvent => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(this.state.newRecord[name], value)) {
      this.markDirty(name);
    }
    const { newRecord } = this.state;
    newRecord[name] = sanitize(value);
    const valid = this.isValidForm();
    this.setState({ newRecord, valid });
  };

  isDirty() {
    return this.state.dirty;
  }

  onDeleteInterval = cxEvent => {
    // console.log("Delete eventKey:", eventKey, event);
    const eventKey = cxEvent.target.value;
    gtmEvents.gtmTrackEvent("xmm.intervals.delete_click");
    window.dispatchEvent(
      new CustomEvent("removeIntervalEvent", {
        detail: eventKey,
        bubbles: true,
        cancelable: true
      })
    );
  };
  /* Handler to create copy of the interval with mileages, user must modify name and mileages to avoid duplicates and save */
  onCopyInterval = cxEvent => {
    const interval = cxEvent.target.value;
    const copyInterval = Object.assign({}, interval);
    const { intervalId } = interval;
    // copy case - mark empty for intervalId, usageCount fields; copy make, dealerCode from original
    copyInterval.intervalId = "";
    copyInterval.name = this.copyToNameLabel.replace("%1", interval.name);
    copyInterval.dealerCode = interval.dealerCode;
    copyInterval.usageCount = 0;
    copyInterval.copyFromIntervalId = intervalId;
    gtmEvents.gtmTrackEvent("xmm.intervals.copy_click");
    this.setIntervalRecord(copyInterval);
    this.dispatchSelectInterval(copyInterval);
  };

  onCopyToOtherMakeInterval = selectedMakes => {
    if (selectedMakes.length > 0) {
      // console.log("copy make action", selectedMakes);
      let isValid = true;
      // check if same mileage points exist in other makes before copy inteval
      const { newRecord } = this.state;
      const { intervalId } = newRecord;
      const interval = Object.assign({}, newRecord);
      interval.intervalId = "";
      interval.copyFromIntervalId = intervalId;
      isValid = !this.verifyCopyMileages(selectedMakes, interval);
      if (!isValid) {
        this.updateStatusBox(this.mileagePointsWarning, "warning", false);
        this.setState({ showCopyModal: false });
        return;
      } else {
        // callback to save copy intervals
        gtmEvents.gtmTrackEvent("xmm.intervals.copy_othermake_click");
        this.saveCopyInterval(selectedMakes, interval);
      }
    }
  };
  showCopyMakeModal = (cxEvent, event) => {
    event.preventDefault();
    // console.log("open copy modal with makes", eventKey, event);
    this.setState({
      showCopyModal: true
    });
  };
  closeCopyModal = () => {
    this.setState({
      showCopyModal: false
    });
  };
  // copy interval to other makes case
  verifyCopyMileages(selectedMakes, intervalRecord) {
    const { mileages, intervalId } = intervalRecord;
    const miles = toEmptyStringIfUndefined(mileages);
    let count = 0;
    selectedMakes.forEach(make => {
      const found = this.checkMileagesByMake(make, miles, intervalId);
      if (found) count++;
      else count = 0;
    });
    return count > 0 ? true : false;
  }

  // validation utils
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
    const { currentPage } = this.context;
    currentPage.dirty = dirty;
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { name, mileageFrom, mileageTo, mileageInterval } =
      this.state.newRecord;
    const { errors } = this.state;
    // console.log("validate", fieldName, this.state.newRecord);

    if (!fieldName || fieldName === "name") {
      const value = name ? name.trim() : "";
      errors["name"] = !value ? this.nameRequiredError : "";
      if (errors["name"] === "") {
        errors["name"] = formvalidator.validateAlphaNumPunctuation(value);
      }
    }

    if (!fieldName || fieldName === "mileageFrom") {
      errors["mileageFrom"] = !mileageFrom ? this.fieldRequiredError : "";
      if (mileageFrom && (parseInt(mileageFrom, 10) || 0) < 1) {
        errors["mileageFrom"] = this.minIntervalLabel;
      }
      if (mileageFrom && (parseInt(mileageFrom, 10) || 0) > MAX_MILEAGE) {
        errors["mileageFrom"] = this.maxIntervalLabel;
      }
    }
    if (!fieldName || fieldName === "mileageTo") {
      errors["mileageTo"] = !mileageTo ? this.fieldRequiredError : "";
      if (mileageTo && (parseInt(mileageTo, 10) || 0) < 1) {
        errors["mileageTo"] = this.minIntervalLabel;
      }
      if (mileageTo && (parseInt(mileageTo, 10) || 0) > MAX_MILEAGE) {
        errors["mileageTo"] = this.maxIntervalLabel;
      }
    }
    if (
      mileageFrom &&
      mileageTo &&
      (parseInt(mileageFrom, 10) || 0) > (parseInt(mileageTo, 10) || 0)
    ) {
      errors["mileageTo"] = this.endGreaterThanBeginError;
    }
    if (!fieldName || fieldName === "mileageInterval") {
      errors["mileageInterval"] = !mileageInterval
        ? this.fieldRequiredError
        : "";
      if (mileageInterval && (parseInt(mileageInterval, 10) || 0) < 1000) {
        errors["mileageInterval"] = this.minIntervalIncrementError;
      }
      if (
        mileageInterval &&
        (parseInt(mileageInterval, 10) || 0) > MAX_MILEAGE
      ) {
        errors["mileageInterval"] = this.maxIntervalLabel;
      }
    }

    const valid = this.isValidForm();
    this.setState({ valid, errors });
    return valid;
  }
  isValidForm(checkGenerate) {
    const { make, name, mileageFrom, mileageTo, mileageInterval, mileages } =
      this.state.newRecord;

    if (!make) {
      return false;
    }
    const naneValue = name ? name.trim() : "";
    if (!naneValue || formvalidator.validateAlphaNumPunctuation(naneValue)) {
      return false;
    }

    if (
      !mileageFrom ||
      (parseInt(mileageFrom, 10) || 0) < 1 ||
      (parseInt(mileageFrom, 10) || 0) > MAX_MILEAGE
    ) {
      return false;
    }
    if (
      !mileageTo ||
      (parseInt(mileageTo, 10) || 0) < 1 ||
      (parseInt(mileageTo, 10) || 0) > MAX_MILEAGE
    ) {
      return false;
    }
    // End should be greater than Begin
    if (
      mileageFrom &&
      mileageTo &&
      (parseInt(mileageFrom, 10) || 0) > (parseInt(mileageTo, 10) || 0)
    ) {
      return false;
    }
    if (
      !mileageInterval ||
      (parseInt(mileageInterval, 10) || 0) < 1000 ||
      (parseInt(mileageInterval, 10) || 0) > MAX_MILEAGE
    ) {
      return false;
    }
    // Add & Edit case - At least one mileage must be in the grid in order to Save
    if (!checkGenerate && isEmptyString(mileages)) {
      return false;
    }
    return true;
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  /* Check for duplicate internalName within same make */
  verifyIntervalName(intervalRecord) {
    const { name, make, intervalId } = intervalRecord;
    const found = this.checkIntervalNameByMake(make, name, intervalId);
    return found;
  }

  verifyMileages(intervalRecord) {
    const { make, mileages, intervalId } = intervalRecord;
    const miles = toEmptyStringIfUndefined(mileages);
    const found = this.checkMileagesByMake(make, miles, intervalId);
    return found;
  }

  /* Method checks if record exists with same interval name within make */
  checkIntervalNameByMake(make, intervalName, intervalId) {
    if (!make || !intervalName) {
      return null;
    }
    const intervals = this.state.dealerIntervalsMap[make];
    if (!intervals) {
      return null;
    }
    // const uniqueNames = [...new Set(intervals.map(x => x.name))];
    // console.log(
    //   "Find IntervalName exist within Make",
    //   intervalId,
    //   intervals,
    //   uniqueNames.length
    // );
    // Edit case - skip compare edited interval from list to compare
    // Add case - find duplicate names in list
    const interval = intervals.filter(function (item) {
      if (!intervalId) {
        return item.name.toString() === intervalName.toString();
      } else {
        return (
          item.intervalId.toString() !== intervalId.toString() &&
          item.name.toString() === intervalName.toString()
        );
      }
    });
    return interval.length !== 0 ? true : false;
  }
  /* Method checks if record exists with same mileages within make */
  checkMileagesByMake(make, mileages, intervalId) {
    if (!make || !mileages) {
      return null;
    }
    const intervals = this.state.dealerIntervalsMap[make];
    if (!intervals) {
      return null;
    }
    // console.log(
    //   "Find mileages exist within Make",
    //   intervalId,
    //   mileages,
    //   intervals
    // );
    // Edit case - skip compare edited interval from list to compare
    // Add case / copy to same make - find duplicate mileages in list
    const interval = intervals.filter(item => {
      const miles = toEmptyStringIfUndefined(item.mileages);
      if (!intervalId) {
        return miles === mileages.toString();
      } else {
        return (
          item.intervalId.toString() !== intervalId.toString() &&
          miles === mileages.toString()
        );
      }
    });
    return interval.length !== 0 ? true : false;
  }

  resetState() {
    const { currentPage } = this.context;
    currentPage.dirty = false;
    this.setState({
      newRecord: Object.assign({}, IntervalRecordDef),
      selectedMake: {
        make: "",
        variant: "",
        dealerCode: ""
      },
      dirty: false,
      valid: false,
      errors: {
        name: "",
        mileageFrom: "",
        mileageTo: "",
        mileageInterval: "",
        mileages: "",
        make: ""
      }
    });
  }

  /* callback to update mileages[] in form state
     Enable Save button when mileages are deleted with vaild form.
  */
  updateMileagesFromGrid = (mileages, isDeleted) => {
    const { newRecord } = this.state;
    const milesArr = this.transformMileages(mileages);
    newRecord.mileages = milesArr.toString();
    const valid = this.isValidForm();
    this.setState({
      isDeleted,
      newRecord,
      mileages,
      valid
    });
  };
  sendParams = () => {
    const { mileageFrom, mileageTo, mileageInterval } = this.state.newRecord;
    const start = !mileageFrom ? 0 : parseInt(mileageFrom, 10);
    const end = !mileageTo ? 0 : parseInt(mileageTo, 10);
    const step = !mileageInterval ? 0 : parseInt(mileageInterval, 10);
    return {
      start,
      end,
      step
    };
  };

  // Return mileages for given range
  getMiles(params) {
    const { start, end, step } = params;
    const range = [];
    const milesArr = [];
    if (end > 0 && step > 0) {
      for (let i = start; i <= end; i = i + step) {
        const mile = {
          mileage: parseInt(i, 10)
        };
        range.push(mile);
        milesArr.push(i.toString());
      }
      // console.log("getmiles", params, range);
    }
    const { newRecord } = this.state;
    newRecord.mileages = milesArr.toString();
    const valid = this.isValidForm();
    this.setState({
      isDeleted: false,
      mileages: range,
      newRecord,
      valid
    });
  }

  loadMileages = event => {
    event.preventDefault();
    const params = this.sendParams();
    this.getMiles(params);
  };
  transformMileages(mileages) {
    const list = [];
    mileages.forEach(item => {
      list.push(item.mileage.toString());
    });
    // console.log("setMiles[]", list);
    return list;
  }
  dispatchIntervalSaved(interval, addMode) {
    window.dispatchEvent(
      new CustomEvent("intervalSaved", {
        detail: { interval, addMode },
        bubbles: true,
        cancelable: true
      })
    );
  }
  dispatchRefreshIntervals() {
    window.dispatchEvent(
      new CustomEvent("refreshIntervals", {
        detail: {},
        bubbles: true,
        cancelable: true
      })
    );
  }
  dispatchSelectInterval(interval) {
    window.dispatchEvent(
      new CustomEvent("selectInterval", {
        detail: { interval },
        bubbles: true,
        cancelable: true
      })
    );
  }
  getCopyPayload(selectedMakes, newRecord) {
    let payload = {};
    const { dealerCode, baseLocale, locale } = this.context;
    let miles = [];
    if (typeof newRecord.mileages === "string") {
      miles = newRecord.mileages.split(",");
    }
    if (!newRecord.intervalId) {
      payload = {
        dealerCode,
        makes: selectedMakes,
        name: newRecord.name,
        mileages: miles,
        mileageFrom: newRecord.mileageFrom,
        mileageTo: newRecord.mileageTo,
        mileageInterval: newRecord.mileageInterval,
        baseLocale,
        locale,
        copyFromIntervalId: newRecord.copyFromIntervalId
      };
    }
    // console.log("copy payload", payload);
    return payload;
  }
  // Save copied interval to other makes
  saveCopyInterval(selectedMakes, record) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const payload = this.getCopyPayload(selectedMakes, record);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    let restEndPoint = "";
    // add case and copy case
    const addMode = !record.intervalId;
    if (addMode) {
      restEndPoint =
        "/ops/proxyapi/ddsproxy/rest/proc/copyDealerIntervalToMakes";
    }
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        // success case
        if (data) {
          // const msgHtml = (
          //   <em>
          //     {this.context.localeStrings["xmm.portal.common.successful_save"]}
          //   </em>
          // );
          // console.log("save copy returns", data);
          this.dispatchRefreshIntervals();
          this.updateStatusBox(this.savedMsg, "success", true);
          // toast.success(msgHtml);
          this.closeCopyModal();
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        // toast.error(msg, {
        //   closeOnClick: true
        // });
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  getEditPayload() {
    const { newRecord } = this.state;
    const { dealerCode, baseLocale, locale } = this.context;
    let miles = [];
    if (typeof newRecord.mileages === "string") {
      miles = newRecord.mileages.split(",");
    }
    let payload = {};
    if (!newRecord.intervalId) {
      payload = {
        dealerCode,
        make: newRecord.make,
        name: newRecord.name,
        mileages: miles,
        mileageFrom: newRecord.mileageFrom,
        mileageTo: newRecord.mileageTo,
        mileageInterval: newRecord.mileageInterval,
        baseLocale,
        locale
      };
    } else {
      payload = {
        intervalId: newRecord.intervalId,
        name: newRecord.name,
        mileages: miles,
        mileageFrom: newRecord.mileageFrom,
        mileageTo: newRecord.mileageTo,
        mileageInterval: newRecord.mileageInterval,
        baseLocale,
        locale
      };
    }
    const { copyFromIntervalId } = newRecord;
    if (!doesEmpty(copyFromIntervalId)) {
      payload.copyFromIntervalId = copyFromIntervalId;
    }
    // console.log("payload", payload, newRecord);
    return payload;
  }
  // save single interval add/edit
  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);

    const { errors, newRecord } = this.state;
    const payload = this.getEditPayload();
    // const addMode = !newRecord.intervalId ? true : false;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    gtmEvents.gtmTrackEvent("xmm.intervals.save_click");
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      // toast.warning(this.correctInvalidFielsError);
      this.updateStatusBox(this.correctInvalidFielsError, "warning", false);
      return;
    }
    // Atleast one mileage exist for interval to save.
    if (payload.mileages.length === 0) {
      isValid = false;
      // toast.warning(this.atLeastOneMileageError);
      this.updateStatusBox(this.atLeastOneMileageError, "warning", false);
      return;
    }
    // Duplicate interval name not allowed within Make (add/edit case)
    isValid = !this.verifyIntervalName(newRecord);
    if (!isValid) {
      // toast.warning(this.uniqueIntervalNameError);
      this.updateStatusBox(this.uniqueIntervalNameError, "warning", false);
      return;
    }
    // check if mileage points matches another interval (add/edit case)
    isValid = !this.verifyMileages(newRecord);
    if (!isValid) {
      // toast.warning(this.duplicateMileagePointsError);
      this.updateStatusBox(this.duplicateMileagePointsError, "warning", false);
      return;
    }

    let restEndPoint = "";
    // add case and copy case
    const addMode = !newRecord.intervalId;
    const { copyFromIntervalId } = newRecord;
    if (!doesEmpty(copyFromIntervalId)) {
      restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/copyDealerInterval";
    } else if (addMode) {
      restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/addDealerInterval";
    } else {
      // edit case
      restEndPoint = "/ops/proxyapi/ddsproxy/rest/proc/editDealerInterval";
    }
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        // success case
        if (data) {
          // const msgHtml = (
          //   <em>
          //     {this.context.localeStrings["xmm.portal.common.successful_save"]}
          //   </em>
          // );
          if (data.length > 0) {
            const interval = this.transformObject(data[0]);
            if (!interval.dealerCode) {
              interval.dealerCode = this.context.dealerCode;
            }
            // console.log("save returns", interval);
            this.dispatchIntervalSaved(interval, addMode);
            // send response to update grid row
            this.setState(
              {
                dirty: false,
                valid: false,
                intervalId: interval.intervalId.toString()
              },
              () => {
                this.setIntervalRecord(interval);
                this.updateStatusBox(this.savedMsg, "success", true);
                // toast.success(msgHtml);
              }
            );
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
        //   toast.error(msg, {
        //     closeOnClick: true
        //   });
      }
    );
  };
  /* Util - to stringfy number values */
  transformObject = intervalRecord => {
    Object.entries(intervalRecord).forEach(([key, val]) => {
      if (val && typeof val === "number") {
        intervalRecord[key] = val.toString();
      }
    });
    return intervalRecord;
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    let editSection = null;
    const { className, formClassName } = this.props;
    const { newRecord, mileages, valid, errors } = this.state;
    const hideForm = !newRecord.dealerCode ? true : false;
    const hideFormCls = hideForm ? "hide" : className;
    const disableField = false;
    const usageCount = toNumber(newRecord.usageCount);
    const { localeStrings } = this.context;
    const template =
      usageCount > 1
        ? localeStrings["xmm.portal.common.used_by_x_items"]
        : localeStrings["xmm.portal.common.used_by_0_or_1_item"];
    const ruleText = template.replace("%1", usageCount);
    const hasRules = !newRecord.intervalId || usageCount > 0;
    const deleteMsg = this.state.isDeleted
      ? localeStrings["xmm.portal.common.some_mileages_excluded"]
      : "";
    const classHide = this.state.isDeleted ? "xmm-modal-help-text" : "hide";
    const isAdd = !newRecord.intervalId ? true : false;
    const disableGenerate = !this.isValidForm(true);
    let copyModal = null;

    if (this.state.showCopyModal) {
      copyModal = (
        <CopyIntervalModal
          show={this.state.showCopyModal}
          closeDialog={this.closeCopyModal}
          intervalRecord={this.state.newRecord}
          copyAction={this.onCopyToOtherMakeInterval}
        />
      );
    }
    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    // const isValid = !valid || !dirty;
    if (this.props.editForm && !hideForm) {
      // show form when edit clicked or add clicked
      editSection = (
        <div className={hideFormCls}>
          <Grid htmlId="settingGrid">
            <Row>
              <Col xs={3} md={3}>
                <h3>{newRecord.make}</h3>
              </Col>
              <Col xs={9} md={9}>
                <div className="pull-right">
                  {statusBox}{" "}
                  <Badge htmlId="badgeBlue" color="blue">
                    {ruleText}
                  </Badge>{" "}
                  <Button
                    htmlId="saveVehicleGroupAction"
                    form="intervalForm"
                    buttonStyle="primary"
                    disabled={!valid}
                    onClick={this.saveHandler}
                  >
                    {
                      this.context.localeStrings[
                        "xmm.portal.common.save_button"
                      ]
                    }
                  </Button>
                  <Dropdown
                    icon={<IconMore />}
                    id="intervalActionBtn"
                    htmlId="intervalActionBtn"
                    name="intervalActionBtn"
                    className="xmm-dotted-dropdown btn--icon"
                    buttonStyle="link"
                    displayCaret={false}
                    size="small"
                    disabled={disableField}
                    options={[
                      {
                        label: this.deleteBtnLabel,
                        value: this.state.newRecord,
                        disabled: hasRules,
                        onSelect: this.onDeleteInterval
                      },
                      {
                        label: this.copyLabel,
                        value: this.state.newRecord,
                        disabled: isAdd,
                        onSelect: this.onCopyInterval
                      },
                      {
                        label: this.copyToAnotherMakeLabel,
                        value: this.state.newRecord,
                        disabled: isAdd,
                        onSelect: this.showCopyMakeModal
                      }
                    ]}
                    pullRight
                  />
                  {/*
                  <DropdownButton
                    title={<IconMore />}
                    id="intervalActionBtn"
                    disabled={disableField}
                    className="xmm-dotted-dropdown btn--icon"
                    pullRight
                  >
                    <DropdownMenuItem
                      htmlId="singleDeleteBtn"
                      eventKey={this.state.newRecord}
                      disabled={hasRules}
                      onSelect={this.onDeleteInterval}
                    >
                      <FormattedMessage
                        defaultMessage="Delete"
                        id="xmm.portal.common.delete_button"
                      />
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      htmlId="singleCopyBtn"
                      disabled={isAdd}
                      eventKey={this.state.newRecord}
                      onSelect={this.onCopyInterval}
                    >
                      {this.copyLabel}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      htmlId="copytoOtherMakeBtn"
                      disabled={isAdd}
                      eventKey={this.state.newRecord}
                      onSelect={this.showCopyMakeModal}
                    >
                      {this.copyToAnotherMakeLabel}
                    </DropdownMenuItem>
                  </DropdownButton>
*/}
                </div>
              </Col>
            </Row>
            <div className={formClassName}>
              <form id="intervalForm" autoComplete="off">
                <Row>
                  <Col xs={8} md={8}>
                    <Row>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {this.nameLabel}{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={8} md={8}>
                        <TextInput
                          htmlId="fieldName"
                          label={this.nameLabel}
                          name="name"
                          disabled={disableField}
                          value={newRecord.name || ""}
                          onBlur={this.onBlurInput}
                          onChange={this.onChangeInput}
                          error={errors.name}
                          displayLabel={false}
                          maxLength={50}
                          minLength={1}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {this.unitsLabel}{" "}
                          <span className="xmm-white-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={8} md={8}>
                        <label className="xmm-form-label capitalized-text">
                          {newRecord.units || ""}
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {this.beginLabel}{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={4} md={4}>
                        <NumericInput
                          htmlId="mileageFrom"
                          label={this.beginLabel}
                          name="mileageFrom"
                          displayLabel={false}
                          onBlur={this.onBlurInput}
                          onChange={this.onChangeInput}
                          value={newRecord.mileageFrom || ""}
                          error={errors.mileageFrom}
                          disabled={disableField}
                          maxLength={6}
                          minLength={1}
                          autoInsertCommas
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {this.endLabel}{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={4} md={4}>
                        <NumericInput
                          htmlId="mileageTo"
                          label={this.endLabel}
                          name="mileageTo"
                          displayLabel={false}
                          onBlur={this.onBlurInput}
                          onChange={this.onChangeInput}
                          value={newRecord.mileageTo || ""}
                          error={errors.mileageTo}
                          disabled={disableField}
                          maxLength={6}
                          minLength={1}
                          autoInsertCommas
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4} md={4}>
                        <span className="float-right">
                          {this.repeatIntervalLabel}{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                      </Col>
                      <Col xs={4} md={4}>
                        <NumericInput
                          htmlId="mileageInterval"
                          label={this.repeatIntervalLabel}
                          name="mileageInterval"
                          displayLabel={false}
                          onBlur={this.onBlurInput}
                          onChange={this.onChangeInput}
                          value={newRecord.mileageInterval || ""}
                          error={errors.mileageInterval}
                          disabled={disableField}
                          maxLength={6}
                          minLength={4}
                          autoInsertCommas
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="xmm-generate-mileage-points-btn"
                        xs={8}
                        md={8}
                        mdOffset={4}
                        xsOffset={4}
                      >
                        <Button
                          htmlId="showMileagesBtn"
                          buttonStyle="secondary"
                          size="small"
                          disabled={disableGenerate}
                          onClick={this.loadMileages}
                        >
                          {this.genMileagePoints}
                        </Button>
                      </Col>
                    </Row>
                    <Col xs={8} md={8} mdOffset={4}>
                      <div className={classHide}>
                        <IconInfoOutline htmlId="InfoHelp" />
                        <span className="xmm-inline-icon">{deleteMsg}</span>
                      </div>
                    </Col>
                  </Col>
                  <Col xs={4} md={4}>
                    <MileageGrid
                      ref={this.showMileageGridRef}
                      intervalId={newRecord.intervalId}
                      updateMileagesFromGrid={this.updateMileagesFromGrid}
                      mileages={mileages}
                    />
                  </Col>
                </Row>
              </form>
            </div>
          </Grid>
          {copyModal}
        </div>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default IntervalForm;

IntervalForm.propTypes = {
  className: PropTypes.string,
  editForm: PropTypes.bool,
  formClassName: PropTypes.string
};

IntervalForm.defaultProps = {
  className: "xmm-manage-vehicle-root",
  formClassName: "xmm-manage-vehicle-form"
};

function convertMiles(newRecord) {
  const { intervalId } = newRecord;
  let miles = [];
  const final = [];
  // Add case
  if (!intervalId) {
    miles = [];
  } else if (typeof newRecord.mileages === "string") {
    miles = newRecord.mileages.split(",");
  }
  if (miles.length > 0) {
    miles.forEach(m => {
      const cloneObj = {
        mileage: parseInt(m, 10)
      };
      final.push(cloneObj);
    });
  }
  // console.log("convertMiles", final);
  return final;
}
/* eslint-enable no-console */
