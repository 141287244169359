import React, { Component } from "react";
import Button from "@cx/ui/Button";
import SlidingDrawerPage from "../../commonUtil/components/SlidingDrawerPage";
import PublishTabs from "../pages/Publish/PublishTabs";
import { AppContext } from "../app-context";
import * as gtmEvents from "../utils/gtag-eventlist";

class PublishButton extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      sliderWidth: 300,
      showSlide: false
    };
    this.publishSlider = this.publishSlider.bind(this);
  }

  /**
   * Add event listener
   */
  componentDidMount() {}

  publishSlider = () => {
    gtmEvents.trackGtagPageview("/publish");
    this.setState(prevState => ({
      showSlide: !prevState.showSlide
    }));
  };

  render() {
    const { localeStrings, makelist,getClassWithBridgeBar } = this.context;
    const disabled = makelist.length === 0;
    return (
      <span className="float-right">
        <Button
          htmlId="PublishBtn"
          buttonStyle="primary"
          size="small"
          disabled={disabled}
          onClick={this.publishSlider}
        >
          {localeStrings["xmm.portal.nav.publish"]}
        </Button>
        <SlidingDrawerPage
          title={localeStrings["xmm.portal.publish.slide_title"]}
          htmlId="SlidingPublish"
          showSlide={this.state.showSlide}
          toggleDrawer={this.publishSlider}
          sliderWidth={this.state.sliderWidth}
          className= {getClassWithBridgeBar("xmm-slider")}
        >
          <PublishTabs closeslider={this.publishSlider} />
        </SlidingDrawerPage>
      </span>
    );
  }
}

export default PublishButton;
