import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import KeyboardArrowLeftIcon from "@cx/ui/Icons/IconKeyboardArrowLeft";
import KeyboardArrowRightIcon from "@cx/ui/Icons/IconKeyboardArrowRight";
import SelectInput from "@cx/ui/SelectInput";
import { useServiceSearchContext } from "../../state/service-search.context";

import "./ButtonGroup.scss";

const ButtonGroup = props => {
  const {
    options,
    onOptionClick,
    onLeftArrowClick,
    onRightArrowClick,
    onDrivingConditionChange,
    vehicle
  } = props;
  const { state } = useServiceSearchContext();
  const { vehicleDefaultDrivingCondition } = state;
  const [menuDrivingCondition, setMenuDrivingCondition] = useState(
    isEmpty(vehicleDefaultDrivingCondition)
      ? "Normal"
      : vehicleDefaultDrivingCondition
  );

  const handleLeftArrowClick = () => {
    onLeftArrowClick(menuDrivingCondition);
  };

  const handleRightArrowClick = () => {
    onRightArrowClick(menuDrivingCondition);
  };

  const handleChangeOption = optionIndex => {
    onOptionClick(optionIndex, menuDrivingCondition);
  };

  const handleDrivingConditionChange = cxEvent => {
    const { value } = cxEvent.target;
    setMenuDrivingCondition(value);
    onDrivingConditionChange(value);
  };
  let drivingConditions = [];
  let showDrivingCondition = false;
  if (!isEmpty(vehicle)) {
    showDrivingCondition =
      vehicle.showDrivingCondition && vehicle.drivingConditions.length > 0;
    drivingConditions = vehicle.drivingConditions;
  }
  return (
    <div id="buttonGroupContainer">
      {options.length && (
        <>
          <Button
            htmlId="leftArrowButton"
            buttonStyle="link"
            onClick={handleLeftArrowClick}
            disabled={options[0].rawRecord.mileage === 0}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          {options[0].rawRecord.mileage > 0 && (
            <Button
              htmlId={`optionButton${options[0].id}`}
              type="button"
              size="sm"
              buttonStyle="secondary"
              onClick={() => handleChangeOption(0)}
            >
              {options[0].label}
            </Button>
          )}
          {options.length > 1 && options[1].rawRecord.mileage > 0 && (
            <Button
              htmlId={`optionButton${options[1].id}`}
              type="button"
              size="sm"
              buttonStyle="primary"
              onClick={() => handleChangeOption(1)}
            >
              {options[1].label}
            </Button>
          )}
          {options.length > 2 && options[2].rawRecord.mileage > 0 && (
            <Button
              htmlId={`optionButton${options[2].id}`}
              type="button"
              size="sm"
              buttonStyle="secondary"
              onClick={() => handleChangeOption(2)}
            >
              {options[2].label}
            </Button>
          )}
          <Button
            htmlId="rightArrowButton"
            buttonStyle="link"
            onClick={handleRightArrowClick}
            disabled={options[options.length - 1].rawRecord.mileage === 0}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </>
      )}

      {showDrivingCondition && (
        <>
          <div className="driving-condition-label">Driving Conditions</div>
          <div className="driving-condition-field">
            <SelectInput
              htmlId="drivingConditionSelect"
              name="drivingConditions"
              label=""
              disabled={!showDrivingCondition}
              displayLabel={false}
              displayPlaceholder={false}
              displayDeselectOption={false}
              maxHeight={150}
              value={menuDrivingCondition}
              onChange={handleDrivingConditionChange}
              options={drivingConditions}
            />
          </div>
        </>
      )}
    </div>
  );
};

ButtonGroup.defaultProps = {
  options: [],
  onOptionClick: () => {},
  onLeftArrowClick: () => {},
  onRightArrowClick: () => {}
};

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ),
  onOptionClick: PropTypes.func,
  onLeftArrowClick: PropTypes.func,
  onRightArrowClick: PropTypes.func
};

export default ButtonGroup;
