import { appTypes } from "../constants/config.constants";
import isEmpty from "lodash/isEmpty";

/**
 * Message utility
 *
 * @param {object} errors
 * @param {object} service
 * @returns string
 */
const getWarningMessages = (errors, service) => {
  return Object.keys(errors)
    .filter(error => errors[error])
    .map(error => {
      if (error === "payTypes") {
        return `The service ${
          service.name
        } can't be added without paytype for dealer ${service.dealer} ${
          service.confirmationId ? `, quote ${service.confirmationId}` : ""
        }`;
      }

      return null;
    })
    .join(", ")
    .replace(/,$/, ".");
};

/**
 * Generates an array of pay type options with a value and a label.
 *
 * @param {Array<{ dealerPayTypeId: number, payCode: string, description: string }>} payTypes - The array of pay types to process.
 * @param {string} appType - Current appType { SQ, CSR }
 * @returns {Array<{ value: string, label: string }>} An array of objects each with a value and label representing a pay type.
 */
const buildPayTypeOption = (payTypes, appType = "") => {
  if (isEmpty(payTypes)) {
    return [];
  }

  const isCSR = appType === appTypes.CSR;

  return payTypes
    .filter(payType => (isCSR ? payType.payCode !== "S" : true))
    .map(({ dealerPayTypeId, payCode }) => ({
      value: dealerPayTypeId.toString(),
      label: payCode
    }));
};

/**
 * This function return parts with { selected: true } property
 * @param {*} parts
 * @returns
 */
const filterPartsBySelected = (parts = []) =>
  parts?.filter(part => part.selected);

export { getWarningMessages, buildPayTypeOption, filterPartsBySelected };
