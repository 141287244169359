/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../app-context";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import SelectInput from "@cx/ui/SelectInput";
import NumericInput from "@cx/ui/NumericInput";
import Button from "@cx/ui/Button";
import IconAdd from "@cx/ui/Icons/IconAdd";
import IconRemove from "@cx/ui/Icons/IconRemove";
import {
  DepartmentOptions,
  AccountOptionsFee,
  AccountOptionsDiscount
} from "../../../../constants/ModuleConstants";
import { PropTypes } from "prop-types";
import { xlate } from "../../../../commonUtil/i18n/locales";
import { isDifferentValue } from "../../../../commonUtil/utils/string";

class AllocationField extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.removeAllocation = this.removeAllocation.bind(this);
    this.updateAllocation = this.updateAllocation.bind(this);
    this.addAllocation = this.addAllocation.bind(this);
    this.initializeLocaleValues();
    const departmentOptions = [...DepartmentOptions];
    const accountOptionsFee = [...AccountOptionsFee];
    const accountOptionsDiscount = [...AccountOptionsDiscount];
    this.state = {
      departmentOptions,
      accountOptionsFee,
      accountOptionsDiscount,
      field: props.field
    };
  }

  initializeLocaleValues() {
    this.removeLabel = xlate("xmm.portal.fees.form.remove_btn");
  }
  onChange = event => {
    const { name, value } = event.target;
    const { field } = this.state;
    if (isDifferentValue(field[name], value)) {
      this.setState(
        () => {
          field[name] = value;
          return { field };
        },
        () => {
          this.updateAllocation(name);
        }
      );
    }
  };
  updateAllocation = name => {
    const { field } = this.state;
    const index = this.props.index;
    const type = this.props.type;
    window.dispatchEvent(
      new CustomEvent("updateAllocationEvent", {
        detail: { index, field, name, type },
        bubbles: true,
        cancelable: true
      })
    );
  };
  addAllocation = () => {
    const index = this.props.index;
    const type = this.props.type;
    window.dispatchEvent(
      new CustomEvent("addAllocationEvent", {
        detail: { index, type },
        bubbles: true,
        cancelable: true
      })
    );
  };
  removeAllocation = () => {
    const index = this.props.index;
    const type = this.props.type;
    window.dispatchEvent(
      new CustomEvent("removeAllocationEvent", {
        detail: { index, type },
        bubbles: true,
        cancelable: true
      })
    );
  };
  renderRegularFields() {
    // console.log("renderRegularFields", this.state.name, this.state.operator);
    const { index } = this.props;
    const {
      departmentOptions,
      accountOptionsFee,
      accountOptionsDiscount,
      field
    } = this.state;
    const allocationRow = this.props.field;
    const totalRows = this.props.totalAllocationFields;
    const pullRows =
      (this.props.type === "parts" || this.props.type === "labor") &&
      index !== 0
        ? 3
        : 0;
    const discountAllocation =
      this.props.type === "parts" || this.props.type === "labor";
    return (
      <Grid htmlId="settingGrid">
        <Row className="show-grid">
          <Col xs={3} md={3} xsOffset={pullRows} mdOffset={pullRows}>
            <SelectInput
              htmlId={`department${index}`}
              name="department"
              options={departmentOptions}
              onChange={this.onChange}
              displayLabel={false}
              required
              value={allocationRow.department}
              displayDeselectOption={false}
            />
          </Col>
          <Col xs={3} md={3}>
            <SelectInput
              htmlId={`account${index}`}
              name="account"
              options={
                discountAllocation ? accountOptionsDiscount : accountOptionsFee
              }
              onChange={this.onChange}
              displayLabel={false}
              required
              disabled={field.department === null || field.department === ""}
              value={allocationRow.account}
              displayDeselectOption={false}
            />
          </Col>
          <Col xs={2} md={2}>
            <NumericInput
              htmlId={`allocation${index}`}
              name="allocation"
              label={
                this.context.localeStrings[
                  "xmm.portal.fees.form.account_allocation_lbl"
                ]
              }
              displayLabel={false}
              inputSuffix="%"
              onChange={this.onChange}
              maxValue={100}
              value={allocationRow.allocation}
              required
              disabled={
                field.account === null ||
                field.account === "" ||
                totalRows === 1
              }
            />
          </Col>
          <Col xs={1} md={1} className="full-col">
            <Button
              htmlId={`ButtonRemove${index}`}
              className={index === 0 ? "hide" : "btn--icon"}
              style={{ paddingLeft: "12px" }}
              aria-label="Remove"
              buttonStyle="link"
              size="sm"
              onClick={this.removeAllocation}
            >
              <IconRemove />
            </Button>
            <Button
              htmlId={`IconAdd${index}`}
              className={index !== 0 ? "hide" : "btn--icon"}
              style={{ paddingLeft: "12px" }}
              aria-label="Add"
              buttonStyle="link"
              size="sm"
              onClick={this.addAllocation}
              disabled={totalRows >= 15}
            >
              <IconAdd />
            </Button>
          </Col>
        </Row>
      </Grid>
    );
  }

  render() {
    return this.renderRegularFields();
  }
}

export default AllocationField;

AllocationField.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  totalAllocationFields: PropTypes.number,
  type: PropTypes.string
};
