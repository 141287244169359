import React, { Component } from "react";
import { bool, PropTypes } from "prop-types";
import SlidingDrawer from "@cx/ui/SlidingDrawer";
import IconMaximize from "@cx/ui/Icons/IconMaximize";
import IconMinimize from "@cx/ui/Icons/IconMinimize";
import Popover from "@cx/ui/Popover";
import Badge from "@cx/ui/Badge";
import { doesEmpty } from "../utils/object";
import { xlate } from "../i18n/locales";
/**
 * @class GenericSlider
 * This Component used as common Form slider in many pages
 */
export default class GenericSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      sliderWidth: props.sliderWidth,
      showSlide: props.showSlide,
      expandSlider: props.expandSlider,
      toggleSlider: props.toggleSlider,
      pricingMethod: props.pricingMethod,
      isExpand: false
    };
  }
  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const slideWidth = this.props.sliderWidth;
    const setWidth = this.props.flexWidth;
    if (setWidth) {
      const flexWidth = Math.round(window.innerWidth - 300);
      this.setState({ sliderWidth: flexWidth });
    } else {
      this.setState({ sliderWidth: slideWidth });
    }
  }
  onIconClick = () => {
    this.setState(
      prevState => {
        return { isExpand: !prevState.isExpand };
      },
      () => {
        const { isExpand } = this.state;
        window.dispatchEvent(
          new CustomEvent("expandSlider", {
            detail: { isExpand },
            bubbles: true,
            cancelable: true
          })
        );
      }
    );
  };

  render() {
    const { pricingMethod } = this.props;
    const clsHide = doesEmpty(pricingMethod)
      ? "hide"
      : "xmm-slider-popover-badge";
    const flowBadge =
      pricingMethod === 1
        ? xlate("xmm.portal.common.calculated_lbl")
        : pricingMethod === 2
        ? xlate("xmm.portal.common.total_flat_lbl")
        : pricingMethod === 0
        ? xlate("xmm.portal.common.no_pricing_lbl")
        : "";
    const workflow =
      pricingMethod === 1
        ? xlate("xmm.portal.operations.calculated_msg")
        : pricingMethod === 2
        ? xlate("xmm.portal.operations.flat_msg")
        : pricingMethod === 0
        ? xlate("xmm.portal.operations.no_pricing_msg")
        : "";
    return (
      <div>
        <SlidingDrawer
          htmlId="GenericSlider"
          show={this.props.showSlide}
          panelWidth={this.state.sliderWidth}
          hide={this.props.toggleSlider} // To be removed after moving to CX4
          onHide={this.props.toggleSlider}
          closeButton={true}
          position="right"
          className={
            (this.props.className ? this.props.className :
            ((this.props.flexWidth ? "xmm-flex-slider" : ""))) +
            (this.state.isExpand ? " xmm-expanded-slider" : "")
          }
        >
          <h3>{this.props.title}</h3>
          <div
            className={
              this.props.expandSlider ? "xmm-slider-expand-button" : "hide"
            }
          >
            <div className={clsHide}>
              <Popover
                htmlId="sliderBadgePopover"
                trigger={["click", "outsideClick"]}
                popoverContent={workflow}
              >
                <Badge htmlId="mixedBadge" className="hand-cursor">
                  {flowBadge}
                </Badge>
              </Popover>
            </div>
            <IconMaximize
              htmlId="IconNoteExample"
              onClick={this.onIconClick}
              hidden={this.state.isExpand}
            />
            <IconMinimize
              htmlId="IconNoteExample"
              onClick={this.onIconClick}
              hidden={!this.state.isExpand}
            />
          </div>

          {this.props.children}
        </SlidingDrawer>
      </div>
    );
  }
}

GenericSlider.propTypes = {
  title: PropTypes.object,
  sliderWidth: PropTypes.number,
  flexWidth: bool,
  showSlide: bool,
  expandSlider: bool,
  children: PropTypes.object,
  toggleSlider: PropTypes.func,
  pricingMethod: PropTypes.number
};
