import React from "react";
import { PropTypes } from "prop-types";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import Badge from "@cx/ui/Badge";
import Tooltip from "@cx/ui/Tooltip";
import { doesEmpty } from "../../../../../commonUtil/utils/object";
import { defaultToZeroIfNullOrEmpty } from "../../../../../commonUtil/utils/value";
import PreviewContext from "../../preview-context";

/* eslint-disable no-console */
class OperationRow extends React.Component {
  static contextType = PreviewContext;
  constructor(props, context) {
    super(props, context);
    this.toggleRowClick = this.toggleRowClick.bind(this);
    const { localeStrings } = context.appContext;
    this.initializeLocaleValues(localeStrings);
    this.state = {
      isExpand: false
    };
  }
  componentDidMount() {}
  initializeLocaleValues(localeStrings) {
    this.consumerLabel = localeStrings["xmm.portal.preview.vehicle.consumer"];
    this.laborLabel = localeStrings["xmm.portal.preview.vehicle.labor"];
    this.hoursLabel = localeStrings["xmm.portal.preview.vehicle.hoours"];
    this.partsLabel = localeStrings["xmm.portal.preview.vehicle.parts"];
    this.descriptionLabel = localeStrings["xmm.portal.common.description_lbl"];
    this.priceLabel = localeStrings["xmm.portal.packages.price_lbl"];
    this.totalLabel = localeStrings["xmm.portal.common.total"];
    this.numberLabel = localeStrings["xmm.portal.common.number"];
    this.qtyLabel = localeStrings["xmm.portal.common.qty"];
    this.pricingSourceLabel =
      localeStrings["xmm.portal.preview.vehicle.inspect_price_source_txt"];
  }
  toggleRowClick = event => {
    event.preventDefault();
    const { service } = this.props;
    const partsExist = service.parts && service.parts.length > 0 ? true : false;
    const laborExist =
      defaultToZeroIfNullOrEmpty(service.time) !== 0 ||
      defaultToZeroIfNullOrEmpty(service.unscheduledLaborPrice) !== 0
        ? true
        : false;
    if (partsExist || laborExist) {
      this.setState(prevState => ({
        isExpand: !prevState.isExpand
      }));
    }
  };

  render() {
    const { isExpand } = this.state;
    const { service } = this.props;
    // const showTip = service.name.trim().length > 64 ? true : false;
    const clsTooltip =
      service.name.trim().length > 64 ? "xmm-tooltip-delay" : "hide";
    const partsTableExist = true;
    // service.parts && service.parts.length > 0 ? true : false;
    const laborExist = true;
    // defaultToZeroIfNullOrEmpty(service.time) !== 0 ||
    // defaultToZeroIfNullOrEmpty(service.unscheduledLaborPrice) !== 0
    //   ? true
    //   : false;
    const showPartLabor = partsTableExist || laborExist ? true : false;
    const clsRow = isExpand ? "op-row modified" : "op-row";

    let laborHtml = null;
    let partsHtml = null;
    const clsLabor = laborExist ? "table-parts" : "xmm-hide-cmp";
    const clsPartsTable = partsTableExist
      ? "table-parts parts-border"
      : "xmm-hide-cmp";
    if (
      defaultToZeroIfNullOrEmpty(service.time) !== 0 ||
      defaultToZeroIfNullOrEmpty(service.unscheduledLaborPrice) !== 0
    ) {
      const laborPrice = doesEmpty(service.unscheduledLaborPrice)
        ? ""
        : "$" + service.unscheduledLaborPrice.toFixed(2);
      laborHtml = (
        <table className={clsLabor} key={"labor-" + service.id}>
          <tbody>
            <tr className="op-labor">
              <td>
                {this.laborLabel} - <strong>{laborPrice}</strong>
              </td>
              <td>
                {service.time} {this.hoursLabel}{" "}
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      laborHtml = (
        <table className={clsLabor} key={"labor-" + service.id}>
          <tbody>
            <tr className="op-labor">
              <td>{this.laborLabel}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    /* This field used to check if service has parts override for A LA carte
      {True} - display parts override total price and strike-out each part price under its service
    */
    const partsAlacartePriceOverride = service.hasOwnProperty(
      "partsAlacartePriceOverride"
    )
      ? service.partsAlacartePriceOverride
      : false;
    let styleLine = {
      textDecoration: "none"
    };
    if (service.hasOwnProperty("parts") && service.parts.length > 0) {
      let totalPrice = 0;
      // case - show total price even if parts [] empty
      const childHtml = service.parts.map((c, index) => {
        totalPrice = totalPrice + c.partsPrice;
        if (partsAlacartePriceOverride) {
          totalPrice = service.partsPrice;
          styleLine = {
            textDecoration: "line-through"
          };
        }
        const keyId = "part-" + c.id + "-" + index;
        return (
          <tr key={keyId}>
            <td />
            <td>
              {c.description || ""} &nbsp;
              {c.oilType || ""}
            </td>
            <td>{c.oemPartNumber || ""}</td>
            <td>{c.quantity > 0 ? c.quantity : ""}</td>
            <td style={styleLine}>
              {c.unitPrice > 0 ? "$" + c.unitPrice.toFixed(2) : ""}{" "}
            </td>
            <td style={styleLine}>
              {c.partsPrice > 0 ? "$" + c.partsPrice.toFixed(2) : ""}
            </td>
          </tr>
        );
      });
      partsHtml = (
        <table className={clsPartsTable}>
          <tbody>
            <tr>
              <td>
                {this.partsLabel} -{" "}
                <strong>
                  {totalPrice > 0 ? "$" + totalPrice.toFixed(2) : ""}
                </strong>
              </td>
              <td>{this.descriptionLabel}</td>
              <td>{this.numberLabel}</td>
              <td>{this.qtyLabel}</td>
              <td>{this.priceLabel}</td>
              <td>{this.totalLabel}</td>
            </tr>
            {childHtml}
          </tbody>
        </table>
      );
    } else {
      partsHtml = (
        <table className={clsPartsTable}>
          <tbody>
            <tr>
              <td>{this.partsLabel}</td>
              <td>{this.descriptionLabel}</td>
              <td>{this.numberLabel}</td>
              <td>{this.qtyLabel}</td>
              <td>{this.priceLabel}</td>
              <td>{this.totalLabel}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    /* else if (service.hasOwnProperty("parts") && service.parts.length === 0) {
      let totalPrice = 0;
      if (partsAlacartePriceOverride) {
        totalPrice = service.partsPrice;
        styleLine = {
          textDecoration: "line-through"
        };
      }
      partsHtml = (
        <table className="table-parts">
          <tbody>
            <tr className="op-labor">
              <td>
                {this.partsLabel} -{" "}
                <strong>
                  {totalPrice > 0 ? "$" + totalPrice.toFixed(2) : ""}
                </strong>
              </td>
              <td> &nbsp; </td>
            </tr>
          </tbody>
        </table>
      );
    } */
    const iconCmp = this.state.isExpand ? (
      <IconKeyboardArrowDown />
    ) : (
      <IconKeyboardArrowRight />
    );

    let priceSource = "-";
    if (service.hasOwnProperty("priceSource")) {
      priceSource = service.priceSource ? (
        <Tooltip
          tooltipContent={this.pricingSourceLabel}
          className="xmm-tooltip-delay"
        >
          <Badge htmlId={"source" + service.id}>{service.priceSource}</Badge>
        </Tooltip>
      ) : (
        ""
      );
    }
    const clsHand = showPartLabor ? "op-title hand-cursor" : "op-title";
    return (
      <div className={clsRow}>
        <div className="op-name">
          <div className="top-level">
            <div className="op-icon" onClick={this.toggleRowClick}>
              {" "}
              {showPartLabor ? iconCmp : <i className="xmm-hide-cmp" />}{" "}
            </div>
            <div className={clsHand} onClick={this.toggleRowClick}>
              <Tooltip
                htmlId={"service"}
                tooltipContent={service.name}
                position="top"
                className={clsTooltip}
              >
                <div className="xmm-truncate-text">{service.name}</div>
              </Tooltip>
            </div>
            <div className="op-opcode">{service.categoryLabel}</div>
            <div className="op-opcode">{service.opCode}</div>
            <div className="op-consumer">{priceSource}</div>
            <div className="op-price">{service.priceLabel}</div>
          </div>
          <div>
            {laborHtml}
            {partsHtml}
          </div>
        </div>
      </div>
    );
  }
}
export default OperationRow;

OperationRow.propTypes = {
  service: PropTypes.object
};
