import moment from "moment";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import { tagsMap, mockPartTemplate } from "./dealer-tire.constants";
const sleepOut = ms => new Promise(resolve => setTimeout(resolve, ms));
/*
 * Place Util methods to support dealer tire page
 */
function dealerCodeToDealerId(dealerCode) {
  return hashCode(dealerCode) & 0x7fffffff;
}

/**
 * Gets the string's hash code (32-bit signed integer)
 * Got from: http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery
 * @param str
 * @returns {Number}
 */
function hashCode(str) {
  let hash = 0,
    i,
    cchar;
  if (str.length === 0) return hash;
  const len = str.length;
  for (i = 0; i < len; i++) {
    cchar = str.charCodeAt(i);
    hash = (hash << 5) - hash + cchar;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Gets url for any service
 * @param secret
 * @param {Object} reqParams At least it must have the following properties: dealer, handle, nonce, return
 * @return {String}
 */
function getServiceUrl(appEnv, secret, reqParams, eventName) {
  setCustomParam(reqParams, eventName);
  const prodUrl = "https://www.e-dealertire.com/product-catalog/";
  const nonprodUrl = "https://demo.e-dealertire.com/product-catalog/";
  const providerUrl = appEnv === "prod" ? prodUrl : nonprodUrl;
  const queryString = getQueryStringWithSignature(secret, reqParams);
  return providerUrl + queryString;
}

/**
 * Modifies reqParams to include customParam
 * @param {Map} reqParams BYREF The webservice params. key-value map
 * @param {Object} [eventName] Any js object to send as param
 */
function setCustomParam(reqParams, eventName) {
  const jsonedCustomParams = JSON.stringify(!eventName ? null : eventName);
  reqParams.return =
    reqParams.return +
    "?" +
    "customParam=" +
    btoa(jsonedCustomParams).replace(/\+/g, "-").replace(/=/g, "_");
  return reqParams;
}

/**
 * Gets the custom params set previously with setCustomParams
 * @param {Map} respGetParams The $_GET sent to return url
 * @return {Object} Any js object sent as param
 */
function getCustomParam(respGetParams) {
  const strCustomParams = respGetParams["customParam"],
    jsonedCustomParams = atob(
      strCustomParams.replace(/-/g, "+").replace(/_/g, "=")
    );
  const customParams = JSON.parse(jsonedCustomParams);
  return customParams;
}

/**
 * Gets http parameters as query string in signature
 * @param {String} secret
 * @param {Object} reqParams Example: {param1: 'value1', param2: 'value2'}
 * @return {String} Example: param1=value1&param2=value2&signature=XYZ
 */
function getQueryStringWithSignature(secret, reqParams) {
  const pairs = [];
  for (const key in reqParams) {
    let value = reqParams[key];
    if (!value) value = "";
    // @note: DON'T add encodeURIComponent here. It should be correct, but Dealer Tire signature algorithm requires not to have it
    pairs.push(key + "=" + value);
  }
  const signSalt = "?" + pairs.join("&");
  let signature = "";
  let hmacBytes = "";
  if (window.Crypto && window.Crypto.HMAC) {
    // eslint-disable-next-line new-cap
    hmacBytes = window.Crypto.HMAC(Crypto.SHA1, signSalt, secret, {
      asBytes: true
    });
    signature = window.Crypto.util.bytesToBase64(hmacBytes);
  } else if (window.CryptoJS) {
    // eslint-disable-next-line new-cap
    hmacBytes = window.CryptoJS.HmacSHA1(signSalt, secret);
    signature = window.CryptoJS.enc.Base64.stringify(hmacBytes);
  }
  console.log("DealerTire Signature: ", signature);
  return (
    signSalt +
    "&signature=" +
    encodeURIComponent(signature) +
    "&includeTax=false"
  );
}

/**
 * Converts a date to DT UTC format. Ex: 2013-11-25T17:03:59
 * @param {Date} date
 * @returns {String}
 */
function dateToUtcFormat(date) {
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  const dateToUtcFormat = moment(utcDate).format("YYYY-MM-DDTHH:mm:ss");
  return dateToUtcFormat;
}

function encodeToQueryString(params) {
  const queryStr = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join("&");
  return queryStr;
}
function toQueryString(entries) {
  const queryStr = Object.keys(entries)
    .map(key => key + "=" + entries[key])
    .join("&");
  return queryStr;
}

function paramsToObject(queryString) {
  const pairs = queryString.replace(/^\?/, "").split("&");
  const result = {};
  pairs.forEach(function (pair) {
    const tpair = pair.split("=");
    result[tpair[0]] = decodeURIComponent(tpair[1] || "");
  });
  return JSON.parse(JSON.stringify(result));
}

const getCacheToken = cacheKey => {
  try {
    const serializedState = localStorage.getItem(cacheKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const setCacheToken = (cacheKey, stateObj) => {
  try {
    const serializedState = JSON.stringify(stateObj);
    localStorage.setItem(cacheKey, serializedState);
  } catch (err) {
    console.error(err);
  }
};

function removeTokenCache(cacheKey) {
  localStorage.setItem(cacheKey, null);
}

function getAppEnvironment() {
  const { hostname } = window.location;
  const host = hostname.includes("localhost")
    ? "servicequoting.dev6.xtimeappsnp.xtime.com"
    : hostname;
  const hostArr = host.split(".");
  let quoteEnv = hostArr[1];
  quoteEnv = quoteEnv.includes("xtime") ? "prod" : quoteEnv;
  let returnedEnv = "qa5";
  switch (quoteEnv) {
    case "dev6":
      returnedEnv = "dev6";
      break;
    case "qa6":
      returnedEnv = "qa6";
      break;
    case "demo":
    case "pt3":
      returnedEnv = "pt3";
      break;
    case "stg4":
      returnedEnv = "stg4";
      break;
    case "prod":
      returnedEnv = "prod";
      break;
    default:
      returnedEnv = "qa5";
  }
  return returnedEnv;
}

function preparePartsWithDealerTire(record) {
  let formattedPart = cloneDeep(mockPartTemplate);
  const notesArr = [];
  if (record.sizeDescription) {
    notesArr.push(record.sizeDescription);
  }
  formattedPart = {
    ...formattedPart,
    partName: record.model,
    quantity: Number(record.quantity),
    oemPartNumber: record.part,
    unitPrice: record.unitPrice,
    partsPrice: record.partsPrice,
    notes: notesArr,
    // @note: by default: set MANUAL for all dealer tire parts
    priceSource: "MANUAL",
    partPriceSource: "MANUAL",
    selected: true,
    rowId: Number(record.id),
    partId: String(record.id)
  };
  return formattedPart;
}

function getNotes(record) {
  let desc = record.brand;
  if (!isEmpty(record.model) && !isEmpty(record.sizeDescription)) {
    const model = record.model.concat(",", record.sizeDescription);
    desc = desc.concat(",", model);
  }
  return desc;
}
function getTagName(totalTires) {
  // Replacing record.quantity for individual services in favor of the total sum of tires selected from dealertire
  const key = "".concat("tire", totalTires);
  const tagName = tagsMap[key];
  console.log("gettag", key, tagName);
  return !tagName ? null : tagName;
}

export {
  getAppEnvironment,
  paramsToObject,
  toQueryString,
  encodeToQueryString,
  dealerCodeToDealerId,
  setCustomParam,
  getCustomParam,
  getServiceUrl,
  dateToUtcFormat,
  getCacheToken,
  setCacheToken,
  removeTokenCache,
  getNotes,
  getTagName,
  preparePartsWithDealerTire,
  sleepOut
};
