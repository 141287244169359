import React, { useState } from "react";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import AccordionGroup from "@cx/ui/AccordionGroup";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Badge from "@cx/ui/Badge";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import { PropTypes } from "prop-types";
import {boolToNumber} from "./utils"

const ImportExportLaborGrid = props => {
  const [errMsg, setErrMsg] = useState("");
  const [file, setFile] = useState(null);
  const [modUser] = useState(props.userName);
  const renderSelectOperationAccordionHeader = () => {
    const title = xlate("xmm.portal.labor_rates.show_export_lbl");
    return (
      <div>
        <span>{title}</span>
        <Badge htmlId="badgeBlue" color="blue" className="pull-right" />
      </div>
    );
  };
  const handelOnUploadFile = event => {
    if (event.target.files[0] === undefined) {
      setFile(null);
      return;
    }
    setFile(event.target.files[0]);
    fileNameWarning(event.target.files[0]);
  };
  const fileNameWarning = file => {
    setErrMsg(null);
    if (!file) return;
  };
  const selectOperationAccordionHeader = renderSelectOperationAccordionHeader();

  const handleCsvImport = dealerLaborGridId => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    makeSecureRestApi(
      {
        url: "/ops/upload/uploadLaborGridCsv",
        method: "post",
        data: formData,
        headers,
        params: { dealerLaborGridId, modUser }
      },
      response => {
        if (
          response &&
          response.statusCodeValue &&
          response.statusCodeValue === 200 &&
          response.body &&
          response.body.message &&
          response.body.message !== "error"
        ) {
          makeSecureRestApi(
            {
              url: `/ops/proxyapi/internaloeproxy/rest/internal/xmmdealer/getLaborGrid/${response.body.message}`,
              method: "get"
            },
            resp => {
              savePayload(resp, response.body.message, props.skillRateGridId);
            },
            () => {
              props.setToastError("Error to fetch the labor grid data.");
            }
          );
        } else {
          props.setToastError(
            "Error in importing the labor grid; please click on the help icon to see the rules."
          );
        }
      },
      () => {
        props.setToastError("Error to import the csv file.");
      }
    );
  };

  const savePayload = (data, laborGridId, skillRateGridId) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/dealerLaborPricingRule/${props.dealerLaborRate.dealerLaborPricingRuleId}?_method=put`;

    const payload = props.dealerLaborRate;
    payload[skillRateGridId] = laborGridId;
    payload.modUser = modUser;
    payload.isLaborGrid = boolToNumber(payload.isLaborGrid);
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        if (!response.success) {
          props.setToastError("Error to save dealerLaborRateCode");
        } else {
          props.updateImportLaborGrid(data);
        }
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        props.setToastError(errMsg);
      }
    );
  };

  const exportCSVFile = (csv, fileTitle) => {
    const exportedFilename = fileTitle + ".csv" || "export.csv";

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const handleCsvExport = dealerLaborGridId => {
    const headers = {
      Accept: "application/octet-stream"
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/oeproxycsv/rest/internal/xmmdealer/downloadLaborGridCsv",
        method: "get",
        params: { dealerLaborGridId },
        headers
      },
      response => {
        if (
          (response.statusCodeValue && response.statusCodeValue === 204) ||
          (response.body &&
            response.body.message &&
            response.body.message === "error")
        ) {
          props.setToastError("Error to export the labor grid, try again.");
        } else {
          const today = new Date();
          const date =
            today.getMonth() +
            1 +
            "-" +
            today.getDate() +
            "-" +
            today.getFullYear();
          exportCSVFile(response, "LaborGrid_Export_" + date);
        }
      },
      () => {
        props.setToastError("Error to export the labor grid.");
      }
    );
  };
  const disableExportButton = () => {
    const result = props.laborGridData.filter(x => {
      const cleanedArray = Object.values(x).filter(y => y);
      if (cleanedArray.length && Object.keys(x).length) {
        return x;
      }
    });
    if (!result.length || !props.dealerLaborRate[props.skillRateGridId]) {
      return true;
    }
    return false;
  };
  const importPopoverContent = (
    <div>
      <p className="xmm-popover-text">
        {xlate("xmm.portal.labor_rates.import_tip_1")}
      </p>
      <p className="xmm-popover-text">
        {xlate("xmm.portal.labor_rates.import_tip_2")}
      </p>
      <p className="xmm-popover-text">
        {xlate("xmm.portal.labor_rates.import_tip_3")}
      </p>
      <p className="xmm-popover-text">
        {xlate("xmm.portal.labor_rates.import_tip_4")}
      </p>
      <p className="xmm-popover-text">
        {xlate("xmm.portal.labor_rates.import_tip_5")}
      </p>
    </div>
  );
  return (
    <AccordionGroup.Container htmlId="importExportAccordionGroup" independent>
      <AccordionGroup.Item
        header={selectOperationAccordionHeader}
        eventKey="1"
        htmlId="importExportAccordion"
        defaultExpanded
      >
        <Row style={{ marginTop: "5px", marginBottom: "10px" }}>
          <Col xs={12} md={12}>
            {xlate("xmm.portal.labor_rates.info_to_export_labor_grid")}
          </Col>
        </Row>
        <Row style={{ marginTop: "5px" }}>
          <Col xs={4} md={4}>
            <input type="file" accept=".csv" onChange={handelOnUploadFile} />
            <div className="xmm-error-msg">{errMsg}</div>
          </Col>
          <Col xs={4} md={4}>
            <Button
              htmlId="importCsvAction"
              buttonStyle="primary"
              disabled={!file}
              onClick={() => handleCsvImport(props.dealerLaborRate.laborGridId)}
            >
              {xlate("xmm.portal.labor_rates.lbl_import_labor_grid")}
            </Button>
            <Popover
              htmlId="popoverOverrides"
              popoverContent={importPopoverContent}
              trigger={["click", "outsideClick"]}
              position="bottom"
            >
              <IconInfoOutline
                htmlId="inspectionItemtooltip"
                className="hand-cursor"
              />
            </Popover>
          </Col>
          <Col xs={4} md={4}>
            <Button
              htmlId="exportCsvAction"
              buttonStyle="primary"
              disabled={disableExportButton()}
              onClick={() =>
                handleCsvExport(props.dealerLaborRate[props.skillRateGridId])
              }
            >
              {xlate("xmm.portal.labor_rates.lbl_export_labor_grid")}
            </Button>
          </Col>
        </Row>
      </AccordionGroup.Item>
    </AccordionGroup.Container>
  );
};

export default ImportExportLaborGrid;

ImportExportLaborGrid.propTypes = {
  dealerLaborRate: PropTypes.object,
  laborGridData: PropTypes.array,
  skillRateGridId: PropTypes.String,
  userName: PropTypes.string,
  updateImportLaborGrid: PropTypes.func,
  setToastError: PropTypes.func
};

ImportExportLaborGrid.defaultProps = {
  userName: "",
  dealerLaborRate: {},
  laborGridData: []
};
