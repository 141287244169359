/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import PriceInput from "@cx/ui/PriceInput";
import DatePicker from "@cx/ui/DatePicker";
import SelectInput from "@cx/ui/SelectInput";
import CheckBoxList from "@cx/ui/CheckBoxList";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import AllocationField from "../../AllocationGroup/AllocationField";
import {
  DiscountTypesOptions,
  CategoryOptions
} from "../../../../../constants/ModuleConstants";
import NumericInput from "@cx/ui/NumericInput";
import {
  BE_DATE_FORMAT,
  UI_DATE_FORMAT,
  convertDateStr
} from "../../../../../commonUtil/utils/date";
class AddDiscountFormPage extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onChangeAllocation = this.onChangeAllocation.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.payTypesHandleCustomError = this.payTypesHandleCustomError(this);
    this.validate = this.validate.bind(this);
    this.isValidForm.bind(this);
    this.initializeLocaleValues();
    const dealerCode = context.dealerCode;
    const discountTypesOptions = [...DiscountTypesOptions];
    const categoryOptions = [...CategoryOptions];
    const { user } = context;
    const { userName } = user;
    // const [selectedDate, setSelectedDate] = useState("");
    const fromDate = convertDateStr(
      props.rowRecord.fromDate,
      BE_DATE_FORMAT,
      UI_DATE_FORMAT
    );
    const expiryDate = convertDateStr(
      props.rowRecord.expiryDate,
      BE_DATE_FORMAT,
      UI_DATE_FORMAT
    );
    const newDiscount = {
      ...props.rowRecord,
      dealerCode,
      modUser: userName,
      fromDate,
      expiryDate
    };
    // const payTypes = newDiscount.payTypes ? newDiscount.payTypes.split(","): [];
    const payTypes = newDiscount.payTypes;
    const discountTypeSelected = newDiscount.discountType;
    const partsAllocationFields =
      newDiscount.dealerDiscountId && newDiscount.partsDepartmentAllocations
        ? JSON.parse(newDiscount.partsDepartmentAllocations)
        : {
            allocationPercentage: 50,
            allocations: [
              {
                department: "Parts",
                account: "",
                allocation: 100
              }
            ]
          };
    const laborAllocationFields =
      newDiscount.dealerDiscountId && newDiscount.laborDepartmentAllocations
        ? JSON.parse(newDiscount.laborDepartmentAllocations)
        : {
            allocationPercentage: 50,
            allocations: [
              {
                department: "Service",
                account: "",
                allocation: 100
              }
            ]
          };
    const neverExpires =
      newDiscount.dealerDiscountId && newDiscount.expiryDate === ""
        ? ["1"]
        : [];
    newDiscount.neverExpires =
      neverExpires.length > 0 && neverExpires[0] === "1";
    this.state = {
      minDate: new Date("2023-09-01T00:00:00"),
      maxDate: new Date("9999-12-31T00:00:00"),
      value: "",
      newDiscount,
      payTypes,
      discountTypeSelected,
      discountValue: "",
      discountTypesOptions,
      categoryOptions,
      partsAllocationFields,
      laborAllocationFields,
      neverExpires,
      dirty: false,
      valid: false,
      errors: { expiryDate: "", fromDate: "" }
    };
  }

  componentDidMount() {
    window.addEventListener(
      "addAllocationEvent",
      this.onAddAllocationEvent,
      false
    );
    window.addEventListener(
      "removeAllocationEvent",
      this.onRemoveAllocationEvent,
      false
    );
    window.addEventListener(
      "updateAllocationEvent",
      this.onUpdateAllocationEvent,
      false
    );
  }
  createEmptyAllocationRow() {
    const newAllocation = {
      department: "",
      account: "",
      allocation: 0
    };
    return newAllocation;
  }
  onAddAllocationEvent = event => {
    const newAllocation = this.createEmptyAllocationRow();
    const { partsAllocationFields, laborAllocationFields } = this.state;
    if (event.detail.type === "parts") {
      partsAllocationFields.allocations.push(newAllocation);
      this.setState({ partsAllocationFields });
    } else {
      laborAllocationFields.allocations.push(newAllocation);
      this.setState({ laborAllocationFields });
    }
  };

  onRemoveAllocationEvent = event => {
    const { index } = event.detail;
    const { partsAllocationFields, laborAllocationFields } = this.state;
    if (event.detail.type === "parts") {
      partsAllocationFields.allocations.splice(index, 1);
      if (partsAllocationFields.allocations.length === 1) {
        partsAllocationFields.allocations.forEach(field => {
          field.allocation = 100;
        });
      }
      this.setState({ partsAllocationFields });
    } else {
      laborAllocationFields.allocations.splice(index, 1);
      if (laborAllocationFields.allocations.length === 1) {
        laborAllocationFields.allocations.forEach(field => {
          field.allocation = 100;
        });
      }
      this.setState({ laborAllocationFields });
    }
    this.markDirty(null, true);
  };
  onUpdateAllocationEvent = event => {
    const { index, field, name } = event.detail;
    const { partsAllocationFields, laborAllocationFields } = this.state;
    if (event.detail.type === "parts") {
      partsAllocationFields.allocations[index] = field;
      this.setState({ partsAllocationFields });
      this.markDirty(name + "Parts", true);
    } else {
      laborAllocationFields.allocations[index] = field;
      this.setState({ laborAllocationFields });
      this.markDirty(name + "Labor", true);
    }
  };
  initializeLocaleValues() {
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.applyFeeTooltip = xlate(
      "xmm.portal.discounts.form.apply_discount_to_tooltip"
    );
    this.detailsLabel = xlate("xmm.portal.operations.form.details_label");
    this.discountTypeTooltip = xlate(
      "xmm.portal.discounts.discount_type_tooltip"
    );
    this.categoryTooltip = xlate("xmm.portal.discounts.category_tooltip");
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.payTypesErrorMsg = xlate("xmm.portal.settings.pay_types_error_msg");
    this.departmentsLabel = xlate("xmm.portal.fees.form.departament_lbl");
    this.costAllocationLabel = xlate(
      "xmm.portal.fees.form.cost_allocation_lbl"
    );
    this.accountAllocationLabel = xlate(
      "xmm.portal.fees.form.account_allocation_lbl"
    );
    this.allocationsLabel = xlate("xmm.portal.fees.form.allocations_lbl");
    this.allocationsTooltip = xlate(
      "xmm.portal.discount.form.allocations_tooltip"
    );
  }
  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };
  onToggleClick = event => {
    const target = event.target;
    const { name } = target;
    const newValue = target.checked ? 1 : 0;
    const { newDiscount } = this.state;

    if (isDifferentValue(newDiscount[name], newValue)) {
      this.setState(
        prevState => {
          return {
            newDiscount: {
              ...prevState.newDiscount,
              [name]: newValue
            }
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };
  // changeEvent for textfield
  onChange = event => {
    const { name, value } = event.target;
    const { newDiscount, payTypes, errors } = this.state;
    let newValue;

    switch (name) {
      case "description":
        newValue = value.replaceAll(/"/g, "");
        newValue = newValue.replaceAll("/", "");
        newValue = newValue.replaceAll("[", "");
        newValue = newValue.replaceAll("]", "");
        break;
      case "payTypes":
        newValue = value.join(",");
        break;
      case "dollarAmount":
      case "percentage":
      case "discountMax":
        if (value && value != "") {
          newValue = parseFloat(value);
        } else {
          newValue = "";
        }
        break;
      case "discountType":
        this.setState({
          discountTypeSelected: value,
          newDiscount: {
            ...newDiscount,
            dollarAmount: "",
            discountMax: "",
            percentage: ""
          },
          errors: {
            ...errors,
            dollarAmount: "",
            discountMax: "",
            percentage: ""
          }
        });
        newValue = value;
        break;
      case "neverExpires":
        newValue = value[0] === "1";
        this.setState({
          newDiscount: {
            ...newDiscount,
            expiryDate: ""
          },
          neverExpires: value
        });
        break;
      default:
        newValue = value;
    }

    if (isDifferentValue(newDiscount[name], newValue)) {
      this.setState(
        prevState => {
          return {
            newDiscount: {
              ...prevState.newDiscount,
              [name]: newValue
            },
            payTypes
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };
  onChangeAllocation = event => {
    const { name, value } = event.target;
    const { partsAllocationFields, laborAllocationFields, errors } = this.state;
    switch (name) {
      case "partsAllocation":
        this.setState(
          prevState => {
            return {
              partsAllocationFields: {
                ...partsAllocationFields,
                allocationPercentage: value
              },
              errors: {
                ...errors,
                partLaborAllocations: ""
              }
            };
          },
          () => {
            this.markDirty(name, true);
          }
        );
        break;
      case "laborAllocation":
        this.setState(
          prevState => {
            return {
              laborAllocationFields: {
                ...laborAllocationFields,
                allocationPercentage: value
              },
              errors: {
                ...errors,
                partLaborAllocations: ""
              }
            };
          },
          () => {
            this.markDirty(name, true);
          }
        );
        break;
    }
  };

  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const { valid, errors } = this.validate(fieldName);
      this.setState({ dirty, valid, errors });
      this.props.onModified();
    } else {
      this.setState({ dirty });
    }
  }
  isValidForm() {
    const {
      description,
      dollarAmount,
      percentage,
      discountMax,
      payTypes,
      fromDate,
      expiryDate,
      neverExpires,
      category
    } = this.state.newDiscount;
    const {
      discountTypeSelected,
      partsAllocationFields,
      laborAllocationFields
    } = this.state;
    if (!description || description.length === 0) {
      return false;
    }
    if (
      !dollarAmount &&
      (discountTypeSelected === "Dollar" ||
        discountTypeSelected === "Actual Retail Value")
    ) {
      return false;
    }
    if (!percentage && discountTypeSelected === "Percent") {
      return false;
    }
    if (
      !discountMax &&
      (discountTypeSelected === "Variable" ||
        discountTypeSelected === "Percent" ||
        discountTypeSelected === "Actual Retail Value")
    ) {
      return false;
    }
    if (!fromDate) {
      return false;
    }
    if (!(neverExpires || expiryDate)) {
      return false;
    }
    if (!category) {
      return false;
    }
    if (!partsAllocationFields.allocationPercentage) {
      return false;
    }
    if (!laborAllocationFields.allocationPercentage) {
      return false;
    }
    let validPartAllocation = true;
    partsAllocationFields.allocations.forEach(allocation => {
      if (!allocation.department) {
        validPartAllocation = false;
      }
      if (!allocation.account) {
        validPartAllocation = false;
      }
    });
    if (!validPartAllocation) {
      return false;
    }
    let validLaborAllocation = true;
    laborAllocationFields.allocations.forEach(allocation => {
      if (!allocation.department) {
        validLaborAllocation = false;
      }
      if (!allocation.account) {
        validLaborAllocation = false;
      }
    });
    if (!validLaborAllocation) {
      return false;
    }
    return payTypes !== "";
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    let valid = true;
    const {
      newDiscount,
      errors,
      discountTypeSelected,
      partsAllocationFields,
      laborAllocationFields
    } = this.state;
    let totalPartAllocations = 0;
    let totalLaborAllocations = 0;
    switch (fieldName) {
      case "description": {
        valid = !!newDiscount.description?.trim().length;
        errors["description"] = !valid
          ? "Discount description can't be blank."
          : "";
        break;
      }
      case "expiryDate": {
        valid =
          new Date(newDiscount.fromDate) <= new Date(newDiscount[fieldName]);
        errors["expiryDate"] =
          !valid && newDiscount.fromDate && newDiscount.expiryDate
            ? "The 'Expires on' date cannot be prior to the 'Valid from' date."
            : "";
        errors["fromDate"] = "";
        break;
      }
      case "fromDate": {
        valid = newDiscount.neverExpires
          ? true
          : new Date(newDiscount.expiryDate) >=
            new Date(newDiscount[fieldName]);
        errors["fromDate"] =
          !valid && newDiscount.fromDate && newDiscount.expiryDate
            ? "The 'Valid from' date cannot be after the 'Expires On' date."
            : "";
        errors["expiryDate"] = "";
        break;
      }
      case "percentage": {
        valid = newDiscount.percentage <= 100.0;
        errors["percentage"] = !valid
          ? "The percentage cannot be more than 100."
          : "";
        break;
      }
      case "discountMax": {
        valid = newDiscount.discountMax < 100000000;
        errors["discountMax"] = !valid
          ? "The Discount Maximum cannot be more than 99999999.99."
          : "";
        if (discountTypeSelected === "Actual Retail Value") {
          valid = newDiscount.discountMax >= newDiscount.dollarAmount;
          errors["discountMax"] = !valid
            ? " The discount maximum needs to be equal to or greater than the discount amount."
            : "";
          errors["dollarAmount"] = "";
        }
        break;
      }
      case "dollarAmount": {
        valid = newDiscount.dollarAmount < 100000000;
        errors["dollarAmount"] = !valid
          ? "The Discount Amount cannot be more than 1000000000."
          : "";
        if (discountTypeSelected === "Actual Retail Value") {
          valid = newDiscount.dollarAmount <= newDiscount.discountMax;
          errors["dollarAmount"] = !valid
            ? " The discount maximum needs to be equal to or greater than the discount amount."
            : "";
          errors["discountMax"] = "";
        }
        break;
      }
      case "payTypes": {
        valid = newDiscount.payTypes?.length !== 0;
        errors["payTypes"] = !valid ? this.payTypesErrorMsg : "";
        break;
      }
      case "allocationParts": {
        partsAllocationFields.allocations.forEach(allocation => {
          totalPartAllocations += parseFloat(allocation.allocation);
        });
        valid = totalPartAllocations === 100;
        errors["allocations"] = !valid
          ? "The total allocation has to equal 100%."
          : "";
        break;
      }
      case "allocationLabor": {
        laborAllocationFields.allocations.forEach(allocation => {
          totalLaborAllocations += parseFloat(allocation.allocation);
        });
        valid = totalLaborAllocations === 100;
        errors["allocations"] = !valid
          ? "The total allocation has to equal 100%."
          : "";
        break;
      }
      case "partsAllocation": {
        valid =
          parseFloat(partsAllocationFields.allocationPercentage) +
            parseFloat(laborAllocationFields.allocationPercentage) ===
          100;
        errors["partLaborAllocations"] = !valid
          ? "The total allocation has to equal 100%."
          : "";
        break;
      }
      case "laborAllocation": {
        valid =
          parseFloat(partsAllocationFields.allocationPercentage) +
            parseFloat(laborAllocationFields.allocationPercentage) ===
          100;
        errors["partLaborAllocations"] = !valid
          ? "The total allocation has to equal 100%."
          : "";
        break;
      }
      default:
        break;
    }
    valid = valid && this.isValidForm();
    // this.setState({ valid, errors });
    return { errors, valid };
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount !== 0;
  }
  getErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let msg = "";
    for (const value of iterator) {
      if (value && value !== "") {
        // In case of valid error string
        msg = msg + value + " ";
      }
    }
    return msg;
  }
  getAddPayload() {
    const { newDiscount, partsAllocationFields, laborAllocationFields } =
      this.state;
    const { fromDate, expiryDate } = newDiscount;
    return {
      ...newDiscount,
      fromDate: convertDateStr(fromDate, UI_DATE_FORMAT, BE_DATE_FORMAT),
      expiryDate: convertDateStr(expiryDate, UI_DATE_FORMAT, BE_DATE_FORMAT),
      partsDepartmentAllocations: JSON.stringify(partsAllocationFields),
      laborDepartmentAllocations: JSON.stringify(laborAllocationFields)
    };
  }
  // TODO - first validate duplicates for code, description per selected make; then proceed
  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors, newDiscount } = this.state;
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      this.updateStatusBox(
        this.correctInvalidFielsError + " - " + this.getErrorStrings(errors),
        "error",
        false
      );
      return;
    }
    let url = null;
    let data = null;
    if(newDiscount.dealerDiscountId){
      //update discount
      url = `/ops/discountAndFees/updateDealerDiscounts`
      const {dealerDiscountId,dealerCode,description,discountType,dollarAmount,percentage,discountMax,fromDate,expiryDate,
        payTypes,applyTax,allowDiscountRepair,modUser,applyWithOtherDiscount,partsDepartmentAllocations,
        laborDepartmentAllocations,category
      } = this.getAddPayload();
      data = {
        dealerDiscountId,dealerCode,description,discountType,dollarAmount,percentage,discountMax,fromDate,expiryDate,
        payTypes,applyTax,allowDiscountRepair,modUser,applyWithOtherDiscount,partsDepartmentAllocations,
        laborDepartmentAllocations,category
      };
    }else{
      //add discount
      url = `/ops/proxyapi/ddsproxy/rest/table/dealerDiscounts`
      data = this.getAddPayload();
    }
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if(newDiscount.dealerDiscountId){
          //update dealer discount reponse
          if(response && Object.getPrototypeOf(response) === Array.prototype && response[0]){
            this.updateStatusBox(this.savedMsg, "success", true);
            const { dealerDiscounts } = response[0];
            // callback grid to add record
            this.props.updateDiscountAfterSave(
              dealerDiscounts,
              newDiscount.dealerDiscountId
            );
            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.hideSlider();
              }, 800);
            });
          }else{
            const msg = "Update the discount fail";
            this.updateStatusBox(msg, "error", false);
          }
        }else{
          //add dealer discount reponse
          if (response?.success) {
            this.updateStatusBox(this.savedMsg, "success", true);
            // callback grid to add record
            const { dealerDiscounts } = response;
            this.props.updateDiscountAfterSave(
              dealerDiscounts,
              newDiscount.dealerDiscountId
            );
            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.hideSlider();
              }, 800);
            });
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    gtmEvents.gtmTrackEvent("xmm.parts&labor.paytypes.save");
    // GTM - push click event to dataLayer
    // gtmEvents.gtmTrackEvent("xmm.laborrates.add_rate_click");
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  payTypesHandleCustomError(value) {
    if (!value) {
      return this.payTypesErrorMsg;
    }
  }

  render() {
    let editSection = null;
    const msgSection = null;
    const {
      newDiscount,
      discountTypesOptions,
      categoryOptions,
      partsAllocationFields,
      laborAllocationFields,
      discountTypeSelected,
      errors,
      minDate,
      maxDate,
      neverExpires,
      valid
    } = this.state;
    const showDollar =
      discountTypeSelected === "Dollar" ||
      discountTypeSelected === "Actual Retail Value"
        ? ""
        : "hide";
    const showPercent = discountTypeSelected === "Percent" ? "" : "hide";
    const showMaxDiscount =
      discountTypeSelected === "Variable" ||
      discountTypeSelected === "Actual Retail Value" ||
      discountTypeSelected === "Percent"
        ? ""
        : "hide";
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    const isValid = valid;
    const payTypesLabel = (
      <div>
        <span className="xmm-select-menu-label">
          {
            this.context.localeStrings[
              "xmm.portal.discounts.apply_discount_to_lbl"
            ]
          }
          &nbsp;
          <span className="xmm-red-label">*</span>
        </span>
      </div>
    );
    const applyTaxToFeeInfo = (
      <div>
        <p>{xlate("xmm.portal.discounts.discount_type_tooltip_1")}</p>
        <p>{xlate("xmm.portal.discounts.discount_type_tooltip_2")}</p>
      </div>
    );
    const allowDiscountRepairInfo = (
      <div>
        <p>{xlate("xmm.portal.discounts.allow_discount_tooltip_2")}</p>
        <p>{xlate("xmm.portal.discounts.allow_discount_tooltip_1")}</p>
      </div>
    );
    const applyWithOtherDiscountInfo = (
      <div>
        <p>{xlate("xmm.portal.discounts.allow_another_discount_tooltip_2")}</p>
        <p>{xlate("xmm.portal.discounts.allow_another_discount_tooltip_1")}</p>
      </div>
    );
    const allocationHeader = (
      <div>
        {this.allocationsLabel}
        <Popover
          htmlId="popoverAllocations"
          popoverContent={this.allocationsTooltip}
          trigger={["click", "outsideClick"]}
        >
          <IconInfoOutline htmlId="allocationsInfo" className="hand-cursor" />
        </Popover>
      </div>
    );
    const totalPartsAllocationFields = partsAllocationFields.allocations?.map(
      (allocationField, index) => {
        const id = index;
        return (
          <AllocationField
            key={`allocation-${id}`}
            field={allocationField}
            index={index}
            totalAllocationFields={partsAllocationFields.allocations.length}
            type="parts"
          />
        );
      }
    );
    const totalLaborAllocationFields = laborAllocationFields.allocations?.map(
      (allocationField, index) => {
        const id = index;
        return (
          <AllocationField
            key={`allocation-${id}`}
            field={allocationField}
            index={index}
            totalAllocationFields={laborAllocationFields.allocations.length}
            type="labor"
          />
        );
      }
    );
    if (this.props.editForm) {
      // show form when edit clicked or add clicked
      editSection = (
        <React.Fragment>
          <div className="xmm-tab-bar">
            {statusBox} <span className="xmm-msg">{msgSection}</span>
            <Button
              htmlId="saveAction"
              buttonStyle="primary"
              disabled={!isValid}
              onClick={this.saveHandler}
            >
              {this.saveLabel}
            </Button>
          </div>
          <div className="xmm-slider-tab-scroll">
            <Card htmlId="addDiscounDetailsId" header={this.detailsLabel}>
              <div className="comment-form">
                <Grid htmlId="settingGrid">
                  <Row className="show-grid">
                    <Col xs={6} xsOffset={1} md={6} mdOffset={1}>
                      <TextInput
                        htmlId="discountDescription"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discounts.description_lbl"
                          ]
                        }
                        name="description"
                        required
                        maxLength={100}
                        onChange={this.onChange}
                        value={newDiscount.description}
                      />
                    </Col>
                    <Col xs={2} md={2}>
                      <DatePicker
                        htmlId="validFrom"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discounts.valid_from_lbl"
                          ]
                        }
                        name="fromDate"
                        required
                        dateFormat={UI_DATE_FORMAT}
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={newDiscount.fromDate}
                        placeholder="mm-dd-yyyy"
                        onChange={this.onChange}
                        hasError={!!errors?.fromDate}
                        errorMessage={errors.fromDate}
                      />
                    </Col>
                    <Col xs={2} md={2}>
                      <DatePicker
                        htmlId="expiryDate"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discounts.expires_on_lbl"
                          ]
                        }
                        name="expiryDate"
                        required={!newDiscount.neverExpires}
                        dateFormat={UI_DATE_FORMAT}
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={newDiscount.expiryDate}
                        onChange={this.onChange}
                        placeholder="mm-dd-yyyy"
                        hasError={!!errors?.expiryDate}
                        errorMessage={errors.expiryDate}
                        disabled={newDiscount.neverExpires}
                      />
                      <CheckBoxList
                        htmlId="neverExpires"
                        name="neverExpires"
                        label="Options"
                        displayLabel={false}
                        onChange={this.onChange}
                        options={[
                          {
                            value: "1",
                            label: "Never expires",
                            selected:
                              newDiscount.dealerDiscountId &&
                              newDiscount.expiryDate === ""
                          }
                        ]}
                        inline
                        values={neverExpires}
                      />
                    </Col>
                    <Col xs={3} xsOffset={1} md={3} mdOffset={1}>
                      <div>
                        <span className="xmm-select-menu-label">
                          {
                            this.context.localeStrings[
                              "xmm.portal.discounts.discount_type_lbl"
                            ]
                          }{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                        <Popover
                          htmlId="popoverDiscountType"
                          popoverContent={this.discountTypeTooltip}
                          trigger={["click", "outsideClick"]}
                        >
                          <IconInfoOutline
                            htmlId="discountTypeInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                      <SelectInput
                        htmlId="discountType"
                        name="discountType"
                        options={discountTypesOptions}
                        onChange={this.onChange}
                        displayLabel={false}
                        required
                        value={newDiscount.discountType}
                        displayDeselectOption={false}
                      />
                    </Col>
                    <Col xs={3} md={3}>
                      <div>
                        <span className="xmm-select-menu-label">
                          {
                            this.context.localeStrings[
                              "xmm.portal.discounts.category_lbl"
                            ]
                          }{" "}
                          <span className="xmm-red-label">*</span>{" "}
                        </span>
                        <Popover
                          htmlId="popoverCategory"
                          popoverContent={this.categoryTooltip}
                          trigger={["click", "outsideClick"]}
                        >
                          <IconInfoOutline
                            htmlId="categoryInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                      <SelectInput
                        htmlId="category"
                        name="category"
                        options={categoryOptions}
                        onChange={this.onChange}
                        displayLabel={false}
                        required
                        value={newDiscount.category}
                        displayDeselectOption={false}
                      />
                    </Col>
                    <Col xs={2} md={2} className={showDollar}>
                      <PriceInput
                        htmlId="dollarAmount"
                        name="dollarAmount"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discounts.discount_amount_lbl"
                          ]
                        }
                        minValue={0.01}
                        maxValue={99999999.99}
                        onChange={this.onChange}
                        value={newDiscount.dollarAmount}
                        required
                      />
                    </Col>
                    <Col xs={2} md={2} className={showPercent}>
                      <NumericInput
                        htmlId="percentage"
                        name="percentage"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discounts.discount_percentage_lbl"
                          ]
                        }
                        inputSuffix="%"
                        allowDecimal={true}
                        onChange={this.onChange}
                        value={newDiscount.percentage}
                        maxValue={100}
                        required
                      />
                    </Col>
                    <Col xs={2} md={2} className={showMaxDiscount}>
                      <PriceInput
                        htmlId="discountMax"
                        name="discountMax"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discounts.discount_max_lbl"
                          ]
                        }
                        maxValue={99999999.99}
                        onChange={this.onChange}
                        value={newDiscount.discountMax}
                        required
                      />
                    </Col>
                    <Row className="row-separator" />
                    <Col xs={3} xsOffset={1} md={3} mdOffset={1}>
                      <CheckBoxList
                        htmlId="payTypes"
                        name="payTypes"
                        label={payTypesLabel}
                        onChange={this.onChange}
                        hasError={!!errors.payTypes}
                        errorMessage={errors.payTypes}
                        options={[
                          { value: "C", label: "Customer Pay" },
                          { value: "I", label: "Internal" },
                          {
                            value: "S",
                            label: "Service Contract"
                          },
                          { value: "W", label: "Warranty" }
                        ]}
                        values={
                          newDiscount.payTypes
                            ? newDiscount.payTypes.split(",")
                            : []
                        }
                      />
                    </Col>
                    <Col xs={8} md={8}>
                      <span className="xmm-select-menu-label">
                        {
                          this.context.localeStrings[
                            "xmm.portal.fees.form.additional_opt_lbl"
                          ]
                        }
                      </span>
                      <div className="xmm-checkbox-container xmm-horizontal-form">
                        <input
                          type="checkbox"
                          name="applyTax"
                          id="applyTax"
                          onClick={this.onToggleClick}
                          checked={newDiscount.applyTax ? true : false}
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            this.context.localeStrings[
                              "xmm.portal.discounts.apply_tax_to_lbl"
                            ]
                          }
                        </span>
                        <Popover
                          htmlId="popoverAllowDiscount"
                          popoverContent={applyTaxToFeeInfo}
                          trigger={["click", "outsideClick"]}
                          position="right"
                        >
                          <IconInfoOutline
                            htmlId="allowDiscountInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                      <div className="xmm-checkbox-container xmm-horizontal-form">
                        <input
                          type="checkbox"
                          name="applyWithOtherDiscount"
                          id="applyWithOtherDiscount"
                          onClick={this.onToggleClick}
                          checked={
                            newDiscount.applyWithOtherDiscount ? true : false
                          }
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            this.context.localeStrings[
                              "xmm.portal.discounts.allow_discount_lbl"
                            ]
                          }
                        </span>
                        <Popover
                          htmlId="popoverAllowDiscount"
                          popoverContent={allowDiscountRepairInfo}
                          trigger={["click", "outsideClick"]}
                          position="right"
                        >
                          <IconInfoOutline
                            htmlId="allowDiscountInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                      <div className="xmm-checkbox-container xmm-horizontal-form">
                        <input
                          type="checkbox"
                          name="allowDiscountRepair"
                          id="allowDiscountRepair"
                          onClick={this.onToggleClick}
                          checked={
                            newDiscount.allowDiscountRepair ? true : false
                          }
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            this.context.localeStrings[
                              "xmm.portal.discounts.allow_another_discount_lbl"
                            ]
                          }
                        </span>
                        <Popover
                          htmlId="popoverAllowAnotherDiscount"
                          popoverContent={applyWithOtherDiscountInfo}
                          trigger={["click", "outsideClick"]}
                          position="right"
                        >
                          <IconInfoOutline
                            htmlId="allowAnotherDiscountInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </Card>
            <Card htmlId="addFeesAllocationsId" header={allocationHeader}>
              <div className="comment-form">
                <Grid htmlId="allocationsGrid">
                  <Row className="show-grid">
                    <Col xs={2} xsOffset={1} md={2} mdOffset={1}>
                      <span className="xmm-select-menu-label">
                        {
                          this.context.localeStrings[
                            "xmm.portal.discount.form.part_allocation_lbl"
                          ]
                        }{" "}
                        &nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={3} md={3}>
                      <span className="xmm-select-menu-label">
                        {this.departmentsLabel} &nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={3} md={3}>
                      <span className="xmm-select-menu-label">
                        {this.costAllocationLabel}&nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={2} md={2}>
                      <span className="xmm-select-menu-label">
                        {this.accountAllocationLabel}&nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    {/* <Col>{allocationTotalFields}</Col> */}
                  </Row>
                  <Row>
                    <Col xs={2} md={2} xsOffset={1} mdOffset={1}>
                      <NumericInput
                        htmlId="partsAllocation"
                        name="partsAllocation"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discount.form.part_allocation_lbl"
                          ]
                        }
                        maxValue={100}
                        onChange={this.onChangeAllocation}
                        value={partsAllocationFields.allocationPercentage}
                        required
                        inputSuffix="%"
                        displayLabel={false}
                      />
                    </Col>
                    <div>{totalPartsAllocationFields}</div>
                  </Row>
                  <Row>
                    <Col xs={2} xsOffset={1} md={2} mdOffset={1}>
                      <span className="xmm-select-menu-label">
                        {
                          this.context.localeStrings[
                            "xmm.portal.discount.form.labor_allocation_lbl"
                          ]
                        }{" "}
                        &nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={3} md={3}>
                      <span className="xmm-select-menu-label">
                        {this.departmentsLabel} &nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={3} md={3}>
                      <span className="xmm-select-menu-label">
                        {this.costAllocationLabel}&nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={2} md={2}>
                      <span className="xmm-select-menu-label">
                        {this.accountAllocationLabel}&nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    {/* <Col>{allocationTotalFields}</Col> */}
                  </Row>
                  <Row>
                    <Col xs={2} md={2} xsOffset={1} mdOffset={1}>
                      <NumericInput
                        htmlId="laborAllocation"
                        name="laborAllocation"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.discount.form.labor_allocation_lbl"
                          ]
                        }
                        maxValue={100}
                        onChange={this.onChangeAllocation}
                        value={laborAllocationFields.allocationPercentage}
                        required
                        inputSuffix="%"
                        displayLabel={false}
                      />
                    </Col>
                    <div>{totalLaborAllocationFields}</div>
                  </Row>
                </Grid>
              </div>
            </Card>
          </div>
        </React.Fragment>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default AddDiscountFormPage;

AddDiscountFormPage.propTypes = {
  rowRecord: PropTypes.object,
  operationName: PropTypes.string,
  editForm: PropTypes.bool,
  discounts: PropTypes.array,
  hideSlider: PropTypes.func,
  updateDiscountAfterSave: PropTypes.func
};
/* eslint-enable no-console */
