import React from "react";

import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";

export default props => {
  return (
    <div className="ag-header-group-cell-label">
      <div className="customHeaderLabel">{props.displayName}</div>
      <Popover
        htmlId="popoverAllocations"
        popoverContent={props.tooltipContent}
        trigger={["click", "outsideClick"]}
      >
        <IconInfoOutline htmlId="allocationsInfo" className="hand-cursor" />
      </Popover>
    </div>
  );
};
