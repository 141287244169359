import Operations from "./Operations";
import IntegratedOperations from "./IntegratedOperations";
import OpcodeValidation from "./OpcodeValidation";

// bundle components into one object and export
const OperationViews = {
  Operations,
  IntegratedOperations,
  OpcodeValidation
};

export default OperationViews;
