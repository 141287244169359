/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import Button from "@cx/ui/Button";
import IconMore from "@cx/ui/Icons/IconMore";
import IconDelete from "@cx/ui/Icons/IconDelete";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import { isArrayExist, doesEmpty } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import {
  blankValueFormatter,
  priceFormatter
} from "../../../../commonUtil/utils/formatter";
// import { priceValueSetter } from "../../../../commonUtil/utils/valuesetter";
import PriceEditor from "../../../../commonUtil/editors/PriceEditor";
import TextEditor from "../../../../commonUtil/editors/TextEditor";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import AddLaborRateFormPage from "./content/AddLaborRateFormPage";
import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import LaborRateCodeEditor from "./content/LaborRateCodeEditor";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import { sortByMake } from "../../../../commonUtil/utils/filter";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";
import * as formvalidator from "../../../formik/formvalidator";
import CheckboxCell from "../../../../commonUtil/renders/CheckboxCell";
import LaborRateTypeEditor from "../../../reusable/editors/LaborRateTypeEditor";
import ModalDialog from "@cx/ui/ModalDialog";
import LaborGridEditPage from "./content/LaborGridEditPage";
import { LaborRateRecord } from "../../../../constants/ModuleConstants";
class LaborRatesPage extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.laborRateFormRef = React.createRef();
    this.laborRateFormRefModal = React.createRef();

    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    // bind other external actions
    this.addActionSlider = this.addActionSlider.bind(this);
    this.hideSlider = this.hideSlider.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.confirmDeleteLaborRate = this.confirmDeleteLaborRate.bind(this);
    this.deleteLaborRate = this.deleteLaborRate.bind(this);
    this.isDealerLaborRateCodeIdUsedByGlobalOpsDealerOpertionRule =
      this.isDealerLaborRateCodeIdUsedByGlobalOpsDealerOpertionRule.bind(this);
    this.isDealerLaborRateCodeIdUsedByGlobalOpsOpertionOverride =
      this.isDealerLaborRateCodeIdUsedByGlobalOpsOpertionOverride.bind(this);
    this.okToDeleteLaborRate = this.okToDeleteLaborRate.bind(this);
    this.renderMoreButton = this.renderMoreButton.bind(this);
    this.updateLaborRateAfterSave = this.updateLaborRateAfterSave.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.handleSaveCellEdit = this.handleSaveCellEdit.bind(this);
    this.handleShowLaborGridModalEvent =
      this.handleShowLaborGridModalEvent.bind(this);
    this.handleShowConfirmEditEvent =
      this.handleShowConfirmEditEvent.bind(this);
    this.savePayload = this.savePayload.bind(this);

    const { localeStrings } = context;
    this.initializeLocaleValues();

    const validationFields = [
      "description",
      "unpublishedPackageRate",
      "unpublishedStandaloneRate"
    ];
    const editableFields = [
      "description",
      "laborRateType",
      "unpublishedPackageRate",
      "unpublishedStandaloneRate"
    ];
    const checkboxFields = ["defaultLaborRateFlag"];

    const { commonOpsLaborGridsEnabled } = context.dealer;

    const gridOptions = {
      // other state props
      commonOpsLaborGridsEnabled,
      searchKey: context.laborRatesGrid.searchKey,
      laborRatesGrid: context.laborRatesGrid,
      pageTitle: this.pageTitle,
      editOption: null, // set values as edit, add, delete
      operationName: "",
      rowRecord: LaborRateRecord,
      sliderWidth: 500,
      flexWidth: false,
      showSlide: false,
      showLaborGrid: false,
      // ag-grid props
      services: null, // should be null - fix to skip "No records found" msg on grid load.
      columnDefs: this.getColumnList(localeStrings, commonOpsLaborGridsEnabled),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClassRules: getCellClassRule(
          validationFields,
          editableFields,
          checkboxFields,
          ["laborRateType"]
        ),
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      // frameworkComponents: {
      //   customLoadingOverlay: CustomLoadingOverlay,
      //   customNoRowsOverlay: CustomLoadingOverlay,
      //   laborRateCodeEditor: LaborRateCodeEditor,
      //   textEditor: TextEditor,
      //   priceEditor: PriceEditor,
      //   laborRateTypeEditor: LaborRateTypeEditor
      // },
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      // localeText: {
      //   filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
      //   selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
      //   totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
      //   totalAndFilteredRows:
      //     localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
      //   noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      // },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "single",
      isRowSelectable() {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          }
        ],
        hiddenByDefault: false
      },
      showEditConfirmation: false,
      editConfirmationMsg: "",
      laborUpdateValue: {}
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    window.addEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
    window.addEventListener(
      "showLaborGridModalEvent",
      this.handleShowLaborGridModalEvent,
      false
    );
    window.addEventListener(
      "showConfirmEditEvent",
      this.handleShowConfirmEditEvent,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
    window.removeEventListener(
      "showLaborGridModalEvent",
      this.handleShowLaborGridModalEvent,
      false
    );
    window.removeEventListener(
      "showConfirmEditEvent",
      this.handleShowConfirmEditEvent,
      false
    );
  }
  initializeLocaleValues() {
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.addRateLabel = xlate("xmm.portal.laborrate.add_rate_lbl");
    this.errorLabel = xlate(
      "xmm.portal.errors.miissing_labor_rate_code_or_description"
    );
    this.pageTitle = xlate("xmm.portal.nav.labor_rates");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deletingErrorMsg = xlate("xmm.portal.errors.deleting");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const laborRatesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      this.setState({
        laborRatesGrid
      });
      this.context.setLaborRatesGridState(laborRatesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, searchKey } =
      this.state.laborRatesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.applyColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      // this.gridApi.setSortModel(sortState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey
        },
        () => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  onFirstDataRendered() {
    this.restoreGridState();
    this.sizeToFit();
  }
  handleColumnResized = () => {
    // this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerLaborRateCodeId; // primary or unique key of record
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  updateState(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
      }
      if (isArrayExist(datalist) && datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      // get the dealerCatalog cache
      const { dealerCatalogs } = this.context;
      const dealerCatalogsMap = {};
      dealerCatalogs.forEach(dc => {
        const { make } = dc;
        dealerCatalogsMap[make] = dc;
      });
      datalist.forEach(l => {
        const { make } = l;
        const dc = dealerCatalogsMap[make];
        l.defaultLaborRateFlag = dc.defaultLaborRateCode === l.laborRateCode;
      });
      this.setState(
        {
          services: datalist
        },
        () => {
          this.sizeToFit();
          // this.restoreGridState();
        }
      );
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const payload = {
      dealerCode
    };
    const restUrl = "/ops/proxyapi/ddsproxy/rest/table/dealerLaborRateCode";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          const filterList = data.filter(function (obj) {
            return obj.pricingMethod === 1;
          });
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(filterList);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "name",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent() {
    // do nothing for now
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    // console.log("onCellValueChanged", params.oldValue, params.newValue, params);
    const field = params.colDef.field;
    const datalist = this.state.services;
    if (
      toEmptyStringIfUndefined(params.oldValue) ===
      toEmptyStringIfUndefined(params.newValue)
    ) {
      return;
    }
    if (
      doesEmpty(params.data.laborRateCode) ||
      doesEmpty(params.data.description)
    ) {
      // params.newValue = params.oldValue;
      // params.value = params.oldValue;
      // params.data[field] = params.oldValue;
      this.setFieldValidation(
        params.data,
        field,
        "xmm.portal.errors.miissing_labor_rate_code_or_description"
      );
      return;
    }
    let count = 0;
    if (field === "laborRateCode" || field === "description") {
      let i;
      for (i = 0; i < datalist.length; i++) {
        const s = datalist[i];
        if (
          s.make === params.data.make &&
          (s.laborRateCode.toUpperCase() === params.newValue.toUpperCase() ||
            s.description.toUpperCase() === params.newValue.toUpperCase())
        ) {
          count++;
          if (count > 1) {
            // console.log("Found duplicates", params.data.make, params.newValue);
            // params.newValue = params.oldValue;
            // params.value = params.oldValue;
            // params.data[field] = params.oldValue;
            this.setFieldValidation(
              params.data,
              field,
              "xmm.portal.errors.miissing_labor_rate_code_or_description"
            );
            return;
          }
        }
      }
    }
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (
        params.colDef.field === "description" ||
        params.colDef.field === "laborRateCode" ||
        params.colDef.field === "laborRateType" ||
        params.colDef.field === "unpublishedPackageRate" ||
        params.colDef.field === "unpublishedStandaloneRate"
      ) {
        if (this.validateField(params)) {
          this.clearFieldValidation(params);
          this.onSaveCellEdit(params);
        }
        return;
      } else {
        // DO Nothing
      }
    }
  }
  resetDefaultLaborRateFlag = make => {
    const { services } = this.state;
    const dealerLaborRateList = services.filter(s => s.make === make);
    dealerLaborRateList.forEach(dc => {
      dc.defaultLaborRateFlag = false;
    });
  };
  saveDealerCatalog = catalog => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { defaultLaborRateCode } = catalog;
    const restAPI = `ops/dealerapi/saveDealerCatalogSettings/${catalog.dealerCode}/${catalog.make}`;
    makeSecureRestApi(
      {
        url: restAPI,
        method: "post",
        data: { defaultLaborRateCode }
      },
      () => {
        this.updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        this.updateStatusBox(errMsg, "error", false, true);
      }
    );
  };
  renderChangeLaborTypeModal() {
    const { showEditConfirmation, editConfirmationMsg } = this.state;
    return showEditConfirmation ? (
      <Confirmation
        htmlId="editLaborType"
        message={editConfirmationMsg}
        proceedButtonStyle="danger"
        show={showEditConfirmation}
        actionFunction={this.confirmEdit}
        closeDialog={this.closeEditModal}
      />
    ) : (
      ""
    );
  }
  confirmEdit = () => {
    const { laborUpdateValue } = this.state;
    laborUpdateValue.data.laborRateType = laborUpdateValue.newValue;
    this.savePayload(laborUpdateValue);
    this.setState({ showEditConfirmation: false, laborUpdateValue: {} });
  };
  closeEditModal = () => {
    this.setState({ showEditConfirmation: false });
  };
  handleShowConfirmEditEvent = event => {
    const { data } = event.detail;
    const editConfirmationMsg = !data.laborRateType
      ? xlate("xmm.portal.paytype.change_to_labor_grid_type_msg")
      : xlate("xmm.portal.paytype.change_to_flat_rate_type_msg");
    this.setState({
      showEditConfirmation: true,
      editConfirmationMsg,
      laborUpdateValue: event.detail
    });
  };
  handleShowLaborGridModalEvent = event => {
    const { data } = event.detail;
    const { description, make } = data;
    this.setState({
      showLaborGrid: true,
      dealerLaborRate: data,
      addEditLaborGridLabel: xlate(
        "xmm.portal.labor_rates.add_edit_labor_grid_modal_title"
      ).replace("%1", `${description} (${make})`)
    });
  };
  handleShowLaborGrid = (description, make, data) => {
    this.setState({
      showLaborGrid: true,
      dealerLaborRate: data,
      addEditLaborGridLabel: xlate(
        "xmm.portal.labor_rates.add_edit_labor_grid_modal_title"
      ).replace("%1", `${description} (${make})`)
    });
  };
  handleSaveCellEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    const dealerLaborRate = data;
    const { dealerCatalogs } = this.context;
    const dealerCatalogList = dealerCatalogs.filter(
      d => d.make === dealerLaborRate.make
    );
    // dealerCatalogList should be 1 since make is unique in the catalogs
    if (dealerCatalogList.length === 1) {
      const dealerCatalog = dealerCatalogList[0];
      // reset the defaultLaborRateFlag to 0 for all the labor rate records for that make
      this.resetDefaultLaborRateFlag(dealerLaborRate.make);
      // set/clear the defaultLaborRateCode value
      if (value) {
        dealerCatalog.defaultLaborRateCode = data.laborRateCode;
      } else {
        dealerCatalog.defaultLaborRateCode = "";
      }
      // update the labor rate defaultLaborRateFlag for the current checkbox
      dealerLaborRate[field] = !!value;
      // refresh all the rows for defaultLaborRateFalg column
      const params = {
        // don't do force since cell would be flashed as well
        force: true
        // columns: [field]
      };
      this.gridApi.refreshCells(params);
      // save the defaultLaborRateCode in DB for the make's dealer catalog
      this.saveDealerCatalog(dealerCatalog);
    }
  };
  onSaveLaborGrid = gridParams => {
    this.setState({ showLaborGrid: false });
    if (gridParams) {
      this.savePayload(gridParams);
    }
  };
  onSaveCellEdit(params) {
    this.savePayload(params);
  }
  /* celledit handler to save edits */
  savePayload(gridParams) {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const record = gridParams.data;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/dealerLaborRateCode/${record.dealerLaborRateCodeId}?_method=put`;
    if (
      gridParams &&
      gridParams.colDef.field === "laborRateType" &&
      (record.laborRateType === "1" || record.laborRateType === 1)
    ) {
      record.unpublishedPackageRate = null;
      record.unpublishedStandaloneRate = null;
      this.refreshCell(record, gridParams.colDef.field);
    }
    const payload = this.getCellEditPayload(record);
    if (record.make === "ANY") {
      payload.publishedPackageRate = payload.unpublishedPackageRate;
      payload.publishedStandaloneRate = payload.unpublishedStandaloneRate;
    }
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      response => {
        if (!response.success) {
          this.updateStatusBox(this.saveErrorMsg, "error", false, true);
        } else {
          this.updateStatusBox(this.savedMsg, "success", true);
          const { dealerLaborRateCode } = response;
          this.context.updateDealerLaborRates("edit", dealerLaborRateCode);
          if (gridParams) {
            const { field } = gridParams.colDef;
            this.refreshCell(record, field);
          }
        }
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        this.updateStatusBox(errMsg, "error", false, true);
      }
    );
  }
  confirmDeleteLaborRate(dealerLaborCode) {
    this.setState({ dealerLaborCode, showDeleteConfirmation: true });
  }
  isDealerLaborRateCodeIdUsedByGlobalOpsDealerOpertionRule =
    async dealerLaborRateCode => {
      const { dealerLaborRateCodeId } = dealerLaborRateCode;
      const url =
        "/ops/rest-db/getValues/dealerOperationRuleCountByDealerLaborRateCodeId";
      const params = { dealerLaborRateCodeId };
      return new Promise((resolve, reject) => {
        makeSecureRestApi(
          {
            url,
            method: "get",
            params
          },
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    };
  isDealerLaborRateCodeIdUsedByGlobalOpsOpertionOverride =
    async dealerLaborRateCode => {
      const { dealerLaborRateCodeId } = dealerLaborRateCode;
      const url =
        "/ops/rest-db/getValues/operationOverrideCountByDealerLaborRateCodeId";
      const params = { dealerLaborRateCodeId };
      return new Promise((resolve, reject) => {
        makeSecureRestApi(
          {
            url,
            method: "get",
            params
          },
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    };

  deleteLaborRate(dealerLaborRateCode) {
    this.updateStatusBox(this.deletingMsg, "pending", false);
    const { dealerLaborRateCodeId, laborGridId } = dealerLaborRateCode;
    const restEndPoint = `ops/proxyapi/ddsproxy/rest/proc/deleteLaborRateCode`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: { dealerLaborRateCodeId, laborGridId }
      },
      response => {
        if (response[0].retVal !== 0) {
          this.updateStatusBox(this.deletingErrorMsg, "error", false, true);
        } else {
          const res = this.gridApi.applyTransaction({
            remove: [dealerLaborRateCode]
          });
          res.remove.forEach(function (rowNode) {
            console.log("Removed Row Node", rowNode);
          });
          this.updateStatusBox(this.deletedMsg, "success", true);
          this.context.updateDealerLaborRates("delete", dealerLaborRateCode);
          const { services } = this.state;

          const index = services.findIndex(
            item =>
              item.dealerLaborRateCodeId ===
              dealerLaborRateCode.dealerLaborRateCodeId
          );
          if (index !== -1) {
            services.splice(index, 1);
          }
          this.setState({ services });
          // GTM - push click event to dataLayer
          gtmEvents.gtmTrackEvent("xmm.laborrates.delete_rate_click");
        }
        this.closeDeleteModal();
      },
      error => {
        this.closeDeleteModal();
        const msg = error["message"] ? error.message : this.deletingErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }

  getCellEditPayload(record) {
    let payload = {};

    payload = {
      dealerCode: record.dealerCode,
      make: record.make,
      laborRateCodeId: record.laborRateCodeId,
      laborRateCode: record.laborRateCode,
      laborRateType: record.laborRateType,
      laborGridId: record.laborGridId,
      description: record.description,
      unpublishedPackageRate: record.unpublishedPackageRate,
      unpublishedStandaloneRate: record.unpublishedStandaloneRate
    };

    return payload;
  }
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#laborrate-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.setState({
        searchKey
      });
    }
  };
  getColumnList(localeStrings, commonOpsLaborGridsEnabled) {
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.grid.make"],
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "make",
        sortingOrder: ["asc", "desc"],
        maxWidth: 150,
        minWidth: 100,
        editable: false,
        valueFormatter(params) {
          return params.value;
        },
        filter: "agSetColumnFilter",
        suppressSizeToFit: true,
        filterParams: {
          buttons: ["clear"],
          comparator: sortByMake
        }
      },
      {
        headerName: localeStrings["xmm.portal.parts_labor.grid_code"],
        field: "laborRateCode",
        // eslint-disable-next-line no-constant-condition
        editable: params => {
          return params.data["laborRateCodeId"] == null;
        },
        cellEditor: TextEditor,
        cellEditorParams: {
          maxLength: 10,
          cellHeight: 25
        },
        cellClass: params => {
          if (params.data["laborRateCodeId"] === null) {
            return "editable-cell";
          } else {
            return "xmm-wrap-cell";
          }
        },
        tooltipField: "Labor Rate Code",
        tooltipComponentParams: { field: "laborRateCode" },
        width: 120,
        sortingOrder: ["asc", "desc"],
        valueFormatter: blankValueFormatter,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.description"],
        field: "description",
        editable(params) {
          const { newValue } = params;
          return (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          );
        },
        cellEditor: TextEditor,
        cellEditorParams: { keepInvalidValue: true, maxLength: 32 },
        cellClass: "editable-cell",
        tooltipField: "description",
        tooltipComponentParams: { field: "description" },
        enableRowGroup: false,
        valueFormatter: blankValueFormatter,
        width: 420,
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.type_lbl"],
        headerClass: "ag-text-header",
        field: "laborRateType",
        hide: !commonOpsLaborGridsEnabled,
        editable: true,
        maxWidth: 150,
        minWidth: 150,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        enableRowGroup: false,
        cellEditor: LaborRateTypeEditor,
        cellRenderer: this.typeCellRenderer,
        cellEditorParams: {
          parentHandle: this
        },
        valueFormatter: params => {
          return !params.value || params.value === 0 || params.value === "0"
            ? "Flat Rate"
            : "Labor Grid";
        },
        refData: { null: "Flat Rate", 0: "Flat Rate", 1: "Labor Grid" }
      },
      {
        headerName: localeStrings["xmm.portal.parts_labor.grid_package_rate"],
        field: "unpublishedPackageRate",
        colId: "unpublishedPackageRate",
        headerClass: "ag-numeric-header",
        cellEditor: PriceEditor,
        editable(params) {
          const { laborRateType } = params.data;
          return laborRateType !== "1" && laborRateType !== 1;
        },
        cellEditorParams: { keepInvalidValue: true },
        cellClass: "editable-cell",
        cellStyle: { textAlign: "right" },
        tooltipField: "unpublishedPackageRate",
        tooltipComponentParams: { field: "unpublishedPackageRate" },
        enableRowGroup: false,
        // valueGetter(params) {
        //   return params.data.unpublishedPackageRate;
        // },
        valueFormatter: localPriceFormatter,
        // valueSetter: priceValueSetter,
        type: "numberColumn"
        // width: 150
      },
      {
        headerName:
          localeStrings["xmm.portal.parts_labor.grid_a_la_carte_rate"],
        field: "unpublishedStandaloneRate",
        colId: "unpublishedStandaloneRate",
        // valueGetter(params) {
        //   return params.data.unpublishedStandaloneRate;
        // },
        valueFormatter: localPriceFormatter,
        // valueSetter: priceValueSetter,
        headerClass: "ag-numeric-header",
        cellEditor: PriceEditor,
        editable(params) {
          const { laborRateType } = params.data;
          return laborRateType !== "1" && laborRateType !== 1;
        },
        cellEditorParams: { keepInvalidValue: true },
        cellClass: "editable-cell",
        cellStyle: { textAlign: "right" },
        tooltipField: "unpublishedStandaloneRate",
        tooltipComponentParams: { field: "unpublishedStandaloneRate" },
        enableRowGroup: false,
        type: "numberColumn"
        // width: 150
      },
      {
        headerName: localeStrings["xmm.portal.parts_labor.default_labor_code"],
        field: "defaultLaborRateFlag",
        cellRenderer: CheckboxCell,
        cellRendererParams: {
          field: "defaultLaborRateFlag"
        },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        editable: false,
        enableRowGroup: false,
        // cellClass: "xmm-grid-cell-checkbox",
        minWidth: 100
      },
      {
        headerName: "",
        field: "",
        // tooltipField: "description",
        // tooltipComponentParams: { field: "description" },
        // cellClass: "xmm-wrap-cell",
        pinned: "right",
        editable: false,
        cellClass: "editable-cell",
        //cellEditor: LaborRateCodeEditor,
        cellEditorParams: {
          parentHandle: this
        },
        cellEditorPopup: true,
        cellRenderer: this.renderMoreButton,
        enableRowGroup: false,
        maxWidth: 61,
        minWidth: 61,
        width: 61,
        filter: false,
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressColumnsToolPanel: true
      }
    ];
    return baseCols;
  }
  setFieldValidation(data, field, errorKey) {
    const { localeStrings } = this.context;
    if (!data.errors) {
      data.errors = {};
    }
    data.errors[field] = localeStrings[errorKey];
    this.refreshCell(data, field);
    this.updateStatusBox(data.errors[field], "error", false, true);
  }
  refreshCell(record) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerLaborRateCodeId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        // columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "description":
          if (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          ) {
            errorKey = "xmm.portal.errors.invalid_field";
          }
          break;
        case "unpublishedPackageRate":
          if (newValue && (parseFloat(newValue) || 0) > 9999.99) {
            errorKey = "xmm.portal.errors.exceed_max_price";
          }
          break;
        case "unpublishedStandaloneRate":
          if (newValue && (parseFloat(newValue) || 0) > 9999.99) {
            errorKey = "xmm.portal.errors.exceed_max_price";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }
  // bind to add rate click event
  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: LaborRateRecord,
      operationName: this.addRateLabel
    }));
  };
  handleRowSelected = () => {
    // console.log(
    //   `labor rate code id: ${event.node.data.laborRateCodeId} is selected=${
    //     event.node.selected
    //   }`,
    //   event.node
    // );
  };

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };
  hideSlider = () => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: LaborRateRecord,
      operationName: this.addRateLabel
    }));
  };
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSlider = () => {
    const callback = () => {
      this.setState({
        showSlide: false
      });
    };
    const isDirtyFunc = () => {
      return this.laborRateFormRef.current.state.dirty;
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showSlide: false
      });
    }
  };
  closeLaborGridModal = laborGridDataChanged => {
    const callback = () => {
      this.setState({
        showLaborGrid: false
      });
    };
    const isDirtyFunc = () => {
      return laborGridDataChanged;
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showLaborGrid: false
      });
    }
  };
  /* update Grid rows when labor rate added */
  updateLaborRateAfterSave = dealerLaborRateCode => {
    let hasDefaultLaborRateCode = false;
    if (this.gridApi && dealerLaborRateCode) {
      dealerLaborRateCode.defaultLaborRateCode =
        dealerLaborRateCode.laborRateCode;
      const res = this.gridApi.applyTransaction({
        add: [dealerLaborRateCode]
      });
      // get the row node with ID
      const rowNode = this.gridApi.getRowNode(
        dealerLaborRateCode.dealerLaborRateCodeId
      );
      this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
      rowNode.setSelected(false);
      const gridParams = {
        force: true,
        rowNodes: [rowNode]
      };
      const { services } = this.state;
      const dealerLaborRateList = services.filter(
        s => s.make === dealerLaborRateCode.make
      );
      dealerLaborRateList.forEach(dc => {
        if (dc.defaultLaborRateFlag) {
          hasDefaultLaborRateCode = true;
        }
      });
      services.push(dealerLaborRateCode);
      this.setState({ services });
      this.context.updateDealerLaborRates("add", dealerLaborRateCode);
      if (!hasDefaultLaborRateCode) {
        dealerLaborRateCode.defaultLaborRateFlag = true;
        this.saveDealerCatalog(dealerLaborRateCode);
      }
      this.callRefreshAfterMillis(gridParams, this.gridApi);
    }
  };
  renderMoreButton(params) {
    const { laborRateCodeId, defaultLaborRateFlag } = params.data;
    const dealerLaborCode = params.data;

    if (!laborRateCodeId) {
      if (defaultLaborRateFlag) {
        return "";
      }
      //this.setState({ dealerLaborCode});
      //return <IconMore className="xmm-rule-dropdown-menu-renderer" />;
      return <IconDelete className="xmm-rule-dropdown-menu-renderer" 
        onClick={() => {
          this.setState({dealerLaborCode, showDeleteConfirmation: true });
          this.confirmDeleteLaborRate(dealerLaborCode);
        }} />;
    }
    return "";
  }
  closeDeleteModal = () => {
    this.setState({ showDeleteConfirmation: false });
  };
  renderDeleteLaborCodeConfirmation() {
    const { dealerLaborCode, showDeleteConfirmation } = this.state;
    const { localeStrings } = this.context;
    return showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteMenuType"
        message={localeStrings["xmm.portal.laborrate.delete_confirmation"]}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={async () => {
          const ok = await this.okToDeleteLaborRate(dealerLaborCode);
          if (ok) {
            this.deleteLaborRate(dealerLaborCode);
          } else {
            this.closeDeleteModal();
            this.updateStatusBox(this.deletingErrorMsg, "error", false, true);
          }
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
  }
  okToDeleteLaborRate = async dealerLaborCode => {
    let okToDelete = true;
    const { dealer } = this.context;
    const { commonOpsRepairsEnabled } = dealer;
    if (commonOpsRepairsEnabled) {
      try {
        const usedCountInDealerOpRule =
          await this.isDealerLaborRateCodeIdUsedByGlobalOpsDealerOpertionRule(
            dealerLaborCode
          );
        const usedCountInOpOverride =
          await this.isDealerLaborRateCodeIdUsedByGlobalOpsOpertionOverride(
            dealerLaborCode
          );
        okToDelete =
          Array.isArray(usedCountInDealerOpRule) &&
          usedCountInDealerOpRule.length &&
          !usedCountInDealerOpRule[0].count &&
          Array.isArray(usedCountInOpOverride) &&
          usedCountInOpOverride.length &&
          !usedCountInOpOverride[0].count;
      } catch (err) {
        okToDelete = false;
      }
    }
    return okToDelete;
  };
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  typeCellRenderer = params => {
    if (!params || !params.data) {
      return "";
    }
    if (!params.value || params.value === 0 || params.value === "0") {
      return "Flat Rate";
    }
    const { description, make } = params.data;
    return (
      <span className="xmm-labor-rate-type-cell">
        <span>Labor Grid</span>
        <span
          className="labor-rate-type-icon"
          onClick={() =>
            this.handleShowLaborGrid(description, make, params.data)
          }
        >
          <i className="far fa-edit" />
        </span>
      </span>
    );
  };
  render() {
    const { getClassWithBridgeBar } = this.context;
    const deleteModal = this.renderDeleteLaborCodeConfirmation();
    const editConfirmModal = this.renderChangeLaborTypeModal();
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="mainGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.services}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            // rowHeight={50}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    const header = (
      <React.Fragment>
        <div className="content-header">
          <h3 className="xmm-main-title">{this.state.pageTitle}</h3>
          <div className="xmm-form-header">
            <div className="float-right">{statusHtml}</div>
            <Button
              htmlId="addActionBtn"
              buttonStyle="secondary"
              disabled={this.state.disableAction}
              onClick={this.addActionSlider}
              className="float-right"
            >
              {this.addRateLabel}
            </Button>
            <div className="xmm-input-search float-right">
              <input
                type="text"
                id="laborrate-search-box"
                className="xmm-input"
                placeholder={this.searchLabel}
                onChange={this.onSearchBoxChanged}
                value={this.state.searchKey}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    // Add components within slider
    const modalTitle = <span>{this.state.operationName}</span>;

    let laborGridModal = "";
    if (this.state.commonOpsLaborGridsEnabled) {
      const laborGridModalTitle = (
        <span>{this.state.addEditLaborGridLabel}</span>
      );
      laborGridModal = (
        <ModalDialog
          htmlId="add-edit-labor-grid"
          show={this.state.showLaborGrid}
          backdrop={"static"}
          animation={false}
          className="xmm-add-edit-labor-grid-modal"
          header={<ModalDialog.Title>{laborGridModalTitle}</ModalDialog.Title>}
          onHide={() => {
            this.closeLaborGridModal(
              this.laborRateFormRefModal.current.isLaborGridDataChanged()
            );
          }}
          footer={false}
        >
          <div>
            <LaborGridEditPage
              key={"editSlider"}
              ref={this.laborRateFormRefModal}
              dealerLaborRate={this.state.dealerLaborRate}
              onCancel={this.closeLaborGridModal}
              onSaveLaborGrid={this.onSaveLaborGrid}
            />
          </div>
        </ModalDialog>
      );
    }

    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editCommonSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        className={getClassWithBridgeBar("xmm-slider")}
      >
        <div>
          <AddLaborRateFormPage
            key={"editSlider"}
            ref={this.laborRateFormRef}
            rowRecord={this.state.rowRecord}
            editForm={true}
            laborRates={this.state.services}
            editOption={this.state.editOption}
            hideSlider={this.hideSlider}
            updateLaborRateAfterSave={this.updateLaborRateAfterSave}
          />
        </div>
      </GenericSlider>
    );
    return (
      <div className="xmm-center-container">
        {header}
        {gridWidget}
        {formSlider}
        {deleteModal}
        {editConfirmModal}
        {laborGridModal}
      </div>
    );
  }
}

export default LaborRatesPage;

LaborRatesPage.propTypes = {};

function localPriceFormatter(params) {
  const { data } = params;
  const { laborRateType } = data;
  if (laborRateType === 1) {
    return "-";
  }
  return priceFormatter(params);
}
