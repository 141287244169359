/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import CatalogRow from "./CatalogRow";
import { AppContext } from "../../../../app-context";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
class CatalogPricing extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.catalogRows = [];
    this.state = {};
    this.updateStatusBox = this.updateStatusBox.bind(this);
  }
  componentDidMount() {}

  // This handler calls its parent handler to autosave record field changes
  onUpdate = (catalog, name, payload) => {
    // console.log("catalogPricing onUpdate", name, payload);
    this.props.onSave(catalog, name, payload, "catalogPricingRef");
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
  };

  callChildMethod = (msg, type, close, errorInTooltip) => {
    this.updateStatusBox(msg, type, close, errorInTooltip);
  };

  /* These Utils used for form validation style */
  updateStatusBox = (msg, type, close, errorInTooltip) => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  };

  render() {
    const { dealerCatalogs, localeStrings } = this.context;
    let resultTable = null;
    let catalogHtml = null;
    const pricebyPopover = (
      <div>
        <b>{localeStrings["xmm.portal.settings.calculated_parts_lbl"]}</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.settings.calculated_tip"]}
        </p>
        <b>{localeStrings["xmm.portal.settings.total_flat_lbl"]}</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.settings.flat_pricing_tip"]}
        </p>
        <b>{localeStrings["xmm.portal.settings.no_pricing_lbl"]}</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.settings.nopricing_tip"]}
        </p>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.settings.price_by_tip"]}
        </p>
      </div>
    );
    if (dealerCatalogs && dealerCatalogs.length !== 0) {
      catalogHtml = dealerCatalogs.map((record, index) => {
        return (
          <CatalogRow
            key={"catalog-" + record.make}
            ref={ref => (this.catalogRows[index + 1] = ref)}
            catalog={record}
            onUpdate={this.onUpdate}
          />
        );
      });

      resultTable = (
        <Grid className="setting-table">
          <Row>
            <Col md={2} sm={2}>
              &nbsp;
            </Col>
            <Col md={10} sm={10} className="xmm-form-content xmm-form-header">
              <div className="xmm-input-col">
                {localeStrings["xmm.portal.settings.price_by_lbl"]}
                <Popover
                  htmlId="tipPriceby"
                  popoverContent={pricebyPopover}
                  position="right"
                  trigger={["click", "outsideClick"]}
                >
                  <IconInfoOutline htmlId="IconPriceBy" />
                </Popover>
              </div>
              <div className="xmm-fixed-col">
                {localeStrings["xmm.portal.settings.show_packages_lbl"]}
              </div>
              <div className="xmm-fixed-col">
                {localeStrings["xmm.portal.settings.show_operations_lbl"]}
              </div>
            </Col>
          </Row>
          {catalogHtml}
        </Grid>
      );
    }
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );

    return (
      <Card htmlId="catalogCard">
        <div className="settings-card-header">
          <div className="header-left">
            <h4>{localeStrings["xmm.portal.settings.catalog_lbl"]}</h4>
            <div className="btn-div" />
          </div>
          <span className="float-right">{statusHtml}</span>
        </div>
        {resultTable}
      </Card>
    );
  }
}

export default CatalogPricing;

CatalogPricing.propTypes = {
  onSave: PropTypes.func
};
