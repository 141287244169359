/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Button from "@cx/ui/Button";
import AccordionGroup from "@cx/ui/AccordionGroup";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import SearchableSelect from "@cx/ui/SearchableSelect";
import ServiceRow from "./ServiceRow";
import InspectionTemplate from "./InspectionTemplate";
import TotalTemplate from "./TotalTemplate";
import PreviewContext from "../../preview-context";
import {
  isEmpty,
  isObject,
  doesEmpty,
  isArrayExist
} from "../../../../../commonUtil/utils/object";
import {
  convertMinutesToTenths,
  convertMinutesToHundredths,
  defaultToZeroIfNullOrEmpty
} from "../../../../../commonUtil/utils/value";
import { menuTypeComparator } from "../../../../../commonUtil/utils/list";
import { DisplayFormikState } from "../../../../reusable/helper";
import { IncludedInspectionTags } from "../../../../../constants/ModuleConstants";

export const getMenuTypes = (cmptIndex,enableDMSPlusContent, dataByMileagePoint, menuTypes) => {
  let tmpMenuTypes = null;

  if (enableDMSPlusContent) {
    if (dataByMileagePoint) {
      tmpMenuTypes = dataByMileagePoint[cmptIndex]?.menuTypes || []; }
    else {
      tmpMenuTypes = [];
    }
  }    
  else {
    tmpMenuTypes = menuTypes || [];
  }
  return tmpMenuTypes;
};

export default class MenuPackagesTable extends React.Component {
  static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.mileagePoints !== prevState.mileagePoints ||
      nextProps.currentMileagePoint !== prevState.currentMileagePoint ||
      prevState.currentMileagePoint === ""
    ) {
      const { enableDMSPlusContent } = prevState;
      if (nextProps.currentMileagePoint !== prevState.currentMileagePoint) {
        const { generateMenuPackages, handleAccordionChange } = prevState;
        if (enableDMSPlusContent) {
          setTimeout(() => {
            handleAccordionChange(nextProps.currentMileagePoint);
          }, 50);
        } else {
          setTimeout(() => {
            generateMenuPackages(nextProps.currentMileagePoint);
          }, 50);
        }
      }
      const { menuResults, mileagePoints, currentMileagePoint } = nextProps;
      let defaultMileage = currentMileagePoint;
      if (!enableDMSPlusContent) {
        if (!currentMileagePoint) {
          defaultMileage =
            mileagePoints && mileagePoints.length > 0
              ? mileagePoints[0].value
              : "";
        }
      }
      return {
        menuResults,
        expandServices: [],
        mileagePoints: isEmpty(mileagePoints) ? [] : mileagePoints,
        currentMileagePoint: defaultMileage
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);
    this.serviceRows = [];
    this.servicesButtonStates = {};
    this.accordionItemStates = {};
    this.dataByMileagePoint = {};
    this.expandAllClick = this.expandAllClick.bind(this);
    this.onMileageChange = this.onMileageChange.bind(this);
    this.generateMenuPackages = this.generateMenuPackages.bind(this);
    this.processMenuData = this.processMenuData.bind(this);
    this.handleAccordionChange = this.handleAccordionChange.bind(this);
    const { localeStrings, enableDMSPlusContent } = context.appContext;
    // preview.resultTabs.selectedIndex = props.selectedIndex;

    this.optionalLabel =
      localeStrings["xmm.portal.preview.vehicle.optional_lbl"];
    this.optiionalPreselectedLabel =
      localeStrings["xmm.portal.preview.vehicle.optional_preselected_lbl"];
    this.state = {
      isToggle: false,
      selectableMenu: false,
      menuResults: isEmpty(props.menuResults) ? null : props.menuResults,
      disableFilter: true,
      isLoaded: false,
      expandAll: false,
      services: [],
      menuTypes: [],
      inspections: [],
      isChanged: false,
      mileagePoints: props.mileagePoints,
      menuClass: "",
      serviceIds: [],
      expandServices: [],
      currentMileagePoint: props.currentMileagePoint,
      generateMenuPackages: this.generateMenuPackages,
      handleAccordionChange: this.handleAccordionChange,
      expandedAccordion: this.props.currentMileage || null,
      allAccordionItemsExpanded: false,
      clickOnGraph: false,
      enableDMSPlusContent
    };
    console.log(props.menuResults);
  }

  componentDidMount() {
    //Used for handling expand/collapse ExpandAll button state with multiple Accordionitems
    const initialStates = {};
    this.preSelectMileage();
    this.props.mileagePoints.forEach((mileagePoint) => {
      initialStates[mileagePoint.mileage] = false;
    });
    this.setState({servicesButtonStates:initialStates,
                   accordionItemStates:initialStates});
  }
  toggleStateCard = event => {
    event.preventDefault();
    this.setState(prevState => ({
      isToggle: !prevState.isToggle
    }));
  };

  toggleExpand= () => {
    this.setState(prevState => ({
        isExpanded: !prevState.isExpanded
      }));
  }

  toggleAllAccordionItems = () => {
    this.setState(prevState => {
      const newExpandedState = !prevState.allAccordionItemsExpanded;
      const expandedStates = {...prevState.accordionItemStates}
      Object.keys(expandedStates).forEach(key => {
        expandedStates[key] = newExpandedState;
      });
      return {
        accordionItemStates: expandedStates,
        allAccordionItemsExpanded:newExpandedState
      };
    });
  }

  /* ExpandAll "icon" click handler: to toggle all expand state*/
  expandAllClick = (event,cmptIndex) => {
    const {enableDMSPlusContent} = this.state;
    if (enableDMSPlusContent) {
      this.setState(prevState => {
        const newExpandedState = !this.state.servicesButtonStates[cmptIndex];
        return {
          servicesButtonStates: {
            ...prevState.servicesButtonStates,
            [cmptIndex] : newExpandedState
          }
        }
      });
    } else {
      event.preventDefault();
      const { expandAll } = this.state;

      this.serviceRows.forEach(ref => {
        // console.log("expandall", ref);
        if (ref) {
          ref.setState({
            isExpand: !expandAll
          });
        }
      });
      this.setState(prevState => ({
        expandAll: !prevState.expandAll
      }));
    }
  };

  /* This call used when Menus tab is re-visited, to load serivces from cache */
  preSelectMileage() {
    const { currentMileagePoint } = this.context.appContext.preview;

    if (currentMileagePoint) {
      this.setState(
        prevState => {
          return {
            currentMileagePoint,
            isLoaded: false,
            isChanged: !prevState.isChanged
          };
        },
        () => {
          // callback
          console.log("call preSelectMileage", currentMileagePoint);
          this.generateMenuPackages(currentMileagePoint);
        }
      );
    }
  }

  onMileageChange = cxEvent => {
    const { currentMileagePoint, enableDMSPlusContent } = this.state;
    let optionValue;
    //US1300602 - cxEvent.id is the unique htmlId of the
    //Accordion item selected.  It is automatically created by the Accordion component
    //but needs to be parsed. It is working well on the tests

    // Click on externalFilters dropdown list with mileages
    if (enableDMSPlusContent && cxEvent.id) {
        optionValue = cxEvent.id.split("-")[0];
    } else if (enableDMSPlusContent && cxEvent.fromGraph) {
      optionValue = cxEvent.fromGraph;
    } else {
        const { name, value } = cxEvent.target;
        optionValue = value && value.length !== 0 ? value[0].value : "";
    }

    if (optionValue === currentMileagePoint && !this.clickOnGraph) {
      this.clickOnGraph = false;
      return;
    }

    this.setState(
      prevState => {
        return {
          [name]: optionValue,
          isLoaded: false,
          isChanged: !prevState.isChanged,
          accordionItemStates : {
            ...prevState.accordionItemStates,
            [optionValue]: true
          }
        };
      },
      () => {
        // callback
        this.context.appContext.updateCurrentMileage(optionValue);
        this.generateMenuPackages(optionValue);
      }
    );
  };

  ensureAccordionItemVisible() {
    const { currentMileagePoint } = this.state;
    const accordionItem =  document.getElementById(currentMileagePoint + '--heading');
    if (accordionItem) {
        accordionItem.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }
  }

  findServicePoint(servicePoints, mileage) {
    let servicePoint = null;
    for (let index = 0; index < servicePoints.length; index++) {
      if (mileage.toString() === servicePoints[index].mileage.toString()) {
        servicePoint = servicePoints[index];
        break;
      }
    }
    return servicePoint;
  }
  isIncludedInspection = service => {
    const { price, serviceCategoryName } = service;
    if (defaultToZeroIfNullOrEmpty(price) !== 0) {
      return false;
    }
    if (!serviceCategoryName) {
      return false;
    }
    const serviceCategoryLowercase = serviceCategoryName.toLowerCase();
    for (let index = 0; index < IncludedInspectionTags.length; index++) {
      if (
        serviceCategoryLowercase.indexOf(IncludedInspectionTags[index]) !== -1
      ) {
        return true;
      }
    }
    return false;
  };
  /*
    service.serviceMenuTypes = [{
                              id
                              name
                              description
                              rank
                              selectableType
                              includedInMenu: true/false
                            }]
  */
  createServiceMenuType(menuTypes) {
    const serviceMenuTypes = menuTypes.map(menuType => {
      const { id, name, description, rank } = menuType;
      return { id, name, description, rank, includedInMenu: false };
    });
    return serviceMenuTypes;
  }
  findServiceMenuById(menuTypes, id) {
    for (let index = 0; index < menuTypes.length; index++) {
      if (menuTypes[index].id.toString() === id.toString()) {
        return menuTypes[index];
      }
    }
    return null;
  }
  getSelectableType(selectable, selectByDefault) {
    // 1. When Service is NOT selectable {false}; display tick mark icon
    // 2. When Service is selectable{true}, and NOT selectByDefault{false}; display EMPTY circle icon
    // 3. When Service is selectable{true}, and is selectByDefault{true}; display CHECKED circle icon
    return !selectable
      ? "non-selected"
      : selectByDefault
      ? "selected-default"
      : "selected";
  }
  /* Check if any menu is selectable; then hide options icons on table */
  isSelectableMenu = servicePoint => {
    const { menuTypes } = servicePoint;
    let selectableMenu = false;
    let selected = 0;
    menuTypes.forEach(menuType => {
      if (
        menuType.hasOwnProperty("selectable") &&
        menuType.selectable === true
      ) {
        selected++;
      }
    });
    selectableMenu = selected > 0 ? true : false;
    setTimeout(() => {
      // console.log("isSelected", selected, selectableMenu);
      this.setState({
        selectableMenu
      });
    }, 30);
  };
  createRegularAndIncludedInspectionServices(servicePoint) {
    // key is service id and value is service for these two maps
    const serviceMap = {};
    const inspectionServiceMap = {};
    const { menuTypes } = servicePoint;
    menuTypes.sort(menuTypeComparator);
    // create serviceMap out of all menuTypes
    menuTypes.forEach(menuType => {
      const { services } = menuType;
      services.forEach(s => {
        // key is serviceId value is service
        let service = s;
        const { id, selectable, selectByDefault } = service;
        const currentMap = this.isIncludedInspection(service)
          ? inspectionServiceMap
          : serviceMap;
        const serviceMapValue = currentMap[id.toString()];
        if (!serviceMapValue) {
          currentMap[id.toString()] = service;
          // clone four up to 4 menu types and attach to each service
          service.serviceMenuTypes = this.createServiceMenuType(menuTypes);
        } else {
          // look up the service from the currentMap since the current service with the same id might not have the serviceMenuType
          service = currentMap[id.toString()];
        }
        const { serviceMenuTypes } = service;
        const serviceMenuType = this.findServiceMenuById(
          serviceMenuTypes,
          menuType.id
        );
        serviceMenuType.includedInMenu = true;
        serviceMenuType.selectableType = this.getSelectableType(
          selectable,
          selectByDefault
        );
      });
    });
    return { serviceMap, inspectionServiceMap };
  }
  processMenuData = (
    menuResults,
    servicePoint,
    serviceMap,
    inspectionServiceMap
  ) => {
    const inspections = this.getInspectionServices(inspectionServiceMap);
    const rawResponse = this.convertMenuResults(
      menuResults,
      serviceMap || {},
      servicePoint.menuTypes,
      servicePoint.mileage
    );
    const { services, serviceIds, rawMenuTypes } = rawResponse;
    this.setState({
      isLoaded: true,
      services,
      serviceIds,
      menuTypes: rawMenuTypes,
      inspections
    });
    return {
      services,
      serviceIds,
      menuTypes: rawMenuTypes,
      inspections
    }
    console.log(
      "Menus inspections for mileage",
      servicePoint.mileage,
      inspections
    );
  };
  generateMenuPackages = mileage => {
    const { menuResults } = this.state;
    if (!isEmpty(menuResults)) {
      const servicePoints = menuResults.servicePoints;
      /* Note: we transform menu-type level services as per below Hashmap
      const service-map = {
        serviceid1 : { serviceobj, menu-map : {menuid1: menu1, menuid2: menu2 } }
        serviceid2 : { serviceobj, menu-map : {menuid1: menu1, menuid3: menu3 } }
      }
      */
      // Fetch services across menutypes for the selected mileage point
      const servicePoint = this.findServicePoint(servicePoints, mileage);
      if (servicePoint) {
        this.isSelectableMenu(servicePoint);
        const { serviceMap, inspectionServiceMap } =
          this.createRegularAndIncludedInspectionServices(servicePoint);
        console.log(
          "Menu Package Service Map, inspections Map for mileage point",
          servicePoint.mileage,
          serviceMap,
          inspectionServiceMap
        );
        const processData = this.processMenuData(
          menuResults,
          servicePoint,
          serviceMap,
          inspectionServiceMap
        );

        this.setState(prevState => ({
          dataByMileagePoint: {
            ...prevState.dataByMileagePoint,
                        [mileage]:processData,
          }
        }));
      }
    }
  };

  /* Method to transform response to support Menus preview component
   */
  convertMenuResults(response, serviceMap, rawMenuTypes, currentMileage) {
    const modifiedResponse = {};
    let pricingMethod = 1; // set default value as 1, to show parts,labor columns for Alacarte packages
    let vehicleReadinessPricingMethod = 0; // by default, 0 - No show pricing, 1 - default pricing; show banner for calculated workflow
    let pricingReady = true; // by default, set true - "Pricing Ready" to hide banner, false - when "Pricing Not ready"; show banner for calculated workflow
    let laborOpsScale = "TENTHS"; // default  case
    let showMenuPricing = 1; // set default value, to show Totals for Menu packages
    const serviceIdList = Object.keys(serviceMap);
    const rawServices = Object.values(serviceMap);

    // Read dealer level Setting
    const { makeVariantMap } = this.context.appContext;
    const { search } = this.context.appContext.preview;
    const { make } = search;
    const dealerCatalog = makeVariantMap[make];
    if (
      dealerCatalog.laborTimePrecision &&
      !isEmpty(dealerCatalog.laborTimePrecision)
    ) {
      laborOpsScale = dealerCatalog.laborTimePrecision.toUpperCase();
      modifiedResponse.laborTimePrecision = laborOpsScale;
    }
    if (!isEmpty(response)) {
      modifiedResponse.actualMileage = currentMileage;

      if (!doesEmpty(response.showMenuPricing)) {
        showMenuPricing = response.showMenuPricing;
        modifiedResponse.showMenuPricing = showMenuPricing;
      }

      if (!doesEmpty(response.pricingMethod)) {
        pricingMethod = response.pricingMethod;
        modifiedResponse.pricingMethod = pricingMethod;
      }
      if (!doesEmpty(response.pricingReady)) {
        pricingReady = response.pricingReady;
        modifiedResponse.pricingReady = pricingReady;
      }
      if (!doesEmpty(response.vehicleReadinessPricingMethod)) {
        vehicleReadinessPricingMethod = response.vehicleReadinessPricingMethod;
        modifiedResponse.vehicleReadinessPricingMethod =
          vehicleReadinessPricingMethod;
      }
      modifiedResponse.services = rawServices;
      /* Important - Since rest api doesn't return services sorted by "order" for each menutype,
       * we apply sorting "using service.order" under each menutype in client-side
       */
      modifiedResponse.services.sort(function (a, b) {
        return parseInt(a.order, 10) - parseInt(b.order, 10);
      });

      let dealerAddTotal = 0; // This var used to store Dealer Add Total sum(dealerFallbackPrice) for each Dealer defined operation.
      let laborTimeMinutes = 0.0; // This var used to store total Flat rate sum(duration) for each service
      let serviceDurationTotal = 0;
      modifiedResponse.services.forEach(aService => {
        const origParts = aService.part; // response has association model as "part"

        // Note: set key with unique id, to react
        aService.key = aService.id;
        aService.mileage = currentMileage;
        // Check if description {key} missing in service
        const isExistDesc = isObject(aService, "description");
        aService.description = isExistDesc ? aService.description : null;

        // set service "price" here
        aService.priceLabel = "";
        if (defaultToZeroIfNullOrEmpty(aService.price) !== 0) {
          aService.priceLabel =
            aService.price > 0 ? "$" + aService.price.toFixed(2) : " ";
        }

        // set "pricingMethod" flag at each service object
        if (!doesEmpty(pricingMethod)) {
          aService.pricingMethod = pricingMethod;
          // rawMenuType.pricingMethod = pricingMethod;
        }
        // set "pricingReady" flag at each service object
        if (!doesEmpty(pricingReady)) {
          aService.pricingReady = pricingReady;
        }
        // set "vehicleReadinessPricingMethod" flag at each service object
        if (!doesEmpty(vehicleReadinessPricingMethod)) {
          aService.vehicleReadinessPricingMethod =
            vehicleReadinessPricingMethod;
        }

        if (aService.id) {
          // aService.rawMenuTypes = rawMenuTypes; // Add menutypes[] to each service
          // convert service "duration" to minutes based on dealer-setting has 'HUNDREDTHS' or TENTHS
          aService.laborTimePrecision = laborOpsScale;
          aService.duration = isObject(aService, "duration")
            ? defaultToZeroIfNullOrEmpty(aService.duration)
            : 0;
          aService.durationInMins = 0;
          if (aService.duration) {
            serviceDurationTotal += aService.duration;
            let durationInMins = 0;
            if (laborOpsScale === "HUNDREDTHS") {
              durationInMins = parseFloat(
                convertMinutesToHundredths(aService.duration)
              );
              laborTimeMinutes += durationInMins;
            } else if (laborOpsScale === "TENTHS") {
              durationInMins = parseFloat(
                convertMinutesToTenths(aService.duration)
              );
              laborTimeMinutes += durationInMins;
            }
            aService.durationInMins = durationInMins;
          }
          /* ENG-45161 - Set "price" value to "dealerFallbackPrice" when parts[] is empty, partsPrice = 0, scheduledLaborPrice = 0  */
          if (
            defaultToZeroIfNullOrEmpty(aService.partsPrice) === 0 &&
            defaultToZeroIfNullOrEmpty(aService.scheduledLaborPrice) === 0
          ) {
            aService.dealerFallbackPrice = aService.price;
            dealerAddTotal += aService.price;
          } else {
            aService.dealerFallbackPrice = 0; // default, set to zero
          }
          // Transform "scheduledLaborPrice" to null when value is 0 or property missing in service{}
          const localLaborPrice = isObject(aService, "scheduledLaborPrice")
            ? defaultToZeroIfNullOrEmpty(aService.scheduledLaborPrice) !== 0
              ? aService.scheduledLaborPrice
              : null
            : null;
          aService.scheduledLaborPrice = localLaborPrice;
          // console.log(
          //   "labor obj for service =>",
          //   aService.id,
          //   aService.duration,
          //   aService.scheduledLaborPrice
          // );

          /* check if part object has part or fluid details */
          let filterParts = [];
          if (!isEmpty(origParts)) {
            if (!isArrayExist(origParts)) {
              filterParts.push(origParts);
            } else if (isArrayExist(origParts) && origParts.length > 0) {
              filterParts = origParts;
            }

            aService.parts = filterParts.map(aPart => {
              aPart.description = isObject(aPart, "description")
                ? aPart.description
                : null;
              if (aPart.partType === "part") {
                aPart.oemPartNumber = isObject(aPart, "oemPartNumber")
                  ? aPart.oemPartNumber
                  : null;
              }
              /* Fluid case - either oilType or oemPartNumber should be displayed */
              if (aPart.partType === "fluid") {
                aPart.oemPartNumber = isObject(aPart, "oemPartNumber")
                  ? aPart.oemPartNumber
                  : null;
                const oilType = isObject(aPart, "oilType")
                  ? aPart.oilType
                  : null;
                aPart.oilType = !isEmpty(oilType) ? oilType : null;
              }
              aPart.adjustedQuantity = doesEmpty(aPart.adjustedQuantity)
                ? null
                : aPart.adjustedQuantity;
              aPart.unitPrice = doesEmpty(aPart.unitPrice)
                ? null
                : parseFloat(aPart.unitPrice);
              aPart.partsPrice = doesEmpty(aPart.partsPrice)
                ? null
                : parseFloat(aPart.partsPrice);
              return aPart;
            });
            filterParts = [];
          } else {
            aService.parts = filterParts;
          }
          // console.log(
          //   "parts exist for serviceId",
          //   aService.id,
          //   aService.parts
          // );
        }
      }); // services

      // Loop servicePoint level MenuTypes - Add Totals here;
      rawMenuTypes.forEach(menuType => {
        menuType.showMenuPricing = showMenuPricing;
        menuType.pricingMethod = pricingMethod;

        // Flat Rate Total - update menutype.duration with sum (service level duration)
        menuType.flatRate = 0;
        menuType.serviceDurationTotal = laborTimeMinutes;
        if (laborOpsScale === "HUNDREDTHS") {
          menuType.duration = convertMinutesToHundredths(serviceDurationTotal);
          menuType.flatRate = parseFloat(
            convertMinutesToHundredths(serviceDurationTotal)
          ).toFixed(2);
        } else {
          menuType.duration = convertMinutesToTenths(serviceDurationTotal);
          menuType.flatRate = parseFloat(
            convertMinutesToTenths(serviceDurationTotal)
          ).toFixed(1);
        }
        menuType.duration = menuType.flatRate;

        // Calculate "Effective Rate"
        menuType.effectiveRate = 0;
        const durationVal = menuType.duration;
        if (
          defaultToZeroIfNullOrEmpty(menuType.laborPrice) > 0 &&
          !doesEmpty(durationVal) &&
          durationVal > 0
        ) {
          const rateVal = parseFloat(menuType.laborPrice / durationVal);
          menuType.effectiveRate = defaultToZeroIfNullOrEmpty(rateVal);
        }

        // ??? DealerAdd Total : sum(dealerFallbackPrice) of each Dealer defined operation
        menuType.dealerAddTotal = defaultToZeroIfNullOrEmpty(dealerAddTotal);

        // ??? Shop Supplies Total - later
        menuType.shopCharge =
          defaultToZeroIfNullOrEmpty(menuType.shopCharge) !== 0
            ? Math.round(menuType.shopCharge * 100) / 100
            : menuType.shopCharge;
        // ??? Taxes Total
        menuType.salesTax =
          defaultToZeroIfNullOrEmpty(menuType.salesTax) !== 0
            ? Math.round(menuType.salesTax * 100) / 100
            : menuType.salesTax;

        // Add dealer-catalog setting props {laborTimePrecision}
        menuType.laborTimePrecision = laborOpsScale;
      });

      modifiedResponse.services = rawServices;
      modifiedResponse.serviceIds = serviceIdList;
      modifiedResponse.rawMenuTypes = rawMenuTypes;
      console.log("convert Menutypes, services ", modifiedResponse);
      return modifiedResponse;
    }
  }

  /* Util to group inspection services out of all Menutypes[] */
  getInspectionServices(inspectionServiceMap) {
    return Object.values(inspectionServiceMap);
  }

  onToggleAccordionItem = (mileageValue) => {
    this.setState(prevState => ({
      accordionItemStates : {
        ...prevState.accordionItemStates,
        [mileageValue]: !prevState.accordionItemStates[mileageValue]
      }
    }));
  }

  totalsWidget = (cmptIndex) => {
    const {enableDMSPlusContent} = this.state;
    let menuTypes;
    if (enableDMSPlusContent && this.state.dataByMileagePoint) {
      menuTypes = this.state.dataByMileagePoint[cmptIndex]?.menuTypes || []; }
    else {
       menuTypes = this.state.menuTypes || []; }

    if (menuTypes.length > 0 ) {
      return (
        <TotalTemplate
          key={"total-" + cmptIndex }
          menuTypes={menuTypes}
          ref={ref => {
              this.serviceRows[0] = ref;
              if (this.state.currentMileagePoint == cmptIndex) {
              }
            }
          }
          isExpand={this.state.servicesButtonStates[cmptIndex]}
        />);
    } else {
      return null;
    }
  }

  serviceHtml = (cmptIndex) => {
    let len = 0;
    let services;
    let serviceRows
    const { enableDMSPlusContent } = this.state;
    if (enableDMSPlusContent && this.state.dataByMileagePoint) {
      services = this.state.dataByMileagePoint[cmptIndex]?.services || []; }
    else {
      services = this.state.services || []; }

    if (services && services.length > 0) {
      len = services.length;
      this.serviceRows = [];

      serviceRows = services.map((s, index) => {
          return (
            <ServiceRow
              key={"service-" + cmptIndex + "-" + s.id}
              ref={ref => {
                  this.serviceRows[index + 1] = ref;
                }
              }
              isExpand={this.state.servicesButtonStates[cmptIndex]}
              service={s}
            />
          );
      });

      return serviceRows;
    } else {
      return null;
    }
  }

  inspectWidget = (cmptIndex) => {
    let len = 0;
    let services;
    let inspections;
    let menuTypes;
    const { enableDMSPlusContent } = this.state;
    if (enableDMSPlusContent && this.state.dataByMileagePoint) {
      services = this.state.dataByMileagePoint[cmptIndex]?.services || [];
      inspections = this.state.dataByMileagePoint[cmptIndex]?.inspections || [];
      menuTypes = this.state.dataByMileagePoint[cmptIndex]?.menuTypes || []; }
    else {
      services = this.state.services || [];
      inspections = this.state.inspections || [];
      menuTypes = this.state.menuTypes || [];
     }

    if (inspections.length > 0 ) {
      len = services.length;
      return (
      <InspectionTemplate
      key={"total-" + cmptIndex}
        menuTypes={menuTypes}
        results={inspections}
        ref={ref => {
            this.serviceRows[len + 1] = ref
            }}
        isExpand={this.state.servicesButtonStates[cmptIndex]}
      />);
    } else {
      return null
    }
  }

  mainWidget = (cmptIndex) => {
    return (
    <div>
      {this.totalsWidget(cmptIndex)}
      <div className={`op-table ${this.state.menuClass}`}>
        {this.serviceHtml(cmptIndex)}
        {this.inspectWidget(cmptIndex)}
      </div>
    </div>);
  }

  menusTemplate = (cmptIndex) => (
    <div className={`op-table ${this.state.menuClass}`}>{this.mainWidget (cmptIndex)}</div>
  );

  // Modify the iconSelect function and integrate it into the component
  iconSelect = (cmptIndex) => {
    const { localeStrings } = this.context.appContext;
    const { enableDMSPlusContent } = this.state;
    const toggleLabel = localeStrings["xmm.portal.ag_grid.expandAll"];
    if (enableDMSPlusContent) {
      return (
      <span id="expandAllServices">
        <span>{toggleLabel}</span>
        <ToggleSwitch
          htmlId={"IconSelectAll-" + cmptIndex}
          /*className="btn--icon"
          buttonStyle="secondary"*/
          checked = {this.state.servicesButtonStates?this.state.servicesButtonStates[cmptIndex]:false}
          onClick={(e) => this.expandAllClick(e, cmptIndex)}
        />
      </span>); }
    else {
      const { expandAll } = this.state;
      return (
      <span id="expandAll">
          {/* <i className={toggleCls} /> */}
          <Button
            htmlId="IconSelectAll"
            className="btn--icon"
            buttonStyle="secondary"
            onClick={this.expandAllClick}
            icon={this.getExpandIcon(expandAll)}
          />
      </span>);
    }
  }

  iconToggleAllAccordionItems = () => {
    return (
        <span id="expandAllAccordionItems">
            <Button
                htmlId={""}
                className="btn--icon"
                buttonStyle="secondary"
                onClick={this.toggleAllAccordionItems}
                icon={this.getExpandIcon(this.state.allAccordionItemsExpanded)}
            />
        </span>
    );
  }

  searchableSelect = () => {
    const { localeStrings } = this.context.appContext;
    const mileageLabel = localeStrings["xmm.portal.common.mileage"]; // "Mileage";
    const { mileagePoints, currentMileagePoint } = this.state;
    return(
    <SearchableSelect
      htmlId="mileageFilter"
      name="currentMileagePoint"
      className="full-row xmm-scrollable-select"
      label={mileageLabel}
      placeholder="Select"
      enableMultiSelect={false}
      maxHeight={200}
      value={currentMileagePoint || ""}
      options={mileagePoints}
      onChange={this.onMileageChange}
    />
    );
  }

  externalFilters = (cmptIndex) => {
    const { mileagePoints, selectableMenu, menuTypes, enableDMSPlusContent } = this.state;
    const stickyCls = "sticky";
    const clsOptionIcon = selectableMenu && !enableDMSPlusContent? "icon-col" : "hide";
    const clsComponentHeader = enableDMSPlusContent ? "header-with-accordion": "component-header"
    if (mileagePoints.length > 0) {
      const iconComponent = enableDMSPlusContent ?
        this.iconToggleAllAccordionItems():
        this.iconSelect(cmptIndex);
      const searchableSelectComponent =  enableDMSPlusContent ?
        null:
        this.searchableSelect();

        return (
        <div className={`${clsComponentHeader} ${stickyCls}`}>
          <div className="header-left column-flex">
            <div>
              {iconComponent}
              {searchableSelectComponent}
            </div>

            <div className={clsOptionIcon}>
              <span>
                <i className="far fa-circle" />
                {` = ${this.optionalLabel}`}
              </span>
              <span>
                <i className="fas fa-check-circle" />
                {` = ${this.optiionalPreselectedLabel}`}
              </span>
            </div>
          </div>

          {!enableDMSPlusContent && menuTypes.map(menu => {
            return (
              <div className="package-header" key={menu.id}>
                {menu.description}
              </div>
            );
          })}
        </div>)
      } else {
        return null;
      }
  };


  serviceFilters = (cmptIndex) => {
    const { mileagePoints, selectableMenu, enableDMSPlusContent, dataByMileagePoint, menuTypes } = this.state;
    const clsOptionIcon = selectableMenu? "icon-col" : "hide";
    let tmpMenuTypes;

    tmpMenuTypes = getMenuTypes (cmptIndex, enableDMSPlusContent, dataByMileagePoint, menuTypes);

    if (mileagePoints.length > 0) {
        return (
        <div className="component-header">
          <div className="header-left column-flex">
            <div>
              {this.iconSelect(cmptIndex)}
            </div>
            <div className={clsOptionIcon}>
                <span>
                  <i className="far fa-circle" />
                  {` = ${this.optionalLabel}`}
                </span>
                <span>
                  <i className="fas fa-check-circle" />
                  {` = ${this.optiionalPreselectedLabel}`}
                </span>
            </div>
          </div>
          <div className="header-right column-flex">
            {tmpMenuTypes.map(menu => {
                return (
                  <div className="package-header" key={menu.id}>
                    {menu.description}
                  </div>
                );
          })}
          </div>
        </div>  )
      } else {
        return null;
      }
  };

  handleAccordionChange = (mileagePoint) => {
    //to avoid errors during setTimeout call;
    if (!this.state)
        return;

    if (!this.state.currentMileagePoint)
        return;
    this.clickOnGraph = true;
    this.onMileageChange({fromGraph: mileagePoint});
    this.ensureAccordionItemVisible(mileagePoint);
    this.clickOnGraph = false;
  }

  getExpandIcon = (expandedState) => {
      return expandedState ? <IconKeyboardArrowDown /> : <IconKeyboardArrowRight />
  }

  render() {
    const stickyCls = "sticky";
    // const units = this.context.appContext.dealer.defaultUnits;
    const { mileagePoints, enableDMSPlusContent } = this.state;
    const { localeStrings } = this.context.appContext;
    const mileageLabel = localeStrings["xmm.portal.common.mileage"]; // "Mileage";

    if (!this.state.isLoaded && !enableDMSPlusContent) {
      return null;
    }
    if (mileagePoints.length > 0) {
      const { isExpanded } = this.state;
    }

    const menuState = {
      isLoaded: this.state.isLoaded,
      services: this.state.services,
      inspection: this.state.inspections,
      menuTypes: this.state.menuTypes
    };

    const stateHeader = (
      <div>
        <DisplayFormikState {...menuState} />
      </div>
    );
    const stateToggleCls = this.state.isToggle
      ? "fas fa-chevron-up"
      : "fas fa-chevron-down";
    const clsToggleHide = this.state.isToggle ? "" : "hide";
    const { currentMileagePoint } = this.state;

    const mileagePoint = mileagePoints.map((mileagePoint, index) => {
      if (currentMileagePoint === mileagePoint.mileage.toString()) {
        return mileagePoint;
      }
    });

    if (enableDMSPlusContent) {
      return (
        <React.Fragment>
          <Card
            htmlId="stateCard"
            className="hide"
            onClick={this.toggleStateCard}
            header={
              <div>
                <i className={stateToggleCls} /> State
              </div>
            }
          >
            <div className={clsToggleHide}>{stateHeader}</div>
          </Card>
          {this.externalFilters (mileagePoint.mileage)}
          <AccordionGroup.Container htmlId="accordionGroupMileages" independent>
              { mileagePoints.map((mileagePoint, index) => (
              <AccordionGroup.Item
                header={mileageLabel + " " + mileagePoint.mileage}
                eventKey={mileagePoint.mileage}
                key={"accordion-item-" + index}
                htmlId={"accordionGroupItem-"+index}
                mileagePoint={mileagePoint}
                onEnter={this.onMileageChange} // Used to setup Accordion setting when mileage changes
                value={currentMileagePoint || ""}
                expanded={this.state.accordionItemStates?this.state.accordionItemStates[mileagePoint.mileage.toString()]:false}
                onToggle={()=>this.onToggleAccordionItem(mileagePoint.mileage.toString())}
                >
                  {this.serviceFilters(mileagePoint.mileage)}
                  {this.menusTemplate (mileagePoint.mileage)}
              </AccordionGroup.Item>))}
          </AccordionGroup.Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Card
            htmlId="stateCard"
            className="hide"
            onClick={this.toggleStateCard}
            header={
              <div>
                <i className={stateToggleCls} /> State
              </div>
            }
          >
            <div className={clsToggleHide}>{stateHeader}</div>
          </Card>
          {this.externalFilters("0")}
          {this.menusTemplate("0")}
        </React.Fragment>
      );
    }
  }
}

MenuPackagesTable.propTypes = {
  // selectedIndex: PropTypes.string,
  menuResults: PropTypes.object,
  mileagePoints: PropTypes.array,
  currentMileagePoint: PropTypes.string
};
/* eslint-enable no-console */
