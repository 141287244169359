import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { formatNumberWithThousands } from "../../../../../commonUtil/utils/string";
import { toFixed } from "../../../../../commonUtil/utils/value";

export default class ChartTooltip extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      // data: props.data,
      // left: 0,
      // right: -1000,
      // loadChartData: this.loadChartData
    };
  }

  render() {
    const { active, mileageUnit } = this.props;

    if (active) {
      const { payload, label } = this.props;
      // console.log("label", label, "payload", payload);
      const rows = payload.map((series, index) => {
        const amount =
          index === -1
            ? "$9,999.99"
            : "$".concat(formatNumberWithThousands(toFixed(series.value)));
        const label =
          index === -1
            ? "This is a 32 character long text. Please see it"
            : series.name;
        const key = `key_${index}`;
        return (
          <div key={key} className="chart-tooltip-row">
            <div className="series-icon">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 10 10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="100" height="100" fill={series.fill} />
              </svg>
            </div>
            <div className="series-label">{label}</div>
            <div className="series-price">{amount}</div>
          </div>
        );
      });
      return (
        <div className="chart-tooltip-box">
          <span>
            {label} {mileageUnit}
          </span>
          {rows}
        </div>
      );
    }

    return null;
  }
}

ChartTooltip.propTypes = {
  active: PropTypes.bool,
  type: PropTypes.string,
  payload: PropTypes.array,
  label: PropTypes.string,
  mileageUnit: PropTypes.string
};
