import React, { useState, useEffect, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import "./DiagnosisQuickFilterPage.scss";
import ServiceList from "../../components/service-list/ServiceList";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import selectServiceFormat from "../../utils/select-service-format";
import EditDiagnosePage from "./EditDiagnosePage";
const DiagnosisQuickFilterPage = props => {
  const { EditServiceModule } = props;
  const { state, dispatch, ctxRequestOperationDetails } =
    useServiceSearchContext();
  const { diagnosisServices, globalOperationDetails, appConfig, vehicle } =
    state;
  const [showEditCmp, setShowEditCmp] = useState(false);
  const [service, setService] = useState(null);
  const [localService, setLocalService] = useState(null);

  const editServiceClick = useCallback(
    service => {
      updateParentState(true);
      if (!isEmpty(service)) {
        setLocalService(service);
        console.log("Diagnose service updated from children cmp", service);
      }
      dispatch({
        type: Actions.SET_SEARCH_HEADER,
        payload: false
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    // We need this check because globalOperationDetails will not be available in context until ctxRequestOperationDetails is triggered
    // by handleDiagnosisClick below. This setup requires service in local state, so we also need to check for it.
    if (!isEmpty(service) && !isEmpty(globalOperationDetails)) {
      const selectedService = selectServiceFormat.formatService(
        service,
        globalOperationDetails
      );
      if (!isEmpty(vehicle?.stockNumber)) {
        selectedService.defaultPayTypeCode = "I";
      }
      const { editModuleAccess } = appConfig;
      if (editModuleAccess) {
        const { rawRecord } = service;
        if (rawRecord.operationSource) {
          editServiceClick(selectedService);
        }
      } else {
        // If parent application is not QUOTING, just dispatch selectedService
        dispatch({
          type: Actions.SET_SELECTED_GLOBAL_OPS_SERVICE,
          payload: selectedService
        });
      }
    }
  }, [service, globalOperationDetails, editServiceClick, dispatch, appConfig]);

  const updateParentState = showEdit => {
    if (!showEdit) {
      setService(null);
      setLocalService(null);
      dispatch({
        type: Actions.REMOVE_GLOBAL_OPERATION_DETAILS
      });
    }
    setShowEditCmp(showEdit);
  };

  // @note - handler to get global ops details for Dealer catalog repair service only
  const handleDiagnosisClick = async service => {
    ctxRequestOperationDetails(service);
    setService(service);
  };

  // TODO: this formatted selectedService is different datamodel to bind for EditDiagnosePage. Hence I created new components
  const editPage =
    showEditCmp && !isEmpty(localService) ? (
      <EditDiagnosePage
        updateParentState={updateParentState}
        service={localService}
        EditServiceModule={EditServiceModule}
      />
    ) : (
      ""
    );
  const parentCls =
    showEditCmp && !isEmpty(localService) ? "hide-ele" : "empty-cls";
  return (
    <>
      <div id="diagnosisQuickFilterPageContainer" className={parentCls}>
        <ServiceList
          services={diagnosisServices}
          viewType="DIAGNOSIS"
          onSelectService={handleDiagnosisClick}
        />
      </div>
      {editPage}
    </>
  );
};

export default DiagnosisQuickFilterPage;
