/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { PackageContext } from "../package-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import PriceInput from "@cx/ui/PriceInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
// import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
// import DropdownButton from "react-bootstrap/lib/DropdownButton";
import Dropdown from "@cx/ui/Dropdown";
import IconMore from "@cx/ui/Icons/IconMore";
import IconSearch from "@cx/ui/Icons/IconSearch";
import RadioButtonList from "@cx/ui/RadioButtonList";
// import { FormattedMessage } from "react-intl";
import {
  isDifferentValue,
  sanitize,
  toEmptyStringIfUndefined
} from "../../../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { defaultToZeroIfNull } from "../../../../../commonUtil/utils/value";
import { toastMessageFormatter } from "../../../../../commonUtil/utils/formatter";
import * as formvalidator from "../../../../formik/formvalidator";
import {
  PackageTemplate,
  PackageEditTemplate
} from "../../../../../constants/ModuleConstants";
// import { DisplayFormikState } from "../../../../reusable/helper";
import { toast } from "@cx/ui/Toast";
import FindOpCodesDialog from "../../../../../commonUtil/dialog/FindOpCodesDialog";
import IntervalSelector from "../../../../reusable/Intervals/IntervalSelector";
import VehicleGroupSelector from "../../../../reusable/VehicleGroups/VehicleGroupSelector";
import {
  findRecord,
  isEmpty,
  isArrayExist
} from "../../../../../commonUtil/utils/object";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
class PackageForm extends Component {
  static contextType = PackageContext;
  static propTypes = {
    onChangeField: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    // Bind Form handlers
    this.onBlur = this.onBlur.bind(this);
    this.onChangeNumOverrides = this.onChangeNumOverrides.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.copyPackage = this.copyPackage.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSingleMileageOrIntervalSelected =
      this.onSingleMileageOrIntervalSelected.bind(this);
    this.onBlurIntervalSelector = this.onBlurIntervalSelector.bind(this);
    this.onBlurVehicleGroup = this.onBlurVehicleGroup.bind(this);
    this.onChangeVehicleGroup = this.onChangeVehicleGroup.bind(this);
    this.onSetNamedVehicleFilter = this.onSetNamedVehicleFilter.bind(this);

    const showOpCodeModal = false;
    const newPackage = JSON.parse(JSON.stringify(props.rowRecord));
    this.initializeLocaleValues();

    const { editOption } = props;
    const { dealer, makeVariantMap } = context.appContext;
    const { dealerCode } = dealer;
    const { make } = newPackage;
    let variant = "";
    if (make && makeVariantMap[make]) {
      variant = makeVariantMap[make].variant;
    }

    this.state = {
      numOverrides: "",
      numOverridesDisabled: true,
      numIntervals: 0,
      appContext: context.appContext,
      supportedMakes: context.makelist,
      menuTypes: [],
      rawMenuTypes: [],
      newPackage,
      rawPackage: JSON.parse(JSON.stringify(props.rowRecord)),
      editOption,
      addMode: !newPackage.id ? true : false,
      showOpCodeModal,
      // This holds selected Make record
      selectedMake: {
        make,
        variant,
        dealerCode
      },
      menuTypeId: toEmptyStringIfUndefined(newPackage.menuTypeId),
      warning: "",
      dirty: false,
      valid: false,
      errors: this.getErrors()
    };
  }

  componentDidMount() {
    window.addEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
    if (this.props.editOption === "edit") {
      this.getSinglePackage();
      const { newPackage } = this.state;
      if (newPackage && newPackage.make) {
        this.loadMenuTypes(newPackage.make);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      "setNamedVehicleFilter",
      this.onSetNamedVehicleFilter,
      false
    );
  }
  initializeLocaleValues() {
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.makeLabel = xlate("xmm.portal.common.make_lbl");
    this.descriptionLabel = xlate("xmm.portal.grid.description");
    this.menuTypeLabel = xlate("xmm.portal.common.menutype");
    this.intervalLabel = xlate("xmm.portal.common.interval");
    this.vehiclesLabel = xlate("xmm.portal.grid.vehicles");
    this.opcodeLabel = xlate("xmm.portal.grid.opcode");
    this.priceLabel = xlate("xmm.portal.packages.price_lbl");
    this.schedulingDurationLabel = xlate(
      "xmm.portal.common.schedule_duration_lbl"
    );
    this.packageTypeLabel = xlate("xmm.portal.grid.package_type");
    this.minutesLabel = xlate("xmm.portal.common.minutes");
    this.valueLabel = xlate("xmm.portal.common.value");
    this.createSingleOverrideLabel = xlate(
      "xmm.portal.common.create_single_override"
    );
    this.createOverrideEachMileageLabel = xlate(
      "xmm.portal.common.create_override_each_mileage"
    );
    this.createOverrideEachMileageTotalLabel = xlate(
      "xmm.portal.common.create_override_each_mileage_total"
    );
    this.atLeastOneOverrideReqError = xlate(
      "xmm.portal.errors.at_lease_one_override_required"
    );
    this.invalidOpcodeError = xlate("xmm.portal.errors.opcode_invalid");
    this.exceedMaxShopDurationError = xlate(
      "xmm.portal.errors.exceed_max_shop_duration"
    );
    this.exceedMaxPriceError = xlate("xmm.portal.errors.exceed_max_price");
    this.fieldRequiredError = xlate("xmm.portal.errors.required_field");
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveError = xlate("xmm.portal.errors.save_data_error");

    this.copyOverrideLabel = xlate("xmm.portal.packages.copyoverride");
    this.deleteLabel = xlate("xmm.portal.common.delete_button");
    // this.copyToBtnLabel = xlate("xmm.portal.dealermenus.copy_to_button_label");
    this.selectableMenuTypeWarning = xlate(
      "xmm.portal.errors.selectable_menu_type"
    );
  }

  getErrors() {
    return {
      make: "",
      name: "",
      menuTypeId: "",
      intervalId: "",
      metaVehicleScope: "",
      dmsOpcode: "",
      price: "",
      shopDuration: ""
    };
  }
  // validate value on blur event
  // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput, TextArea
  onBlur = cxEvent => {
    // const { name, id } = cxEvent.target;
    // const endIndex = id.indexOf("-") !== -1 ? id.indexOf("-") : id.length;
    const { newPackage } = this.state;
    const { name, value } = cxEvent.target;
    if (!value || (Array.isArray(value) && value.length === 0)) {
      if (newPackage[name]) {
        newPackage[name] = "";
        this.markDirty();
      }
    }
    // this.validate(name);
    this.setState({ newPackage }, () => {
      this.validate(name);
    });
  };

  onChangeSelect = cxEvent => {
    const { name, value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    const { newPackage } = this.state;
    if (isDifferentValue(newPackage[name], optionValue)) {
      this.markDirty(name);
    }
    // console.log(name, value);
    if (name === "make" && value && value.length !== 0) {
      const { dealerCode, make, variant } = value[0];
      if (make)
        this.setState(
          prevState => {
            return {
              newPackage: {
                ...prevState.newPackage,
                [name]: !make ? "" : make
              },
              selectedMake: {
                make,
                dealerCode,
                variant
              },
              warning: ""
            };
          },
          () => {
            this.loadMenuTypes(make);
          }
        );
    } else {
      newPackage[name] = optionValue;
      const valid = this.validate(name);
      this.setState({ valid, newPackage, [name]: optionValue });
    }
  };
  /* change handler used on menuType, vehicles  */
  onChangeField = event => {
    const { name, value } = event.target;
    if (isDifferentValue(this.state.newPackage[name], value)) {
      this.markDirty(name);
    }
    this.setState(
      prevState => {
        const { errors } = prevState;

        return {
          newPackage: {
            ...prevState.newPackage,
            [name]: value
          },
          warning: "",
          errors
        };
      },
      () => {
        // callback to validate form
        this.validate(name);
      }
    );
  };

  onChangeNumOverrides = cxEvent => {
    // this.props.onChangeNumOverrides(cxEvent, isValid, domEvent);
    this.setState({ numOverrides: cxEvent.target.value });
  };

  onChangeInput = event => {
    const { name, value } = event.target;
    if (isDifferentValue(this.state.newPackage[name], value)) {
      this.markDirty(name);
      this.setState(
        prevState => {
          return {
            newPackage: {
              ...prevState.newPackage,
              [name]: sanitize(value)
            }
          };
        },
        () => {
          const valid = this.validate(name);
          this.setState({ valid });
          // callback logic
        }
      );
    }
  };
  /* set opcode value which is selected from Findopcode tool */
  setDmsOpcodeValue = value => {
    this.setState(
      prevState => {
        const { errors } = prevState;
        return {
          newPackage: {
            ...prevState.newPackage,
            dmsOpcode: value
          },
          errors
        };
      },
      () => {
        this.markDirty("dmsOpcode", true);
      }
    );
  };

  copyPackage() {
    const { newPackage } = this.state;
    newPackage.addMode = true;
    newPackage.id = "";
    newPackage.name = newPackage.name + " (Copy)";
    this.setState({ newPackage });
    this.markDirty("name", true);
    const changeSliderTitle = this.props.changeSliderTitle;
    changeSliderTitle();
    // this.props.editOption = "edit";
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.packages.copy_single_override_click");
  }

  onBlurIntervalSelector = () => {
    this.validate("intervalId");
  };

  onBlurVehicleGroup = () => {
    this.validate("metaVehicleFilterId");
  };

  onSingleMileageOrIntervalSelected = event => {
    // event.stopPropagation();
    const { data } = event.detail;
    const { newPackage } = this.state;
    if (newPackage && data) {
      const { intervalId, intervalName, newIntervalMileages } = data;
      const { getIntervalMileages } = this.context;
      const intervalMileages = getIntervalMileages(intervalId, newPackage.make);
      this.setState({ numOverrides: "", numOverridesDisabled: true });

      if (intervalMileages && isArrayExist(intervalMileages)) {
        const numIntervals = intervalMileages.length;
        // console.log("single mileage click", numIntervals, intervalMileages);
        this.setState({ numOverrides: "1", numIntervals });

        if (numIntervals > 1) {
          this.setState({ numOverridesDisabled: false });
        } else {
          this.setState({ numOverridesDisabled: true });
        }
      }
      if (
        newPackage.intervalId !== intervalId ||
        newPackage.intervalName !== intervalName
      ) {
        newPackage.intervalId = intervalId;
        newPackage.intervalName = intervalName;
        newPackage.newIntervalMileages = newIntervalMileages;
        this.markDirty("intervalId");
        this.setState({ newPackage });
      }
    }
  };

  onChangeVehicleGroup = event => {
    const { data } = event.detail;
    const { newPackage } = this.state;
    if (newPackage && data) {
      const { metaVehicleFilterId, metaVehicleScope } = data;
      if (
        isDifferentValue(newPackage.metaVehicleFilterId, metaVehicleFilterId)
      ) {
        newPackage.metaVehicleScope = metaVehicleScope;
        if (metaVehicleScope === "1") {
          newPackage.metaVehicleFilterId = "";
        } else {
          newPackage.metaVehicleFilterId = metaVehicleFilterId;
        }
        this.markDirty("metaVehicleFilterId", true);
        this.setState({ newPackage });
      }
    }
  };

  onSetNamedVehicleFilter = event => {
    const { metaVehicleFilterId } = event.detail;
    const { newPackage } = this.state;
    // console.log("onSetNamedVehicleFilter", event.detail, newPackage);
    newPackage.metaVehicleFilterId = metaVehicleFilterId.toString();
    newPackage.metaVehicleScope = "0";
    this.markDirty("metaVehicleFilterId", true);
    this.setState({ newPackage });
  };

  // validation utils
  // call this for each field change event
  markDirty(fieldName, validate) {
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty: true, valid });
    } else {
      this.setState({ dirty: true });
    }
  }

  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { errors } = this.state;
    const {
      make,
      name,
      menuTypeId,
      price,
      dmsOpcode,
      shopDuration,
      packageType,
      intervalId,
      intervalName,
      metaVehicleScope,
      metaVehicleFilterId
    } = this.state.newPackage;

    const naneValue = !name || !name.trim() ? "" : name;
    const dmsOpcodeValue = !dmsOpcode || !dmsOpcode.trim() ? "" : dmsOpcode;

    // validate for these fields only
    if (
      !fieldName ||
      fieldName === "dmsOpcode" ||
      fieldName === "price" ||
      fieldName === "shopDuration"
    ) {
      // Add case:
      if (!packageType) {
        if (!dmsOpcodeValue && !price && !shopDuration) {
          errors["dmsOpcode"] = !dmsOpcodeValue
            ? this.atLeastOneOverrideReqError
            : "";
          errors["price"] = !price ? this.atLeastOneOverrideReqError : "";
          errors["shopDuration"] = !shopDuration
            ? this.atLeastOneOverrideReqError
            : "";
        } else {
          errors["dmsOpcode"] = "";
          errors["shopDuration"] = "";
          errors["price"] = "";
        }
        // check for any invalid case out these fields
        if (dmsOpcodeValue || price || shopDuration) {
          if (
            dmsOpcodeValue &&
            formvalidator.validateAlphaNumPunctuation(dmsOpcodeValue)
          ) {
            errors["dmsOpcode"] = this.invalidOpcodeError;
          }
          if (price && (parseFloat(price) || 0) > 9999.99) {
            errors["price"] = this.exceedMaxPriceError;
          }
          if (shopDuration && (parseInt(shopDuration, 10) || 0) > 1440) {
            errors["shopDuration"] = this.exceedMaxShopDurationError;
          }
        }
      } else {
        // Edit case:
        if (packageType === "DMS_OPCODE") {
          errors["dmsOpcode"] = !dmsOpcodeValue ? this.fieldRequiredError : "";
          if (
            dmsOpcodeValue &&
            formvalidator.validateAlphaNumPunctuation(dmsOpcode)
          ) {
            errors["dmsOpcode"] = this.invalidOpcodeError;
          }
        } else if (packageType === "PRICE") {
          errors["price"] = !price ? this.fieldRequiredError : "";
          if (price && (parseFloat(price) || 0) > 9999.99) {
            errors["price"] = this.exceedMaxPriceError;
          }
        } else if (packageType === "SHOP_DURATION") {
          errors["shopDuration"] = !shopDuration ? this.fieldRequiredError : "";
          if (shopDuration && (parseInt(shopDuration, 10) || 0) > 1440) {
            errors["shopDuration"] = this.exceedMaxShopDurationError;
          }
        } else {
          errors["dmsOpcode"] = "";
          errors["price"] = "";
          errors["shopDuration"] = "";
        }
      }
    }
    if (!fieldName || fieldName === "make") {
      errors["make"] = !make ? this.fieldRequiredError : "";
    }
    if (!fieldName || fieldName === "name" || fieldName === "packageName") {
      errors["name"] = !naneValue ? this.fieldRequiredError : "";
    }
    if (!fieldName || fieldName === "menuTypeId") {
      errors["menuTypeId"] = !menuTypeId ? this.fieldRequiredError : "";
    }
    if (!fieldName || fieldName === "intervalId") {
      if (intervalId === "" && intervalName === "") {
        errors["intervalId"] = this.fieldRequiredError;
      } else {
        errors["intervalId"] = "";
      }
    }
    if (!fieldName || fieldName === "metaVehicleFilterId") {
      if (
        metaVehicleScope === "" ||
        (metaVehicleScope === "0" && !metaVehicleFilterId)
      ) {
        errors["metaVehicleFilterId"] = this.fieldRequiredError;
      } else {
        errors["metaVehicleFilterId"] = "";
      }
    }

    let valid = this.isValidForm();
    // Note: This check will tell if any field has errors. disable save button
    if (valid) {
      valid = !this.hasErrorStrings(errors);
    }
    this.setState({ valid, errors });
    return valid;
  }
  isValidForm() {
    const {
      packageType,
      make,
      name,
      intervalId,
      intervalName,
      metaVehicleScope,
      menuTypeId,
      price,
      dmsOpcode,
      shopDuration
    } = this.state.newPackage;
    let validOpcode = true;
    let validPrice = true;
    let validDuration = true;
    const makeSelected = this.isMakeSelected(make);
    if (!makeSelected) {
      return false;
    }
    if (!menuTypeId) {
      return false;
    }
    const naneValue = !name || !name.trim() ? "" : name;
    const dmsOpcodeValue = !dmsOpcode || !dmsOpcode.trim() ? "" : dmsOpcode;
    if (!naneValue || formvalidator.validateAlphaNumPunctuation(naneValue)) {
      return false;
    }
    if (!intervalId && !intervalName) {
      return false;
    }
    if (!metaVehicleScope) {
      return false;
    }
    // Add case: one or more fields will have invalid values
    if (!packageType) {
      if (!dmsOpcodeValue && !price && !shopDuration) {
        return false;
      }
    } else {
      if (packageType === "DMS_OPCODE")
        if (
          !dmsOpcodeValue ||
          formvalidator.validateAlphaNumPunctuation(dmsOpcodeValue)
        ) {
          validOpcode = false;
        }
      if (packageType === "PRICE")
        if (!price || (parseFloat(price) || 0) > 9999.99) {
          validPrice = false;
        }
      if (packageType === "SHOP_DURATION")
        if (!shopDuration || (parseInt(shopDuration, 10) || 0) > 1440) {
          validDuration = false;
        }
    }

    // Edit case: only one field out of three will have invalid value
    if (!validPrice || !validOpcode || !validDuration) {
      return false;
    }
    return true;
  }
  isMakeSelected(make) {
    if (typeof make === "string") {
      return make !== "";
    }
    return Array.isArray(make) && make.length !== 0;
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  hasErrorStrings(errors) {
    const array1 = Object.values(errors);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  resetState() {
    this.setState({
      newPackage: JSON.parse(JSON.stringify(this.props.rowRecord)),
      selectedMake: {
        make: "",
        variant: "",
        dealerCode: ""
      },
      warning: "",
      dirty: false,
      valid: false,
      errors: this.getErrors()
    });
  }
  loadMenuTypes = make => {
    const { dealerCode, locale } = this.state.appContext;
    const params = {
      dealerCode,
      locale,
      make
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedMenuTypes",
        method: "get",
        data: {},
        params
      },
      data => {
        // console.log("original Menus", data);
        let warning = "";
        const menuTypes = [];
        // sort the menu types by rank
        const rawMenuTypes = data.sort(this.menuTypeComparator);
        rawMenuTypes.forEach(m => {
          // Add case: Display only un-selectable menus (i,e selectable = 0)
          if (m.selectable === 0) {
            m.label = m.description;
            m.value = m.menuTypeId.toString();
            menuTypes.push(m);
          }
        });
        // Edit Case:
        if (data && data.length > 0) {
          const selectableMenu = this.checkPackageMenuType(data);
          if (!isEmpty(selectableMenu)) {
            menuTypes.push(selectableMenu);
            warning =
              selectableMenu.selectable === 1
                ? this.selectableMenuTypeWarning
                : "";
          }
        }
        this.setState(
          {
            menuTypes,
            rawMenuTypes,
            warning
          },
          () => {
            // console.log("sorted menus", menuTypes);
          }
        );
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  /* Edit case:
     if package has selectable Menu Type i,e selectable=1 (thr misconfiguration), then also include this Menu Type in the Menutypes list to avoid errors.
  */
  checkPackageMenuType = rawMenuTypes => {
    const { editOption, rawPackage } = this.state;
    let menu = {};
    if (editOption === "edit") {
      const menuTypeId = parseInt(rawPackage.menuTypeId, 10);
      // pass menutypeId to find match in raw data
      const foundMenutype = findRecord(rawMenuTypes, "menuTypeId", menuTypeId);
      if (
        Object.keys(foundMenutype).length > 0 &&
        foundMenutype.selectable === 1
      ) {
        // console.log("Append selectable menu", foundMenutype);
        menu = foundMenutype;
        menu.label = foundMenutype.description.toString();
        menu.value = foundMenutype.menuTypeId.toString();
      }
    }
    return menu;
  };
  /* This comparator will orderby enabled menus and ascending by rank */
  menuTypeComparator = (menuType1, menuType2) => {
    if (!menuType1 || !menuType2) {
      return -1;
    }
    const make1 = menuType1.make.toUpperCase();
    const make2 = menuType2.make.toUpperCase();
    const comparison = 0;
    if (make1 === make2) {
      const enabled1 = menuType1.enabled;
      const enabled2 = menuType2.enabled;
      if (enabled1 === enabled2) {
        const rank1 = Number(defaultToZeroIfNull(menuType1.rank).toString());
        const rank2 = Number(defaultToZeroIfNull(menuType2.rank).toString());
        if (rank1 === rank2) {
          return 0;
        }
        return rank1 > rank2 ? 1 : -1;
      }
      return enabled1 > enabled2 ? -1 : 1;
    }
    return comparison;
  };

  getPostData(addMode) {
    // add and edit menu type template have different field order for saving data
    const template = addMode ? PackageTemplate : PackageEditTemplate;
    const payload = Object.assign({}, template);
    const { newPackage, rawPackage } = this.state;
    const keys = Object.keys(newPackage);
    const { dealerCode } = this.state.appContext.dealer;
    keys.forEach(key => {
      if (payload[key] !== undefined) {
        const value = newPackage[key];
        payload[key] = typeof value === "string" ? value.trim() : value;
      }
    });
    if (payload["metaVehicles"] === "") {
      payload["metaVehicles"] = [];
    }
    if (Array.isArray(payload.make)) {
      const list = payload.make;
      payload.make = list[0].make;
    }
    payload.dealerCode = dealerCode;
    if (!addMode) {
      payload.packageId = newPackage.id;
    }
    payload.intervalId = newPackage.intervalId;
    // if edit mode and interval is single mileage point, still use old intervalId
    if (!addMode && newPackage.intervalId === "") {
      payload.intervalId = rawPackage.intervalId;
    }
    const { getNewIntervalMileages } = this.context;
    const newIntervalMileages = getNewIntervalMileages(newPackage, rawPackage);
    // const mileages = ["5000", "6000"]; // get dynamic list from Interval editor
    // set interval Id, newIntervalMileages[] to payload here
    payload.newIntervalMileages = newIntervalMileages;
    // set meta vehicle filter here
    if (newPackage.metaVehicleScope === 0) {
      // choose vehicles
      payload.metaVehicleFilterId = newPackage.metaVehicleFilterId;
    } else if (newPackage.metaVehicleScope === 1) {
      // All vehicles
      payload.metaVehicleFilterId = null;
    }
    return payload;
  }

  /*  Add Case: show up to 3 success Toast messages when each override saved
      Edit Case: show success/overlap message if any
  */
  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    // const { localeStrings } = this.context.appContext;
    const { errors } = this.state;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      toast.warning(this.correctInvalidFielsError);
      return;
    }
    // const { addMode } = this.state;
    const { newPackage } = this.state;
    const addMode = !newPackage.id ? true : false;

    let newEndpoint = "";
    if (this.state.numOverrides === "2") {
      newEndpoint = "Multiple";
    }

    const restApiName = addMode
      ? "addDealerPackage" + newEndpoint
      : "editDealerPackage";
    const restEndPoint = `ops/proxyapi/ddsproxy/rest/proc/${restApiName}`;
    const payload = this.getPostData(addMode);
    const newResponse = Object.assign({}, payload);
    newResponse["id"] = payload.packageId;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data) {
          // console.log("save response", data);
          let msgToShow = "";
          if (data.error) {
            //   msgToShow =
            //     "Unable to save changes at this time. Please try again.";
            //   toast.error(msgToShow, {
            //     autoClose: 8000,
            //     closeOnClick: true
            //   });
            this.updateStatusBox(this.saveError, "error", false, true);
          }
          // Error case - show msg for Add/Edit packages
          if (data.response && data.response.statusCode !== 0) {
            msgToShow = toastMessageFormatter(data.response.statusMessage);
            this.updateStatusBox(msgToShow, "error", false, true);
            // toast.error(toastMessageFormatter(msgToShow), {
            //   autoClose: 30000,
            //   closeOnClick: true
            // });
            // Success case - Update grid rows
          } else if (data.response && data.response.statusCode === 0) {
            // Add case - server returns msg.
            if (data.response.statusMessage) {
              msgToShow = toastMessageFormatter(data.response.statusMessage);
              toast.success(msgToShow, {
                autoClose: 5000,
                closeOnClick: true
              });
              this.updateStatusBox(this.savedMsg, "success", true);
            } else {
              // Edit case - generic  msg
              // const msgHtml = (
              //   <em> {localeStrings["xmm.portal.common.successful_save"]} </em>
              // );
              // toast.success(msgHtml);
              this.updateStatusBox(this.savedMsg, "success", true);
            }

            this.setState(
              {
                dirty: false,
                valid: false,
                warning: ""
              },
              () => {
                this.props.updateGridAfterSave(newResponse, restEndPoint);
                setTimeout(() => {
                  this.resetState();
                  this.props.hideSlider();
                }, 2000);
              }
            );
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveError;
        this.updateStatusBox(msg, "error", false, true);
        // const msg = error["message"]
        //   ? error.message
        //   : "There was an error saving your changes.";
        // toast.error(msg, {
        //   autoClose: 3000,
        //   closeOnClick: true
        // });
      }
    );
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.packages.save_override_click");
  };
  handleOpenOpcodeModal = () => {
    this.setState({ showOpCodeModal: true });
  };

  closeOpcodeModal = () => {
    this.setState({ showOpCodeModal: false });
  };

  refreshUpdatedDealerPackage = () => {
    //
  };
  /* Edit case: This rest API called to update mileage points for both named/un-named intervals */

  getSinglePackage() {
    const record = this.state.rawPackage;
    const payload = {
      packageId: record.id,
      showAllVehicles: 0,
      effectiveDate: ""
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/getPackageWOMetavehicles";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          let packageData = {};
          if (!isArrayExist(data) && typeof data === "object") {
            packageData = data.response && data.response.package;
          }
          if (packageData && typeof packageData === "object") {
            let mileages = [];
            const miles = packageData.mileages && packageData.mileages.mileage;
            if (isArrayExist(miles) && miles.length > 0) {
              mileages = miles;
            } else if (!isArrayExist(miles)) {
              mileages.push(miles);
            }
            packageData.newIntervalMileages = mileages.map(mile =>
              mile.toString()
            );
            delete packageData.mileages;

            this.setState(
              prevState => {
                return {
                  newPackage: {
                    ...prevState.newPackage,
                    newIntervalMileages: packageData.newIntervalMileages
                  },
                  rawPackage: {
                    ...prevState.rawPackage,
                    newIntervalMileages: packageData.newIntervalMileages
                  }
                };
              },
              () => {
                // callback logic
                // console.log("refined pacakge", this.state.newPackage);
              }
            );
          }
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  getNumOverrideTypes = newPackage => {
    let count = 0;
    const { dmsOpcode, price, shopDuration } = newPackage;
    if (toEmptyStringIfUndefined(dmsOpcode) !== "") {
      count++;
    }
    if (toEmptyStringIfUndefined(price) !== "") {
      count++;
    }
    if (toEmptyStringIfUndefined(shopDuration) !== "") {
      count++;
    }
    return count;
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    let editSection = null;
    const { localeStrings } = this.context;
    const { locale } = this.context.appContext;
    const {
      newPackage,
      valid,
      dirty,
      errors,
      selectedMake,
      menuTypeId,
      numIntervals,
      numOverrides,
      warning
    } = this.state;
    const warningClass = warning ? "xmm-warning-box" : "hide";
    const numOverrideTypes = this.getNumOverrideTypes(newPackage);
    const msgSection =
      numOverrideTypes > 0 &&
      numIntervals &&
      numIntervals > 0 &&
      numOverrides &&
      numOverrides > 1
        ? `This will create ${numOverrideTypes * numIntervals} overrides`
        : "";
    const intervalData = {
      make: selectedMake.make, // in
      variant: selectedMake.variant, // in
      dealerCode: selectedMake.dealerCode, // in
      locale, // in
      intervalId: newPackage.intervalId, // in and out
      intervalName: newPackage.intervalName // in and out
      // newIntervalMileages - out only
    };
    const vehicleGroup = {
      make: selectedMake.make, // in
      variant: selectedMake.variant, // in
      dealerCode: selectedMake.dealerCode, // in
      metaVehicleScope: newPackage.metaVehicleScope, // in
      metaVehicleFilterId: toEmptyStringIfUndefined(
        newPackage.metaVehicleFilterId
      ) // in
    };

    const deletePackage = this.props.deletePackage;

    // this state used to display props
    /*
    const formstate = {
      newPackage,
      dirty,
      valid,
      errors
    }; */
    const addMode = !newPackage.id ? true : false;
    const packageType = newPackage.packageType;
    const clsHideOpcode =
      !packageType || packageType === "DMS_OPCODE" ? "" : "hide";
    const clsHidePrice = !packageType || packageType === "PRICE" ? "" : "hide";
    const clsHideDuration =
      !packageType || packageType === "SHOP_DURATION" ? "" : "hide";

    const sectionClassName =
      addMode && (newPackage.make === "" || newPackage.make.length === 0)
        ? "hide"
        : "show-grid";
    /* Best Match button to be disabled for Package overrides */
    const findOpCodesModal = (
      <FindOpCodesDialog
        disableBestMatch={true}
        showValidateCatalog={true}
        dealerCode={this.context.dealerCode}
        serviceId={newPackage.id.toString()}
        internalName={newPackage.name}
        dmsOpcode={newPackage.dmsOpcode}
        dmsDescription={newPackage.currentOpcodeDescription}
        localeStrings={this.context.localeStrings}
        manualOpcodes={this.context.appContext.manualOpcodes}
        show={this.state.showOpCodeModal}
        closeDialog={this.closeOpcodeModal}
        setManualOpcodes={this.context.appContext.setManualOpcodes}
        setOpcodeValue={fieldValue => {
          this.setDmsOpcodeValue(fieldValue);
        }}
      />
    );
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    if (this.props.editForm) {
      // show form when edit clicked or add clicked
      editSection = (
        <div className="comment-form">
          <Card id="packageForm" autoComplete="off">
            <div className="xmm-tab-bar">
              {statusBox} <span className="xmm-msg">{msgSection}</span>
              <Button
                htmlId="saveAction"
                buttonStyle="primary"
                disabled={!valid || !dirty}
                onClick={this.saveHandler}
              >
                {this.saveLabel}
              </Button>
              <Dropdown
                icon={<IconMore />}
                id="packagesActionBtn"
                htmlId="packagesActionBtn"
                name="packagesActionBtn"
                className="xmm-dotted-dropdown btn--icon"
                buttonStyle="link"
                displayCaret={false}
                size="small"
                options={[
                  {
                    label: this.deleteLabel,
                    value: "single-delete",
                    disabled: this.props.editOption === "add",
                    onSelect: () => {
                      deletePackage(this.state.newPackage);
                    }
                  },
                  {
                    label: this.copyOverrideLabel,
                    value: "single-copy",
                    disabled: this.props.editOption === "add",
                    onSelect: () => {
                      this.copyPackage();
                    }
                  }
                ]}
                pullRight
              />
              {findOpCodesModal}
            </div>
            <Grid htmlId="settingGrid" className="sidebar-form">
              <Row className="show-grid">
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {this.makeLabel} <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <SearchableSelect
                    displayPlaceholder={true}
                    placeholder={
                      localeStrings["xmm.portal.common.select_make_label"]
                    }
                    className="xmm-scrollable-select"
                    htmlId="make"
                    label="Make"
                    name="make"
                    disabled={!addMode || !!newPackage.make}
                    enableMultiSelect={false}
                    maxHeight={150}
                    onBlur={this.onBlur}
                    onChange={this.onChangeSelect}
                    options={this.state.supportedMakes}
                    value={newPackage.make}
                    displayLabel={false}
                    hasError={!!this.state.errors.make}
                    errorMessage={this.state.errors.make}
                  />
                </Col>
              </Row>
              <Row className={sectionClassName}>
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {this.descriptionLabel}{" "}
                    <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <TextInput
                    htmlId="packageName"
                    label="Description"
                    name="name"
                    disabled={false}
                    required
                    value={newPackage.name || ""}
                    onBlur={this.onBlur}
                    onChange={this.onChangeInput}
                    error={errors.name}
                    displayLabel={false}
                  />
                </Col>
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {this.menuTypeLabel}{" "}
                    <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <SearchableSelect
                    displayPlaceholder={true}
                    placeholder={
                      localeStrings["xmm.portal.common.select_make_label"]
                    }
                    className="xmm-scrollable-select"
                    htmlId="menuTypeId"
                    label="Menu Type"
                    name="menuTypeId"
                    enableMultiSelect={false}
                    maxHeight={150}
                    onBlur={this.onBlur}
                    onChange={this.onChangeSelect}
                    options={this.state.menuTypes}
                    required
                    value={menuTypeId ? menuTypeId.toString() : ""}
                    displayLabel={false}
                    hasError={!!errors.menuTypeId}
                    errorMessage={errors.menuTypeId}
                  />
                  <div className={warningClass}>{warning}</div>
                </Col>
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {this.intervalLabel}{" "}
                    <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <IntervalSelector
                    data={intervalData}
                    error={errors.intervalId}
                    context={this.context.appContext}
                    onBlur={this.onBlurIntervalSelector}
                    onChange={this.onSingleMileageOrIntervalSelected}
                  />
                </Col>
                <Col xs={4} md={4} lgHidden={!addMode} />
                <Col xs={6} md={6} lgHidden={!addMode}>
                  <RadioButtonList
                    displayLabel={false}
                    htmlId="numOverrides"
                    name="numOverrides"
                    label="None"
                    onChange={this.onChangeNumOverrides}
                    value={this.state.numOverrides}
                    options={(() => {
                      if (this.state.numOverridesDisabled) {
                        return [
                          {
                            value: "1",
                            label: this.createSingleOverrideLabel,
                            disabled: true
                          },
                          {
                            value: "2",
                            label: this.createOverrideEachMileageLabel,
                            disabled: true
                          }
                        ];
                      } else {
                        const { numIntervals } = this.state;
                        return [
                          {
                            value: "1",
                            label: this.createSingleOverrideLabel,
                            disabled: false
                          },
                          {
                            value: "2",
                            label:
                              this.createOverrideEachMileageTotalLabel.replace(
                                "%1",
                                numIntervals
                              ),
                            disabled: false
                          }
                        ];
                      }
                    })()}
                  />
                </Col>
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {this.vehiclesLabel}{" "}
                    <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <VehicleGroupSelector
                    // ref="vehicleGroupSelectorRef"
                    data={vehicleGroup}
                    autoLoad={true}
                    error={errors.metaVehicleFilterId}
                    context={this.context.appContext}
                    onBlur={this.onBlurVehicleGroup}
                    onChange={this.onChangeVehicleGroup}
                  />
                </Col>
                <Col md={6} mdOffset={4}>
                  <h5>
                    {this.valueLabel} <span className="xmm-red-label">*</span>
                  </h5>
                </Col>
                <div className={clsHideOpcode}>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {this.opcodeLabel}{" "}
                      <span className="xmm-red-label"> </span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="dmsOpcoode"
                      label="Op Code"
                      name="dmsOpcode"
                      disabled={false}
                      className={clsHideOpcode}
                      value={newPackage.dmsOpcode || ""}
                      onBlur={this.onBlur}
                      onChange={this.onChangeInput}
                      error={errors.dmsOpcode}
                      displayLabel={false}
                      appendChild={
                        <Button
                          // aria-label="More"
                          htmlId="findopcodeFormBtn"
                          icon={<IconSearch htmlId="iconSearchForm" />}
                          buttonStyle="secondary"
                          onClick={this.handleOpenOpcodeModal}
                        />
                      }
                    />
                  </Col>
                </div>
                <div className={clsHidePrice}>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {this.priceLabel} <span className="xmm-red-label"> </span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <PriceInput
                      htmlId="price"
                      label="Price"
                      name="price"
                      displayLabel={false}
                      className={clsHidePrice}
                      onBlur={this.onBlur}
                      onChange={this.onChangeInput}
                      value={toEmptyStringIfUndefined(newPackage.price)}
                      error={errors.price}
                      maxLength={7}
                      minLength={0}
                    />
                  </Col>
                </div>
                <div className={clsHideDuration}>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {this.schedulingDurationLabel}{" "}
                      <span className="xmm-red-label"> </span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <NumericInput
                      htmlId="shopDuration"
                      label="Scheduling Duration"
                      name="shopDuration"
                      displayLabel={false}
                      className={clsHideDuration}
                      onBlur={this.onBlur}
                      onChange={this.onChangeInput}
                      error={errors.shopDuration}
                      value={toEmptyStringIfUndefined(newPackage.shopDuration)}
                      inputSuffix={this.minutesLabel}
                      maxLength={4}
                      minLength={0}
                    />
                  </Col>
                </div>
              </Row>
            </Grid>
          </Card>
          {/* <DisplayFormikState {...formstate} /> */}
        </div>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default PackageForm;

PackageForm.propTypes = {
  rowRecord: PropTypes.object,
  editForm: PropTypes.bool,
  editOption: PropTypes.string,
  hideSlider: PropTypes.func,
  updateGridAfterSave: PropTypes.func,
  deletePackage: PropTypes.func,
  changeSliderTitle: PropTypes.func
};

/* eslint-enable no-console */
