/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../../components/app-context";
import Card from "@cx/ui/Card";
import Col from "@cx/ui/Col";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import VGroupAPI from "../../../reusable/VehicleGroups/index";
import {
  createSets,
  findMakeVehicleGroupMap,
  removeItemFromMap,
  createVehicleGroupOptions,
  getGroupNameList,
  toArrayOfLabelAndValue
} from "../../../reusable/VehicleGroups/VehicleGroupUtil";
import { selectFirstItemFromArrayOfMaps } from "../../../../commonUtil/utils/list";
import { getAllVehicleGroupName } from "../../../../commonUtil/utils/menu";
import { isDifferentValue } from "../../../../commonUtil/utils/string";
import {
  makeSecureRestApi
  // showBodyMask,
  // hideBodyMask
} from "../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import { xlate } from "../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../utils/gtag-eventlist";

class VehicleGroups extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onVehicleGroupSelected = this.onVehicleGroupSelected.bind(this);
    this.onAddVehicleGroupEvent = this.onAddVehicleGroupEvent.bind(this);
    this.onRemoveVehicleGroupEvent = this.onRemoveVehicleGroupEvent.bind(this);
    this.onRemoveVehicleGroupFromList =
      this.onRemoveVehicleGroupFromList.bind(this);
    this.isDirty = this.isDirty.bind(this);
    this.reset = this.reset.bind(this);
    // Note that this callback might be called later during rendering of the VehcileGroupForm
    this.vehicleGroupFormRef = null;
    this.setVehicleGroupFormRef = ref => {
      this.vehicleGroupFormRef = ref;
    };
    this.initializeLocaleValues();
    this.state = {
      pageTitle: this.vehicleGroupsTitle,
      sortedVehicleGroups: [],
      criteriaOptionsMap: {},
      groupNames: [],
      metaVehicleFilter: {
        make: "",
        metaVehicleFilterId: "",
        metaVehicleScope: "1"
      },
      // make: "",
      // metaVehicleFilterId: "",
      selectedItem: null
    };
  }
  componentDidMount() {
    this.getGroupNameList();

    window.addEventListener(
      "dealerNameFilterEvent",
      this.onDealerNameFilterEvent,
      false
    );
    window.addEventListener(
      "vehicleGroupSelectedEvent",
      this.onVehicleGroupSelected,
      false
    );
    window.addEventListener(
      "addVehicleGroupEvent",
      this.onAddVehicleGroupEvent,
      false
    );
    window.addEventListener(
      "removeVehicleGroupEvent",
      this.onRemoveVehicleGroupEvent,
      false
    );
    window.addEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "dealerNameFilterEvent",
      this.onDealerNameFilterEvent,
      false
    );
    window.removeEventListener(
      "vehicleGroupSelectedEvent",
      this.onVehicleGroupSelected,
      false
    );
    window.removeEventListener(
      "addVehicleGroupEvent",
      this.onAddVehicleGroupEvent,
      false
    );
    window.removeEventListener(
      "removeVehicleGroupEvent",
      this.onRemoveVehicleGroupEvent,
      false
    );
    window.removeEventListener(
      "removeVehicleGroupFromList",
      this.onRemoveVehicleGroupFromList,
      false
    );
  }
  initializeLocaleValues() {
    this.vehicleGroupsTitle = xlate("xmm.portal.nav.vehicle_groups");
    this.allVehiclesTmpl = xlate("xmm.portal.common.all_make_vehicles");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deleteError = xlate("xmm.portal.errors.delete_vehicle_group");
  }
  handleVehicleGroupCallback = (
    dealerMakeVehiclesGroupMap,
    makeVehicleGroupsMap,
    sortedVehicleGroups
  ) => {
    const vehicleGroupOptions = createVehicleGroupOptions(
      makeVehicleGroupsMap,
      this.state.metaVehicleFilter.make
    );
    this.setVehicleGroupName(makeVehicleGroupsMap);
    this.setState({
      dealerMakeVehiclesGroupMap,
      makeVehicleGroupsMap,
      sortedVehicleGroups,
      vehicleGroupOptions,
      filteredVehicleGroupOptions: [...vehicleGroupOptions]
    });
    const { vehicleGroupCallbackParams } = this.context;
    let { vehicleGroup } = vehicleGroupCallbackParams;
    if (!vehicleGroup) {
      vehicleGroup = selectFirstItemFromArrayOfMaps(sortedVehicleGroups);
    }
    this.setSelectedItem(vehicleGroup);
  };
  /* This call made get named filters for a dealercode
   */
  getGroupNameList(vehicleGroup) {
    const { context } = this;
    const { vehicleGroupCallbackParams } = context;
    const { metaVehicleFilterId } = this.state;
    vehicleGroupCallbackParams.vehicleGroupCallback =
      this.handleVehicleGroupCallback;
    vehicleGroupCallbackParams.vehicleGroup = vehicleGroup;
    getGroupNameList(metaVehicleFilterId, context);
  }
  setVehicleGroupName = makeVehicleGroupsMap => {
    const { make, metaVehicleFilterId, metaVehicleScope } =
      this.state.metaVehicleFilter;
    if (metaVehicleScope === "1") {
      this.setState({
        metaVehicleGroupName: getAllVehicleGroupName(make, this.allVehiclesTmpl)
      });
    } else if (metaVehicleFilterId !== "") {
      const vehicleGroups = makeVehicleGroupsMap[make];
      if (vehicleGroups) {
        const matches = vehicleGroups.filter(vg => {
          return !isDifferentValue(vg.metaVehicleFilterId, metaVehicleFilterId);
        });
        if (matches.length !== 0) {
          const vehicleGroup = matches[0];
          const metaVehicleGroupName = vehicleGroup.name
            ? vehicleGroup.name
            : "Missing Vehicle Group Name and Description";
          this.setState({ metaVehicleGroupName });
        }
      }
    }
  };

  onDealerNameFilterEvent = event => {
    event.preventDefault();
    event.stopPropagation();
    const vehicleGroup =
      event.detail && Object.keys(event.detail).length !== 0
        ? event.detail
        : null;
    this.getGroupNameList(vehicleGroup);
  };

  onAddVehicleGroupEvent = event => {
    const { value, variant, dealerCode } = event.detail;
    const newVehicleGroupItem = {
      dealerCode,
      make: value,
      variant,
      name: "",
      description: "",
      metaVehicleFilterId: "",
      countOperationRules: 0
    };
    gtmEvents.gtmTrackEvent("xmm.vehiclegroups.add_click");
    this.setSelectedItem(newVehicleGroupItem);
  };

  onRemoveVehicleGroupEvent = event => {
    this.vehicleGroupFormRef.updateStatusBox(
      this.deletingMsg,
      "pending",
      false
    );

    const { metaVehicleFilterId } = event.detail;
    const restEndPoint =
      "/ops/proxyapi/internaloeproxy/rest/internal/xmmservices/deleteMetaVehicleFilter";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          metaVehicleFilterId
        }
      },
      response => {
        if (response && response.success) {
          this.vehicleGroupFormRef.updateStatusBox(
            this.deletedMsg,
            "success",
            true
          );
          setTimeout(() => {
            this.setSelectedItem(null);
            window.dispatchEvent(
              new CustomEvent("removeVehicleGroupFromList", {
                detail: event.detail,
                bubbles: true,
                cancelable: true
              })
            );
          }, 1000);
          // const status = "Vehicle group deleted successfully.";
          // toast.success(status);
        } else {
          // toast.error(
          //   "Unable to Delete vehicle group at this time. Please try again."
          // );
          this.vehicleGroupFormRef.updateStatusBox(
            this.deleteError,
            "error",
            false,
            true
          );
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  onRemoveVehicleGroupFromList = event => {
    const { sortedVehicleGroups } = this.state;
    if (!sortedVehicleGroups) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const metaVehicleFilter = event.detail;
    if (metaVehicleFilter) {
      const { sortedVehicleGroups } = this.state;
      const sortedVehicleGroupsMap = findMakeVehicleGroupMap(
        metaVehicleFilter,
        sortedVehicleGroups
      );
      removeItemFromMap(metaVehicleFilter, sortedVehicleGroupsMap);
      this.setState({ sortedVehicleGroups });
    }
  };

  onVehicleGroupSelected = event => {
    const { selectedItem } = event.detail;
    if (this.state.selectedItem !== selectedItem) {
      this.setSelectedItem(selectedItem);
    }
  };

  createVehicleGroupCriteria = (vehicleGroup, metaVehicleFilter) => {
    Object.entries(metaVehicleFilter).forEach(([key, val]) => {
      if (val && typeof val === "number") {
        metaVehicleFilter[key] = val.toString();
      }
    });
    // console.log("metaVehicleFilter", metaVehicleFilter);
    if (this.vehicleGroupFormRef) {
      this.loadMetaVehicleDimensionsByMake(vehicleGroup, metaVehicleFilter);
    }
    this.setState({ metaVehicleFilter });
  };

  createBodyAndFuelTypes = data => {
    const list = data.detailAttributes
      ? data.detailAttributes.detailAttribute
      : null;
    let bodyTypes = [];
    let fuelTypes = [];
    if (list) {
      list.forEach(item => {
        const value = item.content;
        if (item["columnName"] === "BODY_TYPE_NAME") {
          bodyTypes.push({ label: value, value });
        }
        if (item["columnName"] === "FUEL_TYPE") {
          fuelTypes.push({ label: value, value });
        }
      });
    } else {
      if (data.bodyTypes) {
        bodyTypes = data.bodyTypes;
      }
      if (data.fuelTypes) {
        fuelTypes = data.fuelTypes;
      }
    }
    return { bodyTypes, fuelTypes };
  };

  loadMetaVehicleDimensionsByMake = (vehicleGroup, metaVehicalFilter) => {
    const { metaVehicleDimensionsByMake } = this.context;
    const criteriaOptionsMap = metaVehicleDimensionsByMake[vehicleGroup.make];
    if (criteriaOptionsMap) {
      this.vehicleGroupFormRef.setMetaVehicleFilter(
        metaVehicalFilter,
        criteriaOptionsMap
      );
      return;
    }

    this.setLoadingMask(true);

    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/findMetaVehicleDimensionsByMake";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          dealerCode: vehicleGroup.dealerCode,
          make: vehicleGroup.make,
          variant: vehicleGroup.variant,
          countryId: "",
          requestType: "",
          authId: "",
          menuTypeId: "",
          setCategory: "package"
        }
      },
      response => {
        if (response && response.response) {
          const data = response.response;
          const sets = createSets(data.metaVehicleSets.metaVehicleSet);
          // toArrayOfLabelAndValue(data.metaVehicleSets.metaVehicleSet);
          const models = toArrayOfLabelAndValue(data.models.model);
          // const startAndEndYear = toArrayOfLabelAndValue(data.years.year);
          const startYear = toArrayOfLabelAndValue(data.years.year);
          // startYear.splice(0, 0, { label: "NONE", value: "NONE" });
          const endYear = toArrayOfLabelAndValue(data.years.year);
          // endYear.splice(0, 0, { label: "NONE", value: "NONE" });
          const years = toArrayOfLabelAndValue(data.years.year);
          // years.splice(0, 0, { label: "NONE", value: "NONE" });
          const trims = toArrayOfLabelAndValue(data.trims.trim);
          const engineTypes = toArrayOfLabelAndValue(
            data.engineTypes.engineType
          );
          const engineSizes = toArrayOfLabelAndValue(
            data.engineSizes.engineSize
          );
          const driveTypes = toArrayOfLabelAndValue(data.driveTypes.driveType);
          const transmissionTypes = toArrayOfLabelAndValue(
            data.transmissionTypes.transmissionType
          );
          const bodyTypeAndFuelTypes = this.createBodyAndFuelTypes(data);
          const { bodyTypes } = bodyTypeAndFuelTypes;
          const { fuelTypes } = bodyTypeAndFuelTypes;
          const criteriaOptionsMap = {
            sets,
            models,
            // startAndEndYear,
            startYear,
            endYear,
            years,
            trims,
            engineTypes,
            engineSizes,
            driveTypes,
            transmissionTypes,
            bodyTypes,
            fuelTypes
          };
          // console.log("criteriaOptionsMap", criteriaOptionsMap);
          metaVehicleDimensionsByMake[metaVehicalFilter.make] =
            criteriaOptionsMap;
          if (this.vehicleGroupFormRef) {
            this.vehicleGroupFormRef.setMetaVehicleFilter(
              metaVehicalFilter,
              criteriaOptionsMap
            );
          }
        }
        this.setLoadingMask(false);
      },
      error => {
        this.setLoadingMask(false);
        toast.error(error.message);
      }
    );
  };

  setLoadingMask(showMask) {
    if (this.vehicleGroupFormRef) {
      this.vehicleGroupFormRef.setLoadingMask(showMask);
    }
  }

  loadVehicleGroup = vehicleGroup => {
    const { dealerCode, make, metaVehicleFilterId } = vehicleGroup;

    if (metaVehicleFilterId === "") {
      const metaVehcileFilter = { dealerCode, make, metaVehicleFilterId };
      this.createVehicleGroupCriteria(vehicleGroup, metaVehcileFilter);
      return;
    }
    const nameOrSimple = vehicleGroup.isUnnamed ? "simple" : "named";
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/${nameOrSimple}MetaVehicleFilter`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          metaVehicleFilterId
        }
      },
      response => {
        if (response && response.length > 0) {
          const metaVehicleFilter = response[0];
          this.createVehicleGroupCriteria(vehicleGroup, metaVehicleFilter);
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  setSelectedItem = selectedItem => {
    if (selectedItem) {
      this.loadVehicleGroup(selectedItem);
      this.setState({ selectedItem });
    } else {
      const metaVehicleFilter = {};
      if (this.vehicleGroupFormRef) {
        this.vehicleGroupFormRef.setMetaVehicleFilter(metaVehicleFilter);
      }
      this.setState({ selectedItem: null, metaVehicleFilter });
    }
    this.reset(false);
  };

  isDirty = () => {
    return this.vehicleGroupFormRef.isDirty();
    // return this.state.dirty;
  };

  reset = dirty => {
    if (this.vehicleGroupFormRef) {
      this.vehicleGroupFormRef.reset(dirty);
    }
  };

  render() {
    const {getClassWithBridgeBar} =this.context;
    const msgSection = null;
    const AddGroupMenuButton = VGroupAPI.AddGroupMenuButton;
    const header = (
      <React.Fragment>
        {msgSection}
        <div>
          <Grid htmlId="pageHead">
            <Row className="show-grid">
              <Col xs={4} sm={4} className="full-col">
                <h3>{this.state.pageTitle}</h3>
                <AddGroupMenuButton
                  isFormDirty={this.isDirty}
                  resetForm={this.reset}
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </React.Fragment>
    );
    const GroupNameFilterCmp = VGroupAPI.GroupNameFilter;
    const VehicleGroupFormCmp = VGroupAPI.VehicleGroupForm;
    const vehicleGroupForm = (
      <VehicleGroupFormCmp
        ref={this.setVehicleGroupFormRef}
        className="xmm-vehicle-groups-root"
        formClassName="xmm-vehicle-group-form"
        sortedVehicleGroups={this.state.sortedVehicleGroups}
      />
    );

    const groupNameFilter = (
      <GroupNameFilterCmp
        className="xmm-filter-list"
        groupNames={this.state.sortedVehicleGroups}
        selectedItem={this.state.selectedItem}
      />
    );

    return (
      <div className="xmm-center-container">
        {header}
        <Card htmlId="vehicleGroupsCard" className={getClassWithBridgeBar("xmm-manage-vehicle")}>
          <div className="flex-content">
            {groupNameFilter}
            {vehicleGroupForm}
          </div>
        </Card>
      </div>
    );
  }
}

export default VehicleGroups;

VehicleGroups.propTypes = {};
