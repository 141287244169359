export function boolToNumber(value) {
    if (typeof value !== 'undefined' && value !== null) {
      if (typeof value === 'boolean') {
        value = value ? 1 : 0;
      }
    } 
    return value;
  }

  export function isUserCreatedRule(serviceType, serviceTypeCode) {
    return (!serviceType && !serviceTypeCode);
  }