/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import PriceInput from "@cx/ui/PriceInput";
import NumericInput from "@cx/ui/NumericInput";
import DatePicker from "@cx/ui/DatePicker";
import SelectInput from "@cx/ui/SelectInput";
import CheckBoxList from "@cx/ui/CheckBoxList";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import AllocationField from "../../AllocationGroup/AllocationField";
import { FeesTypesOptions } from "../../../../../constants/ModuleConstants";
import {
  BE_DATE_FORMAT,
  UI_DATE_FORMAT,
  convertDateStr
} from "../../../../../commonUtil/utils/date";
class AddFeesFormPage extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.payTypesHandleCustomError = this.payTypesHandleCustomError(this);
    this.validate = this.validate.bind(this);
    this.createEmptyAllocationRow = this.createEmptyAllocationRow.bind(this);
    this.isValidForm.bind(this);
    this.initializeLocaleValues();
    const dealerCode = context.dealerCode;
    const feesTypesOptions = [...FeesTypesOptions];
    const { user } = context;
    const { userName } = user;
    // const [selectedDate, setSelectedDate] = useState("");
    const fromDate = convertDateStr(
      props.rowRecord.fromDate,
      BE_DATE_FORMAT,
      UI_DATE_FORMAT
    );
    const expiryDate = convertDateStr(
      props.rowRecord.expiryDate,
      BE_DATE_FORMAT,
      UI_DATE_FORMAT
    );
    const newFee = {
      ...props.rowRecord,
      dealerCode,
      modUser: userName,
      fromDate,
      expiryDate
    };
    const payTypes = newFee.payTypes;
    const feeTypeSelected = newFee.feesType;
    const allocationIndex = newFee.departmentAllocations
      ? newFee.departmentAllocations.length
      : 0;
    const allocationFields =
      newFee.dealerFeesId && newFee.departmentAllocations
        ? JSON.parse(newFee.departmentAllocations)
        : [
            {
              department: "",
              account: "",
              allocation: 100
            }
          ];
    const neverExpires =
      newFee.dealerFeesId && newFee.expiryDate === "" ? ["1"] : [];
    newFee.neverExpires = neverExpires.length > 0 && neverExpires[0] === "1";
    this.state = {
      minDate: new Date("2023-09-01T00:00:00"),
      maxDate: new Date("9999-12-31T00:00:00"),
      value: "",
      newFee,
      payTypes,
      feeTypeSelected,
      feeValue: "",
      feesTypesOptions,
      allocationFields,
      allocationIndex,
      neverExpires,
      dirty: false,
      valid: false,
      errors: { expiryDate: "", fromDate: "" }
    };
  }

  componentDidMount() {
    window.addEventListener(
      "addAllocationEvent",
      this.onAddAllocationEvent,
      false
    );
    window.addEventListener(
      "removeAllocationEvent",
      this.onRemoveAllocationEvent,
      false
    );
    window.addEventListener(
      "updateAllocationEvent",
      this.onUpdateAllocationEvent,
      false
    );
  }
  createEmptyAllocationRow() {
    const newAllocation = {
      department: "",
      account: "",
      allocation: null
    };
    return newAllocation;
  }
  onAddAllocationEvent = () => {
    const newAllocation = this.createEmptyAllocationRow();
    const { allocationFields } = this.state;
    allocationFields.push(newAllocation);
    this.setState({ allocationFields });
  };

  onRemoveAllocationEvent = event => {
    const { index } = event.detail;
    const { allocationFields } = this.state;
    allocationFields.splice(index, 1);
    if (allocationFields.length === 1) {
      allocationFields.forEach(field => {
        field.allocation = 100;
      });
    }
    this.setState({ allocationFields });
  };
  onUpdateAllocationEvent = event => {
    const { index, field, name } = event.detail;
    const { allocationFields } = this.state;
    allocationFields[index] = field;
    this.setState({ allocationFields });
    this.markDirty(name, true);
  };

  initializeLocaleValues() {
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.detailsLabel = xlate("xmm.portal.operations.form.details_label");
    this.applyFeeTooltip = xlate("xmm.portal.fee.form.apply_fee_to_tooltip");
    this.feeTypeTooltip = xlate("xmm.portal.fee.fee_type_tooltip");
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.payTypesErrorMsg = xlate("xmm.portal.settings.pay_types_error_msg");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.addDepartmentLabel = xlate("xmm.portal.fees.form.add_department_btn");
    this.allocationsLabel = xlate("xmm.portal.fees.form.allocations_lbl");
    this.departmentsLabel = xlate("xmm.portal.fees.form.departament_lbl");
    this.costAllocationLabel = xlate(
      "xmm.portal.fees.form.cost_allocation_lbl"
    );
    this.accountAllocationLabel = xlate(
      "xmm.portal.fees.form.account_allocation_lbl"
    );
    this.allocationsTooltip = xlate("xmm.portal.fee.form.allocations_tooltip");
  }
  // changeEvent for textfield

  onToggleClick = event => {
    const target = event.target;
    const { name } = target;
    const { newFee } = this.state;
    const newValue = target.checked ? 1 : 0;

    if (isDifferentValue(newFee[name], newValue)) {
      this.setState(
        prevState => {
          return {
            newFee: {
              ...prevState.newFee,
              [name]: newValue
            }
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };
  onChange = event => {
    const { name, value } = event.target;
    const { newFee, payTypes, errors } = this.state;
    let newValue;

    switch (name) {
      case "description":
        newValue = value.replaceAll(/"/g, "");
        newValue = newValue.replaceAll("/", "");
        newValue = newValue.replaceAll("[", "");
        newValue = newValue.replaceAll("]", "");
        break;
      case "payTypes":
        newValue = value.join(",");
        break;
      case "dollarAmount":
      case "percentage":
      case "feeMax":
        if (value && value != "") {
          newValue = parseFloat(value);
        } else {
          newValue = "";
        }
        break;
      case "feesType":
        if (value === "Variable") {
          newFee.applyFeeSvcLine = 0;
        }
        this.setState({
          feeTypeSelected: value,
          newFee: {
            ...newFee,
            dollarAmount: "",
            feeMax: "",
            percentage: "",
            errors: {
              ...errors,
              percentage: "",
              feeMax: "",
              dollarAmount: ""
            }
          }
        });
        newValue = value;
        break;
      case "neverExpires":
        newValue = value[0] === "1";
        this.setState({
          newFee: {
            ...newFee,
            expiryDate: ""
          },
          neverExpires: value
        });
        break;
      default:
        newValue = value;
    }

    if (isDifferentValue(newFee[name], newValue)) {
      this.setState(
        prevState => {
          return {
            newFee: {
              ...prevState.newFee,
              [name]: newValue
            },
            payTypes
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };

  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const { valid, errors } = this.validate(fieldName);
      this.setState({ dirty, valid, errors });
      this.props.onModified();
    } else {
      this.setState({ dirty });
    }
  }
  isValidForm() {
    const {
      description,
      dollarAmount,
      percentage,
      feeMax,
      fromDate,
      expiryDate,
      payTypes,
      neverExpires
    } = this.state.newFee;
    const { feeTypeSelected, allocationFields } = this.state;
    if (!description) {
      return false;
    }
    if (!dollarAmount && feeTypeSelected === "Dollar") {
      return false;
    }
    if (!percentage && feeTypeSelected === "Percent") {
      return false;
    }
    if (
      !feeMax &&
      (feeTypeSelected === "Variable" || feeTypeSelected === "Percent")
    ) {
      return false;
    }
    if (!fromDate) {
      return false;
    }
    if (!(neverExpires || expiryDate)) {
      return false;
    }
    let validAllocation = true;
    allocationFields.forEach(allocation => {
      if (!allocation.department) {
        validAllocation = false;
      }
      if (!allocation.account) {
        validAllocation = false;
      }
    });
    if (!validAllocation) {
      return false;
    }
    return payTypes !== "";
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    let valid = true;
    const { newFee, errors, allocationFields } = this.state;
    let totalAllocations = 0;
    switch (fieldName) {
      case "description": {
        valid = !!newFee.description?.trim().length;
        errors["description"] = !valid ? "Fee description can't be blank." : "";
        break;
      }
      case "expiryDate": {
        valid = new Date(newFee.fromDate) <= new Date(newFee[fieldName]);
        errors["expiryDate"] =
          !valid && newFee.fromDate && newFee.expiryDate
            ? "The 'Expires on' date cannot be prior to the 'Valid from' date."
            : "";
        errors["fromDate"] = "";
        break;
      }
      case "fromDate": {
        valid = newFee.neverExpires
          ? true
          : new Date(newFee.expiryDate) >= new Date(newFee[fieldName]);
        errors["fromDate"] =
          !valid && newFee.fromDate && newFee.expiryDate
            ? "The 'Valid from' date cannot be after the 'Expires On' date."
            : "";
        errors["expiryDate"] = "";
        break;
      }
      case "percentage": {
        valid = newFee.percentage <= 100.0;
        errors["percentage"] = !valid
          ? "The percentage cannot be more than 100."
          : "";
        break;
      }
      case "dollarAmount": {
        valid = newFee.dollarAmount < 100000000;
        errors["dollarAmount"] = !valid
          ? "The Fee Amount cannot be more than 99999999.99."
          : "";
        break;
      }
      case "feeMax": {
        valid = newFee.feeMax < 100000000;
        errors["feeMax"] = !valid
          ? "The Fee Maximum cannot be more than 99999999.99."
          : "";
        break;
      }
      case "payTypes": {
        valid = newFee.payTypes?.length !== 0;
        errors["payTypes"] = !valid ? this.payTypesErrorMsg : "";
        break;
      }
      case "allocation": {
        allocationFields.forEach(allocation => {
          totalAllocations += parseFloat(allocation.allocation);
        });
        valid = totalAllocations === 100;
        errors["allocations"] = !valid
          ? "The total allocation has to equal 100%."
          : "";
        break;
      }
      default:
        break;
    }
    valid = valid && this.isValidForm();
    return { errors, valid };
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  getErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let msg = "";
    for (const value of iterator) {
      if (value && value !== "") {
        // In case of valid error string
        msg = msg + value + " ";
      }
    }
    return msg;
  }
  getAddPayload() {
    const { newFee, allocationFields } = this.state;
    const { fromDate, expiryDate } = newFee;
    return {
      ...newFee,
      fromDate: convertDateStr(fromDate, UI_DATE_FORMAT, BE_DATE_FORMAT),
      expiryDate: convertDateStr(expiryDate, UI_DATE_FORMAT, BE_DATE_FORMAT),
      departmentAllocations: JSON.stringify(allocationFields)
    };
  }
  // TODO - first validate duplicates for code, description per selected make; then proceed
  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors, newFee } = this.state;
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      this.updateStatusBox(
        this.correctInvalidFielsError + " - " + this.getErrorStrings(errors),
        "error",
        false
      );
      return;
    }
    let url = null;
    let data = null;
    if(newFee.dealerFeesId){
      //update fee
      url = `/ops/discountAndFees/updateDealerFees`
      const {dealerFeesId,dealerCode,description,feesType,dollarAmount,percentage,feeMax,fromDate,
        expiryDate,calculationBasis,payTypes,applyTax,applyFeeSvcLine,applyToOverallTotal,modUser,departmentAllocations
      } = this.getAddPayload();
      data = {
        dealerFeesId,dealerCode,description,feesType,dollarAmount,percentage,feeMax,fromDate,
        expiryDate,calculationBasis,payTypes,applyTax,applyFeeSvcLine,applyToOverallTotal,modUser,departmentAllocations
      };
    }else{
      //add fee
      data = this.getAddPayload();
      url = `/ops/proxyapi/ddsproxy/rest/table/dealerFees`
    }
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if(newFee.dealerFeesId){
          //update dealer fee reponse
          if(response && Object.getPrototypeOf(response) === Array.prototype && response[0]){
            this.updateStatusBox(this.savedMsg, "success", true);
            // callback grid to add record
            const { dealerFees } = response[0];
            this.props.updateFeesAfterSave(dealerFees, newFee.dealerFeesId);
            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.hideSlider();
              }, 800);
            });
          }else{
            const msg = "Update the fee fail";
            this.updateStatusBox(msg, "error", false);
          }
        }else{
          //add dealer fee reponse
          if (response && response.success) {
            this.updateStatusBox(this.savedMsg, "success", true);
            // callback grid to add record
            const { dealerFees } = response;
            this.props.updateFeesAfterSave(dealerFees, newFee.dealerFeesId);
            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.hideSlider();
              }, 800);
            });
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false);
      }
    );
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  payTypesHandleCustomError(value) {
    if (!value) {
      return this.payTypesErrorMsg;
    }
  }

  render() {
    let editSection = null;
    const msgSection = null;
    const {
      newFee,
      feesTypesOptions,
      feeTypeSelected,
      allocationFields,
      errors,
      minDate,
      maxDate,
      valid,
      neverExpires
    } = this.state;
    const showDollar = feeTypeSelected === "Dollar" ? "" : "hide";
    const showPercent = feeTypeSelected === "Percent" ? "" : "hide";
    const showMaxFee =
      feeTypeSelected === "Variable" || feeTypeSelected === "Percent"
        ? ""
        : "hide";
    const showMaxFeeFirst = feeTypeSelected === "Variable" ? 3 : 0;
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    const allocationTotalFields = allocationFields?.map(
      (allocationField, index) => {
        const id = index;
        return (
          <AllocationField
            key={`allocation-${id}`}
            field={allocationField}
            fee
            index={index}
            totalAllocationFields={allocationFields.length}
          />
        );
      }
    );
    const isValid = valid;
    const allocationHeader = (
      <div>
        {this.allocationsLabel}
        <Popover
          htmlId="popoverAllocations"
          popoverContent={this.allocationsTooltip}
          trigger={["click", "outsideClick"]}
        >
          <IconInfoOutline htmlId="allocationsInfo" className="hand-cursor" />
        </Popover>
      </div>
    );
    const payTypesLabel = (
      <div>
        <span className="xmm-select-menu-label">
          {this.context.localeStrings["xmm.portal.fee.form.apply_fee_to_lbl"]}
          &nbsp;
          <span className="xmm-red-label">*</span>
        </span>
        <Popover
          htmlId="popoverApplyTaxTooltip"
          popoverContent={this.applyFeeTooltip}
          trigger={["click", "outsideClick"]}
        >
          <IconInfoOutline htmlId="applyTaxInfo" className="hand-cursor" />
        </Popover>
      </div>
    );
    const applyTaxToFee = (
      <div>
        <p>{xlate("xmm.portal.fee.fee_type_tooltip_4")}</p>
        <span>{xlate("xmm.portal.fee.fee_type_tooltip_1")}</span>
        <ul className="tax-calculation-container-list">
          <li>{xlate("xmm.portal.fee.fee_type_tooltip_2")}</li>
          <li>{xlate("xmm.portal.fee.fee_type_tooltip_3")}</li>
        </ul>
      </div>
    );
    const applyFeeToServiceLines = (
      <div>
        <p>{xlate("xmm.portal.fee.form.apply_fee_svc_tooltip_2")}</p>
        <p>{xlate("xmm.portal.fee.form.apply_fee_svc_tooltip_1")}</p>

        <b>{xlate("xmm.portal.fee.form.apply_fee_svc_tooltip_3")}</b>
      </div>
    );
    const applyFeeToOverall = (
      <div>
        <p>{xlate("xmm.portal.fee.form.allow_overall_fee_tooltip_2")}</p>
        <p>{xlate("xmm.portal.fee.form.allow_overall_fee_tooltip_1")}</p>

        <b>{xlate("xmm.portal.fee.form.allow_overall_fee_tooltip_3")}</b>
      </div>
    );
    if (this.props.editForm) {
      // show form when edit clicked or add clicked
      editSection = (
        <React.Fragment>
          <div className="xmm-tab-bar">
            {statusBox} <span className="xmm-msg">{msgSection}</span>
            <Button
              htmlId="saveAction"
              buttonStyle="primary"
              disabled={!isValid}
              onClick={this.saveHandler}
            >
              {this.saveLabel}
            </Button>
          </div>
          <div className="xmm-slider-tab-scroll">
            <Card htmlId="addFeesDetailsId" header={this.detailsLabel}>
              <div className="comment-form">
                <Grid htmlId="settingGrid">
                  <Row className="show-grid">
                    <Col xs={6} xsOffset={1} md={6} mdOffset={1}>
                      <TextInput
                        htmlId="feesDescription"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.fee.description"
                          ]
                        }
                        name="description"
                        required
                        maxLength={100}
                        onChange={this.onChange}
                        value={newFee.description}
                      />
                    </Col>

                    <Col xs={2} md={2}>
                      <DatePicker
                        htmlId="validFrom"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.fee.form.valid_from_lbl"
                          ]
                        }
                        name="fromDate"
                        required
                        dateFormat={UI_DATE_FORMAT}
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={newFee.fromDate}
                        placeholder="mm-dd-yyyy"
                        onChange={this.onChange}
                        hasError={!!errors?.fromDate}
                        errorMessage={errors.fromDate}
                      />
                    </Col>
                    <Col xs={2} md={2}>
                      <DatePicker
                        htmlId="expiryDate"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.fee.form.expires_on_lbl"
                          ]
                        }
                        name="expiryDate"
                        required={!newFee.neverExpires}
                        dateFormat={UI_DATE_FORMAT}
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={newFee.expiryDate}
                        onChange={this.onChange}
                        placeholder="mm-dd-yyyy"
                        hasError={!!errors?.expiryDate}
                        errorMessage={errors.expiryDate}
                        disabled={newFee.neverExpires}
                      />
                      <CheckBoxList
                        htmlId="neverExpires"
                        name="neverExpires"
                        label="Options"
                        displayLabel={false}
                        onChange={this.onChange}
                        options={[
                          {
                            value: "1",
                            label: "Never expires"
                          }
                        ]}
                        inline
                        values={neverExpires}
                      />
                    </Col>
                    <Col xs={3} xsOffset={1} md={3} mdOffset={1}>
                      <div>
                        <span className="xmm-select-menu-label">
                          {this.context.localeStrings["xmm.portal.fee.feeType"]}
                          &nbsp;
                          <span className="xmm-red-label">*</span>
                        </span>
                        <Popover
                          htmlId="popoverFeesTypes"
                          popoverContent={this.feeTypeTooltip}
                          trigger={["click", "outsideClick"]}
                        >
                          <IconInfoOutline
                            htmlId="feesTypeInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                      <SelectInput
                        htmlId="feesType"
                        name="feesType"
                        displayLabel={false}
                        options={feesTypesOptions}
                        onChange={this.onChange}
                        required
                        value={newFee.feesType}
                        displayDeselectOption={false}
                      />
                    </Col>

                    <Row className="column-row">
                      <Col
                        xs={2}
                        xsOffset={3}
                        md={2}
                        mdOffset={3}
                        className={showDollar}
                      >
                        <PriceInput
                          htmlId="dollarAmount"
                          name="dollarAmount"
                          label={
                            this.context.localeStrings[
                              "xmm.portal.fee.form.fee_amount_lbl"
                            ]
                          }
                          minValue={0.01}
                          maxValue={99999999.99}
                          onChange={this.onChange}
                          value={newFee.dollarAmount}
                          required
                        />
                      </Col>
                      <Col
                        xs={2}
                        xsOffset={3}
                        md={2}
                        mdOffset={3}
                        className={showPercent}
                      >
                        <NumericInput
                          htmlId="percentage"
                          name="percentage"
                          maxValue={100}
                          label={
                            this.context.localeStrings[
                              "xmm.portal.fee.form.fee_percentage_lbl"
                            ]
                          }
                          inputSuffix="%"
                          allowDecimal={true}
                          onChange={this.onChange}
                          value={newFee.percentage}
                          required
                        />
                      </Col>
                      <Col
                        xs={2}
                        xsOffset={showMaxFeeFirst}
                        md={2}
                        mdOffset={showMaxFeeFirst}
                        className={showMaxFee}
                      >
                        <PriceInput
                          htmlId="feeMax"
                          name="feeMax"
                          label={
                            this.context.localeStrings[
                              "xmm.portal.fee.form.fee_max_lbl"
                            ]
                          }
                          maxValue={99999999.99}
                          onChange={this.onChange}
                          value={newFee.feeMax}
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="row-separator" />
                    <Col xs={3} xsOffset={1} md={3} mdOffset={1}>
                      <CheckBoxList
                        htmlId="payTypes"
                        name="payTypes"
                        label={payTypesLabel}
                        onChange={this.onChange}
                        hasError={!!errors.payTypes}
                        errorMessage={errors.payTypes}
                        options={[
                          { value: "C", label: "Customer Pay" },
                          { value: "I", label: "Internal" },
                          {
                            value: "S",
                            label: "Service Contract"
                          },
                          { value: "W", label: "Warranty" }
                        ]}
                        values={
                          newFee.payTypes ? newFee.payTypes.split(",") : []
                        }
                      />
                    </Col>
                    <Col xs={8} md={8}>
                      <span className="xmm-select-menu-label">
                        {
                          this.context.localeStrings[
                            "xmm.portal.fees.form.additional_opt_lbl"
                          ]
                        }
                      </span>
                      <div className="xmm-checkbox-container xmm-horizontal-form">
                        <input
                          type="checkbox"
                          name="applyTax"
                          id="applyTax"
                          className="form-checkbox"
                          checked={newFee.applyTax ? true : false}
                          onClick={this.onToggleClick}
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            this.context.localeStrings[
                              "xmm.portal.fee.form.apply_tax_to_lbl"
                            ]
                          }
                        </span>
                        <Popover
                          htmlId="popoverApplyTaxTooltip"
                          popoverContent={applyTaxToFee}
                          trigger={["click", "outsideClick"]}
                          position="right"
                        >
                          <IconInfoOutline
                            htmlId="applyTaxInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                      <div
                        className={
                          feeTypeSelected === "Variable" ||
                          newFee.applyToOverallTotal === 1
                            ? "xmm-checkbox-container xmm-horizontal-form disabled"
                            : "xmm-checkbox-container xmm-horizontal-form"
                        }
                      >
                        <input
                          type="checkbox"
                          name="applyFeeSvcLine"
                          id="applyFeeSvcLine"
                          onClick={this.onToggleClick}
                          checked={newFee.applyFeeSvcLine ? true : false}
                          disabled={
                            feeTypeSelected === "Variable" ||
                            newFee.applyToOverallTotal === 1
                          }
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            this.context.localeStrings[
                              "xmm.portal.fee.form.apply_fee_svc_lbl"
                            ]
                          }
                        </span>
                        <Popover
                          htmlId="popoverApplyFeeSvcTooltip"
                          popoverContent={applyFeeToServiceLines}
                          trigger={["click", "outsideClick"]}
                          position="right"
                        >
                          <IconInfoOutline
                            htmlId="applyFeeSvcInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                      <div
                        className={
                          newFee.applyFeeSvcLine === 1
                            ? "xmm-checkbox-container xmm-horizontal-form disabled"
                            : "xmm-checkbox-container xmm-horizontal-form"
                        }
                      >
                        <input
                          type="checkbox"
                          name="applyToOverallTotal"
                          id="applyToOverallTotal"
                          onClick={this.onToggleClick}
                          checked={newFee.applyToOverallTotal ? true : false}
                          disabled={newFee.applyFeeSvcLine === 1}
                        />
                        <span className="xmm-checkmark" />
                        <span className="label-checkbox">
                          {
                            this.context.localeStrings[
                              "xmm.portal.fee.form.allow_overall_fee_lbl"
                            ]
                          }
                        </span>
                        <Popover
                          htmlId="popoverAllowOverallFee"
                          popoverContent={applyFeeToOverall}
                          trigger={["click", "outsideClick"]}
                          position="right"
                        >
                          <IconInfoOutline
                            htmlId="allowOverallFeeInfo"
                            className="hand-cursor"
                          />
                        </Popover>
                      </div>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </Card>
            <Card htmlId="addFeesAllocationsId" header={allocationHeader}>
              <div className="comment-form">
                <Grid htmlId="allocationsGrid">
                  <Row className="show-grid">
                    <Col xs={3} xsOffset={1} md={3} mdOffset={1}>
                      <span className="xmm-select-menu-label">
                        {this.departmentsLabel} &nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={3} md={3}>
                      <span className="xmm-select-menu-label">
                        {this.costAllocationLabel}&nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                    <Col xs={2} md={2}>
                      <span className="xmm-select-menu-label">
                        {this.accountAllocationLabel}&nbsp;
                        <span className="xmm-red-label">*</span>{" "}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xsOffset={1}
                      mdOffset={1}
                      xs={12}
                      md={12}
                      className="full-col"
                    >
                      {allocationTotalFields}
                    </Col>
                  </Row>
                </Grid>
              </div>
            </Card>
          </div>
        </React.Fragment>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default AddFeesFormPage;

AddFeesFormPage.propTypes = {
  rowRecord: PropTypes.object,
  operationName: PropTypes.string,
  editForm: PropTypes.bool,
  hideSlider: PropTypes.func,
  updateFeesAfterSave: PropTypes.func
};
/* eslint-enable no-console */
