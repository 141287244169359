import ManageIntervalsModal from "./ManageIntervalsModal";
import AddIntervalButton from "./AddIntervalButton";
import GroupNameFilter from "./GroupNameFilter";
import GroupNameList from "./GroupNameList";
import IntervalForm from "./IntervalForm";
import MileageGrid from "./MileageGrid";

// bundle components into one object and export
const IntervalsAPI = {
  ManageIntervalsModal,
  AddIntervalButton,
  GroupNameFilter,
  GroupNameList,
  IntervalForm,
  MileageGrid
};

export default IntervalsAPI;
