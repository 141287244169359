/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import NumericInput from "@cx/ui/NumericInput";
import PriceInput from "@cx/ui/PriceInput";
import {
  isDifferentFloatingValue,
  isDifferentValue
} from "../../../../../commonUtil/utils/string";
import { doesEmpty } from "../../../../../commonUtil/utils/object";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import NoPricingRow from "./NoPricingRow";
import { AppContext } from "../../../../app-context";
import { xlate } from "../../../../../commonUtil/i18n/locales";

class ShopSuppliesRow extends React.Component {
  static contextType = AppContext;
  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.catalog !== prevState.catalog) {
      const { catalog } = nextProps;
      const applyToSupply = getApplyShopSupply(catalog);
      const isNone = applyToSupply === "0-0" ? true : false;
      // console.log(
      //   "props datatype",
      //   catalog.make,
      //   typeof catalog.shopSupplyValue,
      //   typeof catalog.shopSupplyMinimumValue,
      //   typeof catalog.shopSupplyMaximumValue
      // );
      return {
        catalog,
        applyToSupply,
        taxOptions: getTaxOptions(catalog),
        shopSupplyValue: catalog.shopSupplyValue,
        shopSupplyMinimumValue: catalog.shopSupplyMinimumValue,
        shopSupplyMaximumValue: catalog.shopSupplyMaximumValue,
        shopSupplyTaxable: catalog.shopSupplyTaxable,
        dirty: false,
        valid: true,
        errors: {
          applyToSupply: "",
          shopSupplyValue:
            !isNone && doesEmpty(catalog.shopSupplyValue)
              ? xlate("xmm.portal.errors.field_is_required")
              : "",
          shopSupplyMinimumValue: "",
          shopSupplyMaximumValue: "",
          shopSupplyTaxable: ""
        }
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.applyShopChange = this.applyShopChange.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);

    this.onChangeRate = this.onChangeRate.bind(this);
    this.onBlurRate = this.onBlurRate.bind(this);

    const { catalog } = props;
    const applyToSupply = getApplyShopSupply(catalog);
    this.initializeLocaleValue();
    this.state = {
      applyToSupply,
      taxOptions: getTaxOptions(catalog),
      shopSupplyValue: catalog.shopSupplyValue,
      shopSupplyMinimumValue: catalog.shopSupplyMinimumValue,
      shopSupplyMaximumValue: catalog.shopSupplyMaximumValue,
      shopSupplyTaxable: catalog.shopSupplyTaxable,
      dirty: false,
      valid: true,
      errors: {
        applyToSupply: "",
        shopSupplyValue: "",
        shopSupplyMinimumValue: "",
        shopSupplyMaximumValue: "",
        shopSupplyTaxable: ""
      }
    };
  }
  componentDidMount() {}
  initializeLocaleValue() {
    this.notApplicableLabel = xlate("xmm.portal.settings.not_applicable_lbl");
    this.notApplicableTipLabel = xlate(
      "xmm.portal.settings.not_applicable_tip"
    );
    this.fieldRequiredError = xlate("xmm.portal.errors.field_is_required");
    this.rateRequiredError = xlate("xmm.portal.errors.rate_required");
    this.minValueError = xlate("xmm.portal.settings.min_value_warn");
    this.maxValueError = xlate("xmm.portal.settings.max_value_warn");
  }
  /* checkbox change handler */
  onChangeCheckbox = event => {
    const target = event.target;
    const { name } = target;
    const newValue = target.checked ? 1 : 0;
    this.setState({ [name]: newValue });
    const { catalog, onUpdate } = this.props;
    const applyTo = this.state.applyToSupply.toString().split("-");
    const shopSupplyApplyToParts = parseInt(applyTo[0], 10);
    const shopSupplyApplyToLabor = parseInt(applyTo[1], 10);
    const payload = {
      shopSupplyApplyToParts:
        shopSupplyApplyToParts === 2 ? null : shopSupplyApplyToParts,
      shopSupplyApplyToLabor:
        shopSupplyApplyToLabor === 2 ? null : shopSupplyApplyToLabor,
      [name]: newValue
    };
    if (this.state.applyToSupply !== "0-0") {
      catalog[name] = newValue;
      onUpdate(catalog, name, payload);
    }
  };

  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  hasErrorStrings(errors) {
    if (Object.keys(errors).length) {
      const array1 = Object.values(errors);
      const iterator = array1.values();
      let errCount = 0;
      for (const value of iterator) {
        if (value === "" || value === null || value.length === 0) {
          // In case of valid error string
        } else if (value && typeof value === "string") {
          errCount++;
        }
      }
      return errCount === 0 ? false : true;
    } else {
      return false;
    }
  }
  // Rate field validator allows {min = 0% max = 50.00% }
  onBlurRate = cxEvent => {
    const { value, id } = cxEvent.target;
    const endIndex = id.indexOf("-") !== -1 ? id.indexOf("-") : id.length;
    const fieldName = id.substring(0, endIndex);
    const valid = this.validate(fieldName);
    // callback parent handler to push changes
    const { catalog, onUpdate } = this.props;
    const {
      dirty,
      applyToSupply,
      shopSupplyMinimumValue,
      shopSupplyMaximumValue
    } = this.state;
    if (dirty && valid) {
      const newVal = parseFloat(value).toFixed(2);
      if (applyToSupply !== "0-0") {
        const applyTo = applyToSupply.toString().split("-");
        const minVal = !doesEmpty(shopSupplyMinimumValue)
          ? shopSupplyMinimumValue
          : "";
        const maxVal = !doesEmpty(shopSupplyMaximumValue)
          ? shopSupplyMaximumValue
          : "";
        let payload = null;
        // Flat pricing flow
        if (catalog.pricingMethod === 2) {
          payload = {
            shopSupplyApplyToTotal: 1,
            shopSupplyMinimumValue: minVal,
            shopSupplyMaximumValue: maxVal,
            shopSupplyValue: newVal
          };
        } else {
          payload = {
            shopSupplyApplyToParts: parseInt(applyTo[0], 10),
            shopSupplyApplyToLabor: parseInt(applyTo[1], 10),
            shopSupplyMinimumValue: minVal,
            shopSupplyMaximumValue: maxVal,
            shopSupplyValue: newVal
          };
        }
        catalog[fieldName] = newVal;
        onUpdate(catalog, "shopSupplyValue", payload);
        this.setState({
          dirty: false,
          valid: true,
          shopSupplyValue: newVal
        });
      }
    }
  };

  // Min/Max field validation rule
  // max price = $0 to $100.00; min price $0 to $50.00
  onBlurInput = cxEvent => {
    const { value, id } = cxEvent.target;
    const endIndex = id.indexOf("-") !== -1 ? id.indexOf("-") : id.length;
    const fieldName = id.substring(0, endIndex);
    const valid = this.validate(fieldName);
    // callback parent handler to push changes
    const { catalog, onUpdate } = this.props;
    const {
      dirty,
      applyToSupply,
      shopSupplyValue,
      shopSupplyMinimumValue,
      shopSupplyMaximumValue
    } = this.state;
    if (dirty && valid) {
      const newVal = !doesEmpty(value) ? parseFloat(value).toFixed(2) : "";
      const supplyVal = !doesEmpty(shopSupplyValue) ? shopSupplyValue : "";
      let otherFieldVal = null;
      let otherFieldName = "shopSupplyMinimumValue";
      if (fieldName !== "shopSupplyMinimumValue") {
        otherFieldName = "shopSupplyMinimumValue";
        otherFieldVal = !doesEmpty(shopSupplyMinimumValue)
          ? parseFloat(shopSupplyMinimumValue).toFixed(2)
          : shopSupplyMinimumValue;
        otherFieldVal = !doesEmpty(otherFieldVal) ? otherFieldVal : "";
      } else if (fieldName !== "shopSupplyMaximumValue") {
        otherFieldName = "shopSupplyMaximumValue";
        otherFieldVal = !doesEmpty(shopSupplyMaximumValue)
          ? parseFloat(shopSupplyMaximumValue).toFixed(2)
          : shopSupplyMaximumValue;
        otherFieldVal = !doesEmpty(otherFieldVal) ? otherFieldVal : "";
      }

      if (applyToSupply !== "0-0") {
        const applyTo = applyToSupply.toString().split("-");
        let payload = null;
        // Flat pricing flow
        if (catalog.pricingMethod === 2) {
          payload = {
            shopSupplyApplyToTotal: 1,
            shopSupplyValue: supplyVal,
            [fieldName]: newVal,
            [otherFieldName]: otherFieldVal
          };
        } else {
          payload = {
            shopSupplyApplyToParts: parseInt(applyTo[0], 10),
            shopSupplyApplyToLabor: parseInt(applyTo[1], 10),
            shopSupplyValue: supplyVal,
            [fieldName]: newVal,
            [otherFieldName]: otherFieldVal
          };
        }
        catalog[fieldName] = newVal;
        onUpdate(catalog, fieldName, payload);
        this.setState({
          dirty: false,
          valid: true,
          [otherFieldName]: otherFieldVal,
          fieldName: newVal
        });
      }
    }
  };
  onChangeRate = cxEvent => {
    const { name, value } = cxEvent.target;
    if (isDifferentFloatingValue(this.state[name], value)) {
      // console.log("change rate event", name, typeof value, value);
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            dirty: true, // set dirty flag to avoid server call when there is no change
            [name]: value,
            errors
          };
        },
        () => {
          // callback to validate field
        }
      );
    }
  };

  onChangeInput = cxEvent => {
    const { name, value } = cxEvent.target;
    if (isDifferentFloatingValue(this.state[name], value)) {
      this.setState(prevState => {
        const { errors } = prevState;
        return {
          dirty: true, // set dirty flag to avoid server calls when there is no change
          [name]: value,
          errors
        };
      });
    }
  };

  applyShopChange = cxEvent => {
    const { name, value } = cxEvent.target;
    const { catalog, onUpdate } = this.props;
    let rateError = false;
    let shopSupplyApplyToParts = "";
    let shopSupplyApplyToLabor = "";
    let shopSupplyApplyToTotal = "";
    let {
      shopSupplyValue,
      shopSupplyMinimumValue,
      shopSupplyMaximumValue,
      shopSupplyTaxable
    } = this.state;
    if (isDifferentValue(this.state.applyToSupply, value)) {
      if (value) {
        switch (value) {
          case "0-0":
            if (catalog.pricingMethod === 2) {
              // Flat pricing flow
              shopSupplyApplyToTotal = 0;
            } else {
              shopSupplyApplyToParts = 0;
              shopSupplyApplyToLabor = 0;
            }
            shopSupplyValue = null;
            shopSupplyMinimumValue = null;
            shopSupplyMaximumValue = null;
            shopSupplyTaxable = 0;
            break;
          case "1-0":
            shopSupplyApplyToParts = 1;
            shopSupplyApplyToLabor = 0;
            rateError = true;
            break;
          case "0-1":
            shopSupplyApplyToParts = 0;
            shopSupplyApplyToLabor = 1;
            rateError = true;
            break;
          case "1-1":
            shopSupplyApplyToParts = 1;
            shopSupplyApplyToLabor = 1;
            rateError = true;
            break;
          case "2-2":
            shopSupplyApplyToTotal = 1;
            rateError = true;
            break;
          default:
            shopSupplyApplyToParts = 0;
            shopSupplyApplyToLabor = 0;
            shopSupplyApplyToTotal = 0;
            shopSupplyValue = null;
            shopSupplyMinimumValue = null;
            shopSupplyMaximumValue = null;
            shopSupplyTaxable = 0;
            break;
        }
        const applyToSupply = value;
        const { errors, valid } = this.state;
        const errorRate = errors.shopSupplyValue;
        // check if rate field is valid to proceed
        const isValid =
          value === "0-0" || (valid && !doesEmpty(shopSupplyValue));
        errors.shopSupplyValue =
          rateError && doesEmpty(shopSupplyValue)
            ? this.rateRequiredError
            : !valid
            ? errorRate
            : "";
        this.setState({
          applyToSupply,
          errors
        });

        let payload = null;

        // append min, max, rate, taxable values for NONE case
        if (isValid) {
          const minVal = !doesEmpty(shopSupplyMinimumValue)
            ? shopSupplyMinimumValue
            : "";
          const maxVal = !doesEmpty(shopSupplyMaximumValue)
            ? shopSupplyMaximumValue
            : "";
          this.setState({
            applyToSupply,
            shopSupplyValue,
            shopSupplyMinimumValue: minVal,
            shopSupplyMaximumValue: maxVal,
            shopSupplyTaxable
          });
          // Flat pricing work flow
          if (catalog.pricingMethod === 2) {
            payload = {
              shopSupplyApplyToTotal,
              shopSupplyValue,
              shopSupplyMinimumValue: minVal,
              shopSupplyMaximumValue: maxVal,
              shopSupplyTaxable
            };
          } else {
            payload = {
              shopSupplyApplyToParts,
              shopSupplyApplyToLabor,
              shopSupplyValue,
              shopSupplyMinimumValue: minVal,
              shopSupplyMaximumValue: maxVal,
              shopSupplyTaxable
            };
          }
          onUpdate(catalog, name, payload);
        }
      }
    }
  };

  /* common validator called upon onblur() of each field */
  validate() {
    const { errors } = this.state;
    let valid = false;
    const { shopSupplyValue, shopSupplyMinimumValue, shopSupplyMaximumValue } =
      this.state;
    const MinValue = 0;
    const MaxValue = 50;

    if (!shopSupplyValue) {
      errors["shopSupplyValue"] = this.fieldRequiredError;
    } else if ((parseInt(shopSupplyValue, 10) || 0) < 0) {
      errors["shopSupplyValue"] = this.minValueError
        .concat(" ")
        .concat(MinValue);
    } else if (shopSupplyValue && (parseFloat(shopSupplyValue) || 0) > 50) {
      errors["shopSupplyValue"] = this.maxValueError
        .concat(" ")
        .concat(MaxValue);
    } else {
      errors["shopSupplyValue"] = "";
      valid = true;
    }

    if (!shopSupplyMinimumValue) {
      errors["shopSupplyMinimumValue"] = !doesEmpty(shopSupplyMaximumValue)
        ? ""
        : "";
      valid = !doesEmpty(shopSupplyMaximumValue) ? true : true;
    } else if ((parseInt(shopSupplyMinimumValue, 10) || 0) < MinValue) {
      errors["shopSupplyMinimumValue"] = this.minValueError
        .concat(" ")
        .concat(MinValue);
    } else if (
      shopSupplyMinimumValue &&
      (parseFloat(shopSupplyMinimumValue) || 0) > MaxValue
    ) {
      errors["shopSupplyMinimumValue"] = this.maxValueError
        .concat(" ")
        .concat(MaxValue);
    } else {
      errors["shopSupplyMinimumValue"] = "";
      valid = true;
    }

    if (!shopSupplyMaximumValue) {
      errors["shopSupplyMaximumValue"] = !doesEmpty(shopSupplyMinimumValue)
        ? ""
        : "";
      valid = !doesEmpty(shopSupplyMinimumValue) ? true : true;
    } else if ((parseInt(shopSupplyMaximumValue, 10) || 0) < MinValue) {
      errors["shopSupplyMaximumValue"] = this.minValueError
        .concat(" ")
        .concat(MinValue);
    } else if (
      shopSupplyMaximumValue &&
      (parseFloat(shopSupplyMaximumValue) || 0) > 100
    ) {
      errors["shopSupplyMaximumValue"] = this.maxValueError
        .concat(" ")
        .concat(MaxValue);
    } else {
      errors["shopSupplyMaximumValue"] = "";
      valid = true;
    }

    if (shopSupplyMinimumValue && shopSupplyMaximumValue) {
      if (
        (parseFloat(shopSupplyMinimumValue) || 0) >
        (parseFloat(shopSupplyMaximumValue) || 0)
      ) {
        valid = false;
        errors["shopSupplyMinimumValue"] =
          "Min value must be less than Max field";
      }
    }
    // validate all 3 fields any time
    if (valid) {
      valid = !this.hasErrorStrings(errors);
    }
    this.setState({ valid, errors });
    return valid;
  }

  render() {
    const { catalog } = this.props;
    const {
      errors,
      applyToSupply,
      shopSupplyValue,
      shopSupplyMinimumValue,
      shopSupplyMaximumValue
    } = this.state;
    const disableRate = applyToSupply === "0-0" ? true : false;
    let rateVal = doesEmpty(shopSupplyValue) ? "" : shopSupplyValue;
    rateVal = typeof rateVal === "number" ? rateVal.toFixed(2) : rateVal;
    const minVal =
      typeof shopSupplyMinimumValue === "number"
        ? shopSupplyMinimumValue.toFixed(2)
        : shopSupplyMinimumValue;
    const maxVal =
      typeof shopSupplyMaximumValue === "number"
        ? shopSupplyMaximumValue.toFixed(2)
        : shopSupplyMaximumValue;
    const clsHide = "xmm-fixed-col";

    const isAny = false; //catalog.make === "ANY" ? true : false;
    const clsAny = isAny ? "xmm-form-content" : "hidden";
    const clsRow = "xmm-form-content";//catalog.make !== "ANY" ? "xmm-form-content" : "hide";
    const anyMakeRow = (
      <div className="xmm-colspan-label">
        {this.notApplicableLabel}
        <Tooltip
          htmlId="tipRight"
          position="right"
          tooltipContent={this.notApplicableTipLabel}
        >
          <IconInfoOutline htmlId="iconInfo" />
        </Tooltip>
      </div>
    );
    const defaultRow = (
      <>
        <div className="xmm-apply-field">
          <SelectInput
            htmlId="applyToSupply"
            label="Apply To"
            displayLabel={false}
            name="applyToSupply"
            onChange={this.applyShopChange}
            placeholder="Select"
            displayDeselectOption={false}
            displayPlaceholder={false}
            options={this.state.taxOptions}
            value={this.state.applyToSupply}
          />
        </div>
        <div className={clsHide + " no-border"}>
          <NumericInput
            htmlId={"shopSupplyValue-" + catalog.make}
            inputSuffix="%"
            label="Rate"
            displayLabel={false}
            name="shopSupplyValue"
            onChange={this.onChangeRate}
            onBlur={this.onBlurRate}
            maxLength={5}
            minLength={0}
            allowDecimal
            value={rateVal || ""}
            disabled={disableRate}
            error={errors.shopSupplyValue}
          />
        </div>
        <div className={clsHide + " no-border"}>
          <PriceInput
            htmlId={"shopSupplyMinimumValue-" + catalog.make}
            inputPrefix="$"
            maxLength={5}
            minLength={0}
            label="Min"
            displayLabel={false}
            name="shopSupplyMinimumValue"
            onChange={this.onChangeInput}
            onBlur={this.onBlurInput}
            value={minVal || ""}
            disabled={disableRate}
            error={errors.shopSupplyMinimumValue}
          />
        </div>
        <div className={clsHide + " no-border"}>
          <PriceInput
            htmlId={"shopSupplyMaximumValue-" + catalog.make}
            inputPrefix="$"
            maxLength={5}
            minLength={0}
            label="Max"
            displayLabel={false}
            name="shopSupplyMaximumValue"
            onChange={this.onChangeInput}
            onBlur={this.onBlurInput}
            value={maxVal || ""}
            disabled={disableRate}
            error={errors.shopSupplyMaximumValue}
          />
        </div>
        <div className={clsHide}>
          <div className="xmm-checkbox-container">
            <input
              className="form-checkbox"
              name="shopSupplyTaxable"
              id="shopSupplyTaxable"
              type="checkbox"
              checked={this.state.shopSupplyTaxable || 0}
              disabled={disableRate}
              onChange={this.onChangeCheckbox}
            />
            <span className="xmm-checkmark" />
            <span className="label-checkbox" />
          </div>
        </div>
      </>
    );
    const noPricingRow = <NoPricingRow />;
    const rowHtml = catalog.pricingMethod === 0 ? noPricingRow : defaultRow;
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {" "}
          {catalog.make}{" "}
        </Col>
        <Col md={10} sm={10} className={clsRow}>
          {rowHtml}
        </Col>
        <Col md={10} sm={10} className={clsAny}>
          {anyMakeRow}
        </Col>
      </Row>
    );
  }
}

export default ShopSuppliesRow;
ShopSuppliesRow.propTypes = {
  catalog: PropTypes.object,
  onUpdate: PropTypes.func
};

function getApplyShopSupply(catalog) {
  const {
    shopSupplyApplyToParts,
    shopSupplyApplyToLabor,
    shopSupplyApplyToTotal,
    pricingMethod
  } = catalog;
  let taxValue = "0-0";
  if (pricingMethod === 2) {
    if (!shopSupplyApplyToTotal || shopSupplyApplyToTotal === 0)
      taxValue = "0-0";
    else if (shopSupplyApplyToTotal === 1) taxValue = "2-2";
    else taxValue = "0-0";
  } else {
    if (!shopSupplyApplyToParts && !shopSupplyApplyToLabor) {
      taxValue = "0-0";
    } else {
      taxValue =
        shopSupplyApplyToParts.toString() +
        "-" +
        shopSupplyApplyToLabor.toString();
    }
  }
  return taxValue;
}

function getTaxOptions(catalog) {
  let taxOptions = [
    { value: "0-0", label: xlate("xmm.portal.common.none") },
    { value: "1-0", label: xlate("xmm.portal.common.parts_only") },
    { value: "0-1", label: xlate("xmm.portal.common.labor_only") },
    { value: "1-1", label: xlate("xmm.portal.nav.parts_labor") }
  ];
  // Total Flat Price workflow
  if (catalog.pricingMethod === 2) {
    taxOptions = [
      { value: "0-0", label: xlate("xmm.portal.common.none") },
      { value: "2-2", label: xlate("xmm.portal.common.total_flat_price") }
    ];
  }
  return taxOptions;
}
