import { defaultToZeroIfNull } from "./value";
import { toEmptyStringIfUndefined } from "./string";
/**
 *
 * @param {string} menuType1
 * @param {string} menuType2
 * @returns {boolean}
 */
export const menuTypeComparator = (menuType1, menuType2) => {
  if (!menuType1 || !menuType2) {
    return -1;
  }
  const make1 = menuType1.make;
  const make2 = menuType2.make;
  if (make1 === make2) {
    const rank1 = Number(defaultToZeroIfNull(menuType1.rank).toString());
    const rank2 = Number(defaultToZeroIfNull(menuType2.rank).toString());
    if (rank1 === rank2) {
      const description1 = toEmptyStringIfUndefined(
        menuType1.description
      ).toLowerCase();
      const description2 = toEmptyStringIfUndefined(
        menuType2.description
      ).toLowerCase();
      if (description1 === description2) {
        return 0;
      }
      return description1 > description2 ? 1 : -1;
    }
    return rank1 > rank2 ? 1 : -1;
  }
  return make1 > make2 ? 1 : -1;
};
/**
 *
 * @param {object} option1
 * @param {object} option2
 * @returns {boolean}
 */
export const optionListComparator = (option1, option2) => {
  if (option1.label === option2.label) {
    return 0;
  }
  return option1.label > option2.label ? 1 : -1;
};

/**
 * This util returns repeated elements when both array elements merged
 * @param {array} arr1
 * @param {array} arr2
 * @returns {array}
 */
export function mergeArrays(arr1, arr2) {
  const finalArray = [];
  arr1.forEach(e1 =>
    arr2.forEach(e2 => {
      if (e1 === e2) {
        finalArray.push(e1);
      }
    })
  );
  return finalArray;
}

/**
 * This utils to get the Unique elements from the array we need to do few changes to our function.
 * @param {array} arr1
 * @param {array} arr2
 * @returns
 */
export function compareArrays(arr1, arr2) {
  const objMap = {};

  arr1.forEach(e1 =>
    arr2.forEach(e2 => {
      if (e1 === e2) {
        objMap[e1] = objMap[e1] + 1 || 1;
      }
    })
  );
  // console.log(Object.keys(objMap).map(e => Number(e)));
  return Object.keys(objMap).map(e => Number(e));
}
/**
 *
 * @param {object} context
 * @returns {array}
 */
export const getAllMakeFilters = context => {
  const { makelist } = context;
  if (makelist) {
    const makelistFilter = [...makelist];
    makelistFilter.splice(0, 0, {
      label: "All Makes",
      value: "all",
      make: "all",
      dealerCode: "all",
      variant: "all"
    });
    return makelistFilter;
  }
  return [];
};
/**
 * This util used to deep clone source object
 * @param {array} sourceList
 * @returns {array}
 */
export const deepCopyList = sourceList => {
  return sourceList.map(item => {
    const itemClone = Object.assign({}, item);
    return itemClone;
  });
};
/**
 *
 * @param {array} list
 * @returns {array} sorted list
 */
export const sortArrayList = list => {
  const sortedList = list.sort((a, b) => {
    if (a === b) {
      return 0;
    }
    return a > b ? 1 : -1;
  });
  return sortedList;
};
/**
 *
 * @param {object} rawlist
 * @param {string} fieldName
 * @returns {boolean}
 */
export const sortObjectList = (rawlist, fieldName) => {
  return rawlist.sort((a, b) => {
    if (a[fieldName] === b[fieldName]) {
      return 0;
    }
    return a[fieldName] > b[fieldName] ? 1 : -1;
  });
};
/**
 * This util convert object to array
 * @param {object} datalist
 * @returns {array}
 */
export function toArrayList(datalist) {
  if (Array.isArray(datalist)) {
    return datalist;
  }
  if (typeof datalist === "object") {
    return [datalist];
  }
  return [];
}

/**
 * This util returns first object from array
 * @param {array} list
 * @returns {object}
 */
export function selectFirstItemFromArrayOfMaps(list) {
  let selectedItem = null;
  for (let index = 0; index < list.length; index++) {
    const map = list[index];
    const values = Object.values(map);
    const items = values[0];
    if (items.length !== 0) {
      selectedItem = items[0];
      break;
    }
  }
  return selectedItem;
}
