/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import VehicleGroupForm from "../VehicleGroups/VehicleGroupForm";
import {
  createSets,
  toArrayOfLabelAndValue
} from "../../reusable/VehicleGroups/VehicleGroupUtil";

import { AppContext } from "../../../components/app-context";

export default class CreateVehicleGroupModal extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.make !== prevState.make) {
      return {
        make: nextProps.make,
        variant: nextProps.variant
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.onCancel = this.onCancel.bind(this);
    this.onAddNamedVehicleFilterEvent =
      this.onAddNamedVehicleFilterEvent.bind(this);
    this.vehicleGroupFormRef = null;
    this.setVehicleGroupFormRef = ref => {
      this.vehicleGroupFormRef = ref;
      if (ref) {
        const { dealerCode, make, variant } = this.state;
        if (dealerCode && make && variant) {
          this.initializeMetaVehicleDimensionsByMake(dealerCode, make, variant);
        }
      }
    };

    this.state = {
      metaVehicleFilter: {
        metaVehicleFilterId: "",
        dealerCode: this.context ? this.context.dealerCode : "",
        name: "",
        make: props.make
      },
      dealerCode: props.dealerCode,
      make: props.make,
      variant: props.variant,
      criteriaOptionsMap: {}
    };
  }
  /**
   * Add event listener
   */
  componentDidMount() {
    const { dealerCode, make, variant } = this.state;
    this.initializeMetaVehicleDimensionsByMake(dealerCode, make, variant);
    window.addEventListener(
      "addNamedVehicleFilterEvent",
      this.onAddNamedVehicleFilterEvent,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "addNamedVehicleFilterEvent",
      this.onAddNamedVehicleFilterEvent,
      false
    );
  }

  onAddNamedVehicleFilterEvent = event => {
    window.dispatchEvent(
      new CustomEvent("setNamedVehicleFilter", {
        detail: event.detail,
        bubbles: true,
        cancelable: true
      })
    );
  };

  initializeMetaVehicleDimensionsByMake = (dealerCode, make, variant) => {
    const newVehicleGroupItem = {
      dealerCode,
      make,
      variant,
      name: "",
      description: "",
      metaVehicleFilterId: "",
      countOperationRules: 0
    };

    this.loadVehicleGroup(newVehicleGroupItem);
  };

  // isDirty = () => {
  //   return this.state.dirty;
  // };
  isDirty = () => {
    return this.vehicleGroupFormRef.isDirty();
    // return this.state.dirty;
  };

  hide = () => {
    this.props.closeModal();
  };

  onCancel = () => {
    this.hide();
  };

  setDirty = dirty => {
    this.setState({ dirty });
  };

  loadMetaVehicleDimensionsByMake = (vehicleGroup, metaVehicalFilter) => {
    const { metaVehicleDimensionsByMake } = this.context;
    const criteriaOptionsMap = metaVehicleDimensionsByMake[vehicleGroup.make];
    if (criteriaOptionsMap) {
      this.vehicleGroupFormRef.setMetaVehicleFilter(
        metaVehicalFilter,
        criteriaOptionsMap
      );
      return;
    }

    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/findMetaVehicleDimensionsByMake";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          dealerCode: vehicleGroup.dealerCode,
          make: vehicleGroup.make,
          variant: vehicleGroup.variant,
          countryId: "",
          requestType: "",
          authId: "",
          menuTypeId: "",
          setCategory: "package"
        }
      },
      response => {
        if (response && response.response) {
          const data = response.response;
          const sets = createSets(data.metaVehicleSets.metaVehicleSet);
          // toArrayOfLabelAndValue(data.metaVehicleSets.metaVehicleSet);
          const models = toArrayOfLabelAndValue(data.models.model);
          // const startAndEndYear = toArrayOfLabelAndValue(data.years.year);
          const startYear = toArrayOfLabelAndValue(data.years.year);
          // startYear.splice(0, 0, { label: "NONE", value: "NONE" });
          const endYear = toArrayOfLabelAndValue(data.years.year);
          // endYear.splice(0, 0, { label: "NONE", value: "NONE" });
          const years = toArrayOfLabelAndValue(data.years.year);
          // years.splice(0, 0, { label: "NONE", value: "NONE" });
          const trims = toArrayOfLabelAndValue(data.trims.trim);
          const engineTypes = toArrayOfLabelAndValue(
            data.engineTypes.engineType
          );
          const engineSizes = toArrayOfLabelAndValue(
            data.engineSizes.engineSize
          );
          const driveTypes = toArrayOfLabelAndValue(data.driveTypes.driveType);
          const transmissionTypes = toArrayOfLabelAndValue(
            data.transmissionTypes.transmissionType
          );
          const bodyTypeAndFuelTypes = this.createBodyAndFuelTypes(data);
          const { bodyTypes } = bodyTypeAndFuelTypes;
          const { fuelTypes } = bodyTypeAndFuelTypes;
          const criteriaOptionsMap = {
            sets,
            models,
            // startAndEndYear,
            startYear,
            endYear,
            years,
            trims,
            engineTypes,
            engineSizes,
            driveTypes,
            transmissionTypes,
            bodyTypes,
            fuelTypes
          };
          // console.log("criteriaOptionsMap", criteriaOptionsMap);
          metaVehicleDimensionsByMake[metaVehicalFilter.make] =
            criteriaOptionsMap;
          if (this.vehicleGroupFormRef) {
            this.vehicleGroupFormRef.setMetaVehicleFilter(
              metaVehicalFilter,
              criteriaOptionsMap
            );
          }
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  loadVehicleGroup = vehicleGroup => {
    const { dealerCode, make, metaVehicleFilterId } = vehicleGroup;
    const metaVehcileFilter = { dealerCode, make, metaVehicleFilterId };
    this.createVehicleGroupCriteria(vehicleGroup, metaVehcileFilter);
  };

  createVehicleGroupCriteria = (vehicleGroup, metaVehicleFilter) => {
    if (this.vehicleGroupFormRef) {
      this.loadMetaVehicleDimensionsByMake(vehicleGroup, metaVehicleFilter);
    }
    // this.setState({ metaVehicleFilter });
  };

  createBodyAndFuelTypes = data => {
    const list = data.detailAttributes
      ? data.detailAttributes.detailAttribute
      : null;
    let bodyTypes = [];
    let fuelTypes = [];
    if (list) {
      list.forEach(item => {
        const value = item.content;
        if (item["columnName"] === "BODY_TYPE_NAME") {
          bodyTypes.push({ label: value, value });
        }
        if (item["columnName"] === "FUEL_TYPE") {
          fuelTypes.push({ label: value, value });
        }
      });
    } else {
      if (data.bodyTypes) {
        bodyTypes = data.bodyTypes;
      }
      if (data.fuelTypes) {
        fuelTypes = data.fuelTypes;
      }
    }
    return { bodyTypes, fuelTypes };
  };

  render() {
    const { sortedVehicleGroups } = this.props;
    const vehicleGroupForm = (
      <VehicleGroupForm
        ref={this.setVehicleGroupFormRef}
        className="xmm-create-vehicle-root"
        sortedVehicleGroups={sortedVehicleGroups}
      />
    );
    return (
      <React.Fragment>
        <ModalDialog
          htmlId="CreateVehicleModal"
          show={this.props.show}
          backdrop={"static"}
          className="xmm-create-vehicle-group-modal xmm-large-header"
          header={<ModalDialog.Title>{this.props.title}</ModalDialog.Title>}
          onHide={this.hide}
        >
          {vehicleGroupForm}
        </ModalDialog>
      </React.Fragment>
    );
  }
}

CreateVehicleGroupModal.propTypes = {
  show: PropTypes.bool,
  dealerCode: PropTypes.string,
  make: PropTypes.string,
  variant: PropTypes.string,
  sortedVehicleGroups: PropTypes.array,
  children: PropTypes.object,
  title: PropTypes.string,
  closeModal: PropTypes.func
};
