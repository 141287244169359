/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Alert from "@cx/ui/Alert";
import Card from "@cx/ui/Card";
import Button from "@cx/ui/Button";
import SelectInput from "@cx/ui/SelectInput";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { AppContext } from "../../../components/app-context";
import {
  isArrayExist,
  findRecord,
  doesEmpty
} from "../../../commonUtil/utils/object";
import { isEmptyString } from "../../../commonUtil/utils/string";
import { injectIntl } from "react-intl";
import { formatDateTimezone } from "../../../commonUtil/utils/date";
import Popover from "@cx/ui/Popover";
import { Link } from "react-router-dom";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";

class Dashboard extends React.Component {
  static propTypes = {
    onNotifyClick: PropTypes.func
  };
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.onGetDealerVariantChanges = this.onGetDealerVariantChanges.bind(this);
    this.onMakeChange = this.onMakeChange.bind(this);
    this.onNotifyClick = this.onNotifyClick.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);

    this.state = {
      make: context.dashboard.make,
      variantList: [],
      notification: this.getDefaultVariant(),
      allMakesNotification: this.getDefaultVariant(),
      showMask: true, // {false - hide masking}
      // This holds selected Make record
      selectedMake: {
        pricingMethod: "",
        make: "all",
        variant: "",
        dealerCode: context.dealerCode
      },
      showBanner: false,
      bannerText: "",
      pricingMethod: "",
      allMakesPricingMethod: "",
      lastPublishMsg: context.dashboard.lastPublishMsg
    };
  }

  componentDidMount() {
    window.addEventListener(
      "getDealerVariantChanges",
      this.onGetDealerVariantChanges,
      false
    );
    const { rawDealerVariantChanges } = this.context;

    if (rawDealerVariantChanges) {
      const { makelist } = this.context;
      // Note: First time, context.makelist[] is not ready. we use App.js calling loadCatalogNotifications() to avoid race condition,
      // Then below rest API called only when dashboard is revisited.
      if (makelist.length !== 0) {
        this.getAllMakesNotifications();
      } else {
        setTimeout(() => {
          this.setState({ showMask: false });
        }, 0);
      }
    }
  }

  componentWillUnmount() {
    const { make, lastPublishMsg } = this.state;
    const dashboard = {
      make,
      lastPublishMsg
    };
    this.context.setDashboardState(dashboard);

    window.removeEventListener(
      "getDealerVariantChanges",
      this.onGetDealerVariantChanges,
      false
    );
  }
  // This method called from App.js
  onGetDealerVariantChanges = () => {
    this.tranformResponse();
  };

  tranformResponse() {
    const { allMakesPricingMethod, rawDealerVariantChanges } = this.context;
    this.updateLocalState(rawDealerVariantChanges);
    this.setState({
      pricingMethod: allMakesPricingMethod,
      allMakesPricingMethod
    });
  }
  // This API called when Dashboard is revisited
  getAllMakesNotifications() {
    const { dealerCode, updateRawDealerVariantChanges } = this.context;
    const reqParams = {
      make: "",
      dealerCode,
      variant: ""
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getDealerVariantChanges",
        method: "get",
        data: {},
        params: reqParams
      },
      response => {
        this.updateLocalState(response);
        const list = this.filterMakeByPricingMethod(this.context.allMakeList);
        const allMakesPricingMethod = list.length > 0 ? 1 : 0;
        this.setState({
          pricingMethod: allMakesPricingMethod,
          allMakesPricingMethod,
          showMask: false
        });
        updateRawDealerVariantChanges(allMakesPricingMethod, response);
        const { dashboard } = this.context;
        const { make } = dashboard;
        if (make !== "all") {
          this.findMake(make);
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }
  // Group all makes counts and set to single object (all Makes case) and set variant list to state
  updateLocalState = response => {
    const { localeStrings } = this.context;
    if (isArrayExist(response)) {
      const final = [];
      let totalPart = 0,
        totalFluid = 0,
        totalOperation = 0;
      let newPart = 0,
        changedPart = 0;
      let newFluid = 0,
        changedFluid = 0;
      let newOperation = 0,
        changedOperation = 0;
      const bannerMakes = [];
      let bannerText = "";
      let emptyPublishDates = 0;
      response.forEach(statusObj => {
        let lastPublishMsg = "";
        if (Object.keys(statusObj).length > 0) {
          statusObj.totalPartCount =
            statusObj.newPartCount + statusObj.partPriceChangeCount;
          statusObj.totalFluidCount =
            statusObj.newFluidCount + statusObj.fluidPriceChangeCount;
          statusObj.totalOperationCount =
            statusObj.newOperationCount + statusObj.changedOperationCount;
          // sum of (make level counts) here
          newPart += statusObj.newPartCount;
          changedPart += statusObj.partPriceChangeCount;
          newFluid += statusObj.newFluidCount;
          changedFluid += statusObj.fluidPriceChangeCount;
          newOperation += statusObj.newOperationCount;
          changedOperation += statusObj.changedOperationCount;

          totalPart += statusObj.totalPartCount;
          totalFluid += statusObj.totalFluidCount;
          totalOperation += statusObj.totalOperationCount;
          const showBanner = this.comparePublishDates(statusObj);
          if (showBanner) {
            bannerMakes.push(" " + statusObj.make.toString());
          }
          if (
            statusObj.hasOwnProperty("publishDate") &&
            !isEmptyString(statusObj.publishDate)
          ) {
            // if lastPublish is date string, Format as June 15, 2007 at 2:05am PDT
            lastPublishMsg = this.getFormattedDate(statusObj.publishDate);
          } else {
            emptyPublishDates++;
          }
          statusObj.lastPublishMsg = lastPublishMsg;
          final.push(statusObj);
        }
      });
      const allMakeStatus = {
        make: "all",
        variant: "",
        publishDate: "",
        variantVersionDate: "",
        lastPublishMsg: "",
        newPartCount: newPart,
        newDealerPartIds: [],
        partPriceChangeCount: changedPart,
        priceChangeDealerPartIds: [],
        newFluidCount: newFluid,
        newDealerFluidIds: [],
        fluidPriceChangeCount: changedFluid,
        priceChangeDealerFluidIds: [],
        newOperationCount: newOperation,
        newDealerServiceIds: [],
        changedOperationCount: changedOperation,
        changedDealerServiceIds: [],
        totalPartCount: totalPart,
        totalFluidCount: totalFluid,
        totalOperationCount: totalOperation
      };
      // console.log("All variant status", final);
      if (bannerMakes.length > 0) {
        const firstTxt = localeStrings["xmm.portal.dashboard.banner_1"];
        const lastTxt = localeStrings["xmm.portal.dashboard.banner_2"];
        bannerText = firstTxt + bannerMakes.toString() + lastTxt;
      }
      this.setState({
        showMask: false,
        variantList: final,
        allMakesNotification: allMakeStatus,
        notification: allMakeStatus,
        bannerText,
        showCounts: emptyPublishDates === 0 ? false : true,
        showBanner: bannerMakes.length > 0 ? true : false
      });
    } else {
      this.setState({ showMask: false });
    }
  };

  /* This method returns formatted date  */
  getFormattedDate(dateStr) {
    const { locale } = this.context;
    return formatDateTimezone(dateStr, true, locale);
  }
  filterMakeByPricingMethod(list) {
    const filterList = list.filter(function (obj) {
      return !doesEmpty(obj.pricingMethod) && obj.pricingMethod === 1;
    });
    console.log("How many makes has calci flow", filterList);
    return filterList;
  }
  onMakeChange = event => {
    const { name, value } = event.target;
    // load notifications from cache
    if (value && value !== "all") {
      this.setState({
        [name]: value,
        showMask: false
      });
      this.findMake(value);
    } else if (value === "all") {
      const { allMakeList } = this.context;
      this.setState(prevState => ({
        selectedMake: allMakeList[0],
        make: value,
        pricingMethod: prevState.allMakesPricingMethod,
        notification: prevState.allMakesNotification,
        lastPublishMsg: "",
        showMask: false
      }));
    }
  };
  /* This method to find make from All makes validate status object  */
  findMake(makeName) {
    const rawMakes = this.context.makelist;
    const property = "make";
    const selectedMake = findRecord(rawMakes, property, makeName);
    if (selectedMake && Object.keys(selectedMake).length > 0) {
      const found = this.getMakeNotificationFromCache(selectedMake.make);
      // console.log("findMake", selectedMake.make, found);
      this.setState(
        {
          selectedMake,
          make: selectedMake.make,
          pricingMethod: selectedMake.pricingMethod,
          notification: found,
          lastPublishMsg: found.lastPublishMsg,
          showMask: false
        },
        () => {
          if (selectedMake.make) {
            // callback if needed
          }
        }
      );
    }
  }
  getMakeNotificationFromCache(makeName) {
    const { variantList } = this.state;
    const found = findRecord(variantList, "make", makeName);
    if (found && Object.keys(found).length > 0) {
      return found;
    } else {
      return this.getDefaultVariant();
    }
  }
  /* Default notification used to set State */
  getDefaultVariant() {
    const data = {
      make: "",
      variant: "",
      publishDate: "",
      variantVersionDate: "",
      lastPublishMsg: "",
      newPartCount: 0,
      newDealerPartIds: [],
      partPriceChangeCount: 0,
      priceChangeDealerPartIds: [],
      newFluidCount: 0,
      newDealerFluidIds: [],
      fluidPriceChangeCount: 0,
      priceChangeDealerFluidIds: [],
      newOperationCount: 0,
      newDealerServiceIds: [],
      changedOperationCount: 0,
      changedDealerServiceIds: [],
      totalPartCount: 0,
      totalFluidCount: 0,
      totalOperationCount: 0
    };
    return data;
  }

  /* method returns list of ids (added/changed) */
  getIdList(store, fieldName) {
    let list = [];
    store.forEach(function (record, idx) {
      if (record && idx === 0) {
        list = record[fieldName];
      }
    });
    return list;
  }
  /* dispatch event to carry Ids of selected count */
  onNotifyClick = (count, page, variantType) => {
    // Dispatch event only when count exist
    if (count > 0) {
      const { make } = this.state;
      const dispatchState = {
        fromDashboard: true,
        make,
        variantType: variantType.toUpperCase(),
        page
      };
      // console.log("dispatch page", fieldName, dispatchState);
      // callback AppContext to update filters{} carried from dashboard
      this.context.updateVariantFilters(dispatchState);
      let parent = "";
      let node = "";
      if (page === "operations") {
        parent = page;
        node = page;
      } else if (page === "parts") {
        parent = "partslabor";
        node = "partspricing";
      } else if (page === "fluids") {
        // redirect to fluids when page is created
        parent = "partslabor";
        node = "partspricing";
      }
      this.launchModule(parent, node);
    }
  };
  launchModule = (parent, node) => {
    window.dispatchEvent(
      new CustomEvent("navMenuLinkChanged", {
        detail: { parent, node },
        bubbles: true,
        cancelable: true
      })
    );
  };

  // Testing click event
  onLinkClick = page => {
    console.log("link clicked", page);
  };

  /* Method returns true, to show/hide banner
       For Live/Staged dealer, if variantVersionDate > publishDate, then show banner and notification counts
       If publishDate is null, don't compare with variantVersionDate. then show banner but not notifications
  */
  comparePublishDates(record) {
    let showBanner = false;
    if (record.variantVersionDate && record.publishDate) {
      const versionDate = new Date(record.variantVersionDate);
      const publishDate = new Date(record.publishDate);
      if (versionDate.getTime() > publishDate.getTime()) {
        showBanner = true;
      } else if (versionDate.getTime() < publishDate.getTime()) {
        showBanner = false;
      } else if (versionDate.getTime() === publishDate.getTime()) {
        showBanner = false;
      }
    } else if (
      record.hasOwnProperty("publishDate") &&
      isEmptyString(record.publishDate)
    ) {
      showBanner = true;
    }
    return showBanner;
  }
  /* This renders table with make, publish date as popOver */
  renderTooltip() {
    const { localeStrings } = this.context;
    const rawMakes = this.state.variantList;
    const posts = rawMakes.map(post => {
      return (
        <tr className="ant-table-row" key={post.make}>
          <td>
            <div>{post.make}</div>
          </td>
          <td>
            <span>{post.lastPublishMsg}</span>
          </td>
        </tr>
      );
    });
    const main = (
      <div className="table-container">
        <table>
          <thead className="ant-table-thead">
            <tr>
              <th>{localeStrings["xmm.portal.common.make_lbl"]}</th>
              <th>{localeStrings["xmm.portal.dashboard.publish_lbl"]}</th>
            </tr>
          </thead>
          <tbody>{posts}</tbody>
        </table>
      </div>
    );
    return main;
  }
  renderDashboard() {
    const { allMakeList, localeStrings } = this.context;
    const { notification, lastPublishMsg, make, pricingMethod } = this.state;
    const pricingFlow =
      doesEmpty(pricingMethod) || pricingMethod === 1 ? true : false;
    const clsTable = pricingFlow ? "tg" : "tg";
    const tipContent = this.renderTooltip();
    let publishWidget = null;
    let helpText = "";
    if (make !== "all" && lastPublishMsg) {
      publishWidget = (
        <div className="xmm-help-text">
          <span>
            {localeStrings["xmm.portal.dashboard.last_publish_lbl"]}
            &nbsp;
          </span>
          {lastPublishMsg || " "}
        </div>
      );
      helpText = (
        <span>
          {" "}
          {make}{" "}
          <span> {localeStrings["xmm.portal.dashboard.catalog_msg"]} </span>
        </span>
      );
    } else if (make === "all") {
      publishWidget = (
        <Popover
          htmlId="tooltipLink"
          trigger={["click", "outsideClick"]}
          className="xmm-publish-popover"
          popoverContent={tipContent}
        >
          <Button
            htmlId="ButtonLink"
            buttonStyle="link"
            className="xmm-string-link"
          >
            {localeStrings["xmm.portal.dashboard.show_last_publish_lbl"]}
          </Button>
        </Popover>
      );
      helpText = (
        <span> {localeStrings["xmm.portal.dashboard.catalog_msg"]} </span>
      );
    }
    /* Hide banner for ALL makes selection */
    let banner = null;
    if (this.state.showBanner) {
      banner = (
        <Alert
          htmlId="versionBanner"
          type={"warning"}
          displayCloseButton={false}
        >
          {this.state.bannerText}
        </Alert>
      );
    }
    // Add LINK to Count DOM; to redirect to other pages
    let partsAdded = (
      <div
        className={
          notification.newPartCount > 0
            ? "parts-added notif-number hand"
            : "parts-added notif-number hand-disabled"
        }
      >
        <span className="tg-bold"> {notification.newPartCount}</span>
        <br />
        <span>{localeStrings["xmm.portal.dashboard.added_lbl"]}</span>
      </div>
    );
    if (notification.newPartCount > 0)
      partsAdded = (
        <Link
          to={{
            pathname: "/partspricing",
            search: window.location.search,
            state: {
              variantType: "ADDED",
              fromDashboard: true,
              notification
            }
          }}
          onClick={() =>
            this.onNotifyClick(
              notification.newPartCount,
              "parts",
              "added",
              "newPartCount"
            )
          }
        >
          <div
            className={
              notification.newPartCount > 0
                ? "parts-added notif-number hand"
                : "parts-added notif-number hand-disabled"
            }
          >
            <span className="tg-bold"> {notification.newPartCount}</span>
            <br />
            <span>{localeStrings["xmm.portal.dashboard.added_lbl"]}</span>
          </div>
        </Link>
      );

    let partsChanged = (
      <div
        className={
          notification.partPriceChangeCount > 0
            ? "parts-changed notif-number hand"
            : "parts-changed notif-number hand-disabled"
        }
      >
        <span className="tg-bold">{notification.partPriceChangeCount}</span>
        <br /> <span>{localeStrings["xmm.portal.dashboard.changed_lbl"]}</span>
      </div>
    );
    if (notification.partPriceChangeCount > 0)
      partsChanged = (
        <Link
          to={{
            pathname: "/partspricing",
            search: window.location.search,
            state: {
              variantType: "CHANGED",
              fromDashboard: true,
              notification
            }
          }}
          onClick={() =>
            this.onNotifyClick(
              notification.partPriceChangeCount,
              "parts",
              "changed",
              "partPriceChangeCount"
            )
          }
        >
          <div
            className={
              notification.partPriceChangeCount > 0
                ? "parts-changed notif-number hand"
                : "parts-changed notif-number hand-disabled"
            }
          >
            <span className="tg-bold">{notification.partPriceChangeCount}</span>
            <br />{" "}
            <span>{localeStrings["xmm.portal.dashboard.changed_lbl"]}</span>
          </div>
        </Link>
      );
    let fluidsAdded = (
      <div
        className={
          notification.newFluidCount > 0
            ? "fluids-added notif-number hand"
            : "fluids-added notif-number hand-disabled"
        }
      >
        <span className="tg-bold"> {notification.newFluidCount}</span>
        <br />
        <span>{localeStrings["xmm.portal.dashboard.added_lbl"]}</span>
      </div>
    );
    if (notification.newFluidCount > 0)
      fluidsAdded = (
        <Link
          to={{
            pathname: "/fluidspricing",
            search: window.location.search,
            state: {
              variantType: "ADDED",
              fromDashboard: true,
              notification
            }
          }}
          onClick={() =>
            this.onNotifyClick(
              notification.newFluidCount,
              "fluids",
              "added",
              "newFluidCount"
            )
          }
        >
          <div
            className={
              notification.newFluidCount > 0
                ? "fluids-added notif-number hand"
                : "fluids-added notif-number hand-disabled"
            }
          >
            <span className="tg-bold"> {notification.newFluidCount}</span>
            <br />
            <span>{localeStrings["xmm.portal.dashboard.added_lbl"]}</span>
          </div>
        </Link>
      );
    let fluidsChanged = (
      <div
        className={
          notification.fluidPriceChangeCount > 0
            ? "fluids-changed notif-number hand"
            : "fluids-changed notif-number hand-disabled"
        }
      >
        <span className="tg-bold">{notification.fluidPriceChangeCount}</span>
        <br /> <span>{localeStrings["xmm.portal.dashboard.changed_lbl"]}</span>
      </div>
    );
    if (notification.fluidPriceChangeCount > 0)
      fluidsChanged = (
        <Link
          to={{
            pathname: "/partspricing",
            search: window.location.search,
            state: {
              variantType: "CHANGED",
              fromDashboard: true,
              notification
            }
          }}
          onClick={() =>
            this.onNotifyClick(
              notification.fluidPriceChangeCount,
              "fluids",
              "changed",
              "fluidPriceChangeCount"
            )
          }
        >
          <div
            className={
              notification.fluidPriceChangeCount > 0
                ? "fluids-changed notif-number hand"
                : "fluids-changed notif-number hand-disabled"
            }
          >
            <span className="tg-bold">
              {notification.fluidPriceChangeCount}
            </span>
            <br />{" "}
            <span>{localeStrings["xmm.portal.dashboard.changed_lbl"]}</span>
          </div>
        </Link>
      );
    let operationAdded = (
      <div
        className={
          notification.newOperationCount > 0
            ? "ops-added notif-number hand"
            : "ops-added notif-number hand-disabled"
        }
        onClick={() => this.onLinkClick("operations")}
      >
        <span className="tg-bold"> {notification.newOperationCount}</span>{" "}
        <br /> <span>{localeStrings["xmm.portal.dashboard.added_lbl"]}</span>
      </div>
    );
    if (notification.newOperationCount > 0)
      operationAdded = (
        <Link
          to={{
            pathname: "/operations",
            search: window.location.search,
            state: { variantType: "ADDED", fromDashboard: true, notification }
          }}
          className=""
          onClick={() =>
            this.onNotifyClick(
              notification.newOperationCount,
              "operations",
              "added",
              "newOperationCount"
            )
          }
        >
          <div
            className={
              notification.newOperationCount > 0
                ? "ops-added notif-number hand"
                : "ops-added notif-number hand-disabled"
            }
          >
            <span className="tg-bold"> {notification.newOperationCount}</span>{" "}
            <br />{" "}
            <span>{localeStrings["xmm.portal.dashboard.added_lbl"]}</span>
          </div>
        </Link>
      );
    let operationChanged = (
      <div
        className={
          notification.changedOperationCount > 0
            ? "ops-changed notif-number hand"
            : "ops-changed notif-number hand-disabled"
        }
        onClick={() => this.onLinkClick("operations")}
      >
        <span className="tg-bold"> {notification.changedOperationCount}</span>{" "}
        <br /> <span>{localeStrings["xmm.portal.dashboard.changed_lbl"]}</span>
      </div>
    );
    if (notification.changedOperationCount > 0)
      operationChanged = (
        <Link
          to={{
            pathname: "/operations",
            search: window.location.search,
            state: {
              variantType: "CHANGED",
              fromDashboard: true,
              notification
            }
          }}
          className=""
          onClick={() =>
            this.onNotifyClick(
              notification.changedOperationCount,
              "operations",
              "changed",
              "changedOperationCount"
            )
          }
        >
          <div
            className={
              notification.changedOperationCount > 0
                ? "ops-changed notif-number hand"
                : "ops-changed notif-number hand-disabled"
            }
          >
            <span className="tg-bold">
              {" "}
              {notification.changedOperationCount}
            </span>{" "}
            <br />{" "}
            <span>{localeStrings["xmm.portal.dashboard.changed_lbl"]}</span>
          </div>
        </Link>
      );
    let maskWidget = null;
    if (this.state.showMask) {
      maskWidget = (
        <div className="xmm-loading-mask">
          <div className="xmm-loading-label"> {""} </div>
          <LoadingIndicator htmlId="dashboardMask" size="large" />
        </div>
      );
    }

    const tableHeader = pricingFlow ? (
      <tr className="main-row hand-disabled">
        <td className="tg-second tg-bold" colSpan="2">
          <div className="notif-number">{notification.totalPartCount}</div>
          <div>{localeStrings["xmm.portal.dashboard.part_update_lbl"]}</div>
        </td>
        <td className="tg-second tg-bold" colSpan="2">
          <div className="notif-number"> {notification.totalFluidCount}</div>
          <div>{localeStrings["xmm.portal.dashboard.fluid_update_lbl"]}</div>
        </td>
        <td className="tg-second tg-bold" colSpan="2">
          <div className="notif-number">
            {" "}
            {notification.totalOperationCount}
          </div>
          <div>
            {localeStrings["xmm.portal.dashboard.operation_update_lbl"]}
          </div>
        </td>
      </tr>
    ) : (
      <tr className="main-row hand-disabled">
        <td className="tg-second tg-bold" colSpan="12">
          <div className="notif-number">
            {" "}
            {notification.totalOperationCount}
          </div>
          <div>
            {localeStrings["xmm.portal.dashboard.operation_update_lbl"]}
          </div>
        </td>
      </tr>
    );
    const tableContent = pricingFlow ? (
      <tr>
        <td className="tg-second">{partsAdded}</td>
        <td className="tg-second">{partsChanged}</td>
        <td className="tg-second">{fluidsAdded}</td>
        <td className="tg-second">{fluidsChanged}</td>
        <td className="tg-second">{operationAdded}</td>
        <td className="tg-second">{operationChanged}</td>
      </tr>
    ) : (
      <tr>
        <td className="tg-second" colSpan="5" />
        <td className="tg-second">{operationAdded}</td>
        <td className="tg-second no-border">{operationChanged}</td>
        <td className="tg-second" colSpan="5" />
      </tr>
    );
    const notifyWidget = (
      <div className="dashboard-list-body">
        <Card htmlId="dashboardCard" header={helpText}>
          <table className={clsTable}>
            <tbody>
              {tableHeader}
              {tableContent}
            </tbody>
          </table>
        </Card>
      </div>
    );

    return (
      <div className="xmm-center-container">
        <h3>{localeStrings["xmm.portal.nav.dashboard"]}</h3>
        <div>
          <div>{banner}</div>
          <Row>
            <Col xs={3} md={3}>
              <SelectInput
                htmlId="makeSelect"
                label={localeStrings["xmm.portal.common.make_lbl"]}
                name="make"
                className="xmm-left-label"
                layout="horizontal"
                displayDeselectOption={false}
                displayPlaceholder={false}
                placeholder="Select"
                onChange={this.onMakeChange}
                options={allMakeList}
                value={this.state.make}
                maxHeight={200}
              />
            </Col>
            <Col xs={9} md={9}>
              <div className="float-right">{publishWidget}</div>
            </Col>
          </Row>
          <div>
            {maskWidget}
            {notifyWidget}
          </div>
        </div>
      </div>
    );
  }
  renderDashboardForAny() {
    const { localeStrings } = this.context;
    return (
      <div className="xmm-center-container">
        <h3>{localeStrings["xmm.portal.nav.dashboard"]}</h3>
        <Card>{localeStrings["xmm.portal.dashboard.any_only_text"]}</Card>
      </div>
    );
  }
  render() {
    const { makelist } = this.context;
    return makelist.length === 0
      ? this.renderDashboardForAny()
      : this.renderDashboard();
  }
}

export default injectIntl(Dashboard);

// Dashboard.propTypes = {
//   intl: intlShape.isRequired
// };
/* eslint-enable no-console */
