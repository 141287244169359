import React, { useState, useRef, useEffect, useCallback } from "react";
import FilterListIcon from "@cx/ui/Icons/IconFilterList";
import SearchIcon from "@cx/ui/Icons/IconSearch";
import { quickFilterPages } from "../../constants/quick-filters.constants";
import "./SearchServicesModalHeader.scss";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import FilterMenu from "../filter-menu/FilterMenu";
import isEmpty from "lodash/isEmpty";

const SearchServicesModalHeader = () => {
  const searchQueryInput = useRef();
  const { state, dispatch } = useServiceSearchContext();
  const { serviceFilterBy, serviceSearchQuery } = state;
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  useEffect(() => {
    searchQueryInput.current.focus();
  }, []);

  useEffect(() => {
    if (searchQuery !== serviceSearchQuery) {
      setSearchQuery(serviceSearchQuery);
    }
  }, [serviceSearchQuery, searchQuery]);

  const handleFilterClick = () => {
    setShowFilterMenu(!showFilterMenu);
  };
  // @note: workaround - always reset quick filter to TOP_SERVICES when search word is entered or cleared
  const handleSearchQueryChange = event => {
    const word = event.target.value;
    setSearchQuery(word);
    dispatch({
      type: Actions.SET_CHANGE_SEARCH_QUERY,
      payload: word
    });
    dispatch({
      type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
      payload: quickFilterPages.TOP_SERVICES
    });
  };
  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  };
  const handlePaste = event => {
    event.preventDefault();
    const pasteTxt = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    setSearchQuery(pasteTxt);
    dispatch({
      type: Actions.SET_CHANGE_SEARCH_QUERY,
      payload: pasteTxt
    });
    dispatch({
      type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
      payload: quickFilterPages.TOP_SERVICES
    });
  };
  // @note: top services will be landing page when clear button clicked
  const handleClearClick = () => {
    setSearchQuery("");
    dispatch({
      type: Actions.SET_CHANGE_SEARCH_QUERY,
      payload: ""
    });
    dispatch({
      type: Actions.SET_SERVICE_FILTER_BY,
      payload: ""
    });
    dispatch({
      type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
      payload: quickFilterPages.TOP_SERVICES
    });
    searchQueryInput.current.focus();
  };

  const handleSearchIconClick = () => {
    searchQueryInput.current.focus();
  };

  const handleFilterMenuClose = useCallback(() => {
    setShowFilterMenu(false);
  }, []);

  // @todo: callback upon category dropdown selection
  const handleFilterMenuSelectCategory = useCallback(() => {
    setSearchQuery("");
    dispatch({
      type: Actions.SET_CHANGE_SEARCH_QUERY,
      payload: ""
    });
    setShowFilterMenu(false);
    // @todo: always launch TOP Services quick filter
    dispatch({
      type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
      payload: quickFilterPages.TOP_SERVICES
    });
  }, [dispatch]);

  const showClear = searchQuery.length > 0 || !isEmpty(serviceFilterBy);
  return (
    <div id="searchServicesModalHeaderContainer">
      <div id="filterContainer" onClick={() => handleFilterClick()}>
        <FilterListIcon id="filterIcon" />
        {!isEmpty(serviceFilterBy) && serviceFilterBy.name && (
          <span id="selectedFilter">{serviceFilterBy.name}</span>
        )}
      </div>
      <div className="horizontal-divider" />
      <div id="searchContainer">
        <SearchIcon id="searchIcon" onClick={handleSearchIconClick} />
        <input
          ref={searchQueryInput}
          id="searchQueryInput"
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          onKeyDown={e => handleKeyDown(e)}
          onPaste={handlePaste}
          autoComplete="off"
          placeholder="Search for services"
        />
        {showClear && (
          <button id="clearSearchQueryButton" onClick={handleClearClick}>
            Clear
          </button>
        )}
      </div>

      <FilterMenu
        show={showFilterMenu}
        onClose={handleFilterMenuClose}
        onSelectCategory={handleFilterMenuSelectCategory}
      />
    </div>
  );
};

export default SearchServicesModalHeader;
