/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../components/app-context";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import IconDelete from "@cx/ui/Icons/IconDelete";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import * as formvalidator from "../../formik/formvalidator";
import { isDifferentValue, sanitize } from "../../../commonUtil/utils/string";
import { makeSecureRestApi } from "../../../../src/api/xmmAxios";
import { xlate } from "../../../commonUtil/i18n/locales";

export class ManageSequenceModal extends Component {
  static propTypes = {
    show: PropTypes.bool
  };
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.sequenceModalDialogRef = React.createRef();
    this.onChangeInput = this.onChangeInput.bind(this);
    this.createNewForm = this.createNewForm.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.confirmDeleteSequence = this.confirmDeleteSequence.bind(this);
    this.initializeLocaleValues();
    this.state = {
      selectedItem: props.selectedSequence
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (this.props.selectedSequence !== prevProps.selectedSequence) {
      this.setState({ selectedItem: this.props.selectedSequence });
    }
    if (this.props.sequenceList !== prevProps.sequenceList) {
      this.setState({ sequenceList: this.props.sequenceList });
    }
  }

  initializeLocaleValues() {
    this.addBtn = xlate("xmm.portal.common.add_button");
    this.saveBtn = xlate("xmm.portal.common.save_button");
    this.sequencelbl = xlate("xmm.portal.ro_sequences.sequences");
    this.newSequencelbl = xlate("xmm.portal.ro_sequences.new_sequence_number");
    this.sequenceNamelbl = xlate("xmm.portal.ro_sequences.ro_sequence_name");
    this.sequenceNumberlbl = xlate(
      "xmm.portal.ro_sequences.starting_number"
    );
    this.infoDefaultSequence = xlate(
      "xmm.portal.ro_sequences.default_sequence_info"
    );
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deleteError = xlate("xmm.portal.errors.delete_interval");
  }

  hide = () => {
    if (this.state.dirty) {
      this.props.showDiscardEditPopup();
    } else {
      this.props.closeModal();
    }
  };
  selectSequence(sequence) {
    this.setState({ selectedItem: sequence });
    this.setSelectedItem(sequence);
  }
  onChangeInput = cxEvent => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(this.state.selectedItem[name], value)) {
      this.markDirty(name);
    }
    const { selectedItem } = this.state;
    selectedItem[name] = sanitize(value);
    const valid = this.isValidForm();
    this.setState({ selectedItem, valid });
  };
  onSequenceSaved(newSequence, addMode) {
    const { sequenceList } = this.state;
    if(addMode){
      this.props.loadSequenceNumbers();
      this.setState({sequenceList});
    this.props.setSelectedSequence(newSequence.sequenceName);
    }
    this.setSelectedItem(newSequence);

  }
  setSelectedItem = selectedItem => {
    // pre-selected interval case
    if (selectedItem) {
      // console.log("setSelectedItem", selectedItem);
      this.setState({ selectedItem });
    } else {      
      this.setState({ selectedItem: null, intervalRecord });
    }
    this.resetState(false);
  };
  resetState() {    
    this.setState({
      dirty: false,
      valid: false,
      errors: {}
    });
  }
  saveHandler() {
    const { selectedItem } = this.state;
    let url = "";
    let payload = "";
    let method = "";
    let addMode = false;
    if (selectedItem.dealerSequenceNumId) {
      url = "/ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/updateDlrSeqNum"
      payload = {
        dealerCode: selectedItem.dealerCode,
        sequenceName: selectedItem.sequenceName,
        startingNumber: parseInt(selectedItem.startingNumber),
        dealerSequenceNumId: selectedItem.dealerSequenceNumId
      };
      method = "put";
      addMode = false; 
    } else {
      url =
        "/ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/addDealerSequenceNum";
      payload = {
        dealerCode: selectedItem.dealerCode,
        sequenceName: selectedItem.sequenceName,
        startingNumber: parseInt(selectedItem.startingNumber),
        isDefault: selectedItem.isDefault
      };
      method = "post";
      addMode = true; 
    }
    makeSecureRestApi(
      {
        url,
        method,
        data: payload
      },
      () => {        
        this.onSequenceSaved(selectedItem, addMode);
        this.setState({valid: false, dirty: false});
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");      
        console.log(errMsg); 
      }
    );
  }
  confirmDeleteSequence() {
    const { selectedItem } = this.state;

    window.dispatchEvent(
      new CustomEvent("openDeleteSequenceModalFromManager", {
        detail: { selectedItem },
        bubbles: true,
        cancelable: true
      })
    );
  }
  createNewForm() {
    const { dealerCode } = this.context;
    const newSequence = {};
    newSequence.dealerCode = dealerCode;
    newSequence.sequenceName = "";
    newSequence.startingNumber = "";
    newSequence.isDefault = 0;
    this.setState({ selectedItem: newSequence });
  }

  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
    const { currentPage } = this.context;
    currentPage.dirty = dirty;
  }
  validate(fieldName) {
    const { sequenceName, startingNumber } = this.state.newSequence;
    const { errors } = this.state;
    // console.log("validate", fieldName, this.state.newRecord);

    if (!fieldName || fieldName === "sequenceName") {
      const value = sequenceName ? sequenceName.trim() : "";
      errors["name"] = !value ? this.nameRequiredError : "";
      if (errors["name"] === "") {
        errors["name"] = formvalidator.validateAlphaNumPunctuation(value);
      }
    }
    if (!fieldName || fieldName === "startingNumber") {
      errors["startingNumber"] = !startingNumber ? this.fieldRequiredError : "";
      if (startingNumber && (parseInt(startingNumber, 10) || 0) < 1) {
        errors["startingNumber"] = this.minIntervalLabel;
      }
    }
    const valid = this.isValidForm();
    this.setState({ valid, errors });
    return valid;
  }
  isValidForm() {
    const { sequenceName, startingNumber } = this.state.selectedItem;

    const nameValue = sequenceName ? sequenceName.trim() : "";
    if (!nameValue || formvalidator.validateAlphaNumPunctuation(nameValue)) {
      return false;
    }

    if (
      !startingNumber ||
      (parseInt(startingNumber) || 0) < 100001 ||
      (parseInt(startingNumber) || 0) > 9999999999
    ) {
      return false;
    }
    return true;
  }
  render() {
    const { selectedItem, valid, sequenceList } = this.state;
    const modalHeader = (
      <ModalDialog.Title>
        {this.props.title}{" "}
        <Button
          htmlId="addSequenceNumber"
          buttonStyle="secondary"
          onClick={this.createNewForm}
        >
          {this.addBtn}
        </Button>
      </ModalDialog.Title>
    );
    const sequencePanel = sequenceList ? (
      <div className="xmm-filter-list">
        {" "}
        <ul className="list-group">
          <b>{this.sequencelbl}</b>
          {sequenceList.length > 0 &&
            sequenceList.map(sequence => (
              <li
                onClick={() => {
                  this.selectSequence(sequence);
                }}
                className={getClassName(sequence, this.state.selectedItem)}
                key={sequence.sequenceName}
              >
                {sequence.sequenceName}&nbsp;
                <Popover
                  htmlId="popoverDefaultSequence"
                  popoverContent={this.infoDefaultSequence}
                  trigger={["click", "outsideClick"]}
                  position="right"
                >
                  <IconInfoOutline
                    htmlId="defaultSequenceInfo"
                    className={
                      sequence.isDefault ? "seq-hand-cursor" : "hide hand-cursor"
                    }
                  />
                </Popover>
              </li>
            ))}
        </ul>
      </div>
    ) : (
      ""
    );
    const sequenceForm = (
      <div className="xmm-manage-vehicle-root">
        <Grid htmlId="settingGrid">
          <Row>
            <Col xs={6} md={6}>
              <h3>
                { selectedItem.sequenceName !== ""
                  ? selectedItem.sequenceName
                  : this.newSequencelbl}
              </h3>
            </Col>
            <Col xs={6} md={6}>
              <div className="pull-right">
                {/* {statusBox} */}
                <Button
                  htmlId="saveSequenceAction"
                  buttonStyle="primary"
                  disabled={!valid}
                  onClick={this.saveHandler}
                >
                  {this.saveBtn}
                </Button>
                <Button
                  htmlId="deleteSequenceAction"
                  buttonStyle="primary"
                  style={{ paddingLeft: "12px", marginLeft: "2px" }}
                  disabled={
                    selectedItem.isDefault || selectedItem.sequenceName === ""
                  }
                  size="small"
                  onClick={this.confirmDeleteSequence}
                >
                  <IconDelete />
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              <TextInput
                htmlId="sequenceNameField"
                label={this.sequenceNamelbl}
                name="sequenceName"
                value={selectedItem.sequenceName}
                onBlur={this.onBlurInput}
                onChange={this.onChangeInput}
                maxLength={50}
                minLength={1}
                required
                disabled={selectedItem.isDefault}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              <NumericInput
                htmlId="sequenceNumberField"
                label={this.sequenceNumberlbl}
                name="startingNumber"
                onBlur={this.onBlur}
                onChange={this.onChangeInput}
                value={selectedItem.startingNumber || ""}
                // error={errors.rank}
                maxLength={10}
                minLength={6}
                required
              />

              <div className="xmm-modal-help-text">
                {xlate("xmm.portal.ro_sequences.ro_sequence_help_text")}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );

    return (
      <React.Fragment>
        <ModalDialog
          htmlId="ManageSequenceModal"
          show={this.props.show}
          onHide={this.hide}
          backdrop={"static"}
          header={modalHeader}
          className="xmm-full-modal xmm-large-header xmm-vehicle-group-modal"
          ref={this.sequenceModalDialogRef}
        >
          {sequencePanel}
          {sequenceForm}
          {this.props.children}
        </ModalDialog>
      </React.Fragment>
    );
  }
}

function getClassName(current, selected) {
  if (current.dealerSequenceNumId === selected.dealerSequenceNumId) {
    return "list-group-item active";
  }
  return "list-group-item";
}

export default ManageSequenceModal;

ManageSequenceModal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.object,
  title: PropTypes.string,
  selectedSequence: PropTypes.object,
  sequenceList: PropTypes.array,
  closeModal: PropTypes.func,
  loadSequenceNumbers: PropTypes.func,
  setSelectedSequence: PropTypes.func,
  showDiscardEditPopup: PropTypes.func,
  okAction: PropTypes.func
};
/* eslint-enable no-console */
