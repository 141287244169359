import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import { quickFilterPages } from "../../constants/quick-filters.constants";
import { useServiceSearchContext } from "../../state/service-search.context";
import "./QuickFilters.scss";

const QuickFilters = props => {
  const { onFilterClick, ...restProps } = props;
  const [quickFilters, setQuickFilters] = useState([]);
  const { state } = useServiceSearchContext();
  const { saveActionType } = state;

  useEffect(() => {
    let filters = [];
    if (props.filters.length) {
      filters = props.filters
        .map((filter, filterIndex) => ({
          ...filter,
          selected: filterIndex === 0 ? true : false
        }))
        .filter(filter => {
          let keepFilter = true;
          if (filter.hasOwnProperty("serviceCount")) {
            // @note: this logic always adds Top services to quick filters
            keepFilter =
              filter.id === quickFilterPages.TOP_SERVICES
                ? true
                : filter.serviceCount > 0;
          }

          return keepFilter;
        });
    }

    setQuickFilters(filters);
  }, [props.filters]);

  useEffect(() => {
    if (saveActionType === "SAVEANOTHER") {
      setQuickFilters(prevQuickFilters =>
        prevQuickFilters.map(filter => ({
          ...filter,
          selected: filter.id === "TOP_SERVICES"
        }))
      );
    }
  }, [saveActionType]);

  const handleFilterClick = filterId => {
    setQuickFilters(
      quickFilters.map(filter => ({
        ...filter,
        selected: filter.id === filterId ? true : false
      }))
    );
    onFilterClick(filterId);
  };

  return (
    <div id="quickFiltersContainer" {...restProps}>
      <div id="filterButtonsContainer">
        {props.showQuickFilters &&
          quickFilters.map(filter => (
            <Button
              htmlId={`filterButton-${filter.id}`}
              key={`filterButton-${filter.id}`}
              buttonStyle={filter.selected ? "primary" : "secondary"}
              onClick={() => handleFilterClick(filter.id)}
              size="sm"
              className="filter-button"
            >
              {filter.text}{" "}
              {filter.enableCount ? `(${filter.serviceCount})` : null}
            </Button>
          ))}
      </div>
    </div>
  );
};

QuickFilters.defaultProps = {
  filters: [],
  showQuickFilters: true,
  onFilterClick: () => {}
};

QuickFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      serviceCount: PropTypes.number
    })
  ).isRequired,
  showQuickFilters: PropTypes.bool,
  onFilterClick: PropTypes.func.isRequired
};

export default memo(QuickFilters);
