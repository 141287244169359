/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
import React from "react";

import NavItem from "react-bootstrap/lib/NavItem";
import { createBeamerConfigurationObjectString } from "./../../api/app.util";
import useExternalScriptTags from "../../hooks/useExternalScriptTags";
import IconNotification from "@cx/ui/Icons/IconNotification";

const Beamer = ({ dealer, user }) => {
  const beamerConfig = createBeamerConfigurationObjectString(dealer, user);
  useExternalScriptTags(
    "https://app.getbeamer.com/js/beamer-embed.js",
    beamerConfig
  );
  const navDropdownBeamer = (
    <div
      className="beamer-block beamerTrigger hand-cursor"
      data-beamer-keypress="true"
      id="callBeamer"
    >
      <IconNotification htmlId="bellIcon" />
    </div>
  );
  return (
    <>
      <NavItem
        id="beamer-nav"
        className="navbar-dropdown-icon"
        onToggle={() => {
          /* Do nothing. This prop must be declared to supress a warning. */
        }}
      >
        {navDropdownBeamer}
      </NavItem>
    </>
  );
};
export default Beamer;
