/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import SelectInput from "@cx/ui/SelectInput";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { findRecord } from "../../../../../commonUtil/utils/object";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import * as formvalidator from "../../../../formik/formvalidator";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../../utils/gtag-eventlist";
import { PayTypeGroupOptions } from "../../../../../constants/ModuleConstants";
class AddPayTypeFormPage extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onMakeChange = this.onMakeChange.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.initializeLocaleValues();
    const supportedMakes = context.makelist.map(m => {
      return { label: m.make, value: m.make };
    });
    supportedMakes.splice(0, 0, { label: "ANY", value: "ALL MAKES" });
    const payTypeGroupOptions = [...PayTypeGroupOptions];
    const dealerCode = context.dealerCode;
    const { user } = context;
    const { userName } = user;
    const newPayType = { ...props.rowRecord, dealerCode, modUser: userName };
    this.state = {
      newPayType,
      editOption: props.editOption,
      payTypes: props.payTypes,
      supportedMakes,
      payTypeGroupOptions,
      // This holds selected Make record
      selectedMake: {
        make: "ALL MAKES",
        dealerCode
      },
      dirty: false,
      valid: false,
      errors: {
        make: "",
        payCode: "",
        description: "",
        payTypeGroup: ""
      }
    };
  }

  componentDidMount() {}
  initializeLocaleValues() {
    this.selectLabel = xlate("xmm.portal.common.select_a_make_lbl");
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.makeErrorMsg = xlate("xmm.portal.errors.make_is_required");
    this.payCodeErrorMsg = xlate("xmm.portal.errors.pay_code_is_required");
    this.payTypeGroupErrorMsg = xlate(
      "xmm.portal.errors.pay_type_group_is_required"
    );
    this.descriptionErrorMsg = xlate(
      "xmm.portal.errors.description_is_required"
    );
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.duplicateRateCodeError = xlate(
      "xmm.portal.errors.miissing_labor_rate_code_or_description"
    );
  }
  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };
  // changeEvent for textfield
  onChange = (event, isValid, domEvent) => {
    const { name, value } = event.target;
    if (domEvent && domEvent.type === "blur") {
      this.onBlur(name);
      return;
    }
    const { newPayType } = this.state;
    if (
      domEvent &&
      domEvent.type !== "blur" &&
      isDifferentValue(newPayType[name], value)
    ) {
      this.setState(
        prevState => {
          return {
            newPayType: {
              ...prevState.newPayType,
              [name]: value
            }
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };
  onMakeChange = event => {
    const { name, value } = event.target;
    if (isDifferentValue(this.state.newPayType[name], value)) {
      this.setState(
        prevState => {
          return {
            newPayType: {
              ...prevState.newPayType,
              [name]: value
            },
            showMask: true // false - to show loading icon
          };
        },
        () => {
          this.markDirty(name, true);
          // this.findMake(value);
        }
      );
    }
  };
  /* This method returns make obj from make selection */
  findMake(makeName) {
    const rawMakes = this.state.supportedMakes;
    const property = "make";
    const selectedMake = findRecord(rawMakes, property, makeName);
    if (typeof selectedMake === "object") {
      this.setState(
        prevState => {
          const { errors } = prevState;

          return {
            newPayType: {
              ...prevState.newPayType,
              dealerLaborRateCodeId: "",
              make: selectedMake.make,
              dealerCode: selectedMake.dealerCode
            },
            showMask: true, // false - to show loading icon
            selectedMake,
            errors
          };
        },
        () => {
          // callback if any
        }
      );
    }
  }
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
  }
  isValidForm() {
    const { make, description, payCode, payTypeGroup } = this.state.newPayType;
    if (!make) {
      return false;
    }
    if (
      !description ||
      formvalidator.validateAlphaNumPunctuation(description)
    ) {
      return false;
    }
    if (!payCode || formvalidator.validateAlphaNumPunctuation(payCode)) {
      return false;
    }
    if (
      !payTypeGroup ||
      formvalidator.validateAlphaNumPunctuation(payTypeGroup)
    ) {
      return false;
    }
    return true;
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { make, description, payCode, payTypeGroup } = this.state.newPayType;
    const { errors } = this.state;
    const {payTypes} = this.state;
    if (!fieldName || fieldName === "make") {
      errors["make"] = !make ? this.makeErrorMsg : "";
    }
    if (!fieldName || fieldName === "payCode") {
      errors["payCode"] = !payCode ? this.payCodeErrorMsg : "";
      if (errors["payCode"] === "") {
        errors["payCode"] = formvalidator.validateAlphaNumPunctuation(payCode);
      }

      payTypes.forEach((payType) => {
        const payTypeMake = payType.make
        const payTypePayCode = payType.payCode
        if (make === payTypeMake && payCode === payTypePayCode){
          errors["payCode"] = "Pay Code already exists for this make. Please try again."
        }
      });
    }

    if (!fieldName || fieldName === "description") {
      errors["description"] = !description ? this.descriptionErrorMsg : "";
      if (errors["description"] === "") {
        errors["description"] =
          formvalidator.validateAlphaNumPunctuation(description);
      }
    }
    if (!fieldName || fieldName === "payTypeGroup") {
      errors["payTypeGroup"] = !payTypeGroup ? this.payTypeGroupErrorMsg : "";
      if (errors["payTypeGroup"] === "") {
        errors["payTypeGroup"] =
          formvalidator.validateAlphaNumPunctuation(payTypeGroup);
      }
    }


    const valid = this.isValidForm();
    this.setState({ valid, errors });
    return valid;
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  isDefaultPayCode(payTypes, newPayType) {
    if (!payTypes) {
      return true;
    }
    const payTypesByMake = payTypes.filter(
      p =>
        p.make === newPayType.make && p.payTypeGroup === newPayType.payTypeGroup
    );
    return payTypesByMake.length === 0;
  }
  getAddPayload() {
    const { newPayType, payTypes } = this.state;
    if (this.isDefaultPayCode(payTypes, newPayType)) {
      return { ...newPayType, defaultPayType: 1 };
    }
    return newPayType;
  }
  foundDuplicates() {
    const datalist = this.state.payTypes;
    const { make, payCode, description, payTypeGroup } = this.state.newPayType;
    let i;
    for (i = 0; i < datalist.length; i++) {
      const s = datalist[i];
      if (
        s.make === make &&
        (s.payCode.toUpperCase() === payCode.toUpperCase() ||
          s.description.toUpperCase() === description.toUpperCase() ||
          s.payTypeGroup.toUpperCase() === payTypeGroup.toUpperCase())
      ) {
        return true;
      }
    }
    return false;
  }
  // TODO - first validate duplicates for code, description per selected make; then proceed
  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors } = this.state;
    const data = this.getAddPayload();
    // if (this.foundDuplicates()) {
    //   this.updateStatusBox(this.duplicateRateCodeError, "warning", false);
    //   return;
    // }
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      // toast.warning(this.correctInvalidFielsError);
      this.updateStatusBox(this.correctInvalidFielsError, "warning", false);
      return;
    }
    const url = "/ops/proxyapi/ddsproxy/rest/table/dealerPayType?_method=post";
    // const restEndPoint = "/ops/rest-db/addTableData/paytypes";
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if (response && response.success) {
          this.updateStatusBox(this.savedMsg, "success", true);
          // callback grid to add record
          const { dealerPayType } = response;
          this.props.updatePayTypeAfterSave(dealerPayType);
          this.setState({ dirty: false, valid: false }, () => {
            setTimeout(() => {
              this.props.hideSlider();
            }, 800);
          });
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    gtmEvents.gtmTrackEvent("xmm.parts&labor.paytypes.save");
    // GTM - push click event to dataLayer
    // gtmEvents.gtmTrackEvent("xmm.laborrates.add_rate_click");
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    let editSection = null;
    const msgSection = null;
    const { newPayType, valid, errors, supportedMakes, payTypeGroupOptions } =
      this.state;
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    const isValid = valid;
    if (this.props.editForm) {
      // show form when edit clicked or add clicked
      editSection = (
        <React.Fragment>
          <div className="xmm-tab-bar">
            {statusBox} <span className="xmm-msg">{msgSection}</span>
            <Button
              htmlId="saveAction"
              buttonStyle="primary"
              disabled={!isValid}
              onClick={this.saveHandler}
            >
              {this.saveLabel}
            </Button>
          </div>
          <Card htmlId="addPayTypeId">
            <div className="comment-form">
              <Grid htmlId="settingGrid">
                <Row className="show-grid">
                  <Col xs={4} md={4}>
                    <span className="text-float-right">
                      {this.context.localeStrings["xmm.portal.grid.make"]}{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <SelectInput
                      htmlId="make"
                      label={this.context.localeStrings["xmm.portal.grid.make"]}
                      name="make"
                      placeholder={this.selectLabel}
                      displayDeselectOption={false}
                      onChange={this.onMakeChange}
                      displayLabel={false}
                      options={supportedMakes}
                      value={newPayType.make}
                      error={errors.make}
                      maxHeight={200}
                    />
                  </Col>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {
                        this.context.localeStrings[
                          "xmm.portal.pay_types.pay_code"
                        ]
                      }{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="payCode"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.parts_labor.pay_code"
                        ]
                      }
                      name="payCode"
                      maxLength={50}
                      minLength={1}
                      required
                      value={newPayType.payCode}
                      onChange={this.onChange}
                      error={errors.payCode}
                      displayLabel={false}
                      // getError={validateFieldError}
                    />
                  </Col>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {
                        this.context.localeStrings[
                          "xmm.portal.grid.description"
                        ]
                      }{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="description"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.grid.description"
                        ]
                      }
                      name="description"
                      required
                      value={newPayType.description}
                      maxLength={50}
                      minLength={1}
                      onChange={this.onChange}
                      error={errors.description}
                      displayLabel={false}
                      // getError={validateFieldError}
                    />
                  </Col>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {
                        this.context.localeStrings[
                          "xmm.portal.pay_types.pay_type_group"
                        ]
                      }{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <SelectInput
                      htmlId="payTypeGroup"
                      name="payTypeGroup"
                      options={payTypeGroupOptions}
                      value={newPayType.payTypeGroup}
                      hasError={!!errors.payTypeGroup}
                      errorMessage={this.state.errors.payTypeGroup}
                      onChange={this.onChange}
                      displayLabel={false}
                      displayDeselectOption={false}
                    />
                  </Col>
                </Row>
              </Grid>
            </div>
          </Card>
        </React.Fragment>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default AddPayTypeFormPage;

AddPayTypeFormPage.propTypes = {
  rowRecord: PropTypes.object,
  operationName: PropTypes.string,
  editForm: PropTypes.bool,
  editOption: PropTypes.string,
  payTypes: PropTypes.array,
  hideSlider: PropTypes.func,
  updatePayTypeAfterSave: PropTypes.func
};
/* eslint-enable no-console */
