/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import IconMore from "@cx/ui/Icons/IconMore";
// import DropdownMenu from "@cx/ui/DropdownMenu";
// import DropdownMenuItem from "@cx/ui/DropdownMenuItem";
import Dropdown from "@cx/ui/Dropdown";
import { shouldShowContentPanelUp } from "../../../../../commonUtil/utils/menu";

export default class LaborRateCodeEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    // console.log("LaborRateCodeEditor", props);
    this.state = this.createInitialState(props);
  }

  componentDidMount() {
    this.afterGuiAttached();
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data } = props;
    return {
      dealerLaborCode: data,
      highlightAllOnFocus
    };
  }

  onClick = event => {
    console.log("LaborRateCodeEditor.onClick", event);
  };

  getValue() {
    return this.state.dealerLaborCode;
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      const menuItemsUp = shouldShowContentPanelUp(
        "#grid-wrapper",
        this.wrapperRef,
        60
      );
      this.setState({
        highlightAllOnFocus: false,
        menuItemsUp
      });
    }
  }

  isPopup() {
    return true;
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  shouldShowContentPanelUp() {
    const gridBoundingBox = document
      .querySelector("#grid-wrapper")
      .getBoundingClientRect();
    const menuBoundingBox = this.wrapperRef.getBoundingClientRect();
    // console.log("getBoundingClientRect", gridBoundingBox, selectorBoundingBox);
    const panelHeight = 60;
    // if bottom of panel is beyond bottom of the grid rows
    if (menuBoundingBox.bottom + panelHeight > gridBoundingBox.bottom) {
      if (menuBoundingBox.top - panelHeight > gridBoundingBox.top + 40) {
        // show menu items up
        return true;
      } else {
        // show menu itmes down
        return false;
      }
    }
    return false;
  }

  renderDealerRuleMoreButton = () => {
    const { parentHandle } = this.props;
    const { dealerLaborCode, menuItemsUp } = this.state;
    const { confirmDeleteLaborRate } = parentHandle;
    const { context } = parentHandle;
    const { localeStrings } = context;
    const deleteLaborRateText =
      localeStrings["xmm.portal.common.delete_button"];
    return (
      <Dropdown
        icon={<IconMore />}
        id="dealerLaborCodeActionBtn"
        htmlId="dealerLaborCodeActionBtn"
        name="dealerLaborCodeActionBtn"
        className="xmm-dotted-dropdown btn--icon"
        buttonStyle="link"
        displayCaret={false}
        size="small"
        dropup={menuItemsUp}
        defaultOpen={true}
        options={[
          {
            label: deleteLaborRateText,
            value: "deleteLaborRate",
            onSelect: () => {
              confirmDeleteLaborRate(dealerLaborCode);
              this.props.api.stopEditing();
            }
          }
        ]}
        pullRight
      />
    );
  };
  render() {
    const { dealerScheduleRule } = this.state;
    const dealerRuleButton =
      this.renderDealerRuleMoreButton(dealerScheduleRule);
    return (
      <React.Fragment>
        <div ref={this.setWrapperRef}>{dealerRuleButton}</div>
      </React.Fragment>
    );
  }
}

LaborRateCodeEditor.propTypes = {
  data: PropTypes.object,
  parentHandle: PropTypes.object
};
