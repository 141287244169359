/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import PriceRoundingRow from "./PriceRoundingRow";
import { AppContext } from "../../../../app-context";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";

class PriceRounding extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.roundingRows = [];
    this.state = {};
  }
  componentDidMount() {}

  // This handler calls its parent handler to autosave record field changes
  onUpdate = (catalog, name, payload) => {
    // console.log("PriceRound onUpdate", name, payload);
    this.props.onSave(catalog, name, payload, "priceRoundingRef");
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
  };
  callChildMethod = (msg, type, close, errorInTooltip) => {
    this.updateStatusBox(msg, type, close, errorInTooltip);
  };

  /* These Utils used for form validation style */
  updateStatusBox = (msg, type, close, errorInTooltip) => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  };
  render() {
    const { dealerCatalogs, localeStrings } = this.context;
    let resultTable = null;
    let catalogHtml = null;
    if (dealerCatalogs && dealerCatalogs.length !== 0) {
      catalogHtml = dealerCatalogs.map((record, index) => {
        return (
          <PriceRoundingRow
            key={"rounding-" + record.make}
            ref={ref => (this.roundingRows[index + 1] = ref)}
            catalog={record}
            onUpdate={this.onUpdate}
          />
        );
      });

      resultTable = (
        <Grid className="setting-table">
          <Row>
            <Col md={2} sm={2}>
              &nbsp;
            </Col>
            <Col md={10} sm={10} className="xmm-form-content xmm-form-header">
              <div className="xmm-input-col">
                {localeStrings["xmm.portal.settings.rounding_rules_lbl"]}
              </div>
              <div> &nbsp; </div>
            </Col>
          </Row>
          {catalogHtml}
        </Grid>
      );
    }
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    return (
      <Card htmlId="displayCard">
        <div className="settings-card-header">
          <div className="header-left">
            <h4>{localeStrings["xmm.portal.settings.price_rounding_lbl"]}</h4>
          </div>
          <span className="float-right">{statusHtml}</span>
        </div>
        {resultTable}
      </Card>
    );
  }
}

export default PriceRounding;

PriceRounding.propTypes = {
  onSave: PropTypes.func
};
