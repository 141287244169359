/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import Col from "@cx/ui/Col";
import IconSearch from "@cx/ui/Icons/IconSearch";
import Row from "@cx/ui/Row";
import TextInput from "@cx/ui/TextInput";
import Tooltip from "@cx/ui/Tooltip";
import { toast } from "@cx/ui/Toast";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import { PropTypes } from "prop-types";

import { makeSecureRestApi } from "../../../../api/xmmAxios";
import FindOpCodesDialog from "../../../../commonUtil/dialog/FindOpCodesDialog";
import {
  toAscii,
  toEmptyStringIfUndefined,
  isDifferentValue
} from "../../../../commonUtil/utils/string";
import { AppContext } from "../../../../components/app-context";
import { OperationContext } from "../../Operations/operation-context";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";

export default class IntegratedOperations extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.validateOpcode = this.validateOpcode.bind(this);

    this.state = {
      dmsOpcode: "",
      lastDmsOpcode: "",
      lastDefaultOpcode: "",
      defaultOpcode: "",
      enabled: false,
      enableCommonAirFilters: false,
      enableDisplayRepair: false,
      enableFullCommon: false,
      serviceId: "",
      serviceName: "",
      description: "",
      disableDeclinedApp: true,
      dirty: false,
      isValidDmsOpcode: false,
      isValidDefaultOpcode: false
    };
  }

  componentDidMount() {
    this.getDeclinedService();
    this.getDealerSettings();
  }

  updateState(response) {
    if (response && response.response && response.response.service) {
      this.setState({
        dmsOpcode: toEmptyStringIfUndefined(
          response.response.service.dmsOpcode
        ),
        lastDmsOpcode: toEmptyStringIfUndefined(
          response.response.service.dmsOpcode
        ),
        enabled: response.response.service.enabled === 1,
        serviceId: response.response.service.serviceId,
        serviceName: response.response.service.name,
        description: "",
        dirty: false,
        disableDeclinedApp:
          response.response.isDeclinedApplicable === "N" ? true : false
      });
      // this.toggleRef.current.setState({
      //   checked: response.response.service.enabled === 1
      // });
    } else {
      if (
        response &&
        response.response &&
        response.response.isDeclinedApplicable === "Y"
      ) {
        this.setState({
          dmsOpcode: "",
          lastDmsOpcode: "",
          enabled: true,
          serviceId: "",
          serviceName: "",
          description: "",
          dirty: false,
          disableDeclinedApp: false
        });
        // this.toggleRef.current.setState({
        //   checked: true
        // });
      } else {
        // if (this.toggleRef) {
        //   this.toggleRef.current.setState({
        //     checked: false
        //   });
        // }
      }
    }
    this.validateOpcode(this.state.dmsOpcode, false);
    this.validateOpcode(this.state.defaultOpcode, true);
  }

  getDeclinedService() {
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getDeclinedService",
        method: "get",
        data: {},
        params: { dealerCode }
      },
      response => {
        this.updateState(response);
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  getDealerSettings() {
    const { dealerCode } = this.context;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/table/dealerSettings",
        method: "get",
        params: { dealerCode }
      },
      response => {
        if (
          response &&
          response.length &&
          response[0].hasOwnProperty("defaultOpcode")
        ) {
          const defaultData =
            response && Array.isArray(response) && response.length
              ? toEmptyStringIfUndefined(response[0]["defaultOpcode"])
              : "";
          this.setState({
            defaultOpcode: defaultData,
            lastDefaultOpcode: defaultData
          });
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  editDeclinedService(checked) {
    const { dealerCode, localeStrings } = this.context;
    const { dmsOpcode } = this.state;
    const enabled = checked ? 1 : 0;
    // const opcode = toEmptyStringIfUndefined(dmsOpcode);
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/internaloeproxy/rest/internal/xmmservices/editDeclinedService",
        method: "get",
        data: {},
        params: { dealerCode, dmsOpcode, enabled }
      },
      response => {
        // console.log(response);
        this.updateState(response);
        this.setState({ lastDmsOpcode: dmsOpcode });

        toast.success(localeStrings["xmm.portal.common.successful_save"]);
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  updateDefaultOpCode() {
    const { dealerCode, localeStrings } = this.context;
    const { defaultOpcode } = this.state;
    // const opcode = toEmptyStringIfUndefined(dmsOpcode);
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/ddsproxy/rest/table/dealerSettings/${dealerCode}?_method=put`,
        method: "post",
        data: { defaultOpcode }
      },
      response => {
        // console.log(response);
        this.updateState(response);
        this.setState({ lastDefaultOpcode: defaultOpcode });
        toast.success(localeStrings["xmm.portal.common.successful_save"]);
      },
      error => {
        toast.error(error.message);
      }
    );
  }

  onClick = () => {
    const enabled = !this.state.enabled;
    this.setState({ dirty: true, enabled });
    this.editDeclinedService(enabled);
  };

  onClickEnableCommonAirFilters = () => {
    const enableCommonAirFilters = !this.state.enableCommonAirFilters;
    this.setState({ dirty: true, enableCommonAirFilters });
  };

  onBlur = () => {
    if (this.state.dirty && this.dmsOpCodeIsValid()) {
      this.editDeclinedService(this.state.enabled);
    }
  };
  onBlurDefaultOpCode = () => {
    if (this.state.dirty && this.defaultCodeIsValid()) {
      this.updateDefaultOpCode();
    }
  };

  onChangeDefaultOpCode = cxEvent => {
    const opCodeValue = cxEvent.target.value.trim();
    if (
      toEmptyStringIfUndefined(opCodeValue) !==
      toEmptyStringIfUndefined(this.state.defaultOpcode)
    ) {
      const defaultOpcode = toAscii(opCodeValue);
      this.setState({ dirty: true, defaultOpcode });
    }
  };
  onChange = cxEvent => {
    const dmsValue = cxEvent.target.value.trim();
    if (
      toEmptyStringIfUndefined(dmsValue) !==
      toEmptyStringIfUndefined(this.state.dmsOpcode)
    ) {
      const dmsOpcode = toAscii(dmsValue);
      this.setState({ dirty: true, dmsOpcode });
    }
  };

  validateOpcode = (dmsValue, dafaultOpcode) => {
    const { dealerCode } = this.context;
    const opcodeToValidate = dafaultOpcode ? dmsValue : dmsValue.split(";")[0];
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/ddsproxy/rest/proc/findDealerOpcodes?dealerCode=${dealerCode}&matchString=${opcodeToValidate}`,
        method: "get",
        params: {}
      },
      response => {
        let validOpcode = false;
        if (response && response.length !== 0) {
          const result = response.map(({ opcode }) => opcode.toLowerCase());
          validOpcode = result.indexOf(opcodeToValidate.toLowerCase()) !== -1;
        }
        if (dafaultOpcode) {
          this.setState({
            isValidDefaultOpcode: validOpcode
          });
        } else {
          this.setState({
            isValidDmsOpcode: validOpcode
          });
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  openOpcodeModal = () => {
    this.setState({ showOpCodeModal: true });
  };

  dmsOpCodeIsValid = () => {
    if (
      toEmptyStringIfUndefined(this.state.lastDmsOpcode) !==
        toEmptyStringIfUndefined(this.state.dmsOpcode) &&
      isDifferentValue(this.state.lastDmsOpcode, this.state.dmsOpcode)
    ) {
      return true;
    }
    return false;
  };

  defaultCodeIsValid = () => {
    if (
      toEmptyStringIfUndefined(this.state.lastDefaultOpcode) !==
        toEmptyStringIfUndefined(this.state.defaultOpcode) &&
      isDifferentValue(this.state.lastDefaultOpcode, this.state.defaultOpcode)
    ) {
      return true;
    }
    return false;
  };
  showInspectCard = (
    inspectToairFilters,
    repairsEnabled,
    fullCatalogEnabled
  ) => {
    let showInspectCard = "";
    if (
      inspectToairFilters === 0 &&
      repairsEnabled === 0 &&
      fullCatalogEnabled === 0
    ) {
      showInspectCard = "hide";
    }
    if (
      inspectToairFilters === 0 &&
      repairsEnabled === 1 &&
      fullCatalogEnabled === 0
    ) {
      showInspectCard = "hide-store-id";
    }
    return showInspectCard;
  };

  render() {
    const contextValue = {
      localeStrings: this.context.localeStrings,
      discardUnsavedChanges: this.context.discardUnsavedChanges,
      webKey: this.context.webKey,
      dealerCode: this.context.dealerCode,
      appContext: this.context // send appContent as props
    };
    const { dealer } = this.context;
    const {
      commonOpsTwoAirFiltersEnabled,
      commonOpsRepairsEnabled,
      commonOpsFullCatalogEnabled,
      storeId
    } = dealer;

    const { localeStrings } = this.context;
    const findOpCodesModal = (
      <FindOpCodesDialog
        showValidateCatalog={true}
        dealerCode={contextValue.dealerCode}
        serviceId={this.state.serviceId.toString()}
        internalName={this.state.serviceName}
        dmsOpcode={this.state.dmsOpcode}
        dmsDescription={this.state.description}
        localeStrings={contextValue.localeStrings}
        manualOpcodes={this.context.manualOpcodes}
        show={this.state.showOpCodeModal}
        disableBestMatch={true}
        closeDialog={() => {
          this.setState({ showOpCodeModal: false });
        }}
        setManualOpcodes={this.context.setManualOpcodes}
        setOpcodeValue={dmsOpcode => {
          if (this.state.optionSelected === 1) {
            this.onChange({ target: { value: dmsOpcode } });
            if (this.state.dirty && this.dmsOpCodeIsValid()) {
              this.editDeclinedService(this.state.enabled);
            }
          } else {
            this.onChangeDefaultOpCode({ target: { value: dmsOpcode } });
            if (this.state.dirty && this.defaultCodeIsValid()) {
              this.updateDefaultOpCode();
            }
          }
        }}
      />
    );
    const maskStyle = this.state.disableDeclinedApp ? "xmm-mask" : "";
    const declinedHeader =
      localeStrings["xmm.portal.integratedoperations.declined_services_lbl"];
    const defaultHeader =
      localeStrings["xmm.portal.integratedoperations.default_op_code_header"];
    const inspectIntegration =
      localeStrings[
        "xmm.portal.integratedoperations.inspect_integration_setting"
      ];

    const toolTipAirFilter =
      localeStrings["xmm.portal.integratedoperations.icon_text_air_filters"];
    const toolTipRepairOperation =
      localeStrings[
        "xmm.portal.integratedoperations.icon_text_global_repair_operation"
      ];
    const toolTipFullCommonCatalog =
      localeStrings["xmm.portal.integratedoperations.icon_text_common_catalog"];
    const clsInspect = this.showInspectCard(
      commonOpsTwoAirFiltersEnabled,
      commonOpsRepairsEnabled,
      commonOpsFullCatalogEnabled
    );
    const inspectWidget = (
      <div className="xmm-center-container">
        <Card
          htmlId="inspectIntegrationSettings"
          className={clsInspect}
          header={inspectIntegration}
        >
          <Row>
            <Col xs={12} md={12}>
              <div className="store-id">
                <span>Store ID: {storeId || ""}</span>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="xmm-help-text">
                {
                  "Operations & Pricing data can be shared with the Inspect application. Contact your perfomance consultant for more details!"
                }
              </div>
            </Col>
            <Row>
              <Col xs={2} md={2}>
                <div className="float-right">
                  <ToggleSwitch
                    htmlId="toggle-card"
                    onClick={this.onClickEnableCommonAirFilters}
                    defaultChecked={!!commonOpsTwoAirFiltersEnabled}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col xs={6} md={6}>
                <span>{"Common Air Filter Operations in Inspect"} </span>
                <Popover
                  htmlId="toolTipAirFilter"
                  name="toolTipAirFilter"
                  popoverContent={toolTipAirFilter}
                  position="right"
                  trigger={["click", "outsideClick"]}
                >
                  <IconInfoOutline className="info-blue" />
                </Popover>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2}>
                <div className="float-right">
                  <ToggleSwitch
                    htmlId="toggle-card"
                    defaultChecked={!!commonOpsRepairsEnabled}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col xs={6} md={6}>
                <span>{"Display Repair Operations in Catalog Manager"} </span>
                <Popover
                  htmlId="toolTipRepairOperation"
                  name="toolTipRepairOperation"
                  popoverContent={toolTipRepairOperation}
                  trigger={["click", "outsideClick"]}
                  position="right"
                >
                  <IconInfoOutline className="info-blue" />
                </Popover>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2}>
                <div className="float-right">
                  <ToggleSwitch
                    htmlId="toggle-card"
                    defaultChecked={!!commonOpsFullCatalogEnabled}
                    disabled={true}
                  />
                </div>
              </Col>
              <Col xs={6} md={6}>
                <span>
                  {"Full Common Catalog in Inspect"}
                  {"  "}
                </span>
                <Popover
                  htmlId="toolTipFullCommonCatalog"
                  name="toolTipFullCommonCatalog"
                  popoverContent={toolTipFullCommonCatalog}
                  trigger={["click", "outsideClick"]}
                  position="right"
                >
                  <IconInfoOutline className="info-blue" />
                </Popover>
              </Col>
            </Row>
          </Row>
        </Card>
      </div>
    );
    const validOpcodeIcon = (
      <Tooltip htmlId="tooltipValidOpcode" tooltipContent={"Valid Op Code"}>
        <i className="fas fa-check" />
      </Tooltip>
    );
    const InvalidOpcodeIcon = (
      <Tooltip htmlId="tooltipInvalidOpcode" tooltipContent={"Invalid Op Code"}>
        <i className="fas fa-exclamation-circle" />
      </Tooltip>
    );

    return (
      <div className="xmm-center-container">
        <OperationContext.Provider value={contextValue}>
          {findOpCodesModal}
          <h3>{localeStrings["xmm.portal.nav.integrated_operations"]}</h3>
          <Row>
            <Col md={12}>
              <Card
                htmlId="declinedServicesCard"
                header={declinedHeader}
                className={maskStyle}
              >
                <Row>
                  <Col xs={8} md={8}>
                    <div className="xmm-help-text">
                      {
                        localeStrings[
                          "xmm.portal.integratedoperations.provide_opcode_txt"
                        ]
                      }
                      <br />
                      {
                        localeStrings[
                          "xmm.portal.integratedoperations.opcode_shared_txt"
                        ]
                      }
                      <br />
                      {
                        localeStrings[
                          "xmm.portal.integratedoperations.multiple-opcode_txt"
                        ]
                      }
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div className="card-toggle">
                      <ToggleSwitch
                        htmlId="toggle-card"
                        onClick={this.onClick}
                        checked={this.state.enabled}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} md={2}>
                    <span className="float-right">
                      {localeStrings["xmm.portal.common.opcode"]}{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <div className="xmm-opcode-selector">
                      <TextInput
                        htmlId="dmsOpcode"
                        label="Op Code"
                        name="dmsOpcode"
                        displayLabel={false}
                        disabled={!this.state.enabled}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        value={this.state.dmsOpcode}
                        maxLength={50}
                        appendChild={
                          <Button
                            htmlId="dmsOpcodeAppendButton"
                            icon={<IconSearch htmlId="iconSearchForm" />}
                            buttonStyle="secondary"
                            onClick={() => {
                              this.setState({
                                showOpCodeModal: true,
                                optionSelected: 1
                              });
                            }}
                          />
                        }
                      />
                      <span>
                        {this.state.isValidDmsOpcode
                          ? validOpcodeIcon
                          : InvalidOpcodeIcon}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card htmlId="defaultOpCard" header={defaultHeader}>
                <Row>
                  <Col xs={12} md={12}>
                    <div className="xmm-help-text">
                      {
                        localeStrings[
                          "xmm.portal.integratedoperations.provide_default_opcode_txt"
                        ]
                      }
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2} md={2}>
                    <span className="float-right">
                      {localeStrings["xmm.portal.common.opcode"]}{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <div className="xmm-opcode-selector">
                      <TextInput
                        htmlId="defaultOpcode"
                        label="Default Op Code"
                        name="defaultOpcode"
                        displayLabel={false}
                        onBlur={this.onBlurDefaultOpCode}
                        onChange={this.onChangeDefaultOpCode}
                        value={this.state.defaultOpcode}
                        maxLength={50}
                        appendChild={
                          <Button
                            htmlId="defaultOpcodeAppendButton"
                            icon={<IconSearch htmlId="iconSearchForm" />}
                            buttonStyle="secondary"
                            onClick={() => {
                              this.setState({
                                showOpCodeModal: true,
                                optionSelected: 2
                              });
                            }}
                          />
                        }
                      />
                      <span>
                        {this.state.isValidDefaultOpcode
                          ? validOpcodeIcon
                          : InvalidOpcodeIcon}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {inspectWidget}
        </OperationContext.Provider>
      </div>
    );
  }
}
