/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import NumericInput from "@cx/ui/NumericInput";
import SelectInput from "@cx/ui/SelectInput";
import { doesEmpty } from "../../../../../commonUtil/utils/object";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../commonUtil/utils/string";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import NoPricingRow from "./NoPricingRow";
import { AppContext } from "../../../../app-context";
import {
  RoundToOptions,
  RoundToNone,
  RoundToCentsOnDollar,
  RoundToNextNickel,
  RoundToNextDime,
  RoundToNextDollar,
  RoundToNextTenDollars
} from "../../../../../constants/ModuleConstants";

class PriceRoundingRow extends React.Component {
  static contextType = AppContext;

  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.catalog !== prevState.catalog) {
      const { catalog } = nextProps;
      const packageRoundToCents = convertRoundCents(catalog);
      const derivedRoundingRule = convertRoundingRule(catalog);
      return {
        catalog,
        derivedRoundingRule,
        packageRoundToCents,
        showCents: derivedRoundingRule === RoundToCentsOnDollar ? true : false,
        valid: false,
        errors: {
          packageRoundToCents: "",
          derivedRoundingRule: ""
        }
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);
    this.onChangeRoundRule = this.onChangeRoundRule.bind(this);
    this.onChangeCents = this.onChangeCents.bind(this);
    this.onBlurCents = this.onBlurCents.bind(this);

    const { catalog } = props;
    const packageRoundToCents = convertRoundCents(catalog);
    const derivedRoundingRule = convertRoundingRule(catalog);
    this.state = {
      catalog,
      showCents: derivedRoundingRule === RoundToCentsOnDollar ? true : false,
      derivedRoundingRule,
      packageRoundToCents,
      valid: false,
      errors: {
        packageRoundToCents: "",
        derivedRoundingRule: ""
      }
    };
  }
  componentDidMount() {}

  // validation handler for cents field
  onBlurCents = event => {
    // NOTE: CX BUG - event.target.name is BLANK!!!
    const { value } = event.target;
    const { localeStrings } = this.context;
    this.setState(
      prevState => {
        const { errors } = prevState;
        if (!value) {
          errors.packageRoundToCents =
            localeStrings["xmm.portal.errors.required_field"];
        } else if ((parseInt(value, 10) || 0) < 1) {
          errors.packageRoundToCents =
            localeStrings["xmm.portal.settings.min_value_warn"] + "1";
        } else if (value && (parseInt(value, 10) || 0) > 99) {
          errors.packageRoundToCents =
            localeStrings["xmm.portal.settings.max_value_warn"] + "99";
        } else {
          errors.packageRoundToCents = "";
        }
        return { errors };
      },
      () => {
        // callback parent handler to push changes
        const { catalog, onUpdate } = this.props;
        const { derivedRoundingRule, errors, isDirty } = this.state;
        if (isDirty && errors.packageRoundToCents === "") {
          const roundTo = value / 100;
          const packageRoundTo =
            derivedRoundingRule === RoundToNextTenDollars
              ? 10
              : derivedRoundingRule === RoundToNextDollar
              ? 1
              : derivedRoundingRule === RoundToNextDime
              ? 0.1
              : derivedRoundingRule === RoundToNextNickel
              ? 0.05
              : derivedRoundingRule === RoundToCentsOnDollar
              ? roundTo
              : null;
          const payload = {
            packageRoundTo,
            packageRoundRule: 2
          };
          onUpdate(catalog, "packageRoundTo", payload);
          this.setState({ isDirty: false });
        }
      }
    );
  };

  // min = 0 max = 99 for cents
  onChangeCents = cxEvent => {
    const { name, value } = cxEvent.target;
    if (
      toEmptyStringIfUndefined(value) !== "" &&
      value !== "0" &&
      isDifferentValue(this.state[name], value)
    ) {
      this.setState(prevState => {
        const { errors } = prevState;
        return {
          isDirty: true, // set dirty flag to avoid server calls when there is no change
          [name]: value,
          errors
        };
      });
    }
  };

  onChangeRoundRule = cxEvent => {
    const { name, value } = cxEvent.target;
    let showCents = false;
    let cents = 95;
    if (value) {
      const { catalog, onUpdate } = this.props;
      const roundRule = value;
      console.log(name, value, typeof value, typeof roundRule);
      // check if option "ROUND To Cents on Dollar"
      if (roundRule === RoundToCentsOnDollar) {
        showCents = true;
      } else {
        // For other rounding options, set blank to cents
        cents = "";
        showCents = false;
      }

      // calcuate { roundRule, roundBy, roundTo } values and send via payload
      const roundTo = doesEmpty(cents) ? "" : cents / 100;
      const packageRoundRule = roundRule === RoundToNone ? null : 2;

      const packageRoundBy =
        roundRule === RoundToNextTenDollars
          ? 10
          : roundRule === RoundToNextDollar
          ? 1
          : roundRule === RoundToNextDime
          ? 0.1
          : roundRule === RoundToNextNickel
          ? 0.05
          : roundRule === RoundToCentsOnDollar
          ? 1
          : null;

      const packageRoundTo =
        roundRule === RoundToNextTenDollars
          ? 10
          : roundRule === RoundToNextDollar
          ? 1
          : roundRule === RoundToNextDime
          ? 0.1
          : roundRule === RoundToNextNickel
          ? 0.05
          : roundRule === RoundToCentsOnDollar
          ? roundTo
          : null;

      catalog.packageRoundRule = packageRoundRule;
      catalog.packageRoundBy = packageRoundBy;
      catalog.packageRoundTo = packageRoundTo;
      const payload = {
        packageRoundRule,
        packageRoundBy,
        packageRoundTo
      };
      this.setState({
        [name]: roundRule,
        showCents,
        packageRoundToCents: convertRoundCents(catalog)
      });
      onUpdate(catalog, name, payload);
    }
  };

  /* common validator can be called in onblur() for any field */
  validate(fieldName) {
    const { errors } = this.state;
    const { packageRoundToCents } = this.state;
    const { localeStrings } = this.context;
    let valid = true;
    // validate for these fields only
    if (fieldName === "packageRoundToCents") {
      if (!packageRoundToCents) {
        errors["packageRoundToCents"] = !packageRoundToCents
          ? localeStrings["xmm.portal.errors.required_field"]
          : "";
        valid = false;
      } else if (
        packageRoundToCents &&
        (parseInt(packageRoundToCents, 10) || 0) > 99
      ) {
        errors["packageRoundToCents"] =
          localeStrings["xmm.portal.settings.max_value_warn"] + "99";
        valid = false;
      } else {
        errors["packageRoundToCents"] = "";
      }
    }
    this.setState({ valid, errors });
    return valid;
  }
  render() {
    const { catalog } = this.props;
    const { localeStrings } = this.context;
    const { errors, derivedRoundingRule, packageRoundToCents } = this.state;
    const centsVal =
      derivedRoundingRule === RoundToCentsOnDollar ? packageRoundToCents : 95;
    const disableCents =
      this.state.derivedRoundingRule === RoundToCentsOnDollar ? false : true;
    const hideCents =
      this.state.derivedRoundingRule === RoundToCentsOnDollar
        ? "xmm-fixed-col no-border"
        : "hide";
    const isAny = catalog.make === "ANY" ? true : false;
    const clsAny = isAny ? "xmm-form-content" : "hidden";
    const clsRow = catalog.make !== "ANY" ? "xmm-form-content" : "hide";
    const anyMakeRow = (
      <div className="xmm-colspan-label">
        {localeStrings["xmm.portal.settings.not_applicable_lbl"]}
        <Tooltip
          htmlId="tipRight"
          position="right"
          tooltipContent={
            localeStrings["xmm.portal.settings.not_applicable_tip"]
          }
        >
          <IconInfoOutline htmlId="iconInfo" />
        </Tooltip>
      </div>
    );
    const defaultRow = (
      <>
        <div className="xmm-input-col">
          <SelectInput
            htmlId="derivedRoundingRule"
            label=""
            displayLabel={false}
            placeholder="Select"
            displayPlaceholder={false}
            displayDeselectOption={false}
            name="derivedRoundingRule"
            onChange={this.onChangeRoundRule}
            options={RoundToOptions}
            value={this.state.derivedRoundingRule}
            error={errors.derivedRoundingRule}
          />
        </div>
        <div className={hideCents}>
          <NumericInput
            htmlId={catalog.make + "-packageRoundToCents"}
            label=""
            displayLabel={false}
            name="packageRoundToCents"
            className=""
            onChange={this.onChangeCents}
            onBlur={this.onBlurCents}
            value={toEmptyStringIfUndefined(centsVal)}
            inputPrefix="¢"
            maxLength={2}
            minLength={0}
            disabled={disableCents}
            error={errors.packageRoundToCents}
          />
        </div>
      </>
    );
    const noPricingRow = <NoPricingRow />;
    const rowHtml = catalog.pricingMethod === 0 ? noPricingRow : defaultRow;
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {" "}
          {catalog.make}{" "}
        </Col>
        <Col md={10} sm={10} className={clsRow}>
          {rowHtml}
        </Col>
        <Col md={10} sm={10} className={clsAny}>
          {anyMakeRow}
        </Col>
      </Row>
    );
  }
}

export default PriceRoundingRow;
PriceRoundingRow.propTypes = {
  catalog: PropTypes.object,
  onUpdate: PropTypes.func
};

function convertRoundCents(catalog) {
  if (catalog.packageRoundRule === 0) {
    return "";
  }
  return !catalog.packageRoundTo ? "" : catalog.packageRoundTo * 100;
}
// This util returns derived roundingRule for UI field mapping
function convertRoundingRule(catalog) {
  if (doesEmpty(catalog.packageRoundRule) || catalog.packageRoundRule === 0) {
    return RoundToNone; // NONE
  } else if (
    catalog.packageRoundRule === 2 &&
    catalog.packageRoundBy === 1 &&
    catalog.packageRoundTo === 1
  ) {
    return RoundToNextDollar; // ROUND TO NEXT DOLLAR VALUE
  } else if (
    catalog.packageRoundRule === 2 &&
    catalog.packageRoundBy === 10 &&
    catalog.packageRoundTo === 10
  ) {
    return RoundToNextTenDollars; // ROUND TO TEN DOLLARS
  } else if (
    catalog.packageRoundRule === 2 &&
    catalog.packageRoundBy === 0.1 &&
    catalog.packageRoundTo === 0.1
  ) {
    return RoundToNextDime; // ROUND TO NEXT DIME
  } else if (
    catalog.packageRoundRule === 2 &&
    catalog.packageRoundBy === 0.05 &&
    catalog.packageRoundTo === 0.05
  ) {
    return RoundToNextNickel; // ROUND TO NEXT NICKEL
  } else if (catalog.packageRoundRule === 2 && catalog.packageRoundBy === 1) {
    return RoundToCentsOnDollar; // ROUND TO "N" CENTS ON A DOLLAR
  } else {
    return RoundToNone; // No rounding rules to decode; default to NONE
  }
}
