/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../components/app-context";
import GroupNameList from "./GroupNameList";
class GroupNameFilter extends Component {
  static contextType = AppContext;
  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.groupNames !== prevState.groupNames) {
      setTimeout(() => {
        prevState.filterNames(prevState.nameFilter);
      }, 0);
      return {
        items: nextProps.groupNames,
        groupNames: nextProps.groupNames
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props);
    this.onChangeFilterList = this.onChangeFilterList.bind(this);
    this.onSelectVehicleGroup = this.onSelectVehicleGroup.bind(this);
    const { localeStrings } = context;
    this.searchLabel = localeStrings["xmm.portal.common.search_label"];
    this.state = {
      groupNames: this.props.groupNames, // this is master copy used when filters are cleared
      nameFilter: "",
      items: this.props.groupNames, // items are binded to show filtered names
      filterNames: this.filterNames
    };
  }

  componentDidMount() {
    // console.log("State of group Names", this.props.groupNames);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.groupNames !== this.props.groupNames) {
  //     this.setState({ items: nextProps.groupNames });
  //   }
  // }

  // This filters on array of objects {compare with sorted list}
  filterNames = nameFilter => {
    if (nameFilter === "") {
      this.setState({
        items: this.props.groupNames,
        nameFilter
      });
      return;
    }

    const groupNames = this.props.groupNames;
    const items = [];
    groupNames.forEach(map => {
      // key is make
      const key = Object.keys(map)[0];
      // list of vehicle groups
      const values = map[key];
      const filterValues = values.filter(group => {
        return (
          group.name.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1
        );
      });
      if (filterValues && filterValues.length > 0) {
        items.push({ [key]: filterValues });
      } else {
        items.push({ [key]: [] });
      }
    });
    this.setState({ items, nameFilter });
  };

  onChangeFilterList = event => {
    // Please correct filter logic and uncomment this method call
    const searchkey = event.target.value;
    this.filterNames(searchkey);
  };

  onSelectVehicleGroup = selectedItem => {
    // this.setState({ selectedItem });
    window.dispatchEvent(
      new CustomEvent("vehicleGroupSelectedEvent", {
        detail: { selectedItem },
        bubbles: true,
        cancelable: true
      })
    );
  };

  render() {
    const { className } = this.props;
    return (
      <React.Fragment>
        <div className={className}>
          <div className="xmm-input-search">
            <input
              type="text"
              id="vehicle-group-search-box"
              className="form-control form-control-lg"
              placeholder={this.searchLabel}
              autoComplete="off"
              onChange={this.onChangeFilterList}
            />
          </div>
          <GroupNameList
            key="namelist"
            items={this.state.items}
            selectedItem={this.props.selectedItem}
            selectFunc={this.onSelectVehicleGroup}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default GroupNameFilter;

GroupNameFilter.propTypes = {
  className: PropTypes.string,
  groupNames: PropTypes.array,
  selectedItem: PropTypes.object
};

GroupNameFilter.defaultProps = {
  className: "xmm-filter-list"
};
