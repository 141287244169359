/*
TODO: Declare AG-GRID value getters in this file
*/
/**
 *
 *
 * createdByValueGetter
 * @param {object} params
 * @return {string} val
 */
export function createdByValueGetter(params) {
  const val = params.data ? params.data.parentId | 0 : null;
  return val === 0 || val === null ? "Dealer" : "Factory";
}
