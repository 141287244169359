import Settings from "./Settings/SettingsPage";
import VehicleGroups from "./VehicleGroups/VehicleGroupsPage";
import Intervals from "./Intervals/IntervalsPage";
import Translations from "./Translations";
import DiscountPage from "./Discounts/DiscountPage";
import FeePage from "./Fees/FeePage";
import ServiceTypesPage from "./ServiceTypes/ServiceTypesPage";
import ROSequencesPage from "./ROSequences/ROSequencesPage";
// bundle components into one object and export
const SettingsViews = {
  Settings,
  VehicleGroups,
  Intervals,
  Translations,
  DiscountPage,
  FeePage,
  ServiceTypesPage,
  ROSequencesPage
};

export default SettingsViews;
