import { toEmptyStringIfUndefined } from "../../commonUtil/utils/string";
import { DefaultDmsPlusFields } from "../../constants/ModuleConstants";

export const InspectionItemCategory = "service category";

export function updateOperationStatuses(operation, operationStatuses) {
  operation.priceStatus = operationStatuses.priceStatus;
  operation.contentEnabledAlacarte = operationStatuses.contentEnabledAlacarte;
  operation.contentEnabledMenus = operationStatuses.contentEnabledMenus;
  operation.laborTimeRange = operationStatuses.laborTimeRange;
  operation.dealerLaborRateCodeId = operationStatuses.dealerLaborRateCodeId;
  operation.numPricingOverrides = operationStatuses.numPricingOverrides;
  operation.numOpcodeOverrides = operationStatuses.numOpcodeOverrides;
}

export function getInspectionItemDisplay(inspectionItem) {
  if (!inspectionItem) {
    return "";
  }
  const { category, categoryName, itemTypeName, inspectionItemName } =
    inspectionItem;
  const theCategorty = category ? category : categoryName;
  const theInspectionItem = itemTypeName ? itemTypeName : inspectionItemName;
  return `${theCategorty} - ${theInspectionItem}`;
}

export function appplyDefaultDmsFieldValues(operation, payload, field) {
  DefaultDmsPlusFields.forEach(name => {
    if (field !== name && toEmptyStringIfUndefined(operation[name]) === "") {
      switch (name) {
        case "defaultServiceType":
          payload.defaultServiceType = "MR";
          break;
        case "dispatchSkillLevel":
          payload.dispatchSkillLevel = "A";
          break;
        case "applyShopSupplies":
          payload.applyShopSupplies = 1;
          break;
        case "defaultPayType":
          payload.defaultPayType = "Customer Pay";
          break;
        case "defaultTaxCode":
          payload.defaultTaxCode = "SA090700";
          break;
        default:
          break;
      }
    }
  });
}
