/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import { PropTypes } from "prop-types";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import {
  isDifferentValue,
  isSameValue
} from "../../../commonUtil/utils/string";
import ManageIntervalsModal from "../../../components/reusable/Intervals/ManageIntervalsModal";
import { xlate } from "../../../commonUtil/i18n/locales";

export default class IntervalSelector extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      isDifferentValue(nextProps.data.make, prevState.data.make) ||
      isDifferentValue(nextProps.data.intervalId, prevState.data.intervalId) ||
      isDifferentValue(nextProps.error, prevState.error)
    ) {
      if (isDifferentValue(nextProps.data.make, prevState.data.make)) {
        setTimeout(() => {
          window.dispatchEvent(
            new CustomEvent("makeChanged", {
              detail: nextProps.data,
              bubbles: true,
              cancelable: true
            })
          );
        }, 200);
      }
      // TODO - do we need to this state update here?
      // updateState(nextProps, prevState);

      const { data } = nextProps;
      const { showContentPanel, touched } = prevState;
      const error = touched && !showContentPanel ? nextProps.error : "";
      const { dealerCode, make, variant } = data;
      return {
        data,
        dealerCode,
        make,
        variant,
        intervalId: data.intervalId,
        intervalName: data.intervalName,
        error
      };
    }
    return null;
  }
  constructor(props) {
    super(props);

    this.manageIntervalsModalRef = React.createRef();

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMakeChanged = this.handleMakeChanged.bind(this);
    // this.handleIntervalSelected = this.handleIntervalSelected.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onIntervalSaved = this.onIntervalSaved.bind(this);
    this.onRemoveInterval = this.onRemoveInterval.bind(this);
    this.openManageIntervalsModal = this.openManageIntervalsModal.bind(this);
    this.showPanel = this.showPanel.bind(this);
    const { data, error, fromEditor } = props;
    this.state = {
      data,
      error,
      dealerCode: data.dealerCode,
      make: data.make,
      variant: data.variant,
      singleMileagePoint: "",
      intervals: [],
      filteredIntervals: [],
      dealerMileages: [],
      dealerMileagesMap: {}, // hashmap of dealer mileages (key = make)
      searchText: "", // data.intervalName,
      showContentPanel: fromEditor,
      touched: false,
      // state for manage intervals modal
      showManageModal: false,
      dealerIntervalsMap: {}, // hashmap of dealer intervals (key = make)
      intervalsMapByMake: {}, // set this when intervals are ready
      sortedIntervals: [],
      intervalId: data.intervalId,
      intervalName: ""
    };
  }

  componentDidMount() {
    const { dealerCode, make, variant } = this.state;
    this.loadIntervals(dealerCode, make, variant);
    this.loadMileages(dealerCode, make);
    window.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("makeChanged", this.handleMakeChanged);
    // window.addEventListener("intervalSelected", this.handleIntervalSelected);
    window.addEventListener(
      "removeIntervalFromList",
      this.onRemoveInterval,
      false
    );
    window.addEventListener("intervalSaved", this.onIntervalSaved, false);

    if (this.props.fromEditor) {
      this.showPanel();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("makeChanged", this.handleMakeChanged);
    // window.removeEventListener("intervalSelected", this.handleIntervalSelected);
    window.removeEventListener("intervalSaved", this.onIntervalSaved, false);
    window.removeEventListener(
      "removeIntervalFromList",
      this.onRemoveInterval,
      false
    );
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    // console.log("IntervalSelector::handleClickOutside");
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.validateAfterHiddingPanel();
    }
  };

  handleMakeChanged = event => {
    event.stopPropagation();
    const { dealerCode, make, variant } = event.detail;
    this.loadIntervals(dealerCode, make, variant);
    this.loadMileages(dealerCode, make);
  };

  loadIntervals = (dealerCode, make, variant) => {
    if (!dealerCode || !make || !variant) {
      return;
    }
    // check if intervals and intervalsMapByMake already in cache
    const { intervalsMapByMake } = this.props.context;
    if (intervalsMapByMake && intervalsMapByMake[make]) {
      const intervals = this.props.context.intervalsByMake[make];
      const filteredIntervals = this.props.includeWildCardIntervals
        ? [...intervals]
        : intervals.filter(itv => {
            return itv.mileages && itv.mileages.length !== 0;
          });
      this.setState({ intervals, filteredIntervals });
      this.setState({
        intervals,
        filteredIntervals,
        intervalsMapByMake
      });
      return;
    }
    const { includeWildCardIntervals } = this.props;
    const { locale } = this.state.data;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerIntervals",
        method: "get",
        data: {},
        params: { dealerCode, make, variant, locale }
      },
      data => {
        if (!data || data.length === 0) {
          return;
        }
        const intervals = data.sort((a, b) => {
          const name1 = a.name.toLowerCase();
          const name2 = b.name.toLowerCase();
          if (name1 === name2) {
            return 0;
          }
          return name1 > name2 ? 1 : -1;
        });
        const filteredIntervals = includeWildCardIntervals
          ? intervals
          : intervals.filter(itv => {
              return itv.mileages && itv.mileages.length !== 0;
            });
        this.setState({ intervals, filteredIntervals });
        this.cacheIntervalsAndMap(make, intervals);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  loadMileages = (dealerCode, make) => {
    if (!dealerCode || !make) {
      return;
    }
    const { dealerMileagesByMake, dealerMileagesMapByMake } =
      this.props.context;
    if (dealerMileagesByMake && dealerMileagesByMake[make]) {
      const dealerMileages = dealerMileagesByMake[make];
      const dealerMileagesMap = dealerMileagesMapByMake[make];
      this.setState({ dealerMileages, dealerMileagesMap });
      return;
    }
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/table/dealerMileage",
        method: "get",
        data: {},
        params: { dealerCode, make }
      },
      dealerMileages => {
        // console.log("Mileages per Make", dealerMileages);
        const dealerMileagesMap = {};
        dealerMileages.forEach(dealerMileage => {
          dealerMileagesMap[dealerMileage.mileage.toString()] = dealerMileage;
        });
        this.setState({ dealerMileages, dealerMileagesMap });
        this.cacheMileagesAndMap(make, dealerMileages, dealerMileagesMap);
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  cacheIntervalsAndMap = (make, intervals) => {
    const intervalsMap = {};
    intervals.forEach(iv => {
      intervalsMap[iv.intervalId.toString()] = iv;
    });
    const { updateDealerIntervals } = this.props.context;
    if (updateDealerIntervals) {
      updateDealerIntervals(make, intervals, intervalsMap);
    }
    // intervalsByMake[make] = intervals;
    // intervalsMapByMake[make] = intervalsMap;
  };

  cacheMileagesAndMap = (make, dealerMileages, dealerMileagesMap) => {
    // const {
    //   dealerMileagesByMake,
    //   dealerMileagesMapByMake
    // } = this.props.context;
    // dealerMileagesByMake[make] = dealerMileages;
    // dealerMileagesMapByMake[make] = dealerMileagesMap;
    const { updateDealerMileages } = this.props.context;
    if (updateDealerMileages) {
      updateDealerMileages(make, dealerMileages, dealerMileagesMap);
    }
  };

  getFirstMileage(mileages) {
    const commaIndex = mileages ? mileages.indexOf(",") : -1;
    const firstMileage =
      commaIndex !== -1 ? mileages.substring(0, commaIndex) : mileages;
    return firstMileage;
  }

  getSingleMileagePointAndIntervalSearch = searchText => {
    // const { dealerMileagesMap } = this.state;
    let singleMileagePoint = "";
    let intervalSearch = "";
    if (searchText && searchText.trim() !== "") {
      const tokens = searchText.split(" ");
      for (let index = 0; index < tokens.length; index++) {
        const token = tokens[index];
        if (/^\d+$/.test(token)) {
          const tokenValue = parseInt(tokens[index], 10);
          if (!isNaN(tokenValue)) {
            singleMileagePoint = tokens[index];
            break;
          }
        } else {
          // if (/^[a-z]+$/i.test(token)) {
          intervalSearch = token;
          // } else {
          //   const tokenValue = parseInt(tokens[index], 10);
          //   if (!isNaN(tokenValue)) {
          //     singleMileagePoint = tokens[index];
          //     break;
          //   }
        }
        if (singleMileagePoint !== "" && intervalSearch !== "") {
          break;
        }
      }
    }
    return { singleMileagePoint, intervalSearch };
  };

  onSearchBoxChanged = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    if (domEvent && domEvent.type === "blur") {
      // Delay onBlur event so that the single mileage point or interval selection takes precedence
      // setTimeout(() => {
      //   this.onBlur(cxEvent, isValid, domEvent);
      // }, 300);
      return;
    }
    const { intervals, searchText } = this.state;
    if (value === searchText) {
      return;
    }
    const singleMileagePointAndIntervalSearch =
      this.getSingleMileagePointAndIntervalSearch(value);
    const { singleMileagePoint, intervalSearch } =
      singleMileagePointAndIntervalSearch;
    if (
      !value ||
      value === "" ||
      (intervalSearch === "" && singleMileagePoint === "")
    ) {
      this.setState({
        singleMileagePoint: "",
        filteredIntervals: [...intervals],
        searchText: value
      });
    } else {
      let filteredIntervals = [];
      // intervalSearch matches intervalName
      if (intervalSearch !== "" && singleMileagePoint !== "") {
        filteredIntervals = intervals.filter(interval => {
          const firstMileage = this.getFirstMileage(interval.mileages);
          return (
            interval.name &&
            interval.name
              .toLowerCase()
              .indexOf(intervalSearch.toLowerCase()) !== -1 &&
            firstMileage &&
            firstMileage.startsWith(singleMileagePoint)
            // interval.mileages &&
            // interval.mileages.indexOf(singleMileagePoint) !== -1
          );
        });
      } else if (intervalSearch !== "") {
        filteredIntervals = intervals.filter(interval => {
          return (
            interval.name &&
            interval.name
              .toLowerCase()
              .indexOf(intervalSearch.toLowerCase()) !== -1
          );
        });
      } else {
        filteredIntervals = intervals.filter(interval => {
          // const commaIndex = interval.mileages
          //   ? interval.mileages.indexOf(",")
          //   : -1;
          // const firstMileage =
          //   commaIndex !== -1
          //     ? interval.mileages.substring(0, commaIndex)
          //     : interval.mileages;
          const firstMileage = this.getFirstMileage(interval.mileages);
          return (
            firstMileage && firstMileage.startsWith(singleMileagePoint)
            // interval.mileages &&
            // interval.mileages.indexOf(singleMileagePoint) !== -1
          );
        });
      }
      this.setState({
        singleMileagePoint,
        filteredIntervals,
        searchText: value
      });
    }
  };

  getKey(interval) {
    return `key_${interval.intervalId}`;
  }
  /* Testing - Dummy renderer */
  renderIntervalList = () => {
    const { intervals } = this.state;
    return intervals.map(interval => {
      return (
        <li className="list-group-item" key={this.getKey(interval)}>
          {interval.name}
        </li>
      );
    });
  };

  onClick = () => {
    const { showContentPanel } = this.state;
    if (!showContentPanel) {
      this.showPanel();
    } else {
      this.validateAfterHiddingPanel();
    }
  };

  onBlur = (cxEvent, isValid, domEvent) => {
    setTimeout(() => {
      if (!this.state.showContentPanel && this.props.onBlur) {
        this.props.onBlur(cxEvent, isValid, domEvent);
      }
    }, 300);
  };

  onChange = () => {
    // if (domEvent && domEvent.type === "blur") {
    //   this.onBlur(cxEvent, isValid, domEvent);
    // }
  };

  showPanel = () => {
    const showPanelUp = this.shouldShowContentPanelUp();
    this.setState({
      showPanelUp,
      touched: true,
      error: "",
      showContentPanel: true
    });
  };

  hidePanel = () => {
    const { showContentPanel } = this.state;
    if (showContentPanel) {
      const { intervals } = this.state;
      const searchText = "";
      const filteredIntervals = [...intervals];
      this.setState({ showContentPanel: false, filteredIntervals, searchText });
      // this.setState({ showContentPanel: false });
    }
  };

  validateAfterHiddingPanel = () => {
    this.hidePanel();
    const { onBlur } = this.props;
    const { touched } = this.state;
    if (touched && onBlur) {
      onBlur();
      this.setState({ touched: false });
    }
  };

  shouldShowContentPanelUp() {
    const gridBoundingBox = document
      .querySelector("#grid-wrapper")
      .getBoundingClientRect();
    const selectorBoundingBox = this.wrapperRef.getBoundingClientRect();
    // console.log("getBoundingClientRect", gridBoundingBox, selectorBoundingBox);
    const panelHeight = 200;
    // if bottom of panel is beyond bottom of the grid rows
    if (selectorBoundingBox.bottom + panelHeight > gridBoundingBox.bottom) {
      if (selectorBoundingBox.top - panelHeight > gridBoundingBox.top + 40) {
        // show panel up
        return true;
      } else {
        // place panel somewhere in the middle of the grid
        return undefined;
      }
    }
    return false;
  }

  resetSearch = () => {
    const { intervals } = this.state;
    const searchText = "";
    const filteredIntervals = [...intervals];
    this.setState({ filteredIntervals, searchText });
  };

  drawIntervalPanel = () => {
    const {
      data,
      error,
      filteredIntervals,
      selectedIntervalId,
      searchText,
      singleMileagePoint,
      dealerMileagesMap,
      showContentPanel,
      showPanelUp
    } = this.state;
    const { disabled, fromEditor, rowHeight } = this.props;
    const selectContainerClass = fromEditor
      ? "selector-container-editor"
      : "selector-container";
    const { dealer, localeStrings } = this.props.context;
    const { defaultUnits } = dealer;
    const mileageUnitKey =
      defaultUnits === "km"
        ? "xmm.portal.common.mileages.unit.kilometer"
        : "xmm.portal.common.mileages.unit.miles";
    const contentPanelClass = showContentPanel
      ? showPanelUp === undefined
        ? "selector-panel selector-panel-top"
        : showPanelUp
        ? "selector-panel selector-panel-up"
        : "selector-panel selector-panel-down"
      : "hide";
    const validSingleMileagePoint =
      dealerMileagesMap && dealerMileagesMap[singleMileagePoint];
    const singleMileagePointClass = validSingleMileagePoint
      ? "list-group-item"
      : "list-group-item invalid-item";
    const singleMileagePointUI =
      singleMileagePoint === ""
        ? "---"
        : singleMileagePoint.concat(" ").concat(localeStrings[mileageUnitKey]);
    // const intervalList = this.renderIntervalList();
    return (
      <form autoComplete="off">
        <div
          className={selectContainerClass}
          disabled={disabled}
          ref={this.setWrapperRef}
          // onMouseOver={this.stopEventListening}
        >
          <div
            onClick={() => {
              this.onClick();
            }}
          >
            <TextInput
              htmlId="intervalSearch"
              name="intervalSearch"
              label="Interval Search"
              className="selector-search"
              style={{ height: `${rowHeight}px` }}
              displayLabel={false}
              value={data.intervalName}
              placeholder={xlate("xmm.portal.common.select_label")}
              onBlur={this.onBlur}
              onChange={this.onChange}
              // readOnly
              onFocus={() => {
                this.setState({ touched: true });
              }}
              error={error}
              appendChild={
                <Button
                  // aria-label="More"
                  htmlId="intervalDownArrow"
                  icon={
                    <IconKeyboardArrowDown
                      htmlId="intervalSearchIcon"
                      className="pull-right"
                    />
                  }
                  buttonStyle="secondary"
                  style={{ height: `${rowHeight}px` }}
                  onClick={this.onClick}
                />
              }
            />
          </div>
          <div className={contentPanelClass}>
            <div className="selector-full-width">
              <div className="xmm-input-search">
                <input
                  type="text"
                  id="interval-search-box"
                  className="xmm-input pull-right"
                  placeholder={
                    this.props.context.localeStrings[
                      "xmm.portal.common.search_label"
                    ]
                  }
                  value={searchText}
                  onChange={this.onSearchBoxChanged}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="interval-selector-scroll">
              <div className="selector-heading">
                {
                  this.props.context.localeStrings[
                    "xmm.portal.common.single_milage_point_lbl"
                  ]
                }
              </div>
              <ul className="selector-list-group">
                <li
                  className={singleMileagePointClass}
                  onClick={() => {
                    const { data, singleMileagePoint } = this.state;
                    data.intervalId = "";
                    data.intervalName = singleMileagePoint;
                    // data.newIntervalMileages = singleMileagePoint;
                    this.props.onChange({
                      name: "singleMileageOrIntervalSelected",
                      detail: { data }
                    });
                    this.validateAfterHiddingPanel();
                    // this.resetSearch();
                  }}
                >
                  {singleMileagePointUI}
                </li>
              </ul>
              <div className="selector-heading">
                {
                  this.props.context.localeStrings[
                    "xmm.portal.common.all_caps_intervals"
                  ]
                }
              </div>
              <div>
                <ul className="selector-list-group">
                  {filteredIntervals.map(interval => {
                    return (
                      <li
                        className={getClassName(
                          interval.intervalId,
                          selectedIntervalId
                        )}
                        key={this.getKey(interval)}
                        onClick={() => {
                          const { data } = this.state;
                          data.intervalId = interval.intervalId.toString();
                          // NOTE: dealerInterval comes with "name" as the name of the interval
                          //       dealerPackage comes with "intervalName" as the name of the interval
                          data.intervalName = interval.name;
                          // data.newIntervalMileages = interval.mileages;
                          this.props.onChange({
                            name: "singleMileageOrIntervalSelected",
                            detail: { data }
                          });
                          this.validateAfterHiddingPanel();
                          // this.resetSearch();
                        }}
                      >
                        {interval.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <hr className="selector-separator" />
            <Button
              htmlId="manageIntervals"
              className="btn--icon"
              buttonStyle="link"
              size="sm"
              onClick={this.openManageIntervalsModal}
            >
              {localeStrings["xmm.portal.package_overrides.manage_intervals"]}
            </Button>
          </div>
        </div>
      </form>
    );
  };
  onIntervalSaved = event => {
    event.preventDefault();
    event.stopPropagation();
    const { interval } = event.detail;
    const { intervals } = this.state;
    let found = false;
    for (let index = 0; index < intervals.length; index++) {
      if (isSameValue(interval.intervalId, intervals[index].intervalId)) {
        intervals[index] = interval;
        found = true;
        break;
      }
    }
    if (!found) {
      // insert into an intervals in intervalsByMake
      intervals.push(interval);
    }
    // insert/update intervalsMapByMake
    const { intervalsMapByMake } = this.props.context;
    const { make, intervalId } = interval;
    const intervalsMap = intervalsMapByMake[make];
    intervalsMap[intervalId.toString()] = interval;
    // update state
    const filteredIntervals = [...intervals];
    this.setState({ intervals, filteredIntervals });
  };
  onRemoveInterval = event => {
    event.preventDefault();
    event.stopPropagation();
    const interval = event.detail;
    const { intervals, filteredIntervals } = this.state;
    const newIntervals = intervals.filter(itv => {
      return isDifferentValue(itv.intervalId, interval.intervalId);
    });
    const newFilteredIntervals = filteredIntervals.filter(itv => {
      return isDifferentValue(itv.intervalId, interval.intervalId);
    });
    this.setState({
      intervals: newIntervals,
      filteredIntervals: newFilteredIntervals
    });
  };

  closeManageIntervalsModal = () => {
    this.setState({ showManageModal: false });
  };
  openManageIntervalsModalFromEditor = () => {
    const { intervalId } = this.state.data;
    let selectedInterval;
    if (intervalId === "") {
      selectedInterval = {
        make: "",
        variant: "",
        dealerCode: "",
        name: "",
        intervalId: ""
      };
    } else {
      selectedInterval = this.findIntervalById(intervalId);
      if (!selectedInterval) {
        selectedInterval = {
          make: "",
          variant: "",
          dealerCode: "",
          name: "",
          intervalId: ""
        };
      }
    }
    window.dispatchEvent(
      new CustomEvent("openManageIntervalsFromEditor", {
        detail: { selectedInterval },
        bubbles: true,
        cancelable: true
      })
    );
  };
  /* Open Interval modal only for dealer created intervals  */
  openManageIntervalsModal = () => {
    // console.log("open Interval modal", this.state.data);
    const { fromEditor } = this.props;
    if (fromEditor) {
      this.openManageIntervalsModalFromEditor();
    } else {
      this.updateSelectedInterval();
    }
  };

  findIntervalById(intervalId) {
    const interval = this.state.intervals.filter(item => {
      return item.intervalId.toString() === intervalId.toString();
    });
    return interval.length !== 0 ? interval[0] : null;
  }
  updateSelectedInterval() {
    const { intervalId } = this.state.data;
    // console.log("open modal", this.state.data);
    let selectedInterval = null;
    // when unsaved single mileage point, just open modal (pass empty interval object (hide form)
    if (!intervalId) {
      // const { make, variant } = this.state;
      selectedInterval = {
        make: "",
        variant: "",
        dealerCode: "",
        intervalId: "",
        name: ""
      };
    } else {
      selectedInterval = this.findIntervalById(intervalId);
      // when unsaved Un-named interval, i,e varaint-level; then pass empty interval object (hide Form)
      if (!selectedInterval) {
        selectedInterval = {
          make: "",
          variant: "",
          dealerCode: "",
          name: "",
          intervalId: ""
        };
      } else {
        // when Named interval (dealer-level), pass actual interval object (to pre-select interval in Form)
        selectedInterval.intervalId = parseInt(intervalId, 10);
      }
    }
    this.setState(
      {
        showManageModal: true,
        selectedInterval
      },
      () => {
        this.manageIntervalsModalRef.current.setSelectedItem(selectedInterval);
      }
    );
  }

  /* This renders interval modal */
  renderManageIntervalsModal = context => {
    const { localeStrings } = context;
    const groupChild = <div />;
    let widget = null;
    if (this.state.showManageModal) {
      widget = (
        <ManageIntervalsModal
          ref={this.manageIntervalsModalRef}
          show={this.state.showManageModal}
          title={localeStrings["xmm.portal.manage_intervals"]}
          closeModal={event => {
            if (
              context.discardUnsavedChanges(
                event,
                this.closeManageIntervalsModal,
                this.manageIntervalsModalRef.current.isDirty
              )
            ) {
              this.closeManageIntervalsModal();
            }
          }}
          selectedInterval={this.state.selectedInterval}
        >
          {groupChild}
        </ManageIntervalsModal>
      );
    }
    return widget;
  };

  render() {
    const intervalPanel = this.drawIntervalPanel();
    const manageIntervals = this.renderManageIntervalsModal(this.props.context);
    return (
      <React.Fragment>
        {intervalPanel}
        {manageIntervals}
      </React.Fragment>
    );
  }
}

IntervalSelector.propTypes = {
  context: PropTypes.object,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fromEditor: PropTypes.bool,
  rowHeight: PropTypes.number,
  includeWildCardIntervals: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

function getClassName(currentId, selectedlId) {
  return currentId === selectedlId
    ? "list-group-item active"
    : "list-group-item";
}
