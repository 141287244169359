import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import {
  useServiceSearchContext,
  Actions
} from "../../state/service-search.context";
import { quickFilterPages } from "../../constants/quick-filters.constants";
import "./FilterMenu.scss";

const FilterMenu = props => {
  const { show, onClose, onSelectCategory, ...restProps } = props;
  const menuRef = useRef();
  const [startTakingMouseClicks, setStartTakingMouseClicks] = useState(false);
  const { state, dispatch } = useServiceSearchContext();
  const { serviceFilterBy, categoryGroupFilters, quickFilters } = state;

  const clickHandler = useCallback(
    ({ clientX, clientY }) => {
      if (menuRef.current) {
        if (startTakingMouseClicks) {
          const _clientY = clientY + 40;
          const { x, y, width, height } =
            menuRef.current.getBoundingClientRect();
          if (
            clientX < x ||
            clientX > x + width ||
            _clientY < y ||
            _clientY > y + height
          ) {
            onClose();
            setStartTakingMouseClicks(false);
          }
        } else {
          setStartTakingMouseClicks(true);
        }
      }
    },
    [onClose, startTakingMouseClicks, setStartTakingMouseClicks]
  );

  useEffect(() => {
    document.addEventListener("click", clickHandler);

    return () => document.removeEventListener("click", clickHandler);
  }, [startTakingMouseClicks, clickHandler]);

  // @note: For All categories selection reset quick filter to TOP_SERVICES
  const handleOptionClick = option => {
    setStartTakingMouseClicks(false);
    if (!option) {
      dispatch({
        type: Actions.SET_QUICK_FILTERS,
        payload: quickFilters.map(filter => {
          filter.selected = filter.id === quickFilterPages.TOP_SERVICES;
          return filter;
        })
      });
    }
    dispatch({
      type: Actions.SET_SERVICE_FILTER_BY,
      payload: option
    });
    dispatch({
      type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
      payload: ""
    });
    onSelectCategory();
  };

  return (
    <>
      {show && (
        <Card htmlId="filterMenuContainer" {...restProps}>
          <ul ref={menuRef}>
            <li onClick={() => handleOptionClick("")}>All Categories</li>
            {categoryGroupFilters.map(option => (
              <li
                key={`filterOption${option.id}`}
                className={serviceFilterBy.id === option.id ? "selected" : ""}
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </Card>
      )}
    </>
  );
};

FilterMenu.defaultProps = {
  show: false,
  onClose: () => {},
  onSelectCategory: () => {}
};

FilterMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSelectCategory: PropTypes.func.isRequired
};

export default FilterMenu;
