import React from "react";
import PropTypes from "prop-types";
import Popover from "@cx/ui/Popover";

/** Messages that appear on hover and explain elements on the screen */
const XmmTooltip = ({
  children,
  className,
  htmlId,
  tooltipContent,
  position,
  trigger
}) => {
  return (
    <Popover
      className={className}
      htmlId={htmlId}
      // trigger={["hover", "focus"]}
      trigger={trigger}
      popoverContent={tooltipContent}
      position={position}
      tooltip
    >
      {children}
    </Popover>
  );
};

XmmTooltip.propTypes = {
  /** Target node. The tooltip will display upon mouseover of this element. */
  children: PropTypes.node,

  /** Class applied to Tooltip. */
  className: PropTypes.string,

  /** Globally unique and descriptive HTML ID. Used by QA for automated testing. */
  htmlId: PropTypes.string.isRequired,

  /** Sets the direction the Popover is positioned towards.  One of `left`, `right`, `top`, `bottom`. */
  position: PropTypes.oneOf(["left", "right", "top", "bottom"]),

  /** Tooltip content. */
  tooltipContent: PropTypes.string.isRequired,

  trigger: PropTypes.array
};

XmmTooltip.defaultProps = {
  position: "bottom",
  trigger: ["hover", "focus"]
};

export default XmmTooltip;
