/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import { toast } from "@cx/ui/Toast";
import { AppContext } from "../../../components/app-context";
import AddGroupMenuButton from "./AddGroupMenuButton";
import VGroupAPI from "./index";
import {
  createSets,
  toArrayOfLabelAndValue
} from "../../reusable/VehicleGroups/VehicleGroupUtil";
import { makeSecureRestApi } from "../../../../src/api/xmmAxios";
import * as gtmEvents from "../../utils/gtag-eventlist";
import { xlate } from "../../../commonUtil/i18n/locales";

/** Reusable modal window - pass props {title, callback func()} from parent */
class ManageVehicleGroupModal extends Component {
  static propTypes = {
    show: PropTypes.bool
  };
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onVehicleGroupSelected = this.onVehicleGroupSelected.bind(this);
    this.onAddVehicleGroupEvent = this.onAddVehicleGroupEvent.bind(this);
    this.onRemoveVehicleGroupEvent = this.onRemoveVehicleGroupEvent.bind(this);
    this.isDirty = this.isDirty.bind(this);
    this.reset = this.reset.bind(this);

    // Note that this callback might be called later during rendering of the VehcileGroupForm
    this.vehicleGroupFormRef = null;
    this.setVehicleGroupFormRef = ref => {
      this.vehicleGroupFormRef = ref;
    };

    this.initializeLocaleValues();

    this.state = {
      criteriaOptionsMap: {},
      groupNames: [],
      metaVehicleFilter: {},
      selectedItem: null
      // dirty: false
    };
  }

  componentDidMount() {
    window.addEventListener(
      "vehicleGroupSelectedEvent",
      this.onVehicleGroupSelected,
      false
    );
    window.addEventListener(
      "addVehicleGroupEvent",
      this.onAddVehicleGroupEvent,
      false
    );
    window.addEventListener(
      "removeVehicleGroupEvent",
      this.onRemoveVehicleGroupEvent,
      false
    );
    // window.addEventListener(
    //   "dirtyVehicleGroupFormEvent",
    //   this.onDirtyVehicleGroupFormEvent,
    //   false
    // );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "vehicleGroupSelectedEvent",
      this.onVehicleGroupSelected,
      false
    );
    window.removeEventListener(
      "addVehicleGroupEvent",
      this.onAddVehicleGroupEvent,
      false
    );
    window.removeEventListener(
      "removeVehicleGroupEvent",
      this.onRemoveVehicleGroupEvent,
      false
    );
    // window.removeEventListener(
    //   "dirtyVehicleGroupFormEvent",
    //   this.onDirtyVehicleGroupFormEvent,
    //   false
    // );
  }
  initializeLocaleValues() {
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deleteError = xlate("xmm.portal.errors.delete_vehicle_group");
  }

  // onDirtyVehicleGroupFormEvent = event => {
  //   const { dirty } = event.detail;
  //   if (dirty !== this.state.dirty) {
  //     this.setState({ dirty });
  //   }
  // };

  onAddVehicleGroupEvent = event => {
    const { value, variant, dealerCode } = event.detail;
    const newVehicleGroupItem = {
      dealerCode,
      make: value,
      variant,
      name: "",
      description: "",
      metaVehicleFilterId: "",
      countOperationRules: 0
    };
    gtmEvents.gtmTrackEvent("xmm.vehiclegroups.add_click");
    this.setSelectedItem(newVehicleGroupItem);
  };

  onRemoveVehicleGroupEvent = event => {
    const { vehicleGroupFormRef } = this;
    vehicleGroupFormRef.updateStatusBox(this.deletingMsg, "pending", false);
    const { metaVehicleFilterId } = event.detail;
    const restEndPoint =
      "/ops/proxyapi/internaloeproxy/rest/internal/xmmservices/deleteMetaVehicleFilter";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          metaVehicleFilterId
        }
      },
      response => {
        if (response && response.success) {
          vehicleGroupFormRef.updateStatusBox(this.deletedMsg, "success", true);
          setTimeout(() => {
            this.setSelectedItem(null);
            window.dispatchEvent(
              new CustomEvent("removeVehicleGroupFromList", {
                detail: event.detail,
                bubbles: true,
                cancelable: true
              })
            );
          }, 1000);
          // const status = "Vehicle group deleted successfully.";
          // toast.success(status);
        } else {
          // toast.error(xlate(this.deleteError));
          vehicleGroupFormRef.updateStatusBox(
            this.deleteError,
            "error",
            false,
            true
          );
        }
      },
      error => {
        // toast.error(error.message);
        vehicleGroupFormRef.updateStatusBox(
          error.message,
          "error",
          false,
          true
        );
      }
    );
  };

  onVehicleGroupSelected = event => {
    const { selectedItem } = event.detail;
    if (this.state.selectedItem !== selectedItem) {
      this.setSelectedItem(selectedItem);
    }
  };

  createVehicleGroupCriteria = (vehicleGroup, metaVehicleFilter) => {
    Object.entries(metaVehicleFilter).forEach(([key, val]) => {
      if (val && typeof val === "number") {
        metaVehicleFilter[key] = val.toString();
      }
    });
    // console.log("metaVehicleFilter", metaVehicleFilter);
    if (this.vehicleGroupFormRef) {
      this.loadMetaVehicleDimensionsByMake(vehicleGroup, metaVehicleFilter);
    }
    this.setState({ metaVehicleFilter });
  };

  createBodyAndFuelTypes = data => {
    const list = data.detailAttributes
      ? data.detailAttributes.detailAttribute
      : null;
    let bodyTypes = [];
    let fuelTypes = [];
    if (list) {
      list.forEach(item => {
        const value = item.content;
        if (item["columnName"] === "BODY_TYPE_NAME") {
          bodyTypes.push({ label: value, value });
        }
        if (item["columnName"] === "FUEL_TYPE") {
          fuelTypes.push({ label: value, value });
        }
      });
    } else {
      if (data.bodyTypes) {
        bodyTypes = data.bodyTypes;
      }
      if (data.fuelTypes) {
        fuelTypes = data.fuelTypes;
      }
    }
    return { bodyTypes, fuelTypes };
  };

  loadMetaVehicleDimensionsByMake = (vehicleGroup, metaVehicalFilter) => {
    const { metaVehicleDimensionsByMake } = this.context;
    const criteriaOptionsMap = metaVehicleDimensionsByMake[vehicleGroup.make];
    if (criteriaOptionsMap) {
      this.vehicleGroupFormRef.setMetaVehicleFilter(
        metaVehicalFilter,
        criteriaOptionsMap
      );
      return;
    }

    this.setLoadingMask(true);

    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/findMetaVehicleDimensionsByMake";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          dealerCode: vehicleGroup.dealerCode,
          make: vehicleGroup.make,
          variant: vehicleGroup.variant,
          countryId: "",
          requestType: "",
          authId: "",
          menuTypeId: "",
          setCategory: "package"
        }
      },
      response => {
        if (response && response.response) {
          const data = response.response;
          const sets = createSets(data.metaVehicleSets.metaVehicleSet);
          // toArrayOfLabelAndValue(data.metaVehicleSets.metaVehicleSet);
          const models = toArrayOfLabelAndValue(data.models.model);
          // const startAndEndYear = toArrayOfLabelAndValue(data.years.year);
          const startYear = toArrayOfLabelAndValue(data.years.year);
          // startYear.splice(0, 0, { label: "NONE", value: "NONE" });
          const endYear = toArrayOfLabelAndValue(data.years.year);
          // endYear.splice(0, 0, { label: "NONE", value: "NONE" });
          const years = toArrayOfLabelAndValue(data.years.year);
          // years.splice(0, 0, { label: "NONE", value: "NONE" });
          const trims = toArrayOfLabelAndValue(data.trims.trim);
          const engineTypes = toArrayOfLabelAndValue(
            data.engineTypes.engineType
          );
          const engineSizes = toArrayOfLabelAndValue(
            data.engineSizes.engineSize
          );
          const driveTypes = toArrayOfLabelAndValue(data.driveTypes.driveType);
          const transmissionTypes = toArrayOfLabelAndValue(
            data.transmissionTypes.transmissionType
          );
          const bodyTypeAndFuelTypes = this.createBodyAndFuelTypes(data);
          const { bodyTypes } = bodyTypeAndFuelTypes;
          const { fuelTypes } = bodyTypeAndFuelTypes;
          const criteriaOptionsMap = {
            sets,
            models,
            // startAndEndYear,
            startYear,
            endYear,
            years,
            trims,
            engineTypes,
            engineSizes,
            driveTypes,
            transmissionTypes,
            bodyTypes,
            fuelTypes
          };
          // console.log("criteriaOptionsMap", criteriaOptionsMap);
          metaVehicleDimensionsByMake[metaVehicalFilter.make] =
            criteriaOptionsMap;
          this.vehicleGroupFormRef.setMetaVehicleFilter(
            metaVehicalFilter,
            criteriaOptionsMap
          );
        }
        this.setLoadingMask(false);
      },
      error => {
        this.setLoadingMask(false);
        toast.error(error.message);
      }
    );
  };

  setLoadingMask(showMask) {
    if (this.vehicleGroupFormRef) {
      this.vehicleGroupFormRef.setLoadingMask(showMask);
    }
  }

  loadVehicleGroup = vehicleGroup => {
    const { dealerCode, make, metaVehicleFilterId } = vehicleGroup;

    if (metaVehicleFilterId === "") {
      const metaVehcileFilter = { dealerCode, make, metaVehicleFilterId };
      this.createVehicleGroupCriteria(vehicleGroup, metaVehcileFilter);
      return;
    }
    const nameOrSimple = vehicleGroup.isUnnamed ? "simple" : "named";
    const restEndPoint = `/ops/proxyapi/ddsproxy/rest/table/${nameOrSimple}MetaVehicleFilter`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          metaVehicleFilterId
        }
      },
      response => {
        if (response && response.length > 0) {
          const metaVehicleFilter = response[0];
          this.createVehicleGroupCriteria(vehicleGroup, metaVehicleFilter);
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };

  hide = () => {
    this.props.closeModal();
  };

  setSelectedItem = selectedItem => {
    if (selectedItem) {
      this.loadVehicleGroup(selectedItem);
      this.setState({ selectedItem });
    } else {
      const metaVehicleFilter = {};
      if (this.vehicleGroupFormRef) {
        this.vehicleGroupFormRef.setMetaVehicleFilter(metaVehicleFilter);
      }
      this.setState({ selectedItem: null, metaVehicleFilter });
    }
    this.reset(false);
  };

  isDirty = () => {
    return this.vehicleGroupFormRef.isDirty();
    // return this.state.dirty;
  };

  reset = dirty => {
    if (this.vehicleGroupFormRef) {
      this.vehicleGroupFormRef.reset(dirty);
    }
  };

  render() {
    const modalHeader = (
      <ModalDialog.Title>
        {this.props.title}{" "}
        <AddGroupMenuButton isFormDirty={this.isDirty} resetForm={this.reset} />
      </ModalDialog.Title>
    );

    const GroupNameFilterCmp = VGroupAPI.GroupNameFilter;
    const VehicleGroupFormCmp = VGroupAPI.VehicleGroupForm;
    const vehicleGroupForm = (
      <VehicleGroupFormCmp
        ref={this.setVehicleGroupFormRef}
        sortedVehicleGroups={this.props.sortedVehicleGroups}
      />
    );

    const groupNameFilter = (
      <GroupNameFilterCmp
        groupNames={this.props.sortedVehicleGroups}
        selectedItem={this.state.selectedItem}
      />
    );

    return (
      <React.Fragment>
        <ModalDialog
          htmlId="ManageVGroupModal"
          show={this.props.show}
          onHide={this.hide}
          backdrop={"static"}
          header={modalHeader}
          className="xmm-full-modal xmm-large-header xmm-vehicle-group-modal"
        >
          {groupNameFilter}
          {vehicleGroupForm}
          {this.props.children}
        </ModalDialog>
      </React.Fragment>
    );
  }
}
export default ManageVehicleGroupModal;

ManageVehicleGroupModal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.object,
  title: PropTypes.string,
  // selectedVehicleGroup: PropTypes.object,
  sortedVehicleGroups: PropTypes.array,
  closeModal: PropTypes.func,
  okAction: PropTypes.func
};
