/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import * as formvalidator from "../../../../formik/formvalidator";
import { xlate } from "../../../../../commonUtil/i18n/locales";
class AddServiceTypesFormPage extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.initializeLocaleValues();
    const dealerCode = context.dealerCode;
    const { user, enableDMSPlusContent } = context;
    const { userName } = user;
    const newServiceTypes = {
      ...props.rowRecord,
      dealerCode,
      modUser: userName
    };
    this.state = {
      newServiceTypes,
      editOption: props.editOption,
      serviceTypes: props.serviceTypes,
      dealerCode,
      enableDMSPlusContent,
      dirty: false,
      valid: false,
      errors: {
        code: "",
        description: ""
      }
    };
  }

  componentDidMount() {}
  initializeLocaleValues() {
    this.selectLabel = xlate("xmm.portal.common.select_a_make_lbl");
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.makeErrorMsg = xlate("xmm.portal.errors.make_is_required");
    this.payCodeErrorMsg = xlate("xmm.portal.errors.pay_code_is_required");
    this.payTypeGroupErrorMsg = xlate(
      "xmm.portal.errors.pay_type_group_is_required"
    );
    this.descriptionErrorMsg = xlate(
      "xmm.portal.errors.description_is_required"
    );
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.duplicateRateCodeError = xlate(
      "xmm.portal.errors.miissing_labor_rate_code_or_description"
    );
    this.duplicateCodeError = xlate("xmm.portal.errors.opcode_duplicate");
  }
  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };
  // changeEvent for textfield
  onChange = (event, isValid, domEvent) => {
    const { name, value } = event.target;
    if (domEvent && domEvent.type === "blur") {
      this.onBlur(name);
      return;
    }
    const { newServiceTypes } = this.state;
    if (
      domEvent &&
      domEvent.type !== "blur" &&
      isDifferentValue(newServiceTypes[name], value)
    ) {
      this.setState(
        prevState => {
          return {
            newServiceTypes: {
              ...prevState.newServiceTypes,
              [name]: value
            }
          };
        },
        () => {
          this.markDirty(name, true);
        }
      );
    }
  };

  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty, valid });
    } else {
      this.setState({ dirty });
    }
  }
  isValidForm() {
    const { serviceTypeCode, description } = this.state.newServiceTypes;
    if (!serviceTypeCode) {
      return false;
    }
    if (
      !description ||
      formvalidator.validateAlphaNumPunctuation(description)
    ) {
      return false;
    }
    return true;
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { description, serviceTypeCode } = this.state.newServiceTypes;
    const { errors } = this.state;
    if (!fieldName || fieldName === "serviceTypeCode") {
      errors["code"] = "";
    }

    if (!fieldName || fieldName === "description") {
      errors["description"] = !description ? this.descriptionErrorMsg : "";
      if (errors["description"] === "") {
        errors["description"] =
          formvalidator.validateAlphaNumPunctuation(description);
      }
    }
    if (serviceTypeCode && this.validateDuplicateCode(serviceTypeCode) !== -1) {
      errors["code"] = this.duplicateCodeError;
    }

    const valid = this.isValidForm();
    this.setState({ valid, errors });
    return valid;
  }
  validateDuplicateCode(value) {
    if (this.state.serviceTypes) {
      return this.state.serviceTypes.findIndex(
        item =>
          item.serviceTypeCode.toLowerCase().trim() === value.toLowerCase()
      );
    }
    return -1;
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }

  getAddPayload() {
    const { newServiceTypes } = this.state;
    return newServiceTypes;
  }
  // TODO - first validate duplicates for code, description per selected make; then proceed
  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors } = this.state;
    const data = this.getAddPayload();
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      // toast.warning(this.correctInvalidFielsError);
      this.updateStatusBox(this.correctInvalidFielsError, "warning", false);
      return;
    }
    const url =
      "/ops/proxyapi/ddsproxy/rest/table/dealerServiceType?_method=post";
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if (response && response.success) {
          this.updateStatusBox(this.savedMsg, "success", true);
          // callback grid to add record
          const { dealerServiceType } = response;
          this.props.updateServiceTypesAfterSave(dealerServiceType);
          this.initDealerSequenceNumber();
          this.setState({ dirty: false, valid: false }, () => {
            setTimeout(() => {
              this.props.hideSlider();
            }, 800);
          });
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    // GTM - push click event to dataLayer
    // gtmEvents.gtmTrackEvent("xmm.laborrates.add_rate_click");
  };
  initDealerSequenceNumber() {
    const { dealerCode, localeStrings, enableDMSPlusContent } = this.state;
    if (enableDMSPlusContent) {
      makeSecureRestApi(
        {
          url: `ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/initDealerSvcTypeSeqNum/${dealerCode}`,
          method: "get"
        },
        response => {
          console.log(response);
        },
        error => {
          const msg = error["message"]
            ? error.message
            : localeStrings[
                "xmm.portal.errors.fetch_bmw_value_service_enabled_property"
              ];
          toast.error(msg, {
            closeOnClick: true
          });
        }
      );
    }
  }
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    let editSection = null;
    const msgSection = null;
    const {
      newServiceTypes,
      valid,
      errors,
      supportedMakes,
      payTypeGroupOptions
    } = this.state;
    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
    const isValid = valid;
    if (this.props.editForm) {
      // show form when edit clicked or add clicked
      editSection = (
        <React.Fragment>
          <div className="xmm-tab-bar">
            {statusBox} <span className="xmm-msg">{msgSection}</span>
            <Button
              htmlId="saveAction"
              buttonStyle="primary"
              disabled={!isValid}
              onClick={this.saveHandler}
            >
              {this.saveLabel}
            </Button>
          </div>
          <Card htmlId="AddServiceTypesId">
            <div className="comment-form">
              <Grid htmlId="settingGrid">
                <Row className="show-grid">
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {
                        this.context.localeStrings[
                          "xmm.portal.service_types.code"
                        ]
                      }{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="code"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.service_types.code"
                        ]
                      }
                      name="serviceTypeCode"
                      required
                      value={newServiceTypes.serviceTypeCode}
                      maxLength={3}
                      minLength={1}
                      onChange={this.onChange}
                      error={errors.code}
                      displayLabel={false}
                      // getError={validateFieldError}
                    />
                  </Col>
                  <Col xs={4} md={4}>
                    <span className="float-right">
                      {
                        this.context.localeStrings[
                          "xmm.portal.service_types.description"
                        ]
                      }{" "}
                      <span className="xmm-red-label">*</span>{" "}
                    </span>
                  </Col>
                  <Col xs={6} md={6}>
                    <TextInput
                      htmlId="description"
                      label={
                        this.context.localeStrings[
                          "xmm.portal.service_types.description"
                        ]
                      }
                      name="description"
                      required
                      value={newServiceTypes.description}
                      maxLength={50}
                      minLength={1}
                      onChange={this.onChange}
                      error={errors.description}
                      displayLabel={false}
                      // getError={validateFieldError}
                    />
                  </Col>
                </Row>
              </Grid>
            </div>
          </Card>
        </React.Fragment>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default AddServiceTypesFormPage;

AddServiceTypesFormPage.propTypes = {
  rowRecord: PropTypes.object,
  operationName: PropTypes.string,
  editForm: PropTypes.bool,
  editOption: PropTypes.string,
  serviceTypes: PropTypes.array,
  hideSlider: PropTypes.func,
  updateServiceTypesAfterSave: PropTypes.func
};
/* eslint-enable no-console */
