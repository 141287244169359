// import _get from "lodash/get";
import { makeSecureRestApi } from "../../../../api/xmmAxios";

const getMetaVehicleIdForOtherMake = (dealerCode, make, model, year) => {
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/internal/v1/vehicle/find/geo/trim/${dealerCode}/${year}/${make}/${model}`,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
};

const getYearsForOtherMake = (webKey, make) => {
  return new Promise((resolve, reject) => {
    const data = `webKey=${webKey}&scheduledOnly=0`;
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/vehicles/make/${make}/years`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "post",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
};

const getMaintenanceServicesForOtherMake = (webKey, make, model, year) => {
  const data = `make=${make}&model=${model}&year=${year}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/services/dealer/${webKey}/make/${make}/model/${model}/year/${year}/unscheduledservices/kind/maintenance`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "post",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
};

const getRepairServicesForOtherMake = (webKey, make, model, year) => {
  const data = `make=${make}&model=${model}&year=${year}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/services/dealer/${webKey}/make/${make}/model/${model}/year/${year}/unscheduledservices/kind/repair`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "post",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
};

export default {
  getMetaVehicleIdForOtherMake,
  getYearsForOtherMake,
  getMaintenanceServicesForOtherMake,
  getRepairServicesForOtherMake
};
