import isEqual from "lodash/isEqual";
import remove from "lodash/remove";

const selectedMenuPackageReducer = (state, action) => {
  const newServices = JSON.parse(JSON.stringify([...state.services]));
  switch (action.type) {
    case "SET_ORIGINAL_SERVICES": {
      return {
        ...state,
        originalServices: action.payload,
        changedServices: []
      };
    }
    case "SET_MENU_ITEM": {
      newServices[action.payload.serviceIndex] = action.payload.newService;
      const serviceChanged = !isEqual(
        state.originalServices[action.payload.serviceIndex],
        newServices[action.payload.serviceIndex]
      );
      const changedServices = state.changedServices;
      if (serviceChanged) {
        changedServices[action.payload.serviceIndex] =
          action.payload.newService;
      } else {
        remove(
          changedServices,
          service => service.id === action.payload.newService.id
        );
      }
      return {
        ...state,
        services: [...newServices],
        changedServices
      };
    }
    case "SET_MENU_ITEM_PRICE": {
      newServices[action.payload.serviceIndex].price = action.payload.newPrice;
      return {
        ...state,
        services: [...newServices]
      };
    }
    case "SET_MENU_ITEM_LABOR_PRICE": {
      newServices[action.payload.serviceIndex].scheduledLaborPrice =
        action.payload.newPrice;
      return {
        ...state,
        services: [...newServices]
      };
    }

    case "SET_MENU_ITEM_DISCOUNT_REASON": {
      newServices[action.payload.serviceIndex].discountReason =
        action.payload.reason;
      return {
        ...state,
        services: [...newServices]
      };
    }
    case "RESET_PACKAGE_STATE": {
      return {
        ...action.payload
      };
    }
    case "UPDATE_PAYTYPE_MENU_SERVICE": {
      const {
        payTypeCode,
        payTypeDescription,
        payTypeGroup,
        newServices,
        drivingCondition
      } = action.payload;
      return {
        ...state,
        drivingCondition,
        payTypeCode,
        payTypeDescription,
        payTypeGroup,
        services: newServices
      };
    }
    default:
      return state;
  }
};

export default selectedMenuPackageReducer;
