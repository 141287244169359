/*
 * Place Util methods to support quote price calci
 */
const isLocalDev = () => {
  return process.env.NODE_ENV === "development";
};

function convertHoursToMinutes(hours) {
  let laborMins = hours || 0;
  laborMins = hours * 60;
  return laborMins;
}
// const convertMinutesToHours = minutes => +(+minutes / 60).toFixed(2);
const convertMinutesToHours = minutes => {
  const hours = Number.parseFloat(minutes / 60);
  return hours.toFixed(2).endsWith("0") ? hours.toFixed(1) : hours.toFixed(2);
};

/**
 * Returns true if the passed value is empty, false otherwise. The value is deemed to be empty if it is either:
 *
 * - `null`
 * - `undefined`
 * - a zero-length array
 * - a zero-length string (Unless the `allowEmptyString` parameter is set to `true`)
 *
 * @param {Object} value The value to test
 * @param {Boolean} allowEmptyString (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 * @markdown
 */
const doesEmpty = (value, allowEmptyString) => {
  return (
    value === null ||
    value === undefined ||
    (!allowEmptyString ? value === "" : false) ||
    (isArrayExist(value) && value.length === 0)
  );
};

/**
 * Returns true if the passed value is a JavaScript Array, false otherwise.
 *
 * @param {Object} target The target to test
 * @return {Boolean}
 * @method
 */
const isArrayExist = value => {
  return Object.prototype.toString.call(value) === "[object Array]";
};

function getAppEnvironment() {
  const { hostname } = window.location;
  const host = hostname.includes("localhost")
    ? "servicequoting.dev6.xtimeappsnp.xtime.com"
    : hostname;
  const hostArr = host.split(".");
  let quoteEnv = hostArr[1];
  quoteEnv = quoteEnv.includes("xtime") ? "prod" : quoteEnv;
  // console.log("getAppEnvironment", host, quoteEnv);
  let returnedEnv = "qa5";
  switch (quoteEnv) {
    case "dev6":
      returnedEnv = "qa5";
      break;
    case "qa6":
      returnedEnv = "ua9";
      break;
    case "demo":
    case "pt3":
      returnedEnv = "pt3";
      break;
    case "stg4":
      returnedEnv = "stg4";
      break;
    case "prod":
      returnedEnv = "prod";
      break;
    default:
      returnedEnv = "qa5";
  }
  return returnedEnv;
}

const checkSpecialChars = str => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(str);
};

export {
  isLocalDev,
  convertHoursToMinutes,
  convertMinutesToHours,
  doesEmpty,
  isArrayExist,
  getAppEnvironment,
  checkSpecialChars
};
