import { doesEmpty, isEmpty } from "../../commonUtil/utils/object";
import { namespace } from "./../../api/xmmAxios";

/**
 * Created by raja on 09/10/2019.
 * Util methods defined to support support Goggle Tag Manager
 *
 * How to push event dimensions to dataLayer
   Example:
    window.dataLayer.push({
      'appName': 'CommonCatalog',
      'appVersion': 'v7.68-SNAPSHOT - 198', // send app release version
      'environment': 'production', //eg: qaxmm, devxmm
      'schemaName': 'x5',  //eg: x5,x7
      'user': '131000035347',
      'trainingRole': 'XTEMPLOYEE',
      'userDomain': 'xtime.com',
      'readOnly': 'false', // true - readonly access or false - full access
      'isMember': 'false', // true - member; false - non-group
      'webKey': 'xtimemotors14',
      'make': 'AUDI',
      'variant': 'AUDIUSA_ENH2',
      'dealerCode': 'XTIMEMOTORS14'
    });
*/

/* eslint-disable no-console */
export const isObjEmpty = obj => {
  return JSON.stringify(obj) === "{}";
};

export function getDataLayer() {
  const _dataLayer = (window.dataLayer = window.dataLayer || []);
  return _dataLayer;
}

export function logDataLayer() {
  const _dataLayer = getDataLayer();
  console.log(_dataLayer);
}

/**
 * Track page navigation using GTM track type "pageview"; hits are logged in GA (under realtime content, behavior/ site content)
 * @param {String} url The URL to track.
 * Usage:
 *  window.dataLayer = [{
      'appName': 'CommonCatalog',
      'event': 'virtualPageview',
      'trackPageUrl': '/common-catalog/operations'
    }];
 */
export function trackPage(pageObj) {
  console.log("gtag page", pageObj);
  const _dataLayer = getDataLayer();
  if (typeof pageObj === "object" && Object.keys(pageObj).length !== 0) {
    // pageObj.eventPathname = pageObj.trackPageUrl;
    _dataLayer.push(pageObj);
  }
}

/**
 * Track a specific event like button click, dropdown, element click from app
 * @param {String} event, defined as "userEvent"
 * @param {String} eventFeature
 * @param {String} eventElement
 * @param {String} eventAction The action itself.
 * @param {String} eventResult The label of the action.
 * @param {String} eventFeatures
 * @param {String} eventValue, should be Integer (to capture counts, US dollars, price values) - optional
 * @param {String} trackPageUrl (track navigation path)
 */
/*
  var sample = {
      'event': 'userEvent',
      'eventFeature': 'operations',
      'eventElement': 'AddOperation',
      'eventAction': 'Button clicked',
      'eventResult': 'New operation form created',
      'eventFeatures': 'operations> Add Operation',
      'eventValue': undefined,
      'trackPageUrl': 'operations/add-operation'
    };
*/
export function trackEvent(dataObj) {
  const _dataLayer = getDataLayer();

  if (!isObjEmpty(dataObj)) {
    _dataLayer.push(dataObj);
  }
  // logDataLayer();
}

/**
 * Track specific restful service load time using GTM event "userTiming"
 * @param {String} event, defined as "userTiming"
 * @param {String} timingCategory
 * @param {String} timingVar The action itself.
 * @param {String} timingLabel The label of the action.
 * @param {String} timingValue, should be Integer (capture counts, US dollars, price values) - optional
 * @param {String} trackPageUrl (used to log backend serviceUrl)
 */
export function trackRestServiceLoadTime(dataObj) {
  const _dataLayer = getDataLayer();

  if (!isObjEmpty(dataObj)) {
    _dataLayer.push(dataObj);
  }
  // logDataLayer();
}

/**
 * Push application/user related dynamic values to dataLayer (used in GA dimensions)
 * @param {Object} dataObj pushed to dataLayer.
 * Usage:
 *  pushDimensions({
		 	'isMember' :  true
		});
 */
export function pushDimensions(dataObj) {
  const _dataLayer = getDataLayer();
  if (!isObjEmpty(dataObj)) {
    _dataLayer.push(dataObj);
  }
}

/**
 * returns environment to be added to GTM data layer
 * @returns {*}
 */
export function getEnvironment() {
  const env =
    namespace === "prod" || namespace === "prodaus" ? "production" : namespace;
  return env;
}

/**
 * Method to setup application level GTM datalayer variables
 *
 */
export function setApplicationData() {
  const _dataLayer = getDataLayer();
  _dataLayer.push({
    user: "customer",
    appName: "CommonCatalog",
    environment: getEnvironment()
  });
}

// Capture specific rest API only
export function filterServiceName(logObj, response) {
  // track pricing/operations service
  const serviceurl = logObj.serviceName;
  let reqParams = null;
  let previewType = "";
  if (serviceurl.indexOf("/ddsproxy/rest/table/dealerOperationsXMM") > -1) {
    trackGTagPageLoadTime(logObj, "/common-ops/operations/");
  }
  // track Manual scores rest call from find opcode tool
  if (serviceurl.indexOf("/ddsproxy/rest/proc/findDealerOpcodes") > -1) {
    trackGTagPageLoadTime(logObj, "/opcode-validation/findopcode/");
  }
  // track opcode mapping service call
  if (serviceurl.indexOf("/ddsproxy/rest/proc/getAllOpcodeMappings") > -1) {
    trackGTagPageLoadTime(logObj, "/common-ops/opcode-validation/");
  }
  // track package overrides service call
  if (
    serviceurl.indexOf("/ddsproxy/rest/proc/getEnhancedDealerPackages") > -1
  ) {
    trackGTagPageLoadTime(logObj, "/common-ops/package-overrides/");
  }
  // track Menutypes service call
  if (serviceurl.indexOf("/ddsproxy/rest/proc/getEnhancedMenuTypes") > -1) {
    trackGTagPageLoadTime(logObj, "/common-ops/menutypes/");
  }
  if (serviceurl.indexOf("/ddsproxy/rest/table/dealerMenuType") > -1) {
    trackGTagPageLoadTime(logObj, "/common-ops/menutypes/");
  }
  // track service hours rest API when operation has store hours
  if (serviceurl.indexOf("/ddsproxy/rest/table/serviceHours") > -1) {
    trackGTagPageLoadTime(logObj, "/operations/servicehours/");
  }
  if (serviceurl.indexOf("/dealermenu") > -1) {
    reqParams = response.config.params;
    previewType = reqParams.isPreview ? "unpublish" : "publish";
    trackGTagPageLoadTime(
      logObj,
      "/common-ops/preview/dealermenu/" + previewType
    );
  }
  if (serviceurl.indexOf("/dealerUnscheduledServicesForPricing") > -1) {
    reqParams = response.config.params;
    previewType = reqParams.isPreview ? "unpublish" : "publish";
    trackGTagPageLoadTime(
      logObj,
      "/common-ops/preview/alacarte/" + previewType
    );
  }
}
/**
 * Track specific restful service load time using GTM event "userTiming"
 * @param {object} capture services
 * @param {String} pageName
 * @param {String} loadTime; total response time of service
 */
/*
    var sample = {
          event: 'userTiming',
          timingCategory: 'Page: /preview/',
          timingVar:  'Make: AUDI' + 'serviceName: dealerUnscheduledServices',
          timingLabel: 'Track service pageload time',
          timingValue: 125, // send custom dimension value millisecs to GA via GTM dataLayer
          make: 'AUDI',
          dealerCode: 'xtimemotors',
          trackPageUrl: '/panama/dds/rest/proc/dealerUnscheduledServices', // send custom dimension value to GA via GTM dataLayer
          trackLoadTime: 200 // milliseconds - send custom dimension value to GA via GTM dataLayer
      };
*/
export function trackGTagPageLoadTime(logObj, pageName) {
  const pathArray = logObj.serviceName.split("/");
  const newServiceName = pathArray[pathArray.length - 1];
  const timingobj = {
    event: "userTiming",
    timingCategory: "Pageload",
    timingVar: pageName,
    timingLabel: "Track load time of Service: " + newServiceName,
    timingValue: logObj.loadTime, // send total service response time as custom dimension value to GA via GTM dataLayer
    serviceName: logObj.serviceName, // send only serviceName value as custom dimension to GA via GTM dataLayer, instead of service urlPath
    trackLoadTime: logObj.loadTime, // send custom dimension value to GA via GTM dataLayer
    make: "",
    dealerCode: ""
  };
  // console.log("trackPageLoad", newServiceName, timingobj);
  trackRestServiceLoadTime(timingobj);
}

/* Track Http errors in GA */
export function trackError(logObj) {
  console.error("trackError", logObj);
  const eventobj = {
    event: "userEvent",
    eventElement: "Exception in rest API",
    eventAction: "Trigger rest API",
    eventResult: "Track Http Errors for service: " + logObj.statusCode,
    eventFeature: logObj.statusCode,
    eventFeatures: logObj.message,
    eventValue: undefined,
    trackPageUrl: logObj.serviceName
  };
  trackEvent(eventobj);
}

export function trackUnknowError(data, config) {
  if (!isEmpty(data) && !doesEmpty(data.errorCode)) {
    const error = data.errorCode;
    const logObj = {
      eventName: "restException",
      method: config.method,
      statusCode: config.statusCode,
      serviceName: config.url,
      message: !error ? "UNKNOWN Error" : error
    };
    trackError(logObj);
  }
}
