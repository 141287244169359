/* eslint-disable no-console */
import React, { Component } from "react";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import IconAdd from "@cx/ui/Icons/IconAdd";
import SelectInput from "@cx/ui/SelectInput";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
// import { FormattedMessage } from "react-intl";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../components/app-context";
import { MenuTypeTemplate } from "../../../../../constants/ModuleConstants";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import { xlate } from "../../../../../commonUtil/i18n/locales";

export default class MenuTypesHeader extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.makes !== prevState.makes) {
      return {
        makes: nextProps.makes
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    // const { selectedMenuType } = context;
    const { selectedMenuType } = context.menuTypesGrid;
    const make = !selectedMenuType ? "all" : selectedMenuType;

    this.restorePageCache = this.restorePageCache.bind(this);
    this.onManageBtnClick = this.onManageBtnClick.bind(this);
    this.onShowDisabledClick = this.onShowDisabledClick.bind(this);
    this.onFilterByMake = this.onFilterByMake.bind(this);
    this.addMenuType = this.addMenuType.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.initializeLocaleValues();
    this.state = {
      makes: props.makes,
      menuTypesGrid: context.menuTypesGrid,
      showDisabled: context.menuTypesGrid.showDisabled,
      make
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.restorePageCache();
    }, 300);
  }
  componentWillUnmount() {
    this.savePageCache();
  }
  initializeLocaleValues() {
    this.addNewMenuLabel = xlate("xmm.portal.common.add_new_menu");
    this.selectMakeLabel = xlate("xmm.portal.common.select_make_label");
    this.showDisabledMenusLabel = xlate(
      "xmm.portal.menutype.show_disabled_menus"
    );
    this.allMakesLabel = xlate("xmm.portal.common.all_makes");
    this.makeLabel = xlate("xmm.portal.common.make_lbl");
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restorePageCache() {
    const { make } = this.state;
    if (make) {
      this.dispatchFilterMenuTypesByMakeAndShowDisabled();
    }
  }
  onManageBtnClick = () => {
    // TODO
  };
  savePageCache() {
    const { make, showDisabled } = this.state;
    const menuTypesGrid = {
      selectedMenuType: make,
      showDisabled
    };
    this.context.setMenuTypesGridState(menuTypesGrid);
  }
  onShowDisabledClick = () => {
    this.setState(
      prevState => ({
        showDisabled: !prevState.showDisabled
      }),
      () => {
        this.dispatchFilterMenuTypesByMakeAndShowDisabled();
      }
    );
  };

  onFilterByMake = changeEvent => {
    const make = changeEvent.target.value;
    this.setState({ make }, () => {
      this.dispatchFilterMenuTypesByMakeAndShowDisabled();
    });
  };

  dispatchFilterMenuTypesByMakeAndShowDisabled() {
    const { make, showDisabled } = this.state;
    window.dispatchEvent(
      new CustomEvent("filterMenuTypesByMakeAndShowDisabled", {
        detail: {
          make,
          showDisabled
        },
        bubbles: true,
        cancelable: true
      })
    );
  }

  getMakeFilters = makelist => {
    if (makelist) {
      const makelistFilter = [...makelist];
      makelistFilter.splice(0, 0, {
        label: this.allMakesLabel,
        value: "all",
        make: "all",
        dealerCode: "all",
        variant: "all"
      });
      return makelistFilter;
    }
    return [];
  };

  addMenuType = () => {
    const sliderMenuType = Object.assign({}, MenuTypeTemplate);
    sliderMenuType.menuTypeId = "";
    window.dispatchEvent(
      new CustomEvent("showMenuTypeSlider", {
        detail: {
          showSlider: true,
          sliderMenuType
        },
        bubbles: true,
        cancelable: true
      })
    );
  };
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    const { make, makes, showDisabled } = this.state;
    const makelistFilter = this.getMakeFilters(makes);
    const statusBox = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    return (
      <React.Fragment>
        <Row className="xmm-menu-type-header">
          <Col xs={3} md={3}>
            <SelectInput
              htmlId="makeFilter"
              name="make"
              label={this.makeLabel}
              className="xmm-left-label"
              placeholder={this.selectMakeLabel}
              displayDeselectOption={false}
              displayPlaceholder={false}
              layout="horizontal"
              onChange={this.onFilterByMake}
              options={makelistFilter}
              value={make}
              maxHeight={200}
            />
          </Col>
          <Col xs={5} md={5}>
            <ToggleSwitch
              htmlId="menuTypeEnableToggleFilter"
              checked={showDisabled}
              className="xmm-toogle-label"
              label={this.showDisabledMenusLabel}
              onClick={this.onShowDisabledClick}
            />
          </Col>
          <Col xs={4} md={4} className="full-col">
            <div className="xmm-tab-bar no-margin">
              <ButtonToolbar>
                {statusBox}
                <Button
                  htmlId="addMenuTypeLink"
                  className="btn--icon float-right"
                  aria-label="Add"
                  buttonStyle="primary"
                  size="sm"
                  onClick={this.addMenuType}
                >
                  <IconAdd />
                </Button>
                <Button
                  htmlId="addMenuTypeBtn"
                  className="btn--icon float-right"
                  aria-label="Add"
                  buttonStyle="link"
                  onClick={this.addMenuType}
                >
                  {this.addNewMenuLabel}
                </Button>
              </ButtonToolbar>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

MenuTypesHeader.propTypes = {
  makes: PropTypes.array
};
/* eslint-enable no-console */
