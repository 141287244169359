/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../app-context";
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import PriceInput from "@cx/ui/PriceInput";
import SelectInput from "@cx/ui/SelectInput";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { PropTypes } from "prop-types";
import { makeSecureRestApi } from "../../../../api/xmmAxios";
import {
  initBulkEditPartsPricing,
  PartsFluidsPriceColumnIdBySource
} from "../../../../constants/ModuleConstants";
import { PartsPricingContext } from "./parts-pricing-context";
import * as gtmEvents from "../../../utils/gtag-eventlist";

function BulkEditPane(props) {
  const context = useContext(AppContext);
  const opContext = useContext(PartsPricingContext);
  const { localeStrings } = context;
  const { selectionlist } = props;
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  // This payload state will update on each change, used to save bulkedit
  const [payload, setPayload] = useState(null);
  const [formData, setValues] = useState(initBulkEditPartsPricing);
  const [bulkSelections, setBulkSelections] = useState(props.selectionlist);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState({});
  const [dirty, setDirty] = useState(false);
  const [valid, setValid] = useState(false);
  // Note - go with useEffect() if we don't have complex logic to deal(no life cycle methods)
  useEffect(function persistForm() {
    if (formData && Object.keys(formData).length !== 0) {
      // console.log("formData", formData);
    }
  });

  const handleApply = e => {
    e.preventDefault();
    let isValid = true;

    // check if any errors found in fields
    if (Object.keys(errors).length) {
      isValid = !hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      updateLocalBox(
        "Please correct invalid fields to proceed.",
        "error",
        false,
        localeStrings["xmm.portal.errors.bulkedit_save"]
      );
      return;
    }
    const refinePayload = parsePayload();
    setPayload(refinePayload);
    props.updateStatusBox(
      localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    const postObj = getEditPayload(refinePayload);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/table/dealerPart?_method=put";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: postObj,
        params: {},
        headers
      },
      () => {
        // success case - update operations in opContext & ag-grid state
        props.updateStatusBox(
          localeStrings["xmm.portal.common.saved"],
          "success",
          true
        );
        updateSelectionsAfterBulkEdit(refinePayload);
      },
      () => {
        updateLocalBox(
          localeStrings["xmm.portal.common.cannot_save_changes"],
          "error",
          false,
          localeStrings["xmm.portal.errors.bulkedit_save"]
        );
      }
    );
    // GTM - push click event to dataLayer
    gtmEvents.gtmTrackEvent("xmm.partspricing.bulk_edit_click");
  };

  // parse payload before sending to rest call
  function parsePayload() {
    const newObject = {};
    // for (const [key, value] of Object.entries(payload)) {
    //   console.log(`${key}: ${value}`);
    // }
    Object.keys(payload).forEach(key => {
      newObject[key] = payload[key];
    });
    return newObject;
  }
  /*
  // Moved logic to onChange event
  function validateManual() {
    const priceVal = payload["price"];
    if (payload["source"] === "MANUAL") {
      if (!priceVal) {
        setErrors({
          ...errors,
          price: "Manual price is required."
        });
        return false;
      } else if (((priceVal && parseFloat(priceVal)) || 0) > 10000) {
        setErrors({
          ...errors,
          price: "The Maximum value for this field is 9999.99"
        });
        return false;
      }
      return true;
    }
  }
  */
  function validateDMS(match) {
    let count = 0;
    if (match === "DMS") {
      for (let i = 0; i < selectionlist.length; i++) {
        if (selectionlist[i].dmsPrice === null) {
          count++;
        }
      }
    }
    return count;
  }
  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.*/
  function hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null || value.length === 0) {
        // In case of valid error string
      } else if (value && value.length > 1) {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  }
  // callback after bulkedit save
  function updateSelectionsAfterBulkEdit(refinePayload) {
    // console.log("after save", refinePayload);
    const results = bulkSelections.map(parts => {
      return Object.assign(parts, refinePayload);
    });
    setBulkSelections(results);
    props.closeSlider();
    const columns = [PartsFluidsPriceColumnIdBySource[refinePayload.source]];
    opContext.updateGridAfterBulkEdit(results, columns);
  }

  function getEditPayload(request) {
    const dealerParts = selectionlist;
    const postArray = [];
    if (dealerParts && dealerParts.length > 0) {
      dealerParts.forEach(key => {
        const cloneObj = Object.assign({}, request);
        cloneObj.dealerPartId = key.dealerPartId;
        postArray.push(cloneObj);
      });
    }
    // console.log("bulkedit postobj", postArray);
    return postArray;
  }
  /* Price chang event */
  const onChangeInput = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const newValue = !value || value === "" ? "" : value;
    if (domEvent && domEvent.type === "blur") {
      // console.log("blur", name, newValue);
      validatePrice(name, newValue);
      return;
    }
    // Note: skip change event for initial form load
    if (formData[name] === value) {
      return;
    }

    setDirty(true);
    setValues({
      ...formData,
      [name]: newValue
    });
    const hasValid = validatePrice(name, newValue);
    setValid(hasValid);
    if (hasValid) {
      setPayload({
        ...payload,
        [name]: newValue
      });
    }
  };

  /* Field change event handler */
  const changeSource = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const newValue = !value || value === "" ? "" : value;
    // Note: skip change event when form loaded
    if (formData[name] === value) {
      return;
    }
    if (domEvent && domEvent.type === "blur") {
      setErrors({
        ...errors,
        price: "",
        source: ""
      });
      return;
    }
    setDirty(true);
    setValues({
      ...formData,
      [name]: newValue,
      price: ""
    });

    let hasValid = true;
    errors["price"] = "";
    if (!newValue) {
      hasValid = false;
      errors["source"] = "Active price level is required.";
    } else if (newValue === "DMS") {
      const count = validateDMS(newValue);
      if (count > 0) {
        hasValid = false;
        errors["source"] =
          "No DMS price available for " + count + " selected records.";
      }
    } else if (newValue === "MANUAL") {
      errors["source"] = "";
      errors["price"] = "Manual price is required.";
      hasValid = false;
    } else {
      errors["source"] = "";
    }
    setErrors({
      ...errors
    });
    setValid(hasValid);
    setPayload({
      ...payload,
      [name]: newValue,
      price: ""
    });
  };

  /* price validator */
  function validatePrice(fieldName, price) {
    let valid = true;
    if (fieldName === "price") {
      if (price) {
        if (((price && parseFloat(price)) || 0) > 10000) {
          valid = false;
          errors["price"] = "The Maximum value for this field is 9999.99";
        } else {
          errors["price"] = "";
        }
      } else if (!price) {
        valid = false;
        errors["price"] = "Manual price is required.";
      }
    }
    setErrors({
      ...errors
    });
    return valid;
  }

  /* These Utils used for form validation style */
  function updateLocalBox(msg, type, close, errorInTooltip) {
    // console.log("status", msg, type, close);
    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      setStatus({
        ...status,
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  const statusHtml = status.statusMsg ? (
    <StatusBox
      htmlId="statusBox"
      type={status.statusType}
      autoClose={status.autoClose}
      linkHtml={null}
      message={status.statusMsg}
      errorInTooltip={status.errorInTooltip}
    />
  ) : (
    ""
  );

  const markDisabled = !valid || !dirty;
  return (
    <React.Fragment>
      <div className="xmm-bulk-form-bar">
        <span>{statusHtml}</span>
        <span className="float-right">
          <Button
            htmlId="ApplyBulkEditAction"
            buttonStyle="primary"
            disabled={markDisabled}
            onClick={handleApply}
            className="xmm-bulkedit-btn"
          >
            {localeStrings["xmm.portal.bulkedit.apply_btn"]}
          </Button>
        </span>
      </div>
      <Card id="partsPriceBulkEdit" autoComplete="off">
        <div className="bulk-edit-form">
          <div className="xmm-bulk-form-row">
            <span className="text-right">
              {localeStrings["xmm.bulkedit.parts.active_price_lbl"]}
            </span>
            <SelectInput
              htmlId="sourceBulkEdit"
              name="source"
              options={[
                {
                  value: "MSRP",
                  label: localeStrings["xmm.portal.common.msrp"]
                },
                {
                  value: "DMS",
                  label: localeStrings["xmm.bulkedit.parts.DMS_file_opt"]
                },
                {
                  value: "MANUAL",
                  label: localeStrings["xmm.bulkedit.parts.manual_opt"]
                }
              ]}
              value={formData.source}
              hasError={!!errors.source}
              errorMessage={errors.source}
              onChange={changeSource}
              displayLabel={false}
              displayDeselectOption={false}
            />
          </div>
          <div
            className={
              formData["source"] !== "MANUAL" ? "hidden" : "xmm-bulk-form-row"
            }
          >
            <span className="text-right">
              {localeStrings["xmm.bulkedit.parts.manual_price_lbl"]}
            </span>
            <PriceInput
              htmlId="priceBulkEdit"
              name="price"
              onChange={onChangeInput}
              value={formData.price}
              error={errors.price}
              maxLength={7}
              displayLabel={false}
              label="Manual Price"
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}
export default BulkEditPane;

BulkEditPane.propTypes = {
  closeSlider: PropTypes.func,
  selectionlist: PropTypes.array,
  updateStatusBox: PropTypes.func
};
