import LaborRatesPage from "./LaborRates/LaborRatesPage";
import FluidsPricing from "./FluidsPricing/FluidsPricing";
import PartsPricing from "./PartsPricing/PartsPricing";
import PayTypesPage from "./PayTypes/PayTypesPage";
import PricingRulesPage from "./PricingRules/PricingRulesPage";

// bundle components into one object and export
const PartsLaborViews = {
  FluidsPricing,
  PartsPricing,
  LaborRatesPage,
  PayTypesPage,
  PricingRulesPage
};

export default PartsLaborViews;
