/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../app-context";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import SelectInput from "@cx/ui/SelectInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import TextInput from "@cx/ui/TextInput";
import NumericInput from "@cx/ui/NumericInput";
import Badge from "@cx/ui/Badge";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";
import {
  PayTypeOptions,
  RateTypeOptions
} from "../../../../../constants/ModuleConstants";
import Confirmation from "../../../../../commonUtil/dialog/Confirmation";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { isDifferentValue } from "../../../../../commonUtil/utils/string";
import AddPowertrainWarrantyForm from "./AddPowertrainWarrantyForm";
import { toast } from "@cx/ui/Toast";

export const updateCode = (payType,rateType, warrantyType,planCode) => {
  let code = "";
  if (payType && payType === "Customer Pay") {
    code += "C-";
    switch (rateType) {
      case "Wholesale":
        code += "WHSE";
        break;
      case "Fleet":
        code += "FLEET";
        break;
      case "Vendor":
        code += "VENDOR";
        break;
      case "Employee":
        code += "EMP";
        break;
      case "Friends & Family":
        code += "F&F";
        break;
      case "Loyal Customer":
        code += "LOYAL";
        break;
    }
  } else if (payType === "Warranty") {
    code = "W-";
    switch (warrantyType) {
      case "OEM Factory Warranty":
        code += planCode? planCode: "";
        break;
      case "OEM Extended Warranty":
        code +=  planCode? planCode: "";
        break;
      case "OEM Maintenance Plan":
        code +=  planCode? planCode: "";
        break;
    }
  } else if (payType === "Service Contract") {
    code = "S-" + removeSpecialCharacters(planCode);
  }

  return code;
}

export const removeSpecialCharacters = (inputString) => {
  if (inputString)
    return inputString.replace(/[^a-zA-Z0-9 ]/g, '')
  else 
    return "";
}

class AddLaborPricingFormPage extends Component {
  static contextType = AppContext;
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.getVendorList = this.getVendorList.bind(this);
    this.getServiceContractSupplierList = this.getServiceContractSupplierList.bind(this);
    this.getFuelTypeList = this.getFuelTypeList.bind(this);
    this.getItemFromMapById = this.getItemFromMapById.bind(this);
    this.addWarranty = this.addWarranty.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.validate = this.validate.bind(this);
    this.isValidForm.bind(this);
    this.initializeLocaleValues();
    const {dealerCode, enableServiceContractV2} = context;

    const { user } = context;
    const { userName } = user;
    let newLaborPricing = {
      ...props.rowRecord,
      dealerCode,
      modUser: userName
    }

    const payTypeOptions = [...PayTypeOptions];
    const rateTypeOptions = [...RateTypeOptions];
    const powertrainWarrantyFields =
      newLaborPricing.dealerLaborPricingRuleId &&
      newLaborPricing.powertrainWarranties
        ? JSON.parse(newLaborPricing.powertrainWarranties)
        : [];
    this.state = {
      newLaborPricing,
      payTypeOptions,
      rateTypeOptions,
      allVendorList: [],
      allVendorMap: new Map(),
      fuelTypes: [],
      allProductList: [],
      serviceContractSupplierList: [],
      serviceContractSupplierMap: new Map(),
      powertrainWarrantyFields,
      dirty: false,
      valid: false,
      showDeleteConfirmation: false,
      errors: { expiryDate: "", fromDate: "" },
      showWarrantyDisabled: false,
      enableServiceContractV2
    };
  }

  componentDidMount() {
    this.getFuelTypeList();
    if (this.isServiceContractV2()) {
      this.getServiceContractSupplierList((prevState) => {
        //needed to sync service contract supplier and policy dropdowns
          this.updateServiceContractPolicy();
        }
      );
    } else {
      this.getVendorList();
    }
    
    window.addEventListener(
      "updatePowertrainWarranties",
      this.onUpdatePowertrainWarranties,
      false
    );
    window.addEventListener(
      "removePowertrainWarrantyEvent",
      this.onRemovePowertrainWarrantyEvent,
      false
    );
  }

  getClassName(condition) {
    return condition ? "" : "hide";
  };
  onUpdatePowertrainWarranties = event => {
    const { index, field, name } = event.detail;
    const { powertrainWarrantyFields } = this.state;
    powertrainWarrantyFields[index] = field;
    this.setState({ powertrainWarrantyFields });
    this.markDirty(name, true);
  };
  onRemovePowertrainWarrantyEvent = event => {
    const { index } = event.detail;
    const { powertrainWarrantyFields } = this.state;
    powertrainWarrantyFields.splice(index, 1);
    this.setState({ powertrainWarrantyFields });
    this.markDirty(null, true);
  };
  openConfirmationModal = () => {
    const { newLaborPricing } = this.state;
    if (
      newLaborPricing.dealerLaborPricingRuleId &&
      newLaborPricing.payType === "Warranty"
    ) {
      this.setState({ showDeleteConfirmation: true });
    } else {
      this.saveHandler();
    }
  };
  closeDeleteModal = doneDeleting => {
    if (doneDeleting) {
      this.setState({ showDeleteConfirmation: false });
    }
  };
  confirmSaveChanges = () => {
    this.saveHandler();
    this.setState({ showDeleteConfirmation: false });
  };
  initializeLocaleValues() {
    this.saveLabel = xlate("xmm.portal.common.save_button");
    this.addWarrantyLabel = xlate(
      "xmm.portal.pricing_rules.add_powertrain_btn"
    );
    this.addWarrantyOEMTooltip = xlate(
      "xmm.portal.pricing_rules.add_warranty_OEM_tooltip"
    );
    this.addWarrantyExtendedTooltip = xlate(
      "xmm.portal.pricing_rules.add_warranty_extended_tooltip"
    );
    this.saveWarrantyConfirmationMsg = xlate(
      "xmm.portal.pricing_rules.save_powertrain_warranty_msg"
    );
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.addSkillAStandaloneRatTooltip = xlate(
      "xmm.portal.pricing_rules.add_skill_a_standalone_rate_tooltip"
    );
  }
  onBlur = name => {
    const valid = this.validate(name);
    this.setState({ valid });
  };
  updateServiceContractPolicy() {
    const {newLaborPricing} = this.state;
    if (newLaborPricing.payType ===  "Service Contract" && 
      newLaborPricing.serviceContractSupplierId ) {
      const {allVendorMap} = this.state;
      const selectedVendor = this.getItemFromMapById(allVendorMap,this.state.newLaborPricing.serviceContractSupplierId);
      if (!selectedVendor)
        return;

      let customEvent = {
        "target" : {
          "name": "serviceContractSupplierId",
          "value": [{value:selectedVendor.value}],
          "eventType" : "1"
        }
      }
      this.onChange(customEvent);
    }
  }
  updateDesc() {
    const { newLaborPricing, allVendorMap } = this.state;
    let desc = "";
    let code = "";
    if (newLaborPricing.payType && newLaborPricing.payType === "Customer Pay") {
      code += "C-";
      desc = `Special Customer Rate ( ${newLaborPricing.rateType} )`;
      switch (newLaborPricing.rateType) {
        case "Wholesale":
          code += "WHSE";
          break;
        case "Fleet":
          code += "FLEET";
          break;
        case "Vendor":
          code += "VENDOR";
          break;
        case "Employee":
          code += "EMP";
          break;
        case "Friends & Family":
          code += "F&F";
          break;
        case "Loyal Customer":
          code += "LOYAL";
          break;
      }
    } else if (newLaborPricing.payType === "Warranty") {
      desc = newLaborPricing.planName;
      code = "W-";
      switch (newLaborPricing.warrantyType) {
        case "OEM Factory Warranty":
          code += newLaborPricing.planCode? newLaborPricing.planCode: "";
          break;
        case "OEM Extended Warranty":
          code +=  newLaborPricing.planCode? newLaborPricing.planCode: "";
          break;
        case "OEM Maintenance Plan":
          code +=  newLaborPricing.planCode? newLaborPricing.planCode: "";
          break;
      }
    } else if (newLaborPricing.payType === "Service Contract") {
      if (this.isServiceContractV2()) {
        const selectedVendor = this.getItemFromMapById(allVendorMap, newLaborPricing.serviceContractSupplierId);
        if (selectedVendor) {
          desc = (selectedVendor && selectedVendor.label)?selectedVendor.label+"-" :"-";
          desc+= (newLaborPricing.serviceContractPolicy)?newLaborPricing.serviceContractPolicy:"";
        }  
        
        code = updateCode(newLaborPricing.payType, newLaborPricing.rateType, newLaborPricing.warrantyType, newLaborPricing.planCode);  
      } else {  
        desc =
          "3rd Party Service Contract (" + newLaborPricing.warrantyVendor + ")";
        code = "S-3PP"; 
      }
    }
    this.setState({
      newLaborPricing: {
        ...newLaborPricing,
        laborRateDescription: desc,
        code: code
      }
    });
  }

  updateserviceContractPolicy(vendorId) {
    const { serviceContractSupplierMap } = this.state;
    const selectedVendor = this.getItemFromMapById(serviceContractSupplierMap, vendorId);
    if (selectedVendor && selectedVendor.products) {
      this.setState ({allProductList: [...selectedVendor.products.map(product => ({
                        label: product.name,
                        value: product.id}))
                    ].sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
                  });
    } else {
      this.setState({
        allProductList: []
      })
    }              
  }

  // changeEvent for textfield
  onChange = event => {
    const { name, value } = event.target;
    const cleanserviceContractPolicy = !(event.target.eventType);
    const { newLaborPricing, allVendorMap } = this.state;
    let newValue;
    let selectedVendor;
    let selectedProduct;

    switch (name) {
      case "payType":
        newValue = value;
        if (value === "Service Contract") {
          this.setState({
            newLaborPricing: {
              ...newLaborPricing,
              warrantyType: "3rd Party",
              serviceContractSupplier: "",
              serviceContractPolicy: "",
              serviceContractSupplierId: "",
              serviceContractPolicyId: "",
              warrantyVendor: "",
              rateType: "",
              warrantyBaseCoverage: "",
              warrantyMileageCoverage: "",
              powertrainWarranties: [],
              planName: "",
              planCode: ""
            },
            powertrainWarrantyFields: []
          });
        } else if (value === "Warranty") {
          this.setState({
            newLaborPricing: {
              ...newLaborPricing,
              warrantyType: "OEM Factory Warranty",
              serviceContractSupplier: "",
              serviceContractPolicy: "",
              serviceContractSupplierId: "",
              serviceContractPolicyId: "",
              rateType: "",
              planName: "",
              planCode: ""
            }
          });
        } else if (value === "Customer Pay") {
          this.setState({
            newLaborPricing: {
              ...newLaborPricing,
              warrantyType: "",
              serviceContractSupplier: "",
              serviceContractPolicy: "",
              serviceContractSupplierId: "",
              serviceContractPolicyId: "",
              warrantyVendor: "",
              warrantyBaseCoverage: "",
              warrantyMileageCoverage: "",
              rateType: "Fleet",
              planName: "",
              planCode: ""
            },
            powertrainWarrantyFields: [],
          });
        }
        break;
      case "serviceContractSupplierId":
        newValue = value[0]? value[0].value : "";
        selectedVendor = this.getItemFromMapById(allVendorMap, newValue);
        this.setState(
            prevState => ({
              newLaborPricing: {
                ...prevState.newLaborPricing,
                serviceContractSupplierId:newValue,
                serviceContractSupplier:selectedVendor.label,
                serviceContractPolicyId: cleanserviceContractPolicy?"":newLaborPricing.serviceContractPolicyId,
                serviceContractPolicy: cleanserviceContractPolicy?"":newLaborPricing.serviceContractPolicy
              }
            }),
        () => {
          if (selectedVendor && selectedVendor.value)
            this.updateserviceContractPolicy(selectedVendor.value);
        });
        break;
      case "serviceContractPolicyId":
          newValue = value[0] ? value[0].value : "";
          selectedVendor = this.getItemFromMapById(this.state.serviceContractSupplierMap, newLaborPricing.serviceContractSupplierId ); 
          if (selectedVendor) {
            selectedProduct = this.getItemFromMapById(selectedVendor.productsMap,newValue);
            if (selectedProduct) {
              this.setState(
                prevState => ({
                  newLaborPricing: {
                    ...prevState.newLaborPricing,
                    serviceContractPolicyId: newValue,
                    serviceContractPolicy: selectedProduct.name
                  }
                }));
            } else {
              this.setState(
                prevState => ({
                  newLaborPricing: {
                    ...prevState.newLaborPricing,
                    serviceContractPolicyId: "",
                    serviceContractPolicy: ""
                  }
                }));
            }
            
            }    
          break;  
      case "warrantyVendor":
        newValue = value[0] ? value[0].value : "";
        break;

      default:
        newValue = value;
    }
    if (isDifferentValue(newLaborPricing[name], newValue)) {
      this.setState(
        prevState => {
          return {
            newLaborPricing: {
              ...prevState.newLaborPricing,
              [name]: newValue
            }
          };
        },
        () => {
          this.updateDesc();
          this.markDirty(name, true);
        }
      );
    }
  };
  getVendorList() {
    const restUrl = "/ops/proxyapi/ddsproxy/rest/table/warrantyVendor";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          this.setState({
            allVendorList: data.map(vendor => ({
              label: vendor.vendor,
              value: vendor.vendor
            }))
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  getServiceContractSupplierList(callback) {
    const {
      newLaborPricing: { dealerCode }
    } = this.state;
    const restUrl = `ops/proxyapi/oeproxy/rest/internal/xmmdealer/getDealerVendorProducts/${dealerCode}`;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        if (response && response.data) { 
          try {
            const jsonData = JSON.parse(response.data);
            // function to normalize vendor or product ID
            const normalizeId = (id) => id === "" ? "0" : id;

            // function to normalize a supplier
            const normalizeSupplier = (supplier) => ({
              ...supplier,
              vendor: {
                ...supplier.vendor,
                id: normalizeId(supplier.vendor.id),
              },
              products: supplier.products
                ? supplier.products.map(product => ({
                    ...product,
                    id: normalizeId(product.id),
                  }))
                : [],
            });

            // function to create products map for a supplier
            const createProductsMap = (products) => {
              const productsMap = new Map();
              if (products && Array.isArray(products)) {
                products.forEach(product => {
                  const productId = normalizeId(product.id);
                  productsMap.set(productId, {
                    id: productId,
                    name: product.name,
                  });
                });
              }
              return productsMap;
            };

            // Create serviceContractSupplierList by normalizing suppliers
            const serviceContractSupplierList = jsonData.map(normalizeSupplier);

            // Create serviceContractSupplierMap dictionary
            const serviceContractSupplierMap = new Map();
            serviceContractSupplierList.forEach(supplier => {
              const vendorId = supplier.vendor.id;
              const productsMap = createProductsMap(supplier.products);
              serviceContractSupplierMap.set(vendorId, {
                ...supplier,
                productsMap: productsMap,
              });
            });

            // Create allVendorMap dictionary
            const allVendorMap = new Map();
            serviceContractSupplierList.forEach(supplier => {
              const vendorId = supplier.vendor.id;
              allVendorMap.set(vendorId, {
                label: supplier.vendor.name,
                value: vendorId,
              });
            });

            // Create sorted allVendorList
            const allVendorList = serviceContractSupplierList
              .map(supplier => ({
                label: supplier.vendor.name,
                value: supplier.vendor.id,
              }))
              .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

            // Set state with the updated values
            this.setState(
              {
                serviceContractSupplierMap,
                serviceContractSupplierList,
                allVendorMap,
                allVendorList,
              },
              callback
            );
          } catch (e) {
            const msg = e["message"]
            ? "There was an error while processing the contract supplier/policy definition: " + e.message
            : "There was an error while processing the contract supplier/policy definition";
            console.log(msg);
            toast.error(msg, {
              closeOnClick: true});  
          }  
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );                
  }
  getItemFromMapById(map, id) {
    return map.get(id);
  }

  getFuelTypeList() {
    const {
      newLaborPricing: { dealerCode, make, variant }
    } = this.state;
    if (this.state.newLaborPricing.make === "ANY"){
      return
    }
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/proc/findMetaVehicleDimensionsByMake";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: {
          dealerCode: dealerCode,
          make: make,
          variant: variant,
          countryId: "",
          requestType: "",
          authId: "",
          menuTypeId: "",
          setCategory: "package"
        }
      },
      data => {
        if (data) {
          const list = data.response.detailAttributes
            ? data.response.detailAttributes.detailAttribute
            : null;
          if (list) {
            this.setState({
              fuelTypes: list
                .filter(item => item.columnName === "FUEL_TYPE")
                .map(item => ({
                  label: item.content,
                  value: item.content
                }))
            });
          }  
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  createEmptyPowertrainWarranty() {
    const { fuelTypes } = this.state;
    const newWarranty = {
      fuelType: fuelTypes && fuelTypes[0] ? fuelTypes[0].value : "",
      hourlyRate: "",
      baseCoverage: "",
      mileageCoverage: ""
    };
    return newWarranty;
  }
  addWarranty() {
    const newWarranty = this.createEmptyPowertrainWarranty();
    const { powertrainWarrantyFields } = this.state;
    powertrainWarrantyFields.push(newWarranty);
    this.setState({ powertrainWarrantyFields }, () => {
      this.markDirty(name, true);
    });
  }
  // call this for each field change event
  markDirty(fieldName, validate) {
    const dirty = true;
    if (validate) {
      const { valid, errors } = this.validate(fieldName);
      this.setState({ dirty, valid, errors });
      this.props.onModified();
    } else {
      this.setState({ dirty });
    }
  }
  isValidForm() {
    const { newLaborPricing, powertrainWarrantyFields } = this.state;
    let validPowertrainWarranties = true;
    if (!newLaborPricing.skillAStandaloneRate) {
      return false;
    }
    if (
      newLaborPricing.payType === "Warranty" &&
      newLaborPricing.warrantyType === "OEM"
    ) {
      if (!newLaborPricing.warrantyBaseCoverage) {
        return false;
      }
      if (!newLaborPricing.warrantyMileageCoverage) {
        return false;
      }
      powertrainWarrantyFields.forEach(warranty => {
        if (!warranty.hourlyRate) {
          validPowertrainWarranties = false;
        }
        if (!warranty.baseCoverage) {
          validPowertrainWarranties = false;
        }
        if (!warranty.mileageCoverage) {
          validPowertrainWarranties = false;
        }
        if (!warranty.deductible) {
          validPowertrainWarranties = false;
        }
      });
    }
    if (
      newLaborPricing.payType === "Warranty" &&
      newLaborPricing.warrantyType === "Extended"
    ) {     
      powertrainWarrantyFields.forEach(warranty => {
        if (!warranty.hourlyRate) {
          validPowertrainWarranties = false;
        }
        if (!warranty.deductible) {
          validPowertrainWarranties = false;
        }
      });
    }
    if ( newLaborPricing.payType === "Customer Pay") { 
      if(!newLaborPricing.rateType){
        return false;
      }
    }

    if(newLaborPricing.payType === "Warranty"){
      if(!newLaborPricing.warrantyType){
        return false;
      }
      if(!newLaborPricing.planCode || !newLaborPricing.planName){
        return false;
      }
    }
    if (newLaborPricing.payType === "Service Contract") {
      if (!newLaborPricing.warrantyVendor && !this.isServiceContractV2()) {
        return false;
      }

      if (!newLaborPricing.serviceContractSupplierId && this.isServiceContractV2()) {
        return false;
      } 

      if (!newLaborPricing.planCode) {
        return false;
      }
    }
    if (!validPowertrainWarranties) {
      return false;
    }
    return true;
  }
  validate(fieldName) {
    let valid = true;
    const { errors, newLaborPricing, powertrainWarrantyFields } = this.state;
    switch (fieldName) {
      case "skillAStandaloneRate": {
        valid = parseFloat(newLaborPricing.skillAStandaloneRate) <= 999;
        errors["skillAStandaloneRate"] = !valid
          ? "The hourly rate cannot be greater than 999"
          : "";
        break;
      }
      case "warrantyBaseCoverage": {
        valid =
          parseFloat(newLaborPricing.warrantyBaseCoverage) <= 99 &&
          parseFloat(newLaborPricing.warrantyBaseCoverage) > 0;
        errors["warrantyBaseCoverage"] = !valid
          ? "The Base coverage cannot be greater than 99 or less than 1"
          : "";
        break;
      }
      case "warrantyMileageCoverage": {
        valid =
          parseFloat(newLaborPricing.warrantyMileageCoverage) <= 999999 &&
          parseFloat(newLaborPricing.warrantyMileageCoverage) > 0;
        errors["warrantyMileageCoverage"] = !valid
          ? "The Mileage coverage cannot be greater than 999999 or less than 1"
          : "";
        break;
      }
      case "planName": {
        //only allow characters and numbers
        const regex = /^[a-zA-Z0-9]*$/;
        //valid = newLaborPricing.planName && regex.test(newLaborPricing.planName) ? true : false 
        errors["planName"] = !(newLaborPricing.planName && regex.test(newLaborPricing.planName))
          ? "The Plan name only allows numbers and characters"
          : "";
        break;
      }           
      case "": {
        powertrainWarrantyFields.forEach(warranty => {
          valid =
            powertrainWarrantyFields.filter(
              item => item.fuelType === warranty.fuelType
            ).length == 1;
          errors["fuelType"] = !valid
            ? "Duplicate Fuel Types are not allowed in your Powertrain Warranties. Please update to a different Fuel Type."
            : "";
        });
        break;
      }
      default: {
        powertrainWarrantyFields.forEach(warranty => {
          if (warranty.fuelType) {
            valid =
              powertrainWarrantyFields.filter(
                item => item.fuelType === warranty.fuelType
              ).length == 1;
            errors["fuelType"] = !valid
              ? "Duplicate Fuel Types are not allowed in your Powertrain Warranties. Please update to a different Fuel Type."
              : "";
          }
          if (warranty.hourlyRate) {
            valid = parseFloat(warranty.hourlyRate) <= 999;
            errors["hourlyRate"] = !valid
              ? "The hourly rate cannot be greater than 999"
              : "";
          }
          if (warranty.baseCoverage) {
            valid =
              parseFloat(warranty.baseCoverage) <= 99 &&
              parseFloat(warranty.baseCoverage) > 0;
            errors["baseCoverage"] = !valid
              ? "The Base coverage cannot be greater than 99 or less than 1"
              : "";
          }
          if (warranty.mileageCoverage) {
            valid =
              parseFloat(warranty.mileageCoverage) <= 999999 &&
              parseFloat(warranty.mileageCoverage) > 0;
            errors["mileageCoverage"] = !valid
              ? "The Mileage coverage cannot be greater than 999999 or less than 1"
              : "";
          }
        });
      }
    }
    valid = valid && this.isValidForm();
    return { errors, valid };
  }

  getAddPayload() {
    const { newLaborPricing, powertrainWarrantyFields } = this.state;
    //console.log("getAddPayload newLaborPricing---" + newLaborPricing)
    return {
      ...newLaborPricing,
      powertrainWarranties: JSON.stringify(powertrainWarrantyFields)
    };
  }

  saveHandler = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { errors, newLaborPricing,serviceContractSupplierMap } = this.state;
    console.log("saveHandler newLaborPricing---" + newLaborPricing)
    let isValid = true;
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    if (!isValid) {
      this.updateStatusBox(
        this.correctInvalidFielsError + " - " + this.getErrorStrings(errors),
        "error",
        false
      );
      return;
    }
    let data = this.getAddPayload();
    let url;
    if (this.isServiceContractV2()) {
      url = "/ops/proxyapi/ddsproxy/rest/proc/dealerLaborPricingRule";
    } else {  
      url = `/ops/proxyapi/ddsproxy/rest/table/dealerLaborPricingRule${
      newLaborPricing.dealerLaborPricingRuleId
        ? `/${newLaborPricing.dealerLaborPricingRuleId}?_method=put`
        : ``}`;
    }    
    makeSecureRestApi(
      {
        url,
        method: "post",
        data
      },
      response => {
        if (this.isServiceContractV2()) {
          if (response[0] && response[0].dealerLaborPricingRuleId) {
            this.updateStatusBox(this.savedMsg, "success", true);
            const dealerLaborPricingRule = response[0];
            this.props.updatePricingLaborAfterSave(
              dealerLaborPricingRule,
              newLaborPricing.dealerLaborPricingRuleId
            );
            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.hideSlider();
              }, 800);
            });
          }
        } else {
          if (response?.success) {
            this.updateStatusBox(this.savedMsg, "success", true);
            const { dealerLaborPricingRule } = response;
            this.props.updatePricingLaborAfterSave(
              dealerLaborPricingRule,
              newLaborPricing.dealerLaborPricingRuleId
            );
            this.setState({ dirty: false, valid: false }, () => {
              setTimeout(() => {
                this.props.hideSlider();
              }, 800);
            });
          }
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  };

  hasErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      if (value === "" || value === null) {
        // In case of valid error string
      } else if (value && typeof value === "string") {
        errCount++;
      }
    }
    return errCount !== 0;
  }
  getErrorStrings(state) {
    const array1 = Object.values(state);
    const iterator = array1.values();
    let msg = "";
    for (const value of iterator) {
      if (value && value !== "") {
        // In case of valid error string
        msg = msg + value + " ";
      }
    }
    return msg;
  }
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }

  isServiceContractV2 = () => {
    const { enableServiceContractV2 } = this.state;
    return enableServiceContractV2;
  }

  render() {
    const { localeStrings } = this.context;
    const {
      newLaborPricing,
      payTypeOptions,
      rateTypeOptions,
      valid,
      powertrainWarrantyFields,
      fuelTypes,
      showDeleteConfirmation,
      allVendorList,
      allProductList
    } = this.state;
    const showWarrantySelector = newLaborPricing.payType === "Warranty";
    const shorRateTypeSelector = newLaborPricing.payType === "Customer Pay";
    const showWarrantyDisabled = newLaborPricing.payType === "Service Contract";
    const showOEMFields = newLaborPricing.warrantyType === "OEM";
    if (newLaborPricing.make === "ANY"){
      //if make === ANY, set disabled of Warranty payTypeOption is true
      payTypeOptions.map(payTypeOption =>(payTypeOption.value === "Warranty"? payTypeOption.disabled = true: payTypeOption.disabled = false))
    }else{
      //if not, set disabled of Warranty payTypeOption is false
      payTypeOptions.map(payTypeOption => {if (payTypeOption.value === "Warranty"){return payTypeOption.disabled = false}} )
    }
    const powertrainWarrantyCards = powertrainWarrantyFields?.map(
      (warrantyField, index) => {
        const id = index;
        return (
          <AddPowertrainWarrantyForm
            key={`warranty-${id}`}
            field={warrantyField}
            index={index}
            fuelList={fuelTypes}
            extended={showOEMFields}
          />
        );
      }
    );

    let msg = this.saveWarrantyConfirmationMsg;
    const deleteModal = showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteLaborPricing"
        message={msg}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.confirmSaveChanges();
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );

    const statusBox = this.state.statusMsg ? (
      <div className="pull-left">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );

    let warrantyVendorSearchableSelect;
    if (this.isServiceContractV2()) {
      warrantyVendorSearchableSelect = 
        <SearchableSelect
          htmlId="serviceContractSupplierId"
          name="serviceContractSupplierId"
          label={showWarrantyDisabled ? localeStrings["xmm.portal.grid.service_contract_supplier"] :localeStrings["xmm.portal.grid.warranty_vendor"]}
          options={allVendorList}
          onChange={this.onChange}
          value={newLaborPricing.serviceContractSupplierId}
          displayDeselectOption={false}
          enableMultiSelect={false}
          maxHeight={280}
          required
          />;
    } else {
      warrantyVendorSearchableSelect = 
        <SearchableSelect
          htmlId="warrantyVendor"
          name="warrantyVendor"
          label={localeStrings["xmm.portal.grid.warranty_vendor"]}
          options={this.state.allVendorList}
          onChange={this.onChange}
          value={newLaborPricing.warrantyVendor}
          displayDeselectOption={false}
          enableMultiSelect={false}
          maxHeight={280}
        />
    }
    
    let warrantyProductSearchableSelect;
    if (this.isServiceContractV2()) {
      warrantyProductSearchableSelect =
      <Col
      xs="6"
      md="6"
       className={this.getClassName(showWarrantyDisabled)}
    >
      <SearchableSelect
        htmlId="serviceContractPolicyId"
        name="serviceContractPolicyId"
        label={localeStrings["xmm.portal.grid.service_contract_policy"]}
        options={allProductList}
        onChange={this.onChange}
        value={newLaborPricing.serviceContractPolicyId}
        displayDeselectOption={false}
        enableMultiSelect={false}
        maxHeight={280}
      />
    </Col>
    } else {
      warrantyProductSearchableSelect = <></>;
    } 

    let serviceContractRow1;
    if (this.isServiceContractV2()) {
      serviceContractRow1 =
        <Row className="column-row">
        <Col xs="6" md="6" 
          className={this.getClassName(showWarrantyDisabled)}>
          <TextInput
            htmlId="planCode"
            name="planCode"
            label={localeStrings["xmm.portal.common.policy_code"]}
            onChange={this.onChange}
            value={removeSpecialCharacters(newLaborPricing.planCode) || ""}
            maxLength={3}
            required
          />
        </Col>
        <Col xs="6" md="6"
          className={showWarrantyDisabled ? "" : "hide"}>
            <span >
            {this.context.localeStrings["xmm.portal.grid.labor_rate"]}{" "}
            <span className="xmm-red-label">*</span>{" "}
            <Popover
            htmlId="popoverSkillAStandaloneRate"
            popoverContent={this.addSkillAStandaloneRatTooltip}
            trigger={["click", "outsideClick"]}
            position="right"
          >
            <IconInfoOutline
              htmlId="skillAStandaloneRateInfo"
              className="hand-cursor"
            />
          </Popover>
          </span>
          <NumericInput
            htmlId="skillAStandaloneRate"
            name="skillAStandaloneRate"
            label={
              this.context.localeStrings[
                "xmm.portal.grid.labor_rate"
              ]
            }
            inputPrefix="$"
            displayLabel={false}
            onChange={this.onChange}
            value={newLaborPricing.skillAStandaloneRate || ""}
            maxValue="999"
            required
          />
        </Col>
      </Row>;
    } else {
      serviceContractRow1 = <></>;
    }
    
    let serviceContractRow2;
    if (this.isServiceContractV2()) {
      serviceContractRow2 =
      <Row className="column-row">
        <Col xs="6" md="6"
          className={showWarrantyDisabled ? "hide" : ""}>
        <TextInput
          htmlId="oem"
          name="make"
          label={localeStrings["xmm.portal.common.oem"]}
          disabled
          onChange={this.onChange}
          value={newLaborPricing.make || ""}
        />
      </Col>
      <Col xs="6" md="6"
        className={showWarrantyDisabled ? "hide" : ""}>
        <span>
          {this.context.localeStrings["xmm.portal.grid.labor_rate"]}{" "}
          <span className="xmm-red-label">*</span>{" "}
          <Popover
          htmlId="popoverSkillAStandaloneRate"
          popoverContent={this.addSkillAStandaloneRatTooltip}
          trigger={["click", "outsideClick"]}
          position="right"
          >
          <IconInfoOutline
            htmlId="skillAStandaloneRateInfo"
            className="hand-cursor"
          />
          </Popover>
        </span>
        <NumericInput
          htmlId="skillAStandaloneRate"
          name="skillAStandaloneRate"
          label={
            this.context.localeStrings[
              "xmm.portal.grid.hourly_rate"
            ]
          }
          inputPrefix="$"
          displayLabel={false}
          onChange={this.onChange}
          value={newLaborPricing.skillAStandaloneRate || ""}
          maxValue={999}
          required
        />
      </Col>
    </Row>;
    } else {
      serviceContractRow2 =
        <Row className="column-row">
          <Col xs={6} md={6}>
            <TextInput
              htmlId="oem"
              name="make"
              label={localeStrings["xmm.portal.common.oem"]}
              disabled
              onChange={this.onChange}
              value={newLaborPricing.make || ""}
            />
          </Col>
          <Col xs={6} md={6}>
              {this.context.localeStrings["xmm.portal.grid.labor_rate"]}{" "}
              <span className="xmm-red-label">*</span>{" "}
              <Popover
              htmlId="popoverSkillAStandaloneRate"
              popoverContent={this.addSkillAStandaloneRatTooltip}
              trigger={["click", "outsideClick"]}
              position="right"
              >
              <IconInfoOutline
                htmlId="skillAStandaloneRateInfo"
                className="hand-cursor"
              />
              </Popover>
              <NumericInput
                htmlId="skillAStandaloneRate"
                name="skillAStandaloneRate"
                label={
                  this.context.localeStrings[
                    "xmm.portal.grid.hourly_rate"
                  ]
                }
                inputPrefix="$"
                displayLabel={false}
                onChange={this.onChange}
                value={newLaborPricing.skillAStandaloneRate || ""}
                maxValue={999}
                required
              />
          </Col>
        </Row>;
    }

    const editSection = (
      <React.Fragment>
        <div className="xmm-tab-bar">
          {statusBox}
          <Button
            htmlId="saveAction"
            buttonStyle="primary"
            disabled={!valid}
            onClick={this.openConfirmationModal}
          >
            {this.saveLabel}
          </Button>
        </div>
        <div className="xmm-slider-tab-scroll">
          <Card htmlId="addDiscounDetailsId">
            <div className="comment-form">
              <Grid htmlId="settingGrid">
                <Row className="show-grid">
                  <Col xs="6" md="6">
                    <SelectInput
                      htmlId="payType"
                      name="payType"
                      label={localeStrings["xmm.portal.grid.pay_type"]}
                      options={payTypeOptions}
                      onChange={this.onChange}
                      value={newLaborPricing.payType}
                      displayDeselectOption={false}
                      disabled={newLaborPricing.dealerLaborPricingRuleId}
                      required
                    />
                  </Col>
                  <Col
                    xs="6"
                    md="6"
                    className={shorRateTypeSelector ? "" : "hide"}
                  >
                    <SelectInput
                      htmlId="rateType"
                      name="rateType"
                      label={localeStrings["xmm.portal.grid.rate_type"]}
                      options={rateTypeOptions}
                      onChange={this.onChange}
                      value={newLaborPricing.rateType}
                      displayDeselectOption={false}
                    />
                  </Col>
                  <Col
                    xs="6"
                    md="6"
                    className={this.getClassName(showWarrantyDisabled)}
                  >
                    <SelectInput
                      htmlId="warrantyType"
                      name="warrantyType"
                      label={showWarrantyDisabled && this.isServiceContractV2()? localeStrings["xmm.portal.grid.service_contract_type"] : localeStrings["xmm.portal.grid.warranty_type"]}
                      options={[
                        {
                          label: "3rd Party",
                          value: "3rd Party"
                        }
                      ]}
                      onChange={this.onChange}
                      value="3rd Party"
                      displayDeselectOption={false}
                      disabled
                      required
                    />
                  </Col>
                  <Col
                    xs={6.5}
                    md={6.5}
                    className={showWarrantySelector ? "" : "hide"}
                  >
                    <SelectInput
                      htmlId="warrantyType"
                      name="warrantyType"
                      label={localeStrings["xmm.portal.grid.warranty_type"]}
                      options={[
                        {
                          label:
                            localeStrings["xmm.portal.pricing_rules.oem_factory_warranty"],
                          value: "OEM Factory Warranty"
                        },
                        {
                          label:
                            localeStrings["xmm.portal.pricing_rules.oem_extended_warranty"],
                          value: "OEM Extended Warranty"
                        },
                        {
                          label:
                            localeStrings["xmm.portal.pricing_rules.oem_maintenance_plan"],
                          value: "OEM Maintenance Plan"
                        },
                      ]}
                      onChange={this.onChange}
                      value={newLaborPricing.warrantyType}
                      displayDeselectOption={false}
                    />
                  </Col>
                  <Col xs="6" md="6" className={showWarrantySelector ? "" : "hide"}>
                    <TextInput
                      htmlId="planName"
                      name="planName"
                      label={localeStrings["xmm.portal.grid.plan_name"]}
                      onChange={this.onChange}
                      value={newLaborPricing.planName || ""}
                      required
                    />
                  </Col>
                  <Col xs="6" md="6" className={showWarrantySelector ? "" : "hide"}>
                    <TextInput
                      htmlId="planCode"
                      name="planCode"
                      label={localeStrings["xmm.portal.grid.plan_code"]}
                      onChange={this.onChange}
                      value={newLaborPricing.planCode || ""}
                      required
                      maxLength={3}
                    />
                  </Col>
                  <Row className="column-row">
                    <Col
                      xs="6"
                      md="6"
                       className={this.getClassName(showWarrantyDisabled)}
                    >
                    {warrantyVendorSearchableSelect}
                    </Col>
                    {warrantyProductSearchableSelect}
                  </Row>
                  {serviceContractRow1}
                  <Col xs="12" md="12">
                    <TextInput
                      htmlId="laborRateDesc"
                      name="laborRateDesc"
                      label={localeStrings["xmm.portal.grid.labor_rate_desc"]}
                      disabled
                      onChange={this.onChange}
                      value={newLaborPricing.laborRateDescription || ""}
                      appendChild={
                        <Badge htmlId="badgeDefault">
                          {newLaborPricing.code}
                        </Badge>
                      }
                    />
                  </Col>
                  <Row className="column-row">
                    <Col
                      xs="6"
                      md="6"
                      className={
                        showWarrantySelector && showOEMFields ? "" : "hide"
                      }
                    >
                      <NumericInput
                        htmlId="warrantyBaseCoverage"
                        name="warrantyBaseCoverage"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.pricing_rules.base_coverage"
                          ]
                        }
                        inputSuffix={
                          localeStrings["xmm.portal.vehiclegroups.years"]
                        }
                        onChange={this.onChange}
                        value={newLaborPricing.warrantyBaseCoverage || ""}
                        maxValue="99"
                        minValue="1"
                        required
                      />
                    </Col>
                    <Col
                      xs="6"
                      md="6"
                      className={
                        showWarrantySelector && showOEMFields ? "" : "hide"
                      }
                    >
                      <NumericInput
                        htmlId="warrantyMileageCoverage"
                        name="warrantyMileageCoverage"
                        label={
                          this.context.localeStrings[
                            "xmm.portal.pricing_rules.mileage_coverage"
                          ]
                        }
                        inputSuffix={
                          localeStrings["xmm.portal.common.mileages.unit.miles"]
                        }
                        onChange={this.onChange}
                        value={newLaborPricing.warrantyMileageCoverage || ""}
                        maxValue="999999"
                        minValue="1"
                        required
                      />
                    </Col>
                  </Row>
                  {serviceContractRow2}                 
                </Row>
              </Grid>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {editSection}
        {deleteModal}
      </React.Fragment>
    );
  }
}

export default AddLaborPricingFormPage;

AddLaborPricingFormPage.propTypes = {
  rowRecord: PropTypes.object,
  hideSlider: PropTypes.func,
  updatePricingLaborAfterSave: PropTypes.func
};
/* eslint-enable no-console */
