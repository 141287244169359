/* eslint-disable no-console */
import React from "react";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import ModalDialog from "@cx/ui/ModalDialog";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../components/app-context";
import { FormattedMessage } from "react-intl";
import SearchableSelect from "@cx/ui/SearchableSelect";
import { isEmpty } from "../../../commonUtil/utils/object";

export default class CopyIntervalModal extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onChangeSelect = this.onChangeSelect.bind(this);

    this.state = {
      supportedMakes: [],
      intervalRecord: null,
      make: "",
      selections: [],
      errors: {
        make: ""
      }
    };
  }

  componentDidMount() {
    if (this.props.intervalRecord) {
      this.filterMakes();
    }
  }
  filterMakes() {
    const rawMakes = this.context.makelist;
    const currentMake = this.props.intervalRecord.make;
    if (rawMakes.length > 0) {
      const found = rawMakes.filter(function (makeobj) {
        return makeobj.make !== currentMake;
      });
      if (found.length > 0) {
        setTimeout(() => {
          this.setState({
            supportedMakes: found
          });
        }, 300);
      }
    }
  }
  /* change handler used on menuType, vehicles  */
  onChangeSelect = cxEvent => {
    const { name, value } = cxEvent.target;
    let optionValue = value;
    let selections = [];
    // console.log("copy change", value);

    if (value && value.length > 1) {
      const makes = value.map(item => {
        return item.make.toString();
      });
      selections = makes;
      optionValue = makes.length > 0 ? makes.toString() : "";
    } else if (value && value.length === 1) {
      optionValue = value[0].value.toString();
      selections.push(optionValue);
    } else {
      const { errors, make } = this.state;
      if (make && isEmpty(value)) {
        errors.make =
          this.context.localeStrings["xmm.portal.errors.make_is_required"];
      }
    }
    if (optionValue)
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            [name]: value,
            selections,
            errors
          };
        },
        () => {
          // callback to validate form
          // console.log(selections, value);
        }
      );
  };

  copyHandler = () => {
    this.props.copyAction(this.state.selections);
  };

  render() {
    const { localeStrings } = this.context;
    const { make, errors, selections } = this.state;
    const copyDisable = selections.length > 0 ? false : true;
    const msgSection = (
      <div className="xmm-modal-help-text">
        {
          this.context.localeStrings[
            "xmm.portal.common.select_makes_to_copy_intervals"
          ]
        }
      </div>
    );

    return (
      <React.Fragment>
        <ModalDialog
          htmlId="copyIntervalMakeDialog"
          show={this.props.show}
          backdrop={"static"}
          className="xmm-validate-opcode-modal"
          header={
            <ModalDialog.Title>
              {
                this.context.localeStrings[
                  "xmm.portal.common.copy_to_another_make"
                ]
              }
            </ModalDialog.Title>
          }
          onHide={this.props.closeDialog}
          footer={false}
        >
          <Grid htmlId="copyIntervalContent" className={""}>
            {msgSection}
            <Row className="show-grid">
              <Col xs={10} md={10}>
                <SearchableSelect
                  displayPlaceholder={true}
                  placeholder={localeStrings["xmm.portal.common.search_label"]}
                  className="xmm-scrollable-select"
                  htmlId="make"
                  label="Make"
                  name="make"
                  required
                  enableMultiSelect={true}
                  maxHeight={150}
                  onChange={this.onChangeSelect}
                  options={this.state.supportedMakes}
                  value={make}
                  displayLabel={false}
                  error={errors.make}
                />

                <ButtonToolbar>
                  <Button
                    htmlId="cancelCopyButton"
                    className="float-right"
                    text="Cancel Button"
                    buttonStyle="secondary"
                    onClick={this.props.closeDialog}
                  >
                    <FormattedMessage
                      defaultMessage="Cancel"
                      id="xmm.portal.common.cancel_button"
                    />
                  </Button>
                  <Button
                    htmlId="okCopyButton"
                    className="float-right"
                    text="Copy"
                    buttonStyle="primary"
                    disabled={copyDisable}
                    onClick={this.copyHandler}
                  >
                    <FormattedMessage
                      defaultMessage="Copy"
                      id="xmm.portal.common.copy_label"
                    />
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Grid>
        </ModalDialog>
      </React.Fragment>
    );
  }
}

CopyIntervalModal.propTypes = {
  show: PropTypes.bool,
  intervalRecord: PropTypes.object,
  copyAction: PropTypes.func,
  closeDialog: PropTypes.func
};
/* eslint-enable no-console */
