import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "@cx/ui/Button";
import CheckBoxList from "@cx/ui/CheckBoxList";
import RadioButtonList from "@cx/ui/RadioButtonList";
import Col from "@cx/ui/Col";
import DatePicker, {
  registerLocale,
  getDefaultLocale,
  setDefaultLocale
} from "react-datepicker";
// import en from "date-fns/locale/en";
import { enCA, enUS, enAU, frCA } from "date-fns/locale";
import AlertBox from "../../../../commonUtil/components/templates/AlertBox";
import Alert from "@cx/ui/Alert";
import { AppContext } from "../../../../components/app-context";
import { isArrayExist, doesEmpty } from "../../../../commonUtil/utils/object";
import { makeSecureRestApi } from "../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import * as transformer from "../../../utils/DataTransformer";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import { xlate } from "../../../../commonUtil/i18n/locales";
import * as taskrunner from "../../../utils/TaskRunner";

import "react-datepicker/dist/react-datepicker.css";
// import "bootstrap/dist/css/bootstrap.min.css";
/* eslint-disable no-console */
export default class PublishSchedule extends Component {
  static propTypes = {
    onMakeChange: PropTypes.func,
    closeslider: PropTypes.func
  };
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.onMakeChange = this.onMakeChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onChangeEmailChk = this.onChangeEmailChk.bind(this);
    this.publishHandler = this.publishHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    const localeStrings = context.localeStrings;
    this.initializeLocaleValues();
    this.state = {
      checkAll: true,
      showPicker: false,
      disablePublish: true,
      localeStrings,
      // field props
      supportedMakes: [],
      selectedMakes: [],
      publishNow: "1", // Now = 1; Future = 0
      futureDate: "",
      futureTime: "",
      userEmailCheck: false,
      adminEmailCheck: false,
      isMakesLoaded: false,
      // error props
      dateRequired: false,
      timeRequired: false,
      errors: {},
      error: null,
      // opcode banner props
      showOpcodeBanner: false,
      opcodeStatusMsg: "",
      // publish banner Props
      statusType: "",
      statusMsg: "",
      statusCode: "",
      disableAction: false,
      taskMsg: "",
      disableCancel: false
    };
  }
  componentDidMount() {
    gtmEvents.trackGtagPageview("/publish/publish-schedule");
    this.getMakeOptions();
    this.getAllMakesValidationStatus(this.context.dealer.dealerCode);
    this.triggerPublishStatus();
    // this.getAllMakesPublishState();
  }

  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.stopTask(false);
  }

  initializeLocaleValues() {
    registerLocale("fr_CA", frCA);
    registerLocale("en_US", enUS);
    registerLocale("en_CA", enCA);
    registerLocale("en_AU", enAU);
    setDefaultLocale(this.context.locale);
    console.log(getDefaultLocale());
    this.dateTimeFormat = this.getDateTimeFormat();
    this.correctInvalidFielsError = xlate(
      "xmm.portal.errors.correct_invalid_fields"
    );
    this.timeLabel = xlate("xmm.portal.common.time_lbl");
    this.selectFutureDateLabel = xlate("xmm.portal.publish.select_future_date");
  }
  getDateTimeFormat() {
    const { locale } = this.context;
    switch (locale) {
      case "en_CA":
      case "fr_CA":
        return "yyyy/MM/dd HH:mm";
      case "en_AU":
        return "dd/MM/yyyy hh:mm aa";
      default:
        return "MM/dd/yyyy hh:mm aa";
    }
  }
  setErrors(fieldName, errorMsg) {
    const { errors } = this.state;
    this.setState({
      errors: { ...errors },
      [fieldName]: errorMsg
    });
  }
  getMakeOptions() {
    const rawMakes = this.context.makelist;
    const supportedMakes = [];
    const selectedMakes = [];
    if (rawMakes.length > 0) {
      rawMakes.forEach(function (obj) {
        if (obj.make) {
          obj.value = obj.make;
          obj.label = obj.make;
          supportedMakes.push(obj);
          selectedMakes.push(obj.make);
        }
      });
      // react takes time to update state, need timeout on sync block.
      setTimeout(() => {
        this.setState(
          {
            supportedMakes,
            selectedMakes,
            disablePublish: false,
            isMakesLoaded: true
          },
          () => {
            // console.log("Dealer catalogs [PublishSchedule.js]", rawMakes);
          }
        );
      }, 200);
    }
  }
  // future date change event
  onDateChange = date => {
    console.log(date);
    const name = "futureDate";
    const value = date;
    const isValid = true;
    const { errors } = Object.assign({}, this.state);
    // if (domEvent && domEvent.type === "blur") {
    //   errors[name] = !doesEmpty(value)
    //     ? ""
    //     : xlate("xmm.portal.errors.date_required");
    //   this.setState({ errors });
    //   return;
    // }
    const { futureDate } = this.state;
    if (futureDate === value) {
      return;
    }
    errors[name] = !doesEmpty(value)
      ? ""
      : xlate("xmm.portal.errors.date_required");
    if (value) {
      this.setState({
        [name]: value,
        disablePublish: !isValid,
        errors,
        timeRequired: true
      });
    } else {
      this.setState({
        errors,
        disablePublish: !isValid,
        [name]: ""
      });
    }
  };
  // future time change event
  onTimeChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    // console.log("Time change", value);
    const { errors } = Object.assign({}, this.state);
    if (domEvent && domEvent.type === "blur") {
      errors[name] = !doesEmpty(value)
        ? ""
        : xlate("xmm.portal.errors.time_required");
      this.setState({ errors });
      return;
    }
    const { futureTime } = this.state;
    if (futureTime === value) {
      return;
    }
    errors[name] = !doesEmpty(value)
      ? ""
      : xlate("xmm.portal.errors.time_required");
    if (value) {
      this.setState({
        errors,
        disablePublish: !isValid,
        [name]: value
      });
    } else {
      this.setState({
        errors,
        disablePublish: !isValid,
        [name]: ""
      });
    }
  };
  onChangeSelectAll = cxEvent => {
    const { checked } = cxEvent.target;
    const selectedMakes = [];
    let checkAll = false;
    if (checked) {
      checkAll = true;
      const { makelist } = this.context;
      makelist.forEach(function (obj) {
        if (obj.make) {
          selectedMakes.push(obj.make);
        }
      });
    }
    this.setState({ selectedMakes, checkAll });
  };
  // Makes checkbox change event
  onMakeChange = cxEvent => {
    const { name, value } = cxEvent.target;
    let isValid = true;
    const { errors } = Object.assign({}, this.state);
    if (value && value.length > 0) {
      isValid = true;
    } else if (value === null || value.length === 0) {
      isValid = false;
    }
    errors[name] = isValid
      ? undefined
      : xlate("xmm.portal.errors.atleast_one_make_required");
    // console.log("Make change", isValid, value, errors);
    this.setState({
      [name]: value,
      disablePublish: !isValid,
      errors
    });
  };

  // Future/ Now radio change
  onRadioChange = cxEvent => {
    let showPicker = false; // default Now = 1
    let dateRequired = false;
    let disablePublish = false;
    const { value, name } = cxEvent.target;
    const { errors } = Object.assign({}, this.state);
    errors[name] = "";
    // Future = 0
    // console.log("radio", value, typeof value);
    if (value === "0") {
      showPicker = true;
      dateRequired = true;
      disablePublish = true;
      // errors[name] = "Future Date Time fields are Required";
    } else if (value === "1") {
      disablePublish = false;
      // errors[name] = undefined;
    } else if (!value) {
      disablePublish = true;
    }
    if (value) {
      this.setState({
        errors,
        publishNow: value,
        disablePublish,
        futureDate: "",
        futureTime: "",
        dateRequired,
        showPicker
      });
    }
  };

  // call to publish changes
  publishHandler = event => {
    event.preventDefault();
    let isValid = true;
    const { errors } = this.state;
    // check if any errors exist on fields
    if (Object.keys(errors).length) {
      isValid = !this.hasErrorStrings(errors);
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      toast.warning(this.correctInvalidFielsError);
      return;
    }
    this.setState({
      disablePublish: true,
      disableAction: true
    });
    gtmEvents.gtmTrackEvent("xmm.publish.submit_click");
    this.savePublishCatalogs();
  };

  /*
   This fun() checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings.
  */
  hasErrorStrings = errors => {
    const array1 = Object.values(errors);
    const iterator = array1.values();
    let errCount = 0;
    for (const value of iterator) {
      // console.log("errors", value, array1);
      if (!value || value === "" || value === null || value.length === 0) {
        // In case of valid error string
      } else if (value && value.length > 1) {
        errCount++;
      }
    }
    return errCount === 0 ? false : true;
  };

  cancelHandler = e => {
    e.preventDefault();
    const payload = this.getCancelPayload();
    // console.log("cancel payload", payload);

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: "ops/xmmpublish/cancelPublishes",
        method: "post",
        data: payload,
        params: {},
        headers
      },
      () => {
        // console.log("cancel - blank response");
        this.resetProcess();
        this.setState({
          disablePublish: false,
          disableAction: false
        });
      },
      () => {
        const msg = xlate("xmm.portal.errors.cancel_publish");
        toast.error(msg, {
          closeOnClick: true
        });
        this.setState({ disableCancel: true });
      }
    );
  };

  getMakeAndVariantList() {
    const rawMakes = this.context.makelist;
    const makes = [];
    const variants = [];
    if (rawMakes.length > 0) {
      rawMakes.forEach(function (m) {
        if (m.make && m.make !== "ANY") {
          makes.push(m.make);
          variants.push(m.variant);
        }
      });
    }
    const makeobj = {
      makes,
      variants
    };
    return makeobj;
  }

  getCancelPayload() {
    const makeobj = this.getMakeAndVariantList();
    const supportedMakes = makeobj.makes;
    const supportedVariants = makeobj.variants;

    const payload = {
      authId: "",
      makes: supportedMakes.length > 0 ? supportedMakes.toString() : "",
      variants:
        supportedVariants.length > 0 ? supportedVariants.toString() : "",
      dealerCode: this.context.dealer.dealerCode,
      isSnapshot: 0 // 0 = set this param to support Publish snapshot
    };
    return payload;
  }
  findSelectedVariants(selectedMakes) {
    const rawMakes = this.context.makelist;
    const variants = [];
    if (rawMakes.length > 0 && selectedMakes.length > 0) {
      selectedMakes.forEach(function (match) {
        const found = rawMakes.filter(function (makeobj) {
          return makeobj.make === match;
        });
        if (found.length === 1) {
          variants.push(found[0].variant);
        }
      });
      // console.log("variants", variants);
    }
    return variants;
  }
  getPublishPayload() {
    const { locale } = this.context;
    const { dealerCode, catalogAdminEmail } = this.context.dealer;
    const { username, email } = this.context.user;
    const { selectedMakes, futureDate, futureTime, publishNow } = this.state;
    const futurePublish = publishNow === "0" ? true : false;
    let inHours = 0;
    const dateToPublish = new Date();
    if (futurePublish) {
      // Future = 0
      if (!futureDate) {
        inHours = this.getInHours(dateToPublish);
      } else {
        inHours = this.getHoursFromDateTime(futureDate, futureTime);
      }
    } else {
      // Now = 1
      inHours = this.getInHours(dateToPublish);
    }
    const selectedVariants = this.findSelectedVariants(selectedMakes);
    const adminEmail = this.state.adminEmailCheck ? catalogAdminEmail : "";
    const userEmail = this.state.userEmailCheck ? email : "";
    const payload = {
      authId: "",
      makes: selectedMakes.length > 0 ? selectedMakes.toString() : "",
      variants: selectedVariants.length > 0 ? selectedVariants.toString() : "",
      dealerCode,
      username,
      inHours,
      adminEmail,
      userEmail,
      isSnapshot: 0, // 0 = set this param to support Publish snapshot
      isAutoPublish: 0, // 0 = auto publish is false
      locale
    };
    return payload;
  }
  /**
   * get the date from the date picker (calendar) and time picker (time string);
   * combine to create a date/time as date object
   */
  getHoursFromDateTime(dateStr, timeStr) {
    const today = new Date();
    let dt = dateStr;
    if (timeStr) dt = dateStr + " " + timeStr;
    const futureDate = new Date(dt);
    const diff = futureDate.getTime() - today.getTime();
    const seconds = diff / 1000;
    let inHours = seconds / 60 / 60;
    // console.log("future date convert", inHours, diff);
    if (inHours > 0) {
      inHours *= 100;
      inHours = Math.round(inHours);
      inHours /= 100;
      return inHours;
    } else {
      return 0;
    }
  }

  /**
   * calculate the difference between user picked date/time and current date/time (in user's browser's time zone);
   * calculate the difference in hours; round to 2 decimal places;
   * if current date time is greater than user picked date because user did not pick a time, send 0;
   * value of 0 for inHours implies immediate publish to the user
   */
  getInHours(dateToPublish) {
    const currDate = new Date();
    let inHours = dateToPublish - currDate;
    if (inHours > 0) {
      inHours /= 3600 * 1000;
      inHours *= 100;
      inHours = Math.round(inHours);
      inHours /= 100;
      return inHours;
    } else {
      return 0;
    }
  }
  savePublishCatalogs() {
    const payload = this.getPublishPayload();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    this.statusCheckCount = 1;
    makeSecureRestApi(
      {
        url: "ops/xmmpublish/publishMakes",
        method: "post",
        data: payload,
        params: {},
        headers
      },
      () => {
        // console.log("Save Publish - blank response");
        // this.getAllMakesPublishState();
        this.triggerPublishStatus();
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  closeSlider = () => {
    // console.log("call parent to close slider");
    gtmEvents.gtmTrackEvent("xmm.publish.validate_opcodes_link");
    this.props.closeslider();
  };
  /* This method returns publish status for all makes
   */
  getAllMakesPublishState() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    const params = {
      dealerCode: this.context.dealer.dealerCode,
      isSnapshot: 0
    };
    makeSecureRestApi(
      {
        url: "ops/xmmpublish/getPublishStates",
        method: "get",
        data: {},
        params,
        headers
      },
      response => {
        if (response) {
          this.publishStatesCallback(response);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_dealer_data");
        toast.error(msg);
      }
    );
  }
  publishStatesCallback(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
      }
      const { locale } = this.context;
      const statusobj = transformer.transformPublishStates(
        datalist,
        locale,
        this.state.selectedMakes,
        this.statusCheckCount
      );
      // console.log("publish state", statusobj);
      // disable publish form until status is completed
      if (statusobj && Object.keys(statusobj).length > 0) {
        const disable =
          statusobj.statusCode === "PENDING" ||
          statusobj.statusCode === "FUTURE"
            ? true
            : false;
        if (statusobj.statusCode === "NONE") {
          console.log("## STOP Task", statusobj.statusCode);
          this.stopTask(false);
        }
        this.setState({
          taskMsg: statusobj.statusCode,
          statusType: statusobj.statusType,
          statusMsg: statusobj.statusMsg,
          statusCode: statusobj.statusCode,
          disableAction: disable
        });
        this.statusCheckCount++;
      } else {
        this.resetProcess();
      }
    }
  }
  resetProcess() {
    this.statusCheckCount = 0;
    this.setState(
      {
        taskMsg: "",
        statusType: "",
        statusMsg: "",
        statusCode: "",
        disableAction: false,
        disableCancel: false
      },
      () => {
        // callback if any
        this.stopTask(false);
      }
    );
  }

  /*
    Show opcode status banner when last validation date is more than 30 days old for atleast one make
  */
  getAllMakesValidationStatus(dealerCode) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: "ops/proxyapi/ddsproxy/rest/proc/getAllOpcodeValidationStatus",
        method: "get",
        data: {},
        params: { dealerCode },
        headers
      },
      response => {
        if (response) {
          this.validationStatusCallback(response);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_dealer_data");
        toast.error(msg);
      }
    );
  }
  validationStatusCallback(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
      }
      const { opcodeScoringEnabled } = this.context.dealer;
      const status = transformer.transformOpcodeData(
        datalist,
        opcodeScoringEnabled
      );
      let opcodeStatusMsg = "";
      if (status) {
        opcodeStatusMsg = xlate("xmm.portal.publish.operation_opcode_msg");
      }
      this.setState({
        showOpcodeBanner: status,
        opcodeStatusMsg
      });
    }
  }

  /* User Email checkbox change handler */
  onChangeEmailChk = event => {
    const target = event.target;
    const { name } = target;
    // const newValue = target.checked ? 1 : 0;
    this.setState({
      [name]: target.checked
    });
  };

  /* When Page loaded, run task to get recent publish status for each make with 30 seconds interval;
     Stop task until publish status is completed for each make */
  triggerPublishStatus() {
    this.stopTask(false); // This will cancel all previous tasks.
    this.getAllMakesPublishState();
    this.startTask();
  }

  // execute callback() after 30 seconds. since publish process in queue will take 30 seconds to update DB table
  startTask() {
    taskrunner.startTask(this.runStatus, this);
  }

  /* These methods used to run getStatus() task in an interval */
  runStatus(ctrl) {
    ctrl.getAllMakesPublishState();
  }
  stopTask(doRefresh) {
    taskrunner.stopTask();
    if (doRefresh) {
      // callback if any data to refresh when task status completed.
    }
  }

  render() {
    let widget = null;
    let editSection = null;
    let msgSection = null;
    let opcodeMsgBanner = null;
    const { catalogAdminEmail } = this.context.dealer;
    const { email } = this.context.user;
    const {
      errors,
      showPicker,
      statusCode,
      statusMsg,
      disableAction,
      disablePublish,
      disableCancel,
      statusType,
      userEmailCheck,
      adminEmailCheck
    } = this.state;
    // disable fields, show cancel when publish in progress
    const classHide = disableAction ? "" : "hide";
    const classPicker = showPicker ? "" : "hide";
    const dirtyCheck = disableAction || disablePublish ? true : false;
    const classMask = disableAction ? "xmm-mask" : "";
    const locationDescriptor = {
      pathname: "/opcodevalidation",
      search: window.location.search
    };
    const linkSection = (
      <Link
        to={locationDescriptor}
        className="nav__link"
        onClick={event => this.closeSlider(event, "opcodevalidation")}
      >
        {xlate("xmm.portal.publish.click_validate_opcodes_link")}
      </Link>
    );
    if (statusMsg) {
      const showMask =
        statusCode === "PENDING" || statusCode === "FUTURE" ? true : false;
      msgSection = (
        <AlertBox
          htmlId="publishBanner"
          type={statusType}
          closeButton={false}
          linkHtml={null}
          message={statusMsg}
          loading={showMask}
        />
      );
    }
    if (this.state.opcodeStatusMsg) {
      opcodeMsgBanner = (
        <Alert
          htmlId="opcodeMsgBanner"
          className=""
          type={"warning"}
          displayCloseButton={true}
        >
          {this.state.opcodeStatusMsg} {linkSection}
        </Alert>
      );
    }

    // show form when edit clicked or add clicked
    if (this.state.isMakesLoaded) {
      const { makelist } = this.context;
      const checkAllClassname =
        makelist && makelist.length > 10
          ? "xmm-checkbox-container  xmm-horizontal-form"
          : "hide";
      editSection = (
        <div className="xmm-publish-form">
          <div className={"xmm-mask-container " + classMask}>
            <h5>{xlate("xmm.portal.publish.which_make_to_publish_lbl")}</h5>
            <div className={checkAllClassname}>
              <input
                className="form-checkbox"
                name="checkAll"
                id="checkAll"
                type="checkbox"
                checked={this.state.checkAll}
                onChange={this.onChangeSelectAll}
              />
              <span className="xmm-checkmark" />
              <span className="label-checkbox">Check All</span>
            </div>
            <CheckBoxList
              htmlId="makesChecklist"
              name="selectedMakes"
              label={xlate("xmm.portal.publish.which_make_to_publish_lbl")}
              displayLabel={false}
              onChange={this.onMakeChange}
              options={this.state.supportedMakes}
              required={true}
              error={errors.selectedMakes}
              values={this.state.selectedMakes}
            />
            <h5>{xlate("xmm.portal.publish.when_to_publish_lbl")}</h5>
            <Col xs={12} md={12} className="full-col">
              <Col xs={3} md={2} className="full-col">
                <RadioButtonList
                  htmlId="RadioButtonListDefault"
                  name="publishNow"
                  required
                  error={errors.publishNow}
                  label={xlate("xmm.portal.publish.when_to_publish_lbl")}
                  displayLabel={false}
                  onChange={this.onRadioChange}
                  value={this.state.publishNow}
                  className="xmm-radio-form"
                  options={[
                    {
                      value: "1",
                      label: xlate("xmm.portal.publish.now_lbl")
                    },
                    {
                      value: "0",
                      label: xlate("xmm.portal.publish.future_time_lbl")
                    }
                  ]}
                />
              </Col>
              <Col xs={5} md={5}>
                <div className="xmm-datepicker">
                  <DatePicker
                    name="futureDate"
                    className={classPicker}
                    selected={this.state.futureDate}
                    onChange={this.onDateChange}
                    onChangeRaw={e => e.preventDefault()}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption={this.timeLabel}
                    minDate={new Date()}
                    placeholderText={this.selectFutureDateLabel}
                    dateFormat={this.dateTimeFormat}
                  />
                </div>
              </Col>
              {/*
              <Col xs={3} md={3}>
                <DatePicker
                  htmlId="DatePickerDefaultSettings"
                  label={xlate("xmm.portal.common.date_lbl")}
                  name="futureDate"
                  className={`xmm-radio-form ${classPicker}`}
                  textFieldFormat={xlate("xmm.portal.publish.date_format_lbl")}
                  selectedDate={this.state.futureDate}
                  onDateChange={this.onDateChange}
                  required={this.state.dateRequired}
                  displayLabel={false}
                  error={errors.futureDate}
                />
              </Col>
              <Col xs={3} md={3}>
                <TimePicker
                  htmlId="TimePickerDefaultSettings"
                  label={xlate("xmm.portal.common.time_lbl")}
                  name="futureTime"
                  className={`xmm-radio-form ${classPicker}`}
                  placeholder={xlate("xmm.portal.common.time_lbl")}
                  onChange={this.onTimeChange}
                  selectedTime={this.state.futureTime}
                  required={this.state.timeRequired}
                  displayLabel={false}
                  error={errors.futureTime}
                />
              </Col>
              */}
            </Col>
            <h5>{xlate("xmm.portal.publish.who_notify_lbl")}?</h5>
            <Col xs={12} md={12} className="full-col">
              <div className="xmm-checkbox-container xmm-horizontal-form">
                <input
                  className="form-checkbox"
                  name="userEmailCheck"
                  id="userEmail"
                  type="checkbox"
                  disabled={!email ? true : false}
                  checked={userEmailCheck || false}
                  onChange={this.onChangeEmailChk}
                />
                <span className="xmm-checkmark" />
                <span className="label-checkbox full-label">
                  {xlate("xmm.portal.common.me_lbl")} ({email})
                </span>
              </div>
              <div className="xmm-checkbox-container xmm-horizontal-form">
                <input
                  className="form-checkbox"
                  name="adminEmailCheck"
                  id="adminEmail"
                  type="checkbox"
                  disabled={!catalogAdminEmail ? true : false}
                  checked={adminEmailCheck || false}
                  onChange={this.onChangeEmailChk}
                />
                <span className="xmm-checkmark" />
                <span className="label-checkbox full-label">
                  {xlate("xmm.portal.publish.catalog_admin_lbl")} (
                  {catalogAdminEmail})
                </span>
              </div>
            </Col>
          </div>
          <Col xs={12} md={12} className="full-col">
            <ButtonToolbar>
              <Button
                htmlId="savePublishBtn"
                onClick={this.publishHandler}
                disabled={dirtyCheck}
                buttonStyle="primary"
              >
                {xlate("xmm.portal.nav.publish")}
              </Button>
              <Button
                htmlId="cancelPublishBtn"
                onClick={this.cancelHandler}
                className={classHide}
                buttonStyle="danger"
                disabled={disableCancel}
              >
                {xlate("xmm.portal.publish.cancel_publish_lbl")}
              </Button>
            </ButtonToolbar>
          </Col>
          <Col xs={12} md={12} className="full-col">
            <div className="xmm-help-text">
              {xlate("xmm.portal.publish.disclaimer_txt")}
            </div>
          </Col>
        </div>
      );
    }
    widget = (
      <div className="xmm-tab-scroll-content">
        {opcodeMsgBanner}
        {msgSection}
        {editSection}
      </div>
    );

    return widget;
  }
}
