import React from "react";
import { PropTypes } from "prop-types";
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";
// import { Provider } from "react-redux";

const Root = () => (
  <BrowserRouter className="main-layout">
    <div>
      <Route path="/" component={App} />
    </div>
  </BrowserRouter>
);

export default Root;

Root.propTypes = {
  store: PropTypes.object
};
