import React from "react";

export default React.createContext({
  appContext: null,
  localeStrings: {},
  locale: "",
  webKey: "",
  dealerCode: "",
  dealerCatalogs: [],
  makelist: []
});
