/* eslint-disable no-console */
import React, { Component } from "react";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import { PropTypes } from "prop-types";
/** Reusable popup - pass props {title, msg, callback func()} from parent */
class DirtyCheckPopup extends Component {
  state = {
    showModal: this.props.showDirtyModal || false,
    title: this.props.title || "Confirm Changes",
    message:
      this.props.message ||
      "There are unsaved changes. Do you want to save the changes?",
    okText: this.props.okText || "Ok",
    cancelText: this.props.cancelText || "Cancel",
    hideCancel: this.props.hideCancel || false,
    hideOk: this.props.hideOk || false
  };

  /* local handler - to close speed bump and execute callback  */
  customClose = () => {
    this.props.discardAction();
    this.props.cancelAction();
  };
  render() {
    const { title, message, hideCancel, hideOk, okText, cancelText } =
      this.state;
    const classOk = hideOk ? "hide" : "";
    const classCancel = hideCancel ? "hide" : "";
    return (
      <React.Fragment>
        <ModalDialog
          htmlId="dirtyCheckPopup"
          // className="sample-modal-dialog"
          show={this.props.showDirtyModal}
          header={<ModalDialog.Title>{title}</ModalDialog.Title>}
          footer={
            <div>
              <Button
                htmlId="popupDiscardbtn"
                buttonStyle="danger"
                className={classOk}
                onClick={this.customClose}
                text="Discard"
              >
                {okText}
              </Button>
              <Button
                htmlId="popupCancelbtn"
                buttonStyle="primary"
                className={classCancel}
                onClick={this.props.cancelAction}
                text="Stay"
              >
                {cancelText}
              </Button>
            </div>
          }
          onHide={this.props.cancelAction}
        >
          <div>
            <p>{message}</p>
          </div>
        </ModalDialog>
      </React.Fragment>
    );
  }
}
export default DirtyCheckPopup;

DirtyCheckPopup.propTypes = {
  showDirtyModal: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  hideCancel: PropTypes.bool,
  hideOk: PropTypes.bool,
  discardAction: PropTypes.func,
  cancelAction: PropTypes.func
};
