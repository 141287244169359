/* eslint-disable react/prop-types */
import React from "react";
import isNull from "lodash/isNull";
import TruncateText from "@cx/ui/TruncateText";
import { useServiceSearchContext } from "../../../state/service-search.context";
import { priceValueFormatter } from "../../../utils/format.util";
import { UnitOfMeasureMap } from "../../../utils/search.constants";
import { doesEmpty, convertMinutesToHours } from "../../../utils/quote.util";
import Badge from "@cx/ui/Badge";

const MenuServiceDetails = props => {
  const { state } = useServiceSearchContext();
  const { localeStrings, debugQuote } = state;
  const { service, expandDetails, isPackagePriceOverriden } = props;

  const partsExist =
    Array.isArray(service.part) && service.part.length > 0 ? true : false;
  const laborExist =
    !isNull(service.scheduledLaborPrice) || !isNull(service.durationMins)
      ? true
      : false;

  const showPartLabor = partsExist || laborExist ? true : false;
  const clsRow = expandDetails && showPartLabor ? "pkg-quote-details" : "hide";
  let laborHtml = null;
  let partsHtml = null;
  const clsLabor = laborExist ? "pkg-quote-labor no-border" : "hide";
  const clsParts = partsExist ? "pkg-quote-parts no-border" : "hide";
  const clsPartsTable = partsExist ? "pkg-parts-box" : "hide";
  const clsPartShowHide = expandDetails ? "pkg-parts-container" : "hide";

  // fix: read calculated fields added to service{} like finalLaborPrice, partsPrice from menuService
  const laborServiceId = service.id;
  const laborPrice = Number(service?.finalLaborPrice || 0);
  // read laborTime as durationMins from menuService
  let laborTime = Number(service?.durationMins || 0);
  if (!doesEmpty(laborTime)) {
    laborTime = convertMinutesToHours(laborTime);
  }
  if (!doesEmpty(laborTime) || laborPrice) {
    const laborPriceVal = priceValueFormatter(laborPrice);

    laborHtml = (
      <div
        className={[clsLabor, "margin-left-18"].join(" ")}
        key={"labor-" + laborServiceId}
      >
        <div className="labor-title">
          <span>
            {localeStrings["sq.search.common.edit_service.summary.labor_lbl"]} -{" "}
          </span>
          <span className="labor-time">{laborTime}</span>
          <span className="labor-hours">
            {localeStrings["sq.newquote.summary.hours_lbl"]}
          </span>
        </div>
        {isPackagePriceOverriden ? (
          ""
        ) : (
          <>
            {!debugQuote
              ? ""
              : service?.laborPriceOverridden && (
                  <span className="override-badge-container">
                    <Badge htmlId="overrideBadge" color="red">
                      {priceValueFormatter(service.totalLaborPriceOverride)}
                    </Badge>
                  </span>
                )}
            <div className="labor-price">{laborPriceVal}</div>
          </>
        )}
      </div>
    );
  }
  // Parts Table
  const styleLine = {
    textDecoration: "none"
  };
  // fix: always read partsPrice from service has with/without parts[]
  // TODO: With util calculation logic, parts price returned in finalPartsPrice- check this
  const totalPrice = service?.finalPartsPrice;
  if (service.hasOwnProperty("part") && service.part.length > 0) {
    const childHtml = service.part.map((partObj, index) => {
      const unitQty =
        partObj.partType === "fluid" && partObj.adjustedQuantity > 0
          ? UnitOfMeasureMap[partObj.adjustedUom]
          : "";
      const keyId = "part-" + partObj.extPartId + "-" + index;
      const partNumber = partObj.oemPartNumber || "";
      const showPartNumTip =
        partNumber && partNumber.trim().length > 10 ? true : false;

      return (
        <div className="parts-row" key={keyId}>
          <div className="part-desc">
            {partObj.description || ""}
            <span className="padding-left-4">{partObj.oilType || ""}</span>
          </div>
          <TruncateText htmlId="partNumberText" displayToolTip={showPartNumTip}>
            <div className="part-number">{partNumber || ""} </div>
          </TruncateText>
          {partObj.hasOwnProperty("adjustedQuantity") ? (
            <div className="qty">
              {localeStrings["sq.search.common.edit_service.summary.qty_lbl"]}:
              <span className="padding-left-4">
                {partObj.adjustedQuantity > 0 ? partObj.adjustedQuantity : ""}
              </span>
              <span className="padding-left-2">{unitQty}</span>
            </div>
          ) : (
            <div className="qty" />
          )}
          {isPackagePriceOverriden ? (
            ""
          ) : (
            <div className="unit-price" style={styleLine}>
              {partObj.unitPrice >= 0
                ? priceValueFormatter(partObj.unitPrice)
                : ""}
              {partObj.partType === "part" ? "/item" : ""}
            </div>
          )}
          <div className="stock" />
          {isPackagePriceOverriden ? (
            ""
          ) : (
            <div className="part-price" style={styleLine}>
              {partObj.partsPrice >= 0
                ? priceValueFormatter(partObj.partsPrice)
                : ""}
            </div>
          )}
        </div>
      );
    });
    partsHtml = (
      <>
        <div className="pkg-quote-labor no-border">
          <div className="labor-title">
            {" "}
            {localeStrings["sq.search.common.edit_service.summary.parts_lbl"]} (
            {service.part.length})
          </div>
          {isPackagePriceOverriden ? (
            ""
          ) : (
            <>
              {!debugQuote
                ? ""
                : service?.partsPriceOverridden && (
                    <span className="override-badge-container">
                      <Badge htmlId="overrideBadge" color="red">
                        {priceValueFormatter(service.totalPartsPriceOverride)}
                      </Badge>
                    </span>
                  )}
              <div className="labor-price">
                {totalPrice >= 0 ? priceValueFormatter(totalPrice) : ""}
              </div>
            </>
          )}
        </div>
        <div className={[clsPartShowHide, "margin-left-22"].join(" ")}>
          <div className={clsPartsTable}>{childHtml}</div>
        </div>
      </>
    );
  } else if (service.hasOwnProperty("part") && service.part.length === 0) {
    partsHtml = (
      <div className={clsParts}>
        <div className="labor-title">Parts ({service.part.length})</div>
        {!debugQuote
          ? ""
          : service?.partsPriceOverridden && (
              <span className="override-badge-container">
                <Badge htmlId="overrideBadge" color="red">
                  {priceValueFormatter(service.totalPartsPriceOverride)}
                </Badge>
              </span>
            )}
        <div className="labor-price">
          {totalPrice >= 0 ? priceValueFormatter(totalPrice) : ""}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={[clsRow, "margin-top-8 margin-right-13"].join(" ")}>
        {laborHtml}
        {partsHtml}
      </div>
    </>
  );
};

export default MenuServiceDetails;
