/* eslint-disable no-console */
import React, { Component } from "react";
import CriteriaField from "./CriteriaField";
// import CriteriaFieldForYears from "./CriteriaFieldForYears";
import {
  // AllMetaVehicleCriteriaList,
  // MetaVehicleCriteria,
  // MetaVehicleCriterion,
  MetaVehicleFields
  // vehicleGroupState,
  // payloadShowVehicles
} from "../../../constants/ModuleConstants";
import { toEmptyStringIfUndefined } from "../../../commonUtil//utils/string";

class CriteriaContainer extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.validateAllCriteria = this.validateAllCriteria.bind(this);
    this.state = {
      criteriaFields: [],
      metaVehicleFilter: {},
      criteriaOptionsMap: {}
    };
  }

  componentDidMount() {
    window.addEventListener("addCriteriaEvent", this.onAddCriteriaEvent, false);
    window.addEventListener(
      "removeCriteriaEvent",
      this.onRemoveCriteriaEvent,
      false
    );
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log("shouldComponentUpdate", nextProps, nextState);
  //   const componentUpdate =
  //     nextProps.criteriaOptionsMap !== this.state.criteriaOptionsMap ||
  //     nextState.criteriaFields !== this.state.criteriaFields ||
  //     nextState.metaVehicleFilter !== this.state.metaVehicleFiltert;
  //   return componentUpdate;
  // }

  componentWillUnmount() {
    window.removeEventListener(
      "addCriteriaEvent",
      this.onAddCriteriaEvent,
      false
    );
    window.addEventListener(
      "removeCriteriaEvent",
      this.onRemoveCriteriaEvent,
      false
    );
  }

  createEmptyCriteriaField = () => {
    const newCriteria = {
      name: "",
      operator: "is",
      startYear: [],
      endYear: [],
      values: []
    };
    return newCriteria;
  };

  onAddCriteriaEvent = event => {
    //
    const newCriteria = this.createEmptyCriteriaField();
    const { index } = event.detail;
    const { criteriaFields } = this.state;
    criteriaFields.splice(index + 1, 0, newCriteria);
    this.setState({ criteriaFields });
  };

  onRemoveCriteriaEvent = event => {
    const { index } = event.detail;
    const { criteriaFields } = this.state;
    criteriaFields.splice(index, 1);
    this.setState({ criteriaFields });
    window.dispatchEvent(
      new CustomEvent("dirtyVehicleGroupFormEvent", {
        detail: { dirty: true },
        bubbles: true,
        cancelable: true
      })
    );
  };

  removeBlankCriteria = () => {
    const { criteriaFields } = this.state;
    const length = criteriaFields.length;
    if (length > 1) {
      let removed = false;
      for (let index = length - 1; index >= 0; index--) {
        const field = criteriaFields[index];
        const { name, values } = field;
        if (name === "" && values.length === 0) {
          criteriaFields.splice(index, 1);
          removed = true;
        }
      }
      if (removed) {
        this.setState({ criteriaFields });
      }
    }
  };
  getCriteriaFields = () => {
    const { criteriaFields } = this.state;
    const includedYears = criteriaFields.filter(field => {
      return field.name === "years" && field.operator === "isfrom";
    });
    if (includedYears.length === 0) {
      return criteriaFields;
    }
    const actualCriteriaFields = criteriaFields.filter(field => {
      return field.name !== "years" || field.operator !== "isfrom";
    });
    includedYears.forEach(yr => {
      actualCriteriaFields.push({
        name: "startYear",
        operator: "is",
        values: yr.startYear,
        key: yr.key
      });
      actualCriteriaFields.push({
        name: "endYear",
        operator: "is",
        values: yr.endYear,
        key: yr.key
      });
    });
    return actualCriteriaFields;
  };

  clearCriteriaFields = metaVehicleFilter => {
    const criteriaFields = [];
    this.setState({ criteriaFields, metaVehicleFilter });
  };

  setCriteriaFields = (metaVehicleFilter, criteriaOptionsMap) => {
    const criteriaFields = this.createCriteriaFields(metaVehicleFilter);
    if (criteriaFields.length === 0) {
      const newCriteria = this.createEmptyCriteriaField();
      criteriaFields.push(newCriteria);
    }
    this.setState({
      criteriaFields,
      metaVehicleFilter,
      criteriaOptionsMap
    });
  };

  // e.g. Set(s) to sets
  convertLabelToKey(label) {
    return label.replace(/[^a-zA-Z]/g, "").replace(/^\w/g, c => {
      return c.toLowerCase();
    });
  }

  // e.g. includedYears to years
  convertFieldKeyToName(key) {
    return key
      .replace("included", "")
      .replace("excluded", "")
      .replace(/^\w/g, c => {
        return c.toLowerCase();
      });
  }

  // convert object to [object]
  getValues(values) {
    if (Array.isArray(values)) {
      // if (values && values.length !== 0 && values.value === undefined) {
      //   return values.map(v => {
      //     return { value: v, label: v };
      //   });
      // }
      return values;
    }
    // return [{ value: values, label: values }];
    return [values];
  }

  // create composite key: includedYears for startYear and endYear and its resulting CriteriaField
  getIncludedYearsCriteriaFields(metaVehicleFilter, keys) {
    const startAndEndYearCriteria = keys
      .filter(key => {
        return (
          (key === "startYear" || key === "endYear") &&
          toEmptyStringIfUndefined(metaVehicleFilter[key]) !== ""
        );
      })
      .map(k => {
        return {
          name: k,
          operator: "is",
          values: this.getValues(metaVehicleFilter[k])
        };
      });
    if (startAndEndYearCriteria.length !== 0) {
      const includedYears = {
        name: "includedYears",
        label: "Year(s)",
        operator: "isfrom",
        startYear: [],
        endYear: [],
        values: [],
        yrsValues: startAndEndYearCriteria
      };
      startAndEndYearCriteria.forEach(yr => {
        if (yr.values && yr.values.length !== 0) {
          if (yr.values.value === undefined) {
            includedYears[yr.name] = [
              { value: yr.values[0], label: yr.values[0] }
            ];
          } else {
            includedYears[yr.name] = yr.values;
          }
        }
      });
      return includedYears;
    }
    return null;
  }

  getOperator(name, key, metaVehicleFilter) {
    const included = key.indexOf("excluded") === -1;
    if (name === "sets") {
      const includedSetOp = metaVehicleFilter.includedSetOp
        ? metaVehicleFilter.includedSetOp
        : "any";
      const excludedSetOp = metaVehicleFilter.excludedSetOp
        ? metaVehicleFilter.excludedSetOp
        : "any";
      const anyOrAll = included ? includedSetOp : excludedSetOp;
      const operator = included
        ? "is".concat(anyOrAll)
        : "isnot".concat(anyOrAll);
      return operator;
    }
    const operator = included ? "is" : "isnot";
    return operator;
  }

  createCriteriaFields(metaVehicleFilter) {
    // e.g. key: sets label: Set(s)
    // const nameToLabelMap = {};
    // AllMetaVehicleCriteriaList.forEach(c => {
    //   const key = this.convertLabelToKey(c);
    //   nameToLabelMap[key] = c;
    // });

    // get used keys from metaVehicleFilter
    const keys = Object.keys(metaVehicleFilter).filter(key => {
      if (MetaVehicleFields.includes(key)) {
        if (Array.isArray(metaVehicleFilter[key])) {
          return metaVehicleFilter[key].length !== 0;
        }
        return toEmptyStringIfUndefined(metaVehicleFilter[key]) !== "";
      }
      return false;
    });
    // Convert key of startYear and  endYear to includedYears, with startYear[] and endYear[] fields
    const includedYearsCriteriaFields = this.getIncludedYearsCriteriaFields(
      metaVehicleFilter,
      keys
    );
    const newKeys = [];
    if (includedYearsCriteriaFields) {
      keys.forEach(k => {
        if (k !== "startYear" && k !== "endYear") {
          // push non start/endYear keys
          newKeys.push(k);
        } else {
          // push just one includedYears key for start/endYear key
          if (!newKeys.includes("includedYears")) {
            newKeys.push("includedYears");
          }
        }
      });
    }
    const theKeys = newKeys.length === 0 ? keys : newKeys;
    const criteriaFields = theKeys.map(key => {
      if (key === "includedYears") {
        const name = this.convertFieldKeyToName(key);
        includedYearsCriteriaFields.name = name;
        return includedYearsCriteriaFields;
      }
      const name = this.convertFieldKeyToName(key);
      // const included = key.indexOf("excluded") === -1;
      // const label = nameToLabelMap[name];
      const operator = this.getOperator(name, key, metaVehicleFilter); // included ? "is" : "isnot";
      const values = this.getValues(metaVehicleFilter[key]);
      return {
        name,
        // label,
        operator,
        startYear: [],
        endYear: [],
        values
      };
    });

    return criteriaFields;
  }

  validateAllCriteria = duplicateCriteriaFieldsMap => {
    if (this.criteriaFieldRefs && this.criteriaFieldRefs.length !== 0) {
      this.criteriaFieldRefs.forEach(criteriaRef => {
        if (criteriaRef) {
          criteriaRef.validateCriteria();
          const { field } = criteriaRef.props;
          const duplicateField = duplicateCriteriaFieldsMap[field.key];
          if (duplicateField) {
            criteriaRef.setState({
              errors: {
                name: "Duplicate field",
                operator: "Duplicate operator"
              }
            });
          }
        }
      });
    }
  };

  // getKey = (criteria, index) => {
  //   const make = toEmptyStringIfUndefined(this.state.metaVehicleFilter.make);
  //   const newKey = make.concat(new Date().getTime());
  //   console.log("getKey", newKey);
  //   return newKey;
  // };

  renderCriteriaFields(criteriaFields, criteriaOptionsMap) {
    let keyCount = new Date().getTime();
    this.criteriaFieldRefs = [];
    return criteriaFields.map((criteria, index) => {
      if (criteria.key === undefined) {
        criteria.key = keyCount++;
        // console.log("criteria", criteria);
      }
      return (
        <CriteriaField
          ref={criteriaFieldRef => {
            this.criteriaFieldRefs[index] = criteriaFieldRef;
          }}
          key={criteria.key}
          index={index}
          criteriaOptionsMap={criteriaOptionsMap}
          field={criteria}
          totalCriteriaFields={criteriaFields.length}
        />
      );
    });
  }

  /* 11 attributes = sets, models, trims, start year, end year, engine types, engine size,
  drive type, transmission type, body type, Fuel type */
  render() {
    const { criteriaOptionsMap, criteriaFields } = this.state;
    if (
      criteriaFields === undefined ||
      criteriaFields === null ||
      criteriaFields.length === 0 ||
      criteriaOptionsMap === undefined ||
      criteriaOptionsMap === null ||
      Object.keys(criteriaOptionsMap).length === 0
    ) {
      return "";
    }
    // console.log("CriteriaContainer.render", criteriaFields);
    return (
      <div>{this.renderCriteriaFields(criteriaFields, criteriaOptionsMap)}</div>
    );
  }
}

export default CriteriaContainer;
