/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import SequenceSelector from "../ROSequences/SequenceSelector";
import { PropTypes } from "prop-types";

// const KEY_BACKSPACE = 8;
// const KEY_DELETE = 46;
// const KEY_F2 = 113;

export default class SequenceEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);

    this.sequenceSelectorRef = React.createRef();

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data } = props;
    return {
      outdata: data,
      sequenceName: data.sequenceName,
      highlightAllOnFocus
    };
  }

  onBlur = () => {
    //
  };

  onChange = event => {
    const { data } = event.detail;
    this.props.data.outdata = data;
    this.setState({ sequenceName: data.sequenceName });
  };
  onSequenceChanged = event => {
    const { data, sequence } = event.detail;
    window.dispatchEvent(
      new CustomEvent("onSequenceSelected", {
        detail: { data, sequence },
        bubbles: true,
        cancelable: true
      })
    );
  }

  onClick = event => {
    console.log("SequenceEditor.onClick", event);
  };

  getValue() {
    return this.state.sequenceName;
  }

  // for testing
  setValue(newValue) {
    this.setState({ sequenceName: newValue });
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      this.setState({
        highlightAllOnFocus: false
      });
    }
    const selectorRef = this.sequenceSelectorRef.current;
    selectorRef.showPanel();
  }

  isPopup() {
    return true;
  }

  render() {
    const { outdata } = this.state;
    return (
      <React.Fragment>
        <div>
          <SequenceSelector
            ref={this.sequenceSelectorRef}
            data={outdata}
            error={this.props.error}
            onBlur={this.onBlur}
            onChange={this.onSequenceChanged}
            fromEditor={true}
            rowHeight={this.props.node.rowHeight}
            sequenceList={this.props.parentHandle.state.sequenceList}
          />
        </div>
      </React.Fragment>
    );
  }
}

SequenceEditor.propTypes = {
  data: PropTypes.object,
  error: PropTypes.string,
  parentHandle: PropTypes.object
};
