/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import IntervalSelector from "../Intervals/IntervalSelector";
import { PropTypes } from "prop-types";

// const KEY_BACKSPACE = 8;
// const KEY_DELETE = 46;
// const KEY_F2 = 113;

export default class IntervalEditor extends Component {
  // static contextType = AppContext;
  constructor(props) {
    super(props);

    this.intervalSelectorRef = React.createRef();
    console.log("IntervalEditor", props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = this.createInitialState(props);
  }

  createInitialState(props) {
    const highlightAllOnFocus = true;
    const { data, parentHandle } = props;
    const { make } = data;
    const { locale, makelist } = parentHandle.context;
    const makes = makelist.filter(m => {
      return m.make === make;
    });
    const { dealerCode, variant } = makes[0];
    const dataValue = {
      dealerCode, // in
      make, // in
      variant, // in
      locale, // in
      intervalId: data.intervalId ? data.intervalId.toString() : "", // in and out
      intervalName: data.intervalName // in and out
    };
    const includeWildCardIntervals = data.hasOwnProperty(
      "dealerOperationRuleId"
    );

    return {
      includeWildCardIntervals,
      outdata: dataValue,
      intervalName: data.intervalName,
      highlightAllOnFocus
    };
  }

  onBlur = () => {
    //
  };

  onChange = event => {
    const { data } = event.detail;
    this.props.data.outdata = data;
    this.setState({ intervalName: data.intervalName });
  };

  onClick = event => {
    console.log("IntervalEditor.onClick", event);
  };

  getValue() {
    return this.state.intervalName;
  }

  // for testing
  setValue(newValue) {
    this.setState({ intervalName: newValue });
  }

  isCancelBeforeStart() {
    return false;
  }

  isCancelAfterEnd() {
    return false;
  }

  afterGuiAttached() {
    if (this.state.highlightAllOnFocus) {
      this.setState({
        highlightAllOnFocus: false
      });
    }
    const selectorRef = this.intervalSelectorRef.current;
    selectorRef.showPanel();
  }

  isPopup() {
    return true;
  }

  render() {
    const { includeWildCardIntervals, outdata } = this.state;
    const editorStyle = this.props.column
      ? { width: this.props.column.actualWidth }
      : {};
    return (
      <React.Fragment>
        <div style={editorStyle}>
          <IntervalSelector
            ref={this.intervalSelectorRef}
            context={this.props.parentHandle.context}
            data={outdata}
            includeWildCardIntervals={includeWildCardIntervals}
            error={this.props.error}
            onBlur={this.onBlur}
            onChange={this.onChange}
            fromEditor={true}
            rowHeight={this.props.node.rowHeight}
          />
        </div>
      </React.Fragment>
    );
  }
}

IntervalEditor.propTypes = {
  data: PropTypes.object,
  error: PropTypes.string,
  parentHandle: PropTypes.object
};
