import React, { useState, useEffect } from "react";
import "./MultiSelectCheckBoxes.css";

const MultiSelect = props => {
  const [options, setOptions] = useState(props.options || []);
  const [filteredOptions, setFilteredOptions] = useState(props.options || []);
  const [selectedOptions, setSelectedOptions] = useState(
    props.defaultValues ? props.defaultValues : []
  );
  const [dropdownActive, setDropdownActive] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [value, setValue] = useState(props.value || "value");
  const [name, setName] = useState(props.label || "name");
  const [textInputRef, setTextInputRef] = useState(React.createRef());
  const [multiSelectClass, setMultiSelectClass] = useState("multi-disabled");
  const [selectClass, setSelectClass] = useState("select-disabled");

  const multiSelectRef = React.useRef();

  useEffect(() => {
    if (selectedOptions && selectedOptions.length) {
      props.onChange(selectedOptions, name);
    }
  }, [selectedOptions]);

  useEffect(() => {
    setMultiSelectClass(!props.disabled ? "" : "multi-disabled");
    setSelectClass(!props.disabled ? "" : "select-disabled");
  }, [props.disabled]);

  useEffect(() => {
    if (props.defaultValues) {
      setSelectedOptions(props.defaultValues ? props.defaultValues : []);
    }
  }, [props.defaultValues]);

  useEffect(() => {
    if (props.options && props.options.length) {
      setFilteredOptions(props.options || []);
      setOptions(props.options || []);
    }
  }, [props.options]);

  useEffect(() => {
    setOptions(props.options || []);
    setFilteredOptions(props.options || []);
    setSelectedOptions(props.defaultValues ? props.defaultValues : []);
    props.onChange(selectedOptions);
  }, []);

  useEffect(() => {
    // Anything in here is fired on component mount.
    document.addEventListener("mousedown", handleMousedown);

    return () => {
      // Anything in here is fired on component unmount.
      document.removeEventListener("mousedown", handleMousedown);
    };
  }, []);
  // Controls the dropdown visibility
  const handleDropdownClick = () => {
    setCursor(0);
    setDropdownActive(!dropdownActive);
    resetDropdown();
  };
  // Resets dropdown to initial state
  const resetDropdown = () => {
    if (dropdownActive) {
      setFilteredOptions([...props.options]);
    }
  };
  // Handle search and keyboard events
  const handleSearchChange = e => {
    e.persist();
    this.setState(() => {
      const value = e.target[this.state.value].replace(/[^a-zA-Z0-9 ]/g, "");
      const filter = new RegExp(value, "i");
      const filteredOptions = this.state.options.filter(opt => {
        return filter.test(opt[this.state.value]);
      });
      return {
        cursor: 0,
        filteredOptions
      };
    });
  };
  // Handle arrow keys and enter key
  const handleSearchKeyDown = e => {
    const { cursor } = this.state;
    const key = e.which || e.keyCode || 0;
    // Up Arrow
    if (key === 38 && cursor > 0) {
      this.setState(state => {
        return {
          cursor: state.cursor - 1
        };
      });
    }
    // Down arrow
    if (key === 40 && cursor < this.state.filteredOptions.length) {
      this.setState(state => {
        return {
          cursor: state.cursor + 1
        };
      });
    }
    // Select by enter
    if (key === 13) {
      const highlightedItem = this.state.filteredOptions[this.state.cursor];
      if (!highlightedItem) {
        return;
      }
      if (
        !this.state.selectedOptions.find(
          opt => opt.value === highlightedItem.value
        )
      ) {
        this.setState(
          state => {
            return {
              selectedOptions: [...state.selectedOptions, highlightedItem]
            };
          },
          () => this.props.onChange(this.state.selectedOptions)
        );
      } else {
        this.setState(
          state => {
            return {
              selectedOptions: state.selectedOptions.filter(
                opt => opt.value !== highlightedItem.value
              )
            };
          },
          () => this.props.onChange(this.state.selectedOptions)
        );
      }
    }
  };
  // Handles checking or unchecking of items
  const handleOptionChange = e => {
    e.persist();
    let newSelectedOptions;
    const selectedItem = options.find(
      opt => opt[value] === parseInt(e.target.value, 10)
    );
    if (e.target.checked) {
      newSelectedOptions = [...selectedOptions, selectedItem];
    } else {
      // Unchecked item is removed from the chosen items list
      newSelectedOptions = selectedOptions.filter(
        opt => opt[value] !== parseInt(e.target.value, 10)
      );
    }
    setSelectedOptions(newSelectedOptions);
    // props.onChange(selectedOptions, e, selectedItem);
  };
  // Remove item with X button
  const handleOptionRemoveClick = (value, e) => {
    e.stopPropagation();
  };
  // Hide dropdown if clicked outside
  const handleMousedown = e => {
    e.stopPropagation();
    console.log(multiSelectRef);
    if (multiSelectRef.current && !multiSelectRef.current.contains(e.target)) {
      if (multiSelectRef.current.classList.contains("is-active")) {
        setDropdownActive(false);
      }
    }
  };
  // Hide dropdown with ESC key
  const handleEscKeyUp = e => {
    const key = e.which || e.keyCode || 0;
    if (key === 27) {
      setDropdownActive(false);
    }
  };
  // Check item is already in the chosen items list, if so then make that option checked
  const isChecked = val => {
    if (value) {
      return selectedOptions.find(
        item => item[value] && parseInt(item[value], 10) === val
      )
        ? true
        : false;
    }
    return false;
  };
  return (
    <div
      className={`multiselect-wrapper ${multiSelectClass} ${
        dropdownActive ? "is-active" : ""
      }`}
      ref={multiSelectRef}
    >
      <div
        className={`${selectClass} multiselect__control`}
        onClick={handleDropdownClick}
      >
        <span
          className={`multiselect__placeholder ${
            selectedOptions.length ? "is-hidden" : ""
          }`}
        >
          Choose the option(s)...
        </span>
        <span
          className={`multiselect__placeholder ${
            !selectedOptions.length ? "is-hidden" : ""
          }`}
        >
          {`${selectedOptions.length} item(s) selected `}
        </span>
        <span
          className={`multiselect__arrow-icon fa ${
            dropdownActive ? "fa-chevron-up" : "fa-chevron-down"
          }`}
        />
      </div>
      <div
        className={`multiselect__result-area ${
          dropdownActive ? "is-active" : ""
        }`}
      >
        <div className="multiselect-results">
          {filteredOptions.map((option, index) => (
            <div
              key={option[value]}
              className={`multiselect-results__item ${
                isChecked(option[value]) ? "is-active" : ""
              } ${index === cursor ? "is-highlighted" : ""}`}
            >
              <input
                type="checkbox"
                onChange={handleOptionChange}
                className="custom-checkbox"
                id={`opt-${props.name}-${option[value]}`}
                value={option[value]}
                name={props.name}
                checked={isChecked(option[value])}
                disabled=""
                tabIndex="8"
              />
              <label
                htmlFor={`opt-${props.name}-${option[value]}`}
                className="custom-checkbox-label"
              >
                {option[name]}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
