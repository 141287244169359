/* eslint-disable no-console */
import React, { Component } from "react";
import Button from "@cx/ui/Button";
import { PropTypes } from "prop-types";
import { xlate } from "../../../commonUtil/i18n/locales";
import {
  makeSecureRestApi
} from "../../../api/xmmAxios";

export default class SequenceSelector extends Component { 
  constructor(props) {
    super(props);

    this.manageSequenceModalRef = React.createRef();

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.openManageSequenceModal = this.openManageSequenceModal.bind(this);
    const { data, error } = props;
    this.initializeLocaleValues();
    this.state = {
      data,
      error,
      showManageModal: false,
      touched: false,
      sequenceList: []
    };
  }

  componentDidMount() {
    if (this.props.fromEditor) {
      this.showPanel();
    }
  }

  componentWillUnmount() {
  }

  initializeLocaleValues() {
    this.manageSequencelbl = xlate("xmm.portal.ro_sequences.manage_sequences");
    this.defaultLbl = xlate("xmm.portal.pay_types.default");
  }
  setWrapperRef = node => {
    this.wrapperRef = node;
  };


  onClick = () => {
  };

  onBlur = (cxEvent, isValid, domEvent) => {};

  onChange = () => {};
  showPanel = () => {
    const showPanelUp = this.shouldShowContentPanelUp();
    this.setState({
      showPanelUp,
      touched: true,
      error: "",
      showContentPanel: true
    });
  };
  

  shouldShowContentPanelUp() {
    const gridBoundingBox = document
      .querySelector("#grid-wrapper")
      .getBoundingClientRect();
    const selectorBoundingBox = this.wrapperRef.getBoundingClientRect();
    // console.log("getBoundingClientRect", gridBoundingBox, selectorBoundingBox);
    const panelHeight = 200;
    // if bottom of panel is beyond bottom of the grid rows
    if (selectorBoundingBox.bottom + panelHeight > gridBoundingBox.bottom) {
      if (selectorBoundingBox.top - panelHeight > gridBoundingBox.top + 40) {
        // show panel up
        return true;
      } else {
        // place panel somewhere in the middle of the grid
        return undefined;
      }
    }
    return false;
  }

  openManageSequenceFromEditor = () => {
    const { data, sequenceList } = this.state;
    
    window.dispatchEvent(
      new CustomEvent("openManageSequenceFromEditor", {
        detail: { data, sequenceList },
        bubbles: true,
        cancelable: true
      })
    );
  };

  openManageSequenceModal = () => {
    const { fromEditor } = this.props;
    if (fromEditor) {
      this.openManageSequenceFromEditor();
    }
  };
  drawSequencePanel = () => {
    const { data, showPanelUp, showContentPanel } = this.state;
    const { sequenceList } = this.props;
    const header = data.isDefault ? this.defaultLbl : data.sequenceName;
    const contentPanelClass = showContentPanel
      ? showPanelUp === undefined
        ? ""
        : showPanelUp
        ? "selector-panel roseq-selector-panel-up"
        : ""
      : "hide";
    const sequencePanel = sequenceList ? (
      <div className={contentPanelClass}>
      <div className="interval-selector-scrol">
        <ul className="selector-list-group">
          {sequenceList.length > 0 &&
            sequenceList.map(sequence => (
              <li
                onClick={() => {
                  const {data} = this.state;
                  this.props.onChange({                   
                    name: "sequenceSelected",
                    detail: { data, sequence }
                  });
                }}
                className="list-group-item"
                key={sequence.sequenceName}
              >
                {sequence.sequenceName}
              </li>
            ))}
        </ul>
        <hr className="selector-separator" />
            <Button
              htmlId="manageSequence"
              className="btn--icon"
              buttonStyle="link"
              size="sm"
              onClick={event => {this.openManageSequenceModal()}}
            >
              {this.manageSequencelbl}
            </Button>
      </div>
      </div>
    ): "";

    return (
      <form autoComplete="off">
        <div className="selector-container" ref={this.setWrapperRef}>
          <div className="selector-panel">
            <div className="selector-list-group">{sequencePanel}</div>
          </div>
        </div>
      </form>
    );
  };
  render() {
    const sequencePanel = this.drawSequencePanel();
    return (
      <React.Fragment>
        {sequencePanel}
      </React.Fragment>
    );
  }
}

SequenceSelector.propTypes = {  
  data: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fromEditor: PropTypes.bool,
  rowHeight: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  sequenceList: PropTypes.array
};

