/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import NoPricingRow from "./NoPricingRow";
import { AppContext } from "../../../../app-context";

class DisplayPricingRow extends React.Component {
  static contextType = AppContext;

  // Any changes to props, this method updates state
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.catalog !== prevState.catalog) {
      const { catalog } = nextProps;
      return {
        catalog,
        showPricingBusiness: catalog.showPricingBusiness,
        showPricingConsumer: catalog.showPricingConsumer,
        showPricingConsumerMenu: catalog.showPricingConsumerMenu,
        showPricingConsumerAlacarte: catalog.showPricingConsumerAlacarte,
        showPricing: catalog.showPricing,
        showPricingMsa: catalog.showPricingMsa
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeConsumer = this.onChangeConsumer.bind(this);

    const { catalog } = props;
    this.state = {
      catalog,
      showPricingBusiness: catalog.showPricingBusiness,
      showPricingConsumer: catalog.showPricingConsumer,
      showPricingConsumerMenu: catalog.showPricingConsumerMenu,
      showPricingConsumerAlacarte: catalog.showPricingConsumerAlacarte,
      showPricing: catalog.showPricing,
      showPricingMsa: catalog.showPricingMsa
    };
  }
  componentDidMount() {}

  /* consumer checkbox handler
     ANY make - only this checkbox "showPricingConsumerAlacarte" is enabled
  */
  onChangeConsumer = event => {
    const payload = {};
    const target = event.target;
    const { name } = target;
    const newValue = target.checked ? 1 : 0;
    this.setState({ [name]: newValue });
    const { catalog, onUpdate } = this.props;
    catalog[name] = newValue;
    payload[name] = newValue;
    if (
      catalog["showPricingConsumerMenu"] === 0 &&
      catalog["showPricingConsumerAlacarte"] === 0
    ) {
      catalog["showPricingConsumer"] = 0;
      this.setState({ [name]: newValue, showPricingConsumer: 0 });
      payload["showPricingConsumer"] = 0;
    } else {
      catalog["showPricingConsumer"] = 1;
      this.setState({ [name]: newValue, showPricingConsumer: 1 });
      payload["showPricingConsumer"] = 1;
    }
    // For ANY catalog case
    if (catalog.make === "ANY") {
      this.setState({
        showPricingConsumerAlacarte: newValue,
        showPricingConsumer: newValue
      });
      payload["showPricingConsumer"] = newValue;
      payload["showPricingConsumerMenu"] = newValue;
    }
    onUpdate(catalog, name, payload);
  };

  onChangeCheckbox = event => {
    const target = event.target;
    const { name } = target;
    const newValue = target.checked ? 1 : 0;
    this.setState({ [name]: newValue });
    const { catalog, onUpdate } = this.props;
    catalog[name] = newValue;
    const payload = {
      [name]: newValue
    };
    onUpdate(catalog, name, payload);
  };

  render() {
    const { catalog } = this.props;
    const clsRow = "xmm-form-content";
    /* This "SCHEDULER" field should be disabled and set {true} for all catalogs regardless of User permissions */
    const disableScheduler = true;
    const disableAny = catalog.make === "ANY" ? true : false;
    const anyConsumer = disableAny ? 0 : this.state.showPricingConsumerMenu;
    const anyShowPricing = disableAny ? 0 : this.state.showPricing;

    const defaultRow = (
      <>
        <div className="xmm-fixed-col">
          <div
            className="xmm-checkbox-container"
            id={"showPricingBusiness-" + catalog.make}
          >
            <input
              className="form-checkbox"
              name="showPricingBusiness"
              id="showPricingBusiness"
              type="checkbox"
              checked={this.state.showPricingBusiness || 1}
              disabled={disableScheduler}
            />
            <span className="xmm-checkmark" />
            <span className="label-checkbox" />
          </div>
        </div>
        <div className="xmm-fixed-col">
          <div
            className="xmm-checkbox-container"
            id={"showPricingConsumerMenu-" + catalog.make}
          >
            <input
              className="form-checkbox"
              name="showPricingConsumerMenu"
              id="showPricingConsumerMenu"
              type="checkbox"
              checked={anyConsumer || 0}
              onChange={this.onChangeConsumer}
              disabled={disableAny}
            />
            <span className="xmm-checkmark" />
            <span className="label-checkbox" />
          </div>
        </div>
        <div className="xmm-fixed-col">
          <div
            className="xmm-checkbox-container"
            id={"showPricingConsumerAlacarte-" + catalog.make}
          >
            <input
              className="form-checkbox"
              name="showPricingConsumerAlacarte"
              id="showPricingConsumerAlacarte"
              type="checkbox"
              checked={this.state.showPricingConsumerAlacarte || 0}
              onChange={this.onChangeConsumer}
            />
            <span className="xmm-checkmark" />
            <span className="label-checkbox" />
          </div>
        </div>
        <div className="xmm-fixed-col">
          <div
            className="xmm-checkbox-container"
            id={"showPricing-" + catalog.make}
          >
            <input
              className="form-checkbox"
              name="showPricing"
              id="showPricing"
              type="checkbox"
              checked={anyShowPricing || 0}
              onChange={this.onChangeCheckbox}
              disabled={disableAny}
            />
            <span className="xmm-checkmark" />
            <span className="label-checkbox" />
          </div>
        </div>
        <div className="xmm-fixed-col">
          <div
            className="xmm-checkbox-container"
            id={"showPricingMsa-" + catalog.make}
          >
            <input
              className="form-checkbox"
              name="showPricingMsa"
              id="showPricingMsa"
              type="checkbox"
              checked={this.state.showPricingMsa || 0}
              onChange={this.onChangeCheckbox}
            />
            <span className="xmm-checkmark" />
            <span className="label-checkbox" />
          </div>
        </div>
      </>
    );
    const noPricingRow = <NoPricingRow />;
    const rowHtml = catalog.pricingMethod === 0 ? noPricingRow : defaultRow;
    return (
      <Row>
        <Col md={2} sm={2} className="xmm-row-title">
          {catalog.make}
        </Col>
        <Col md={10} sm={10} className={clsRow}>
          {rowHtml}
        </Col>
      </Row>
    );
  }
}

export default DisplayPricingRow;
DisplayPricingRow.propTypes = {
  catalog: PropTypes.object,
  onUpdate: PropTypes.func
};
