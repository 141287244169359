/* eslint-disable react/prop-types */
import React, { Component } from "react";

export default class WeekDaysTooltip extends Component {
  componentDidMount() {
    this.props.reactContainer.className = "xmm-custom-tooltip";
  }

  render() {
    let data = "";
    /* set dynamic cell value */
    let cellValue = "";
    if (this.props.api) {
      const field = this.props.field;
      data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;
      const daysOfWeek = data[field]
        .sort((a, b) => {
          return Number(a.value) - Number(b.value);
        })
        .map(v => {
          return v.label;
        });
      cellValue = daysOfWeek.join(",");
    }
    return (
      <div
        className="xmm-custom-tooltip"
        style={{ backgroundColor: this.props.color }}
      >
        <p>
          <span>{cellValue}</span>
        </p>
      </div>
    );
  }
}
