import * as React from "react";
import { getMetaTagContentByName } from "../commonUtil/utils/dom";

export const Footer = () => {
  const appInfo = {
    branch: getMetaTagContentByName("xtime:gitBranch"),
    buildNumber: getMetaTagContentByName("xtime:buildNumber")
  };
  return (
    <div className="xmm-footer">
      <span>
        <strong>© 2019 Xtime Inc. All Rights Reserved </strong>({appInfo.branch}{" "}
        - {appInfo.buildNumber})
      </span>
    </div>
  );
};
