/* eslint-disable no-console */
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import IconMore from "@cx/ui/Icons/IconMore";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Dropdown from "@cx/ui/Dropdown";
import IconDelete from "@cx/ui/Icons/IconDelete";
import { isArrayExist } from "../../../../commonUtil/utils/object";
import PriceEditor from "../../../../commonUtil/editors/PriceEditor";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { navigateToNextCell } from "../../../../commonUtil/utils/keyNavigation";
import { toast } from "@cx/ui/Toast";
import Button from "@cx/ui/Button";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import {
  datetimeComparator,
  isExpiredDate,
  stringToDate
} from "../../../../commonUtil/utils/date";
// import BulkEditPane from "./BulkEditPane";
import { FeePageContext } from "./fee-context";
// import ImportDMSPartsPricingModal from "../PartsPricing/ImportDMSPartsPricingModal";
import FeeCodeEditor from "./content/FeeCodeEditor";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import AddFeesFormPage from "./content/AddFeesFormPage";
import DirtyCheckPopup from "../../../reusable/modals/DirtyCheckPopup";
import { FeesRecord, YesNoMap } from "../../../../constants/ModuleConstants";
import { boolToStringFormatter } from "../../../../commonUtil/utils/formatter";
import CheckboxCell from "../../../../commonUtil/renders/CheckboxCell";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import moment from "moment";

class FeePage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.updateFeesAfterSave = this.updateFeesAfterSave.bind(this);
    this.onToggleFeeEnabled = this.onToggleFeeEnabled.bind(this);
    this.renderMoreButton = this.renderMoreButton.bind(this);
    this.confirmDeleteFee = this.confirmDeleteFee.bind(this);

    // bind other external actions
    this.initializeLocaleValues();
    const localeStrings = context.localeStrings;
    // javascript map used in map
    const { serviceId } = props;
    const gridOptions = {
      serviceId,
      pageTitle: "Fees",
      editOption: null, // set values as edit, add, delete
      selectionlist: [],
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      columnDefs: this.getColumnList(serviceId),
      feewName: "",
      flexWidth: true,
      showSlide: false,
      showFeeGridSpeedBump: false,
      fees: null,
      rowRecord: FeesRecord,
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        // suppressKeyboardEvent: applyCustomKeyNavigation,
        cellClassRules: getCellClassRule(["price"], ["price"], []),
        rowGroup: false
      },
      navigateToNextCell,
      multiSortKey: "ctrl",
      components: {},
      // frameworkComponents: {
      //   customLoadingOverlay: CustomLoadingOverlay,
      //   customNoRowsOverlay: CustomLoadingOverlay,
      //   textEditor: TextEditor,
      //   priceEditor: PriceEditor
      // },
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        loadingMessage: "No feeds found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      // localeText: {
      //   filteredRows: xlate("xmm.portal.ag_grid.filteredRows"],
      //   selectedRows: xlate("xmm.portal.ag_grid.selectedRows"],
      //   totalRows: xlate("xmm.portal.ag_grid.totalRows"],
      //   totalAndFilteredRows:
      //     xlate("xmm.portal.ag_grid.totalAndFilteredRows"],
      //   noRowsToShow: xlate("xmm.portal.ag_grid.noRowsToShow"]
      // },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 120,
          minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "multiple",
      isRowSelectable() {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        showImportPartsModal: false,
        hiddenByDefault: false
      },
      feesModified: false,
      noRowsTemplate: `"<span">no rows to show</span>"`,
      user: this.context.user
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    window.addEventListener(
      "saveCellEditEvent",
      this.onToggleFeeEnabled,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    window.removeEventListener(
      "saveCellEditEvent",
      this.onToggleFeeEnabled,
      false
    );
  }
  initializeLocaleValues() {
    this.addFeeLabel = xlate("xmm.portal.fee.create_fee_lbl");
    this.feeLabel = xlate("xmm.portal.fee.fees_lbl");
    this.deletedMsg = xlate("xmm.portal.fee.deleted_msg");
    this.deletingErrorMsg = xlate("xmm.portal.fee.error_deleting_msg");
  }

  onFirstDataRendered() {
    // this.restoreGridState();
    this.sizeToFit();
  }
  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        // console.log("before clear> fitler", filterModel);
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      document.querySelector("#fee-search-box").value = "";
      this.gridApi.setQuickFilter("");
    }
  }

  handleColumnResized = () => {
    // this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerFeesId; // primary or unique key of record
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };
  updateState(data) {
    if (data) {
      let datalist = [];
      if (!isArrayExist(data) && typeof data === "object") {
        datalist.push(data);
      } else if (isArrayExist(data) && data.length > 0) {
        datalist = data;
      }
      if (isArrayExist(datalist) && datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }

      this.setState(
        {
          rowData: datalist
        },
        () => {
          this.sizeToFit();
        }
      );
    }
  }

  isGlobalOperation(serviceId) {
    return serviceId?.toString().startsWith("g");
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const payload = {
      dealerCode
    };
    const { serviceId } = this.state;
    let globalOpFlag = false;
    let restUrl;
    if (serviceId) {
      globalOpFlag = this.isGlobalOperation(serviceId);
      if (globalOpFlag) {
        payload.operationId = serviceId.substring(1);
        restUrl = "/ops/discountAndFees/getServiceFees";
      } else {
        payload.serviceId = serviceId;
        restUrl = "/ops/proxyapi/ddsproxy/rest/proc/getServiceFees";
      }
    } else {
      showBodyMask();
      restUrl = "/ops/discountAndFees/getFees";
    }
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params: payload
      },
      data => {
        if (data) {
          this.gridApi?.hideOverlay();
          hideBodyMask();
          const nowMoment = moment(new Date());
          let datalist = globalOpFlag && serviceId ? data.fees : data;
          if (datalist && datalist.fees) {
            datalist = datalist.fees;
          }
          if (serviceId) {
            datalist = datalist.filter(
              fee => !isExpiredDate(fee.expiryDate, nowMoment)
            );
          }
          this.updateState(datalist);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi?.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "description",
        sortIndex: 1,
        sort: "asc"
      },
      {
        colId: "partNumber",
        sortIndex: 2,
        sort: "asc"
      },
      {
        colId: "apiSpec",
        sortIndex: 3,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi?.applyColumnState({
      state: defaultSortModel,
      defaultState: {
        // important to say 'null' as undefined means 'do nothing'
        sort: null
      }
    });
  };
  callRefreshAfterMillis(params, gridApi) {
    gridApi.redrawRows(params);
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 30);
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    const { user } = this.state;

    if (field === "description" && user.editDiscountsAndFees) {
      const record = params.data;
      this.setState(prevState => ({
        showSlide: !prevState.showSlide,
        editOption: "edit",
        rowRecord: record,
        operationName: this.feeLabel
      }));
      // gtmEvents.gtmTrackEvent("xmm.operations.open-settings-tab");
    }
  }

  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // this.ontoggleFeeEnabled(params);
    }
  }
  onToggleFeeEnabled = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    if (data.hasOwnProperty("dealerFeesId")) {
      const dealerFee = data;
      this.toggleFeeEnabled(dealerFee, field, value);
    }
  };
  toggleFeeEnabled = (dealerFee, field, value) => {
    console.log(dealerFee, field, value);
    this.updateStatusBox("Saving", "pending");

    const { dealerCode } = this.context;
    const { serviceId } = this.state;
    const { dealerFeesId, enabled } = dealerFee;
    const params = { dealerFeesId };
    const globalOpFlag = this.isGlobalOperation(serviceId);
    if (globalOpFlag) {
      params.dealerCode = dealerCode;
      params.operationId = serviceId.substring(1);
    } else {
      params.serviceId = serviceId;
    }
    if (enabled) {
      const { userName: modUser } = this.context.user;
      const modTime = moment().format("YYYY-MM-DD").substring(0, 10);
      params.modUser = modUser;
      params.modTime = modTime;
    }
    let restUrl = "";
    const action = enabled ? "enableServiceFee" : "disableServiceFee";
    if (globalOpFlag) {
      restUrl = `/ops/discountAndFees/${action}`;
    } else {
      restUrl = `/ops/proxyapi/ddsproxy/rest/proc/${action}`;
    }
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
      },
      data => {
        console.log(data);
        this.updateStatusBox("Saved", "success", true);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error enabling or disabling the fee.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  };

  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = () => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#fee-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.clearGridSelections();
      this.setState({
        searchKey
      });
    }
  };

  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: FeesRecord,
      operationName: this.feeLabel
    }));
  };
  closeSlider = () => {
    if (this.state.feesModified) {
      this.setState({ showFeeGridSpeedBump: true });
    } else {
      this.setState({
        showSlide: false
      });
    }
  };
  hideSlider = () => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: FeesRecord,
      operationName: this.feeLabel,
      feesModified: false,
      showFeeGridSpeedBump: false
    }));
  };
  updateFeesAfterSave = (fee, updating) => {
    if (this.gridApi && fee) {
      const res = updating
        ? this.gridApi.applyTransaction({
            update: [fee]
          })
        : this.gridApi.applyTransaction({
            add: [fee]
          });
      // get the row node with ID
      const rowNode = this.gridApi.getRowNode(String(fee.dealerFeesId));
      this.gridApi.ensureIndexVisible(
        updating ? res.update[0].rowIndex : res.add[0].rowIndex,
        "top"
      );
      rowNode.setSelected(false);
      const gridParams = {
        force: true,
        rowNodes: [rowNode]
      };
      const { rowData } = this.state;
      rowData.push(fee);
      this.setState({ rowData });
      this.callRefreshAfterMillis(gridParams, this.gridApi);
    }
    if(!fee && updating){
      //update the current fee
      //we need to reload the grid info after we update fee
      this.loadGridData();
    }
  };
  renderMoreButton(params) {
    const fees = params.data;
    //return <IconMore className="xmm-rule-dropdown-menu-renderer" />;
    return <IconDelete className="xmm-rule-dropdown-menu-renderer" 
        onClick={() => {
          this.confirmDeleteFee(fees);
        }} />;
  }
  closeDeleteModal = doneDeleting => {
    if (doneDeleting) {
      this.setState({ selectionlist: [], showDeleteConfirmation: false });
    }
  };
  confirmDeleteFee = fees => {
    this.setState({ fees, showDeleteConfirmation: true });
  };
  renderDeleteFeeConfirmation() {
    const { localeStrings } = this.context;
    const { fees, showDeleteConfirmation } = this.state;
    const { operationCount, menuCount } = fees;

    let msg = localeStrings["xmm.portal.fee.delete_confirmation"];

    if (operationCount && !menuCount) {
      msg = localeStrings["xmm.portal.fee.associatted_operation_lbl"];
      msg = msg.replace("N", operationCount);
    } else if (!operationCount && menuCount) {
      msg = localeStrings["xmm.portal.discounts.associatted_menu_lbl"];
      msg = msg.replace("M", menuCount);
    } else if (operationCount && menuCount) {
      msg =
        localeStrings["xmm.portal.discounts.associatted_operation_menu_lbl"];
      msg = msg.replace("N", operationCount).replace("M", menuCount);
    }
    return showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteFee"
        message={msg}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.deleteFee(fees);
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
  }

  deleteFee = fee => {
    const { dealerCode } = this.context;
    if (fee) {
      const { dealerFeesId } = fee;
      const params = { dealerCode, dealerFeesId };
      const url = "/ops/discountAndFees/deleteFee";
      makeSecureRestApi(
        {
          url,
          method: "get",
          params
        },
        response => {
          if (response && response.success) {
            const res = this.gridApi.applyTransaction({
              remove: [fee]
            });
            res.remove.forEach(function (rowNode) {
              console.log("Removed Row Node", rowNode);
            });
            this.updateStatusBox(this.deletedMsg, "success", true);
            this.setState({ showDeleteConfirmation: false });
          }
        },
        error => {
          this.setState({ showDeleteConfirmation: false });
          const msg = error["message"] ? error.message : this.deletingErrorMsg;
          this.updateStatusBox(msg, "error", false, true);
        }
      );
    }
  };
  getColumnList(serviceId) {
    const { user, enableDMSPlusContent} = this.context;

    const baseCols = [
      {
        headerName: xlate("xmm.portal.ag_grid.enabled"),
        field: "enabled",
        valueFormatter: boolToStringFormatter,
        cellRenderer: CheckboxCell,
        cellRendererParams: {
          field: "enabled"
        },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        refData: YesNoMap,
        editable: false,
        enableRowGroup: false,
        hide: !serviceId,
        suppressColumnsToolPanel: !serviceId,
        suppressFiltersToolPanel: !serviceId,
        minWidth: 85,
        maxWidth: 85
      },
      {
        headerName: xlate("xmm.portal.fee.description"),
        headerClass: "ag-text-header",
        field: "description",
        tooltipField: "description",
        editable: false,
        cellClass:
          user.editDiscountsAndFees && !serviceId ? "xmm-link-cell" : "",
        maxWidth: 800,
        minWidth: 250,
        width: 300,
        sortingOrder: ["asc", "desc"],
        valueFormatter(params) {
          return params.value;
        },
        filterParams: {
          buttons: ["clear"]
        },
        enableRowGroup: false
      },
      {
        headerName: xlate("xmm.portal.discount.form.operations_count_lbl"),
        field: "operationCount",
        colId: "operationCount",
        editable: false,
        headerClass: "ag-text-header",
        cellStyle: { textAlign: "right" },
        filter: "agSetColumnFilter",
        maxWidth: 150,
        minWidth: 130,
        type: "numberColumn",
        cellRenderer: countRenderer,
        enableRowGroup: false,
        hide: serviceId ? true : false,
        suppressColumnsToolPanel: serviceId ? true : false,
        suppressFiltersToolPanel: serviceId ? true : false
      },
      {
        headerName: xlate("xmm.portal.fee.feeType"),
        field: "feesType",
        editable: false,
        headerClass: "ag-text-header",
        cellClass: "editable-caret-cell",
        maxWidth: 150,
        minWidth: 130
      },
      {
        headerName: xlate("xmm.portal.fee.dollarAmount"),
        field: "dollarAmount",
        colId: "dollarAmount",
        editable: false,
        headerClass: "ag-numeric-header",
        cellEditor: PriceEditor,
        cellStyle: { textAlign: "right" },
        valueFormatter: amountFormatter,
        valueParser: amountParser,
        cellEditorParams: { keepInvalidValue: true },
        type: "numberColumn",
        maxWidth: 130,
        minWidth: 100,
        width: 100
      },
      {
        headerName: xlate("xmm.portal.fee.percentage"),
        field: "percentage",
        colId: "percentage",
        editable: false,
        headerClass: "ag-numeric-header",
        cellEditorParams: { keepInvalidValue: true },
        cellStyle: { textAlign: "right" },
        valueFormatter: percentageFormatter,
        valueParser: percentageParser,
        type: "numberColumn",
        maxWidth: 130,
        minWidth: 100,
        width: 100
      },
      {
        headerName: xlate("xmm.portal.fee.feeMax"),
        field: "feeMax",
        colId: "feeMax",
        editable: false,
        headerClass: "ag-numeric-header",
        cellEditor: PriceEditor,
        cellStyle: { textAlign: "right" },
        cellEditorParams: { keepInvalidValue: true },
        valueFormatter: maxFormatter,
        valueParser: maxParser,
        type: "numberColumn",
        maxWidth: 130,
        minWidth: 100,
        width: 100
      },
      /* {
        headerName: xlate("xmm.portal.fee.calculation"),
        field: "calculationBasis",
        colId: "calculationBasis",
        editable: false,
        valueFormatter: calculationFormatter,
        cellStyle: { textAlign: "right" },
        cellEditorParams: { keepInvalidValue: true },
        maxWidth: 130,
        minWidth: 100,
        width: 100
      },*/
      {
        headerName: xlate("xmm.portal.fee.payTypes"),
        headerClass: "ag-text-header",
        field: "payTypes",
        editable: false,
        cellClass: "editable-cell",
        maxWidth: 250,
        minWidth: 150,
        width: 300,
        sortingOrder: ["asc", "desc"],
        valueFormatter: payTypesFormatter,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: xlate("xmm.portal.fee.fromDate"),
        field: "fromDate",
        headerClass: "ag-text-header",
        suppressSizeToFit: true,
        maxWidth: 150,
        width: 150,
        valueFormatter: stringToDate,
        comparator: datetimeComparator,
        filter: "agDateColumnFilter",
        filterParams: {
          debounceMs: 500,
          buttons: ["clear"],
          suppressAndOrCondition: true,
          comparator(filterLocalDateAtMidnight, cellValue) {
            if (cellValue == null) {
              return 0;
            }
            const date = cellValue.substring(0, 10);
            const dateParts = date.split("-");
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: xlate("xmm.portal.fee.expiryDate"),
        field: "expiryDate",
        headerClass: "ag-text-header",
        suppressSizeToFit: true,
        maxWidth: 150,
        width: 150,
        valueFormatter: stringToDate,
        comparator: datetimeComparator,
        filter: "agDateColumnFilter",
        filterParams: {
          debounceMs: 500,
          buttons: ["clear"],
          suppressAndOrCondition: true,
          comparator(filterLocalDateAtMidnight, cellValue) {
            if (cellValue == null) {
              return 0;
            }
            const date = cellValue.substring(0, 10);
            const dateParts = date.split("-");
            const year = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const day = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: xlate("xmm.portal.fee.tax"),
        headerClass: "ag-text-header",
        field: "applyTax",
        editable: false,
        cellClass: "editable-cell",
        maxWidth: 500,
        minWidth: 300,
        width: 300,
        sortingOrder: ["asc", "desc"],
        refData: YesNoMap,
        valueFormatter(params) {
          return params.value === 1 ? "Yes" : "No";
        },
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        enableRowGroup: false
      },
      {
        headerName: "",
        field: "",
        pinned: "right",
        /* editable: true, */
        cellClass: "editable-cell",
        /*cellEditorSelector() {
          return { component: FeeCodeEditor };
        }, */
        cellEditorParams: {
          parentHandle: this
        },
        cellRenderer: this.renderMoreButton,
        enableRowGroup: false,
        maxWidth: 61,
        minWidth: 61,
        width: 61,
        filter: false,
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        hide: serviceId
          ? true
          : false ||
            !(
              user.editDiscountsAndFees && enableDMSPlusContent
            )
      }
    ];
    return baseCols;
  }
  handleRowSelected = () => {
    // Do Nothing
  };

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    // console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerPartId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }

  render() {
    const { serviceId, showFeeGridSpeedBump, showDeleteConfirmation, user } =
      this.state;
    const { localeStrings,getClassWithBridgeBar } = this.context;
    const contextValue = {
      selectionlist: this.state.selectionlist,
      dealerPartId: this.state.dealerPartId,
      appContext: this.context,
      updateGridAfterBulkEdit: this.updateGridAfterBulkEdit
    };
    const deleteModal = showDeleteConfirmation ? (
      this.renderDeleteFeeConfirmation()
    ) : (
      <></>
    );
    const dirtyPopup = (
      <DirtyCheckPopup
        showDirtyModal={showFeeGridSpeedBump}
        title={xlate("xmm.portal.speed_bump.title")}
        message={xlate("xmm.portal.speed_bump.message")}
        okText={xlate("xmm.portal.speed_bump.discard")}
        cancelText={xlate("xmm.portal.speed_bump.stay_on_page")}
        cancelAction={() => this.setState({ showFeeGridSpeedBump: false })}
        discardAction={() =>
          this.setState({ showSlide: false, feesModified: false })
        }
      />
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="mainGrid" className={getClassWithBridgeBar("ag-grid-container")}>
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.rowData}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            enableCellChangeFlash={true}
            onFilterChanged={this.onFilterChanged}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const clsServiceId = serviceId ? "hidden" : "";
    const contentFeeTitlePopover = (
      <div>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.tab.fee.title_tool_tip"]}
        </p>
      </div>
    );
    const header = (
      <React.Fragment>
        {/* {importDMSPartsPricingModal} */}
        <div className="content-header">
          <h3 className="xmm-main-title">
            <div className="title-fee-container">
              {this.state.pageTitle}
              <div className={`xmm-header-info ${!serviceId ? "hidden" : ""}`}>
                <Popover
                  htmlId="popoverTitleDiscount"
                  popoverContent={contentFeeTitlePopover}
                  trigger={["click", "outsideClick"]}
                >
                  <IconInfoOutline
                    htmlId="popoverTitleDiscountInfo"
                    className="hand-cursor"
                  />
                </Popover>
              </div>
            </div>
          </h3>
          <div className="xmm-form-header">
            <div className="float-right">{statusHtml}</div>
            <Button
              htmlId="addActionBtn"
              buttonStyle="secondary"
              disabled={this.state.disableAction}
              onClick={this.addActionSlider}
              className={`float-right ${clsServiceId}`}
              hidden={!user.editDiscountsAndFees}
            >
              {this.addFeeLabel}
            </Button>
            <div className="xmm-input-search float-right">
              <input
                type="text"
                id="fee-search-box"
                className="xmm-input"
                placeholder={xlate("xmm.portal.common.search_label")}
                onChange={this.onSearchBoxChanged}
                value={this.state.searchKey}
                autoComplete="off"
              />
            </div>
            <Dropdown
              icon={<IconMore />}
              id="fluidsPricingActionBtn"
              htmlId="fluidsPricingActionBtn"
              name="fluidsPricingActionBtn"
              className={`xmm-dotted-dropdown btn--icon ${clsServiceId}`}
              buttonStyle="link"
              displayCaret={false}
              size="small"
              pullRight
              options={[
                {
                  label: xlate("xmm.portal.common.import_fluids_pricing"),
                  value: "importDMSPartsPricing",
                  onSelect: this.openImportPartsModal
                },
                {
                  label: xlate("xmm.portal.common.clear_filters"),
                  value: "clear-filters",
                  onSelect: this.clearFilters
                }
              ]}
            />
          </div>
        </div>
      </React.Fragment>
    );
    const modalTitle = <span>{this.state.operationName}</span>;
    const formSlider = !serviceId && (
      <GenericSlider
        title={modalTitle}
        htmlId="editCommonSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        className={getClassWithBridgeBar("xmm-slider")}
      >
        <div>
          <AddFeesFormPage
            key={"editSlider"}
            ref={this.payTypeFormRef}
            rowRecord={this.state.rowRecord}
            editForm={true}
            fees={this.state.fees}
            hideSlider={this.hideSlider}
            updateFeesAfterSave={this.updateFeesAfterSave}
            onModified={() => this.setState({ feesModified: true })}
          />
        </div>
      </GenericSlider>
    );

    return (
      <React.Fragment>
        <FeePageContext.Provider value={contextValue}>
          {header}
          {gridWidget}
          {formSlider}
          {dirtyPopup}
          {deleteModal}
        </FeePageContext.Provider>
      </React.Fragment>
    );
  }
}

function maxFormatter(params) {
  if (!params.value) return "";
  return `$${params.value}`;
}
function maxParser(params) {
  if (!params.newValue) return "";
  let value = params.newValue;

  value = String(value);
  if (value.startsWith("$")) {
    value = value.slice(1);
  }
  return parseFloat(value);
}

function amountFormatter(params) {
  if (!params.value) return "";
  if (
    params.data.feesType !== "Dollar" &&
    params.data.feesType !== "Actual Retail"
  ) {
    return "";
  }
  return `$${params.value}`;
}
function amountParser(params) {
  let value = params.newValue;
  if (value == null || value === "") {
    return null;
  }
  if (params.data.feesType !== "Dollar") return "";

  value = String(value);
  if (value.startsWith("$")) {
    value = value.slice(1);
  }
  return parseFloat(value);
}
// function calculationFormatter(params) {
//   if (!params.value) return "";

//   if (params.data && params.data.feesType === "Percent") {
//     return `${params.value}`;
//   }
//   return "";
// }
function percentageFormatter(params) {
  if (!params.value) return "";

  if (params.data && params.data.feesType === "Percent") {
    return `${params.value}%`;
  }
  return "";
}
function percentageParser(params) {
  let value = params.newValue;
  if (value == null || value === "") {
    return "";
  }
  if (params.data.feesType !== "Percent") return "";

  value = String(value);
  if (value.endsWith("%")) {
    value = value.slice(1);
  }
  return parseFloat(value);
}
function countRenderer(params) {
  let countBadge = "";
  if (!params.value) return countBadge;
  if (params.data && params.data) {
    const value = params.value;
    countBadge = (
      <div>
        <span>{value}</span>
      </div>
    );
  }
  return countBadge;
}
function payTypesFormatter(params) {
  let payTypesOrder = ["C", "I", "S", "W"];
  let value = params.value ? params.value.split(",") : "";
  value.sort((a, b) => payTypesOrder.indexOf(a) - payTypesOrder.indexOf(b));
  value.join(", ");
  return value;
}
export default FeePage;

FeePage.propTypes = {
  serviceId: PropTypes.string
};
FeePage.defaultProps = {
  serviceId: null
};

/* eslint-enable no-console */
