/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import PreviewContext from "../../preview-context";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  // Cell,
  Brush,
  Bar
} from "recharts";
import ChartTooltip from "./ChartTooltip";

export default class ChartPreview extends Component {
  static contextType = PreviewContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      const { data } = nextProps;
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("chartDataChanged", {
            detail: { data: nextProps.data },
            bubbles: true,
            cancelable: true
          })
        );
      }, 200);
      return { data };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.handleChartDataChanged = this.handleChartDataChanged.bind(this);
    this.selectMenuPackage = this.selectMenuPackage.bind(this);
    this.selectMenuPackageByServicePoint =
      this.selectMenuPackageByServicePoint.bind(this);
    this.selectMenuPackageByCursor = this.selectMenuPackageByCursor.bind(this);
    this.loadChartData = this.loadChartData.bind(this);
    const { localeStrings } = context.appContext;
    this.initializeLocaleValues(localeStrings);
    this.state = {
      data: props.data,
      left: 0,
      right: -1000,
      loadChartData: this.loadChartData
    };
  }
  componentDidMount() {
    window.addEventListener(
      "chartDataChanged",
      this.handleChartDataChanged,
      false
    );
    this.loadChartData();
  }
  componentWillUnmount() {
    window.removeEventListener(
      "chartDataChanged",
      this.handleChartDataChanged,
      false
    );
  }
  initializeLocaleValues(localeStrings) {
    this.menuNotAvail =
      localeStrings["xmm.portal.preview.vehicle.menu_not_available"];
    this.menuPackagesNotAvail =
      localeStrings["xmm.portal.preview.vehicle.menu_packages_not_available"];
  }
  handleChartDataChanged = event => {
    const { data } = event.detail;
    this.loadChartData(data);
  };
  loadChartData = () => {
    const { data } = this.props;
    const { servicePoints, sortedMenuTypes } = data;
    if (!servicePoints || servicePoints.length === 0) {
      this.setState({
        chartData: [],
        sortedMenuTypes,
        legendToKeyMap: {},
        keyToLegendMap: {},
        mileageRange: 10000,
        mileageWidth: 10,
        seriesCount: 0
      });
      return;
    }
    // console.log("servicePoints", servicePoints);
    // set up key to legend and legend to key maps
    const legendToKeyMap = {};
    const keyToLegendMap = {};
    for (let index = 0; index < sortedMenuTypes.length; index++) {
      const menuType = sortedMenuTypes[index];
      const legend = menuType.description;
      const key = `key${index + 1}`;
      legendToKeyMap[legend] = key;
      keyToLegendMap[key] = legend;
    }
    // set up series into the mileage
    const chartData = servicePoints.map(servicePoint => {
      const mileage =
        parseFloat(servicePoint.mileage / 1000, 10).toString() + "K";

      const dataPoint = { mileage, servicePoint };
      const { menuTypes } = servicePoint;
      menuTypes.forEach(menuType => {
        const legend = menuType.description;
        const key = legendToKeyMap[legend];
        const { selectableTotalPrice } = menuType;
        dataPoint[key] = selectableTotalPrice;
      });
      return dataPoint;
    });
    // console.log("chartData", chartData);
    const minMileages = chartData[0].servicePoint.mileage;
    const maxMileages = chartData[chartData.length - 1].servicePoint.mileage;
    const mileageRange = maxMileages - minMileages;
    const left = 0;
    const right = 0;
    const mileageWidth = parseInt(mileageRange / chartData.length, 10);
    const seriesCount = Object.keys(keyToLegendMap).length;

    // console.log(minMileages, maxMileages, mileageRange, left, right);

    this.setState({
      chartData,
      legendToKeyMap,
      keyToLegendMap,
      mileageRange,
      mileageWidth,
      seriesCount,
      left,
      right
    });
  };

  getChartKeys = keyToLegendMap => {
    if (!keyToLegendMap) {
      return [];
    }
    const keys = Object.keys(keyToLegendMap);
    const sortedKeys = keys.sort((a, b) => {
      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    });
    return sortedKeys;
  };

  getChartLegends = keyToLegendMap => {
    if (!keyToLegendMap) {
      return [];
    }
    const sortedKeys = this.getChartKeys(keyToLegendMap);
    const sortedLegends = sortedKeys.map(key => {
      return keyToLegendMap[key];
    });
    return sortedLegends;
  };

  renderRechartBrush(totalPackages) {
    return totalPackages > 60 ? (
      <Brush
        dataKey="mileage"
        height={12}
        startIndex={0}
        endIndex={17}
        travellerWidth={2}
      />
    ) : null;
  }

  // Begin Recharts methods
  renderRechartBars(keyToLegendMap) {
    if (!keyToLegendMap) {
      return null;
    }
    const fills = {
      key1: "#96BDFF",
      key2: "#173A78",
      key3: "#83F279",
      key4: "#298C20"
    };
    // const { chartData } = this.state;
    const keys = Object.keys(keyToLegendMap);
    return keys.map(key => {
      return (
        <Bar
          key={key}
          name={keyToLegendMap[key]}
          dataKey={key}
          cursor="pointer"
          // label={keyToLegendMap}
          fill={fills[key]}
          // onClick={this.selectMenuPackage}
        >
          {/*
          {chartData.map((d, index) => {
            return (
              <Cell
                key={`cell-${key}`}
                stroke={fills[key]}
                storkeWidth={4}
                onClick={event => {
                  console.log(event);
                }}
              />
            );
          })}
          */}
        </Bar>
      );
    });
  }

  renderReferenceArea = (currentMileage, nextMileage) => {
    // console.log("this.state", this.state);
    return !currentMileage ? (
      ""
    ) : (
      <ReferenceArea
        // className="selected-bar-effect"
        cursor="pointer"
        x1={currentMileage}
        x2={nextMileage}
        y1={0}
        // y2={undefined} - default to height of the max value in Y-axis
        //
        fill="black"
        fillOpacity={0.05}
        stroke="black"
        strokeWidth={1}
        strokeOpacity={0.2}
        // onClick={event => {
        //   console.log("event", event);
        // }}
      />
    );
  };

  updateSelection = currentMileage => {
    const { chartData } = this.state;
    let nextMileage = "";
    for (let index = 0; index < chartData.length; index++) {
      if (chartData[index].mileage === currentMileage) {
        nextMileage =
          index + 1 >= chartData.length
            ? undefined // default to max x value
            : chartData[index + 1].mileage;
      }
    }
    this.setState({ currentMileage, nextMileage });
  };

  selectMenuPackage = event => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    const { servicePoint } = event;
    this.selectMenuPackageByServicePoint(servicePoint, event.mileage);
    console.log("selecting a service point:", event);
  };

  selectMenuPackageByCursor = event => {
    if (event) {
      const { activePayload } = event;
      if (activePayload && activePayload.length !== 0) {
        const { payload } = activePayload[0];
        const { mileage, servicePoint } = payload;
        this.selectMenuPackageByServicePoint(servicePoint, mileage);
      }
    }
    console.log(event);
  };
  selectMenuPackageByServicePoint = (servicePoint, mileageLabel) => {
    const { mileage } = servicePoint;
    const { updateCurrentMileage } = this.context.appContext;
    this.updateSelection(mileageLabel);
    updateCurrentMileage(mileage.toString());
  };

  createCustomTooltip = () => {
    const { dealer, localeStrings } = this.context.appContext;
    const { defaultUnits } = dealer;
    const mileageUnitKey =
      defaultUnits === "km"
        ? "xmm.portal.common.mileages.unit.kilometer"
        : "xmm.portal.common.mileages.unit.miles";
    return <ChartTooltip mileageUnit={localeStrings[mileageUnitKey]} />;
  };

  createCustomCursor = () => {
    return (
      <div
        onClick={event => {
          console.log("event", event);
        }}
      >
        Testing
      </div>
    );
  };

  valueFormatter = value => {
    return "$".concat(value);
  };

  labelFormatter = value => {
    return value.concat(" ", "Menus");
  };

  render() {
    const {
      chartData,
      sortedMenuTypes,
      keyToLegendMap,
      seriesCount,
      currentMileage,
      nextMileage
    } = this.state;
    const totalPackages = (chartData ? chartData.length : 0) * seriesCount;
    // console.log(
    //   "totalPackages",
    //   totalPackages,
    //   "keyToLegendMap",
    //   keyToLegendMap
    // );
    const errorMessage =
      sortedMenuTypes && sortedMenuTypes.length === 0
        ? this.menuNotAvail
        : chartData && chartData.length === 0
        ? this.menuPackagesNotAvail
        : "";
    const customTooltip = this.createCustomTooltip();
    // const customCursor = this.createCustomCursor();
    // const chartWidth = Math.floor(previewBodyBoundingBox.width) - 50;
    const rechartBars = this.renderRechartBars(keyToLegendMap);
    const referenceArea = this.renderReferenceArea(currentMileage, nextMileage);
    const brush = this.renderRechartBrush(totalPackages);
    const barGraph =
      chartData && chartData.length > 0 ? (
        keyToLegendMap ? (
          <React.Fragment>
            <ResponsiveContainer width="95%" height="100%">
              <BarChart
                data={chartData}
                barCategoryGap="5"
                barGap="1"
                barSize="20"
                // cursor="pointer"
                maxBarSize={44}
                margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
                onClick={this.selectMenuPackageByCursor}
              >
                <CartesianGrid vertical={false} strokeDasharray="8 4" />
                {referenceArea}
                <Tooltip
                  cursor={{
                    fill: "black",
                    fillOpacity: 0.25,
                    stroke: "black",
                    strokeWidth: 1,
                    strokeOpacity: 0.2
                  }}
                  // cursor={customCursor}
                  content={customTooltip}
                  triggerEventType={"click"}
                  // onClick={this.selectMenuPackage}
                />
                {rechartBars}
                <XAxis dataKey="mileage" tickLine={false} />
                <YAxis
                  label={{
                    value: "Dollars",
                    angle: -90,
                    position: "insideLeft"
                  }}
                  tickLine={false}
                  // hide
                />
                {brush}
              </BarChart>
            </ResponsiveContainer>
          </React.Fragment>
        ) : null
      ) : (
        <div className="xmm-content-adjust">
          {" "}
          <b>{errorMessage}</b>
        </div>
      );
    return <React.Fragment>{barGraph}</React.Fragment>;
  }
}

ChartPreview.propTypes = {
  data: PropTypes.object
};
