import React from "react";
export const PartsPricingContext = React.createContext({
  // localeStrings: {},
  selectionlist: [],
  dealerPartId: "",
  /* Implement these handlers in context provider scope */
  updateGridAfterSave: () => {},
  updateGridAfterBulkEdit: () => {},
  deleteSingleOperation: () => {}
});
