/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import Tooltip from "@cx/ui/Tooltip";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import ConsumerMenusRow from "./ConsumerMenusRow";
import { AppContext } from "../../../../app-context";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";

class ConsumerMenus extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.consumerMenusRows = [];
    this.state = {};
  }
  componentDidMount() {}

  // This handler calls its parent handler to autosave record field changes
  onUpdate = (catalog, name, payload) => {
    // console.log("PriceRound onUpdate", name, payload);
    this.props.onSave(catalog, name, payload, "consumerMenusRef");
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
  };
  callChildMethod = (msg, type, close, errorInTooltip) => {
    this.updateStatusBox(msg, type, close, errorInTooltip);
  };

  /* These Utils used for form validation style */
  updateStatusBox = (msg, type, close, errorInTooltip) => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  };
  render() {
    const { dealerCatalogs, localeStrings } = this.context;
    let resultTable = null;
    let catalogHtml = null;
    const dealerNameTip =
      localeStrings["xmm.portal.settings.dealer_make_name_tip"];
    if (dealerCatalogs && dealerCatalogs.length !== 0) {
      catalogHtml = dealerCatalogs.map((record, index) => {
        return (
          <ConsumerMenusRow
            key={"consumer-" + record.make}
            ref={ref => (this.consumerMenusRows[index + 1] = ref)}
            catalog={record}
            onUpdate={this.onUpdate}
          />
        );
      });

      resultTable = (
        <Grid className="setting-table">
          <Row>
            <Col md={2} sm={2}>
              &nbsp;
            </Col>
            <Col md={10} sm={10} className="xmm-form-content xmm-form-header">
              <div className="xmm-fixed-col">
                {localeStrings["xmm.portal.settings.launch_menus_app_lbl"]}
              </div>
              <div className="xmm-input-col">
                {localeStrings["xmm.portal.settings.dealer_make_name_lbl"]}
                <Tooltip
                  htmlId="tipDealerName"
                  tooltipContent={dealerNameTip}
                  position="top"
                >
                  <IconInfoOutline htmlId="IconDealerName" />
                </Tooltip>
              </div>
            </Col>
          </Row>
          {catalogHtml}
        </Grid>
      );
    }
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    return (
      <Card htmlId="consumerCard">
        <div className="settings-card-header">
          <div className="header-left">
            <h4>{localeStrings["xmm.portal.settings.menus_app_lbl"]}</h4>
          </div>
          <span className="float-right">{statusHtml}</span>
        </div>
        {resultTable}
      </Card>
    );
  }
}

export default ConsumerMenus;

ConsumerMenus.propTypes = {
  onSave: PropTypes.func
};
