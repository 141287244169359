import MenuTypesPage from "./MenuTypes/MenuTypesPage";
import DealerMenusPage from "./DealerMenus/DealerMenusPage";
import PackagesPage from "./Packages";
// bundle components into one object and export
const MenuViews = {
  MenuTypesPage,
  DealerMenusPage,
  PackagesPage
};

export default MenuViews;
