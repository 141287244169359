/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import ShopSuppliesRow from "./ShopSuppliesRow";
import { AppContext } from "../../../../app-context";
import StatusBox from "../../../../../commonUtil/components/templates/StatusBox";

class ShopSupplies extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.shopSupplyRows = [];

    this.state = {};
  }
  componentDidMount() {}

  onUpdate = (catalog, name, payload) => {
    // console.log("Shopsupply onUpdate", name, payload);
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    this.props.onSave(catalog, name, payload, "shopSuppliesRef");
  };
  callChildMethod = (msg, type, close, errorInTooltip) => {
    this.updateStatusBox(msg, type, close, errorInTooltip);
  };

  /* These Utils used for form validation style */
  updateStatusBox = (msg, type, close, errorInTooltip) => {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  };
  render() {
    const { dealerCatalogs, localeStrings } = this.context;
    let resultTable = null;
    let catalogHtml = null;
    if (dealerCatalogs && dealerCatalogs.length !== 0) {
      catalogHtml = dealerCatalogs.map((record, index) => {
        return (
          <ShopSuppliesRow
            key={"shopSupply-" + record.make}
            ref={ref => (this.shopSupplyRows[index + 1] = ref)}
            catalog={record}
            onUpdate={this.onUpdate}
          />
        );
      });

      resultTable = (
        <Grid className="setting-table">
          <Row>
            <Col md={2} sm={2}>
              {" "}
              &nbsp;{" "}
            </Col>
            <Col md={10} sm={10} className="xmm-form-content xmm-form-header">
              <div className="xmm-apply-field">
                {localeStrings["xmm.portal.settings.apply_to_lbl"]}{" "}
              </div>
              <div className="xmm-fixed-col left-aligned">
                {" "}
                {localeStrings["xmm.portal.settings.rate_lbl"]}{" "}
              </div>
              <div className="xmm-fixed-col left-aligned">
                {" "}
                {localeStrings["xmm.portal.settings.min_lbl"]}{" "}
              </div>
              <div className="xmm-fixed-col left-aligned">
                {" "}
                {localeStrings["xmm.portal.settings.max_lbl"]}{" "}
              </div>
              <div className="xmm-fixed-col">
                {" "}
                {localeStrings["xmm.portal.settings.taxable_lbl"]}{" "}
              </div>
            </Col>
          </Row>
          {catalogHtml}
        </Grid>
      );
    }
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    return (
      <Card htmlId="ShopSuppliesCard">
        <div className="settings-card-header">
          <div className="header-left">
            <h4>{localeStrings["xmm.portal.settings.shop_supplies_lbl"]}</h4>

            <div className="btn-div" />
          </div>
          <span className="float-right">{statusHtml}</span>
        </div>
        {resultTable}
      </Card>
    );
  }
}

export default ShopSupplies;

ShopSupplies.propTypes = {
  onSave: PropTypes.func
};
