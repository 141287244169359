/* eslint-disable no-console */
import xmmAxios from "./xmmAxios";
/*
   Constants used for the application config
*/
export const DEFAULT_LOCALE = "en_US";
export const DENIED = "DENIED";
export const NOT_AUTHORIZED = "NOT_AUTHORIZED";
export const FULL_ACCESS = "FULL_ACCESS";
export const ACCESS_DEALER_PAGE = "DEALER_SELECTOR";
export const ACCESS_ERROR = "ERROR";
export const APPLICATIONS = {
  CATALOG: "catalog"
};

/**
 * Takes the dealer and user objects as arguments and returns the stringified configuration object that Beamer expects.
 * @param {object} dealer
 * @param {object} user
 * @returns {string} beamerConfigurationObjectString
 */
export function createBeamerConfigurationObjectString(dealer, user) {
  const { dmsType } = dealer;
  const dmsParam = !dmsType ? "" : dmsType.toString().toLowerCase();
  const { trainingRole } = user;
  const roleParam = !trainingRole ? "" : trainingRole.toString().toLowerCase();
  // localhost will be treated as catalog
  const appParam = APPLICATIONS.CATALOG;
  // Expected namespace values: prod, prodaus, dev6, qa6, stg4
  const namespace = xmmAxios.getCatalogManagerNameSpace();
  const envParam = namespace.includes("prod") ? "prod" : "nonprod";
  return `var beamer_config =
    {
      product_id : 'XkaUnoUL56886',
      counter: true,
      alert : true,
      selector: '.beamerTrigger, #callBeamer',
      filter: 'app=${appParam};dms=${dmsParam};role=${roleParam};env=${envParam}'
    };
  `;
}
