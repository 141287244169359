/* eslint-disable no-console */
// custom console-polyfill script add as first line in src/index.js
import { handleConsole } from "../config/console-util";
import "../config/app-polyfill.js";
import React from "react";
import { render } from "react-dom";
// Login Routing Layouts
// import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import AuthorizedRoute from "./components/layouts/AuthorizedRoute";
// import UnauthorizedLayout from "./components/layouts/UnauthorizedLayout";
// import DealerApp from "./components/DealerApp";
import Root from "./components/Root";
import axios from "axios";
import "./index.scss";
import { unregister } from "./registerServiceWorker";
import CxThemeProvider from "@cx/ui/CxThemeProvider";

// import registerServiceWorker from "./registerServiceWorker";
// import { saveState, loadState } from "./store/localStorage";
// import configureStore from "./store/configureStore";
// import throttle from "lodash/throttle";
if (module.hot) {
  module.hot.accept();
}
if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");
}
// Global axios defaults
axios.defaults.baseURL = window.location.origin;
axios.defaults.headers.post["Content-Type"] = "application/json";

// TODO - if we want to load state from localstorage
// const persistedState = loadState();
// Redux store - create store instance first time
/*
const store = configureStore();
if (process.NODE_ENV !== 'production') {
  // debugging purposes
  window.store = store;
}
store.subscribe(
  throttle(() => {
    saveState({
      app: store.getState().app
    });
  }, 1000)
  );
  */
// Reference your top level component directly instead of <BrowserRouter> and the child <Route> below
const ParentApp = () => <Root store={{ state: "react-context" }} />;

/* Uncomment this block to launch Login page and redirect to dashboard based on redux store */
/*
const ParentApp = props => (
  <Provider store={store}>
    <BrowserRouter className="main-layout">
      <div>
        <Switch>
          <Route path="/auth" component={UnauthorizedLayout} />
          <AuthorizedRoute path="/app" component={DealerApp} />
          <Redirect to="/auth" />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>
);
*/
// @params showConsole, {true} console object will be enabled, (keep 'false' in production)
const showConsole = false;
handleConsole(showConsole);
// testConsole();
const rootElement = document.getElementById("root");
render(
  <CxThemeProvider loadCxStylesLast={true}>
    <ParentApp />
  </CxThemeProvider>,
  rootElement
);
// registerServiceWorker();
unregister();

/* eslint-enable no-console */
