import React from "react";
export const PackageContext = React.createContext({
  localeStrings: {},
  webKey: "",
  dealerCode: "",
  editOption: null, // set as edit, add, delete
  rowRecord: {},
  selectionlist: [],
  packages: [],
  menutypes: [], // set menutypes of all makes
  filterConfig: {},
  sortConfig: [],
  /* Implement these handlers in context provider scope */
  updateGridAfterSave: () => {},
  updateGridAfterBulkEdit: () => {},
  deleteSinglePackage: () => {}
});
