/* eslint-disable react/prop-types */
import React from "react";
import IconError from "@cx/ui/Icons/IconError";

const FieldError = ({ error }) =>
  error ? (
    <div className="xmm-input-error-msg">
      <IconError htmlId="IconError" />
      <span>{error}</span>
    </div>
  ) : null;

export default FieldError;
