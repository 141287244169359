/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "./reusable/loadingmask/CustomLoadingOverlay";
import {
  convertToHours,
  hasAllVehicleAttributesSelected,
  hasSelectableVehicleAttributes
} from "./utils/helper";
import NumericEditor from "./editors/NumericEditor";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";

class LaborGrid extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const operationChanged =
      (nextProps.partsAndLabor && !prevState.partsAndLabor) ||
      (!nextProps.partsAndLabor && prevState.partsAndLabor) ||
      nextProps.partsAndLabor.operation !== prevState.partsAndLabor.operation;
    if (
      nextProps.partsAndLabor !== prevState.partsAndLabor ||
      nextProps.rowData !== prevState.rowData ||
      operationChanged
    ) {
      const { partsAndLabor, rowData, additionalColumns, selectedLabors } =
        nextProps;
      const columnDefs = prevState.getColumnList(
        nextProps,
        prevState.parentHandle
      );
      if (!preselectRowScheduled && nextProps.partsAndLabor) {
        preselectRowScheduled = true;
        setTimeout(() => {
          // prevState.adjustGridColumns();
          prevState.parentHandle.preselectRow();
          preselectRowScheduled = false;
        }, 200);
      }
      return {
        partsAndLabor,
        rowData,
        additionalColumns,
        columnDefs,
        selectionlist: operationChanged || !selectedLabors ? [] : selectedLabors
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.onGridReady = this.onGridReady.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    // this.noteCellRenderer = this.noteCellRenderer.bind(this);
    this.adjustGridColumns = this.adjustGridColumns.bind(this);
    this.sizeToFit = this.sizeToFit.bind(this);
    this.setAutoHeight = this.setAutoHeight.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);

    const { rowData, additionalColumns, partsAndLabor, selectedLabors } = props;

    const gridOptions = {
      partsAndLabor,
      // other state props
      pageTitle: "Labor",
      // ag-grid props
      rowData: rowData ? rowData : null,
      additionalColumns: additionalColumns ? additionalColumns : [],
      selectedLabors,
      selectionlist: [], // selectedLabors,
      columnDefs: this.getColumnList(props, this),
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        filter: false,
        filterParams: {
          buttons: ["clear"]
        },
        floatingFilter: false, // true - enable column header filters
        suppressMenu: true,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        numericEditor: NumericEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      rowSelection: "multiple", // allows multiple row selections with check column
      isRowSelectable() {
        return true; // to see checkbox
      },
      columnTypes: {
        nonEditableColumn: { editable: false },
        numberColumn: {
          width: 160,
          filter: "agNumberColumnFilter"
        },
        actionColumn: {
          filter: false,
          editable: false,
          sortable: false,
          suppressMenu: true,
          enableRowGroup: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      domLayout: "autoHeight",
      popupParent: document.body,
      sideBar: false,
      getColumnList: this.getColumnList,
      adjustGridColumns: this.adjustGridColumns,
      parentHandle: this
    };
    this.state = gridOptions;
  }

  componentDidMount() {
    console.log("componentDidMount()...");
  }

  componentWillUnmount() {
    console.log("componentWillUnmount()...");
  }

  getColumnList(props, parentHandle) {
    const { additionalColumns, rowData } = props;
    const { dealerPublishedCatalog } = rowData.length ? rowData[0] : {};
    const columns = [
      {
        headerName: "",
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: "left",
        field: "checked",
        type: "actionColumn",
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true, // hide item in sidebar.columns
        maxWidth: 37,
        minWidth: 37,
        width: 37,
        cellStyle: params => {
          return params.data.dealerPublishedCatalog
            ? { "pointer-events": "none", opacity: "0.4" }
            : {};
        }
      },
      {
        headerName: "Qty",
        headerClass: "ag-text-header",
        pinned: "left",
        field: "quantity",
        editable: dealerPublishedCatalog ? false : true,
        cellEditor: "numericEditor",
        cellEditorParams: { parentHandle, maxLength: 2, maxValue: 10 },
        cellStyle: {
          color: "black",
          textAlign: "left"
        },
        // type: "numberColumn",
        maxWidth: 45,
        minWidth: 45
      },
      {
        headerName: "Description",
        field: "displayName",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell"
      },
      {
        headerName: "Labor hrs",
        field: "laborHours",
        headerClass: "ag-numeric-header",
        cellClass: "xmm-wrap-cell",
        valueFormatter: hrFormatter,
        maxWidth: 60,
        minWidth: 60,
        width: 60
      },
      {
        headerName: "Notes",
        field: "footnote",
        headerClass: "ag-text-header",
        cellRendererFramework: noteCellRenderer,
        maxWidth: 50,
        minWidth: 50,
        hide: dealerPublishedCatalog ? true : false
      }
    ];
    if (additionalColumns && additionalColumns.length !== 0) {
      additionalColumns.forEach(column => {
        const { field, headerName } = column;
        columns.push({
          headerName,
          field,
          headerClass: "ag-text-header"
          // cellClass: "xmm-wrap-cell",
          // maxWidth: 150,
          // minWidth: 120
          // width: 120
        });
      });
    }
    return columns;
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      this.adjustGridColumns();
      this.preselectRow();
    }, 200);
    this.gridApi.closeToolPanel();
  };
  setAutoHeight = () => {
    // this.gridApi && this.gridApi.setDomLayout("autoHeight");
    // document.querySelector("#laborGrid").style.height = "";
    const { domLayout } = this.state;
    const { rowData } = this.props;
    if (rowData) {
      const newDomLayout = rowData.length < 8 ? "autoHeight" : "";
      if (domLayout !== newDomLayout) {
        this.gridApi.setDomLayout(newDomLayout);
        this.setState({ domLayout: newDomLayout });
      }
    }
  };
  sizeToFit = () => {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  };
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      let selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows && selectedRows.length > 1) {
        // make only one selected labor
        const { selectionlist } = this.state;
        const newSelectedRows = [];
        selectedRows.forEach(item => {
          if (selectionlist.includes(item)) {
            const node = this.gridApi.getRowNode(item.id);
            if (node) {
              node.setSelected(false);
            }
            item.manuallySelected = false;
          } else {
            item.manuallySelected = true;
            newSelectedRows.push(item);
          }
        });
        selectedRows = newSelectedRows;
      } else {
        const { rowData } = this.state;
        rowData.forEach(item => {
          item.manuallySelected = selectedRows.includes(item);
        });
      }
      const { onSelectRow } = this.props;
      const params = {
        force: true,
        columns: ["quantity"]
      };
      this.gridApi.refreshCells(params);
      onSelectRow(selectedRows);
      this.setState({ selectionlist: selectedRows });
    }
  };
  /* "filterChanged" - listen to the column filter events; can be used to  clear column filters */
  onFilterChanged = () => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  /* Un-select all rows, regardless of filtering from grid */
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  getRowNodeId(data) {
    return data.id;
  }
  adjustGridColumns() {
    this.sizeToFit();
    this.setAutoHeight();
    this.applySortConfig();
  }
  preselectRow() {
    const { rowData, selectionlist } = this.state;

    if (rowData && !rowData.length) {
      return;
    }

    const { partsAndLabor } = this.props;

    const { selectedVehicleAttributeMap, selectableVehicleAttributes } =
      partsAndLabor;

    if (
      hasSelectableVehicleAttributes(selectableVehicleAttributes) &&
      !hasAllVehicleAttributesSelected(
        selectedVehicleAttributeMap,
        selectableVehicleAttributes.length
      )
    ) {
      if (this.gridApi && selectionlist && selectionlist.length !== 0) {
        selectionlist.forEach(item => {
          const node = this.gridApi.getRowNode(item.id);
          if (node) {
            node.setSelected(false);
          }
        });
      }
      return;
    }

    if (this.gridApi && rowData && rowData.length === 1) {
      this.gridApi.forEachNode(node => {
        node.setSelected(true);
      });
    } else if (selectionlist && selectionlist.length !== 0) {
      selectionlist.forEach(item => {
        const node = this.gridApi.getRowNode(item.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }
  resetSelection() {
    const { selectionlist } = this.state;
    if (selectionlist && selectionlist.length !== 0) {
      selectionlist.forEach(item => {
        const node = this.gridApi.getRowNode(item.id);
        if (node) {
          node.setSelected(false);
        }
      });
    }
  }
  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "displayName",
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  renderPageTitle(rowData) {
    let pageTitle = "Labor";
    if (rowData && rowData.length > 0) {
      pageTitle = "Labor (" + rowData.length + ")";
    }
    return pageTitle;
  }
  render() {
    const { rowData } = this.state;
    const gridClassname =
      rowData && rowData.length < 8
        ? "ag-grid-container ops-auto-height no-striped-grid ops-scroll-x-hidden ag-theme-balham"
        : "ag-grid-container ops-labor-grid no-striped-grid ops-scroll-x-hidden ag-theme-balham";
    const pageTitle = this.renderPageTitle(rowData);
    return (
      <React.Fragment>
        <h4>{pageTitle}</h4>
        <div id="laborGrid" className={gridClassname}>
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            getRowNodeId={this.getRowNodeId}
            rowData={rowData}
            rowSelection={this.state.rowSelection}
            multiSortKey={this.state.multiSortKey}
            singleClickEdit={false}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            statusBar={this.state.statusBar}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            onFilterChanged={this.onFilterChanged}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            isRowSelectable={this.state.isRowSelectable}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            domLayout={this.state.domLayout}
            popupParent={this.state.popupParent}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default LaborGrid;

LaborGrid.propTypes = {
  partsAndLabor: PropTypes.object,
  selectedLabors: PropTypes.array,
  rowData: PropTypes.array,
  additionalColumns: PropTypes.array,
  onSelectRow: PropTypes.func
};
LaborGrid.defaultProps = {
  selectedLabors: [],
  rowData: [],
  additionalColumns: [],
  onSelectRow: () => {
    // default empty function
  }
};

let preselectRowScheduled = false;

function hrFormatter(params) {
  if (!params || !params.data) {
    return "";
  }
  const { laborHours } = params.data;
  if (laborHours === 0){
    return 0
  }
  return !laborHours ? "" : convertToHours(laborHours, 1);
}

function renderNotes(notes) {
  if (notes && Array.isArray(notes) && notes.length !== 0) {
    if (notes.length === 1) {
      return <div>{notes[0]}</div>;
    } else {
      const list = notes.map((note, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`key${i}`}>{note}</li>
      ));
      return <ul>{list}</ul>;
    }
  }
  return "";
}
function noteCellRenderer(params) {
  const { id, notes } = params.data;
  const label = renderNotes(notes);
  return label === "" ? (
    ""
  ) : (
    <div className="ops-notes">
      <Tooltip
        htmlId={`id_${id}`}
        tooltipContent={label}
        className="list-tooltip"
      >
        <IconInfoOutline className="info" />
      </Tooltip>
    </div>
  );
}

/* eslint-enable no-console */
